import {connect} from 'react-redux'
import {fetchVehicle} from '../redux/fleet'
import Vehicle from "../pages/Vehicle";


function mapStateToProps(state) {
  const { vehicle, selected } = state.fleet;
  return { vehicle, selected };
}

const mapDispatchToProps = dispatch => {
  return {
    fetchVehicle: (fleetId, vehicleId) => {
      dispatch(fetchVehicle(fleetId, vehicleId));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Vehicle);
