/**
 * Format a duration in seconds to a human readable format using the notion
 * "h+:mm:ss", e.g. "4:40:78". Negative durations are preceeded by "-".
 *
 * @param t Duration in seconds
 * @return The formatted duration string
 */
import moment from "moment";
var readableDuration = (function() {

    // Each unit is an object with a suffix s and divisor d
    var units = [
        {s: '', d: 1}, // Seconds
        {s: ':', d: 60}, // Minutes
        {s: ':', d: 60}, // Hours
    ];

    // Closure function
    return function(t) {
        t = parseInt(t); // In order to use modulus
        var trunc, n = Math.abs(t), i, out = []; // out: list of strings to concat
        for (i = 0; i < units.length; i++) {
            n = Math.floor(n / units[i].d); // Total number of this unit
            // Truncate e.g. 26h to 2h using modulus with next unit divisor
            if (i+1 < units.length) // Tweak substr with two digits
                trunc = ('00'+ n % units[i+1].d).substr(-2, 2); // …if not final unit
            else
                trunc = n;
            out.unshift('' + trunc + units[i].s); // Output
        }
//        if (t < 0) ? out.unshift('-') : null; // Handle negative durations
        if (t < 0) {
            out.unshift('-')    // Handle negative durations
        }
        return out.join('');
    };
})();

export const secondsToHms = (d) => {
//    d = Number(d);
//    var h = Math.floor(d / 3600);
//    var m = Math.floor(d % 3600 / 60);
//    var s = Math.floor(d % 3600 % 60);
//    return ((h > 0 ? h + ":" + (m < 10 ? "0" : "") : "") + m + ":" + (s < 10 ? "0" : "") + s);

    // console.log( "converting to hms" + d)

    return readableDuration(d);
}

export const minutesToHoursAndMinutes = (minutesToConvert, t) => {
    const timeObject = moment.duration(minutesToConvert, "minutes");
    const hours = isPlural(timeObject.hours(), t("commons.hour"));
    const minutes = isPlural(timeObject.minutes(), t("commons.minute"));
    return `${hours} ${minutes}`;
}

const isPlural = (number, translation) => {
    if (!number) return "";
    else if (number === 1) return `${number} ${translation}`;
    else return `${number} ${translation}s`;
}

