import {connect} from 'react-redux'
import ExpensesConfig from "../pages/ExpensesConfig";
import {fetchCreditCards, getExpensesAlertsConfiguration, updateFleetExpenseAlertConfig} from '../redux/fleet'

function mapStateToProps(state) {
  const { selected, fleetDetail, expenses, loading, creditCards, expensesAlertsConfig } = state.fleet;
  return { selected, fleetDetail, expenses, loading, creditCards, expensesAlertsConfig};
}

const mapDispatchToProps = dispatch => {
  return {
    fetchCreditCards: (fleetId) => {
      dispatch(fetchCreditCards(fleetId));
    },
    getExpensesAlertsConfiguration: (fleetId) => {
      dispatch(getExpensesAlertsConfiguration(fleetId));
    },
    updateFleetExpenseAlertConfig: (fleetId, type, amount) => {
      dispatch(updateFleetExpenseAlertConfig(fleetId, type, amount));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpensesConfig);
