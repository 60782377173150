import {CRT_CLOSED, CRT_DELETED, CRT_OPEN} from "../../pages/Crts";
import React, {Component} from 'react';
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";


class CrtStatus extends Component {

    render() {
      let {crt, t} = this.props;
      return (
          <div>
           {crt.status === CRT_OPEN && <span className="tag is-primary">{t('crtsTable.status.open')}</span>}
           {crt.status === CRT_CLOSED && <span className="tag is-warning">{t('crtsTable.status.closed')}</span>}
           {crt.status === CRT_DELETED && <span className="tag is-danger">{t('crtsTable.status.deleted')}</span>}
          </div>
      )
    }

}

export default withRouter(withTranslation('translation')(CrtStatus));
