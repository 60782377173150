import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import connect from "react-redux/es/connect/connect";
import ComplexMap from "../maps/ComplexMap";
import {getVehicleTrips} from "../../redux/vehicle";
import Loading from "../Loading";


const Message = ({title, body1, body2}) => (
  <div className="columns margin-top-15x">
    <div className="message is-warning column is-6 is-offset-3">
      <div className="message-header">{title}</div>
      <div className="message-body"><strong>{body1}</strong> {body2}</div>
    </div>
  </div>
);

class TabVehicleLive extends Component {

  componentWillMount() {
    const {fleetId, vehicleId, selected} = this.props;
    if (selected.show_routes || selected.rt_tracking) {
      this.props.getVehicleTrips(fleetId, vehicleId);
    }
  }

  render() {
    const {t, selected, vehicleTrips} = this.props;

    return (
      <div>
        {!vehicleTrips && <Loading wait={0}/>}

        <div className="live-vehicle-map-wrapper">
          <ComplexMap trips={vehicleTrips} showAlerts={false}
                      showTrips={selected.show_routes} showLastLocation={selected.rt_tracking} />

          <div className="floating-messages-wrapper">

            {selected && !selected.rt_tracking &&
              <Message title={t('commons.upSell.liveDisabled.title')}
                       body1={t('commons.upSell.liveDisabled.body-1')}
                       body2={t('commons.upSell.liveDisabled.body-2')}/>
            }

            {selected && !selected.show_routes &&
              <Message title={t('commons.upSell.routesDisabled.title')}
                       body1={t('commons.upSell.routesDisabled.body-1')}
                       body2={t('commons.upSell.routesDisabled.body-2')} />
            }

            {selected && selected.show_routes && vehicleTrips && vehicleTrips.length === 0 &&
                <Message title={t('tabVehiclesLive.noTripsMessage.title')}
                         body1={t('tabVehiclesLive.noTripsMessage.body')}/>
            }

          </div>
        </div>

      </div>
    );
  }
}


const mapDispatchToProps = dispatch => {
  return  {
    getVehicleTrips: (fleetId, vehicleId) => {
      dispatch(getVehicleTrips(fleetId, vehicleId))
    },
  }
};

function mapStateToProps(state) {
  const { selected } = state.fleet;
  const { vehicleTrips } = state.vehicle;
  return { vehicleTrips, selected };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(TabVehicleLive))
