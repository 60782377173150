import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Redirect } from 'react-router'
import Loading from '../components/Loading'
import DriverDescription from "../components/driver/DriverDescription";
import AlertsDescription from "../components/driver/AlertsDescription";
import RadialChart from '../components/charts/RadialChart'
import Evolution from '../components/charts/Evolution'
import TripPathMap from "./TripPathMap";
import SuggestionDescription from "../components/driver/SuggestionDescription";
import TripsTable from "../components/driver/TripsTable";
import {withTranslation} from "react-i18next";
import _ from 'lodash';

class DriverDetail extends Component {

  // componentWillMount() {
  //
  //   const { loading, driverDetail, selected} = this.props
  //   if (!driverDetail) {
  //     if(!loading && selected) {
  //       const { id } = this.props.match.params
  //       this.props.getDriverDetail(selected.id, id);
  //     }
  //   }
  // }

  render() {
    const { driverDetail, selected, driverTrips, tripsLoading, t } = this.props;

    if (_.isEmpty(selected)) {
      return <Redirect to={{ pathname: '/' }}/>;
    }

    const { id } = this.props.match.params;

        // if fleetDetail is null or is not the same that was showed latest
    if (!driverDetail || driverDetail.uid !== id) {
      this.props.getDriverDetail(selected.id, id);
      this.props.getDriverTrips(selected.id, id, new Date());
      return (<Loading/>)
    }

    driverDetail.weekStats.evolution.sort((a, b)=>(
        a.date - b.date
    ));

    return (

      <div className="is-fullwidth" >
        <div className="columns">
          <div className="column">

            <DriverDescription
              name={driverDetail.name}
              id={driverDetail.uid}
              kms={driverDetail.stats.km}
              totalTrips={driverDetail.trips.length}
              average={driverDetail.weekStats.average}
              durationAverage={driverDetail.weekStats.durationAverage}
              total={driverDetail.weekStats.total}
              phone={driverDetail.phone}
            />
          </div>
          <div className="column">
            <AlertsDescription lastLogin={driverDetail.lastLogin}
                               deviceGps={driverDetail.deviceGps}
                               lastTrip={driverDetail.lastTrip}
                               deviceBT={driverDetail.deviceBT}
                               deviceWidget={driverDetail.deviceWidget}
                               app_version={driverDetail.app_version}
                               fleet_privacy={selected.privacy_configuration} />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">{t('driverDetail.todayTrips')}</p>
              </header>
              <div className="card-content" style={{ height: `430px` }}>
                <div className="media">
                  <div className="media-content">
                    <TripPathMap driverTrips={driverTrips||[]} tripsLoading={tripsLoading}
                                 enableMessage={true} mapBottomPosition={0} noDataMessage={selected.show_routes === false && t('driverDetail.tripsDisabled')}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <SuggestionDescription stats={driverDetail.stats}/>
          </div>
        </div>
        <div className="columns">
          <div className="column is-one-quarter">
            <RadialChart title="Puntaje Total" points={[Math.round(driverDetail.stats.total), 100 - Math.round(driverDetail.stats.total)]}/>
          </div>

          <div className="column is-three-quarters">
            <Evolution data={driverDetail.weekStats.evolution}/>
          </div>
        </div>

        <div className="columns no-margin-bottom">
          <div className="column">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">{t('driverDetail.recentTrips')}</p>
              </header>
              <div className="card-content">
                <div className="media">
                  <div className="media-content">
                    <TripsTable trips={driverDetail.trips} alerts={driverDetail.deviceGps.h} fleet={selected}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

DriverDetail.propTypes = {
  getDriverDetail: PropTypes.func.isRequired,
  getDriverTrips: PropTypes.func.isRequired,
  tripsLoading: PropTypes.bool.isRequired,
  driverTrips: PropTypes.array,
  selected: PropTypes.object,
  driverDetail: PropTypes.object
};

export default withTranslation('translation')(DriverDetail);
