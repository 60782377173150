import React, {Component} from 'react';
import { withTranslation } from "react-i18next";

class Warning extends Component {

    render() {
        const {title, message} = this.props;
        return (
            <div className="columns is-centered is-vcentered is-fullwidth">
                <div className="message is-warning column is-half ">
                    <div className="message-header"><p>{title}</p></div>
                    <div className="message-body">{message}</div>
                </div>
            </div>
        )

    }
}

export default withTranslation('translation')(Warning);