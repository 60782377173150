import React from 'react'
import { Radar } from 'react-chartjs-2';
import {withTranslation} from "react-i18next";

import CollapsableCard from '../../components/CollapsableCard'

const options = {
  legend: {
    display: false
  },
  maintainAspectRatio: false
};

const getConfig = ({t, values}) => (
  {
    labels: [t("commons.totalShort"), t("commons.eco"), t("commons.control"), t("commons.focus"), t("commons.caution")],
    datasets: [{
      label: t("home.score"),
      data: values,
      backgroundColor: "rgba(0, 198, 168, 0.2)",
      borderColor: 'rgba(0, 198, 168, 0.5)',
      borderWidth: 1
    }]
  }
);

const RadarChart = (props) => (
  <CollapsableCard title={props.t('home.score')}>
    <Radar data={getConfig(props)} options={options} width={200} height={200} />
  </CollapsableCard>
);

export default withTranslation('translation')(RadarChart);