import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import Timestamp from "react-timestamp";
import classNames from "classnames";


class ActivityStream extends Component {

  state = {
  };

  getMarker = (act) => {
    switch (act.type) {
      case 'expense': return 'fa-money';
      case 'trip': return 'fa-car';
      case 'odometer': return 'fa-tachometer';
      case 'empty': return 'fa-times';
      default: return'';
    }
  };

  render() {
    const {activities, t} = this.props;

    if (activities && activities.length === 0) {
      activities.push({type: "empty", timestamp: new Date().getTime()});
    }

    return (
      <div className="card">
        <header className="card-header">
          <div className="card-header-title">
            <div className="media-left">{t('activityStream.title')}</div>
          </div>
        </header>
        <div className="card-content">
          <div className="timeline is-rtl">
            <header className="timeline-header">
              <span className="tag is-medium is-primary">{t('commons.start')}</span>
            </header>
            {
              activities.map((act, index) => (
                <div className="timeline-item" key={index + '-div'}>
                  <div className="timeline-marker is-icon is-48x48 is-right">
                    <i className={classNames("fa", this.getMarker(act), "fa-2x")}></i>
                  </div>
                  <div className={classNames("timeline-content", {"is-danger": act.type === "empty"})}>
                    <p className="heading"><Timestamp time={act.timestamp / 1000} format="full"/></p>
                    <p>{t(`activityStream.content.${act.type}`, {count: act.extra})}</p>
                  </div>
                </div>
                )
              )
            }
            <div className="timeline-header">
              <span className="tag is-medium is-primary">{t('commons.end')}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation('translation')(ActivityStream));
