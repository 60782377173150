import React, {Component} from "react";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";

class MarkerDeleteModal extends Component {

    render() {
        const {marker, onClose, t} = this.props
        return (
            <div className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">{t('markerTable.delete.title')}</p>
                    </header>

                    <section className="modal-card-body">
                        {t('markerTable.delete.body', {name: marker.name})}
                    </section>
                    <footer className="modal-card-foot">
                        <div className="buttons">
                            <button className="button is-danger"
                                    onClick={() => onClose(true, marker)}>{t('commons.delete')}</button>
                            <button className="button"
                                    onClick={() => onClose(false, marker)}>{t('commons.cancel')}</button>
                        </div>
                    </footer>
                </div>
            </div>
        );
    }
}

export default withRouter(withTranslation('translation')(MarkerDeleteModal));