/**
 * Created by jje-personal on 18/01/2019.
 */
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import DriverSuggestionContainer from '../../containers/DriverSuggestionContainer'
import Loading from '../../components/Loading'


const DAILY= 'Daily';
const WEEKLY = 'Weekly';
const MONTHLY = 'Monthly';

class CardsTable extends Component {

  state = {
    card: null,
    selectedAlert: null,
    editAlert: false,
    changeDriver:false
  }

  getHolderName = (card) => (card.driver !== undefined ? card.driver.name: card.holder)

  editCardAlert = (card, typeIndex, alert) => {
    this.setState({card:card, editAlert:true, editAlertIndex: typeIndex, inputValue: alert.amount})
  }

  editDriver = (card) => {
    this.setState({card:card, changeDriver:true})
  }

  onDriverSelected = (event, driver) => {
    this.setState({driverSelected: driver});
  };

  changeDriver = () => {
    // todo
    const { card, driverSelected, fleet} = this.state
    const { fleetDetail } = this.props

    this.props.changeCardHolder(fleetDetail.id, card.id, driverSelected.uid)

    // card.driver = driverSelected
    // on callback
    this.setState({card:null, driverSelected:null, creditCards:[card], changeDriver: false})

  }

  updateCardAlert = () => {
    const { fleetDetail } = this.props;
    const { card, editAlertIndex, inputValue} = this.state;
    const alert = this.getExpenseAlertConfig(card)[editAlertIndex];
    this.props.addCreditAlertConfiguration(fleetDetail.id, card.id, alert.type, inputValue);

    this.setState({editAlert:false, editAlertIndex:0, card:null})
  }

  updateInputValue = evt => {
    this.setState({
      inputValue: evt.target.value
    });
  }

  getExpenseAlertConfig = (card) => {
    const { expensesAlertsConfig } = this.props
    const alerts = [
      { type: DAILY, amount: 0},
      { type: WEEKLY, amount: 0},
      { type: MONTHLY, amount: 0},
    ];

    if (card) {
      for (let e of alerts) {
        if (expensesAlertsConfig.hasOwnProperty("cards")) {
          alert = expensesAlertsConfig.cards.find(element => element.cardId === card.id && element.type === e.type);
          if (alert) {
            e.amount = alert.amount
          }
        }
      }
    }

    return alerts
  }

  changeDriverPopup = () => {

    const { changeDriver } = this.state
    if (changeDriver) {
      return (
        <div className={changeDriver ? "modal is-active" : "modal"}>
            <div className="modal-background"/>
            <div className="modal-content">
              <div className="box">

                <div className="field">
                  <label className="label is-pulled-left">Cambiar Driver</label>
                  <DriverSuggestionContainer selected={[]} onSelected={this.onDriverSelected} cleanOnSelected={false}/>
                </div>

                <div className="buttons is-right">
                  <div className="button is-danger is-outlined"
                       onClick={() => this.setState({changeDriver: false, card:null})}>
                    <span>Cancelar</span>
                    <span className="icon is-small">
                      <i className="fa fa-times"/>
                    </span>
                  </div>
                  <div className="button is-primary"
                       onClick={() => this.changeDriver()}>
                    <span>Aceptar</span>
                    <span className="icon is-small">
                      <i className="fa fa-check"/>
                    </span>
                  </div>
                </div>

              </div>
            </div>

        </div>
      )
    }
    return
  }

  changeAlertPopup = () => {

    const {t} = this.props
    const { card, editAlert, editAlertIndex } = this.state
    const alerts = this.getExpenseAlertConfig(card)
    if (editAlert) {
      const alert = alerts[editAlertIndex]
      return (
        <div className={editAlert ? "modal is-active" : "modal"}>
            <div className="modal-background"/>
            <div className="modal-content">
              <div className="box">
                <div className="field">
                  <label className="label">{card.id} - {this.getHolderName(card)}</label>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label">
                    <label className="label">{t('expensesConfig.alert.limit.' + alert.type)}</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input className="input" type="number" value={this.state.inputValue} onChange={this.updateInputValue}/>
                      </div>
                      <p className="help pull-left">{t('expensesConfig.alert.help')}</p>
                    </div>

                  </div>

                </div>

                <div className="buttons is-right">
                  <div className="button is-danger is-outlined"
                       onClick={() => this.setState({editAlert: false, card:null, editAlertIndex:0, inputValue:0})}>
                    <span>Cancelar</span>
                    <span className="icon is-small">
                      <i className="fa fa-times"/>
                    </span>
                  </div>
                  <div className="button is-primary"
                       onClick={() => this.updateCardAlert()}>
                    <span>Aceptar</span>
                    <span className="icon is-small">
                      <i className="fa fa-check"/>
                    </span>
                  </div>
                </div>

              </div>
            </div>

        </div>
      )
    }
    return
  }



  render() {
    const { creditCards, t, loading } = this.props;


    if (creditCards === undefined || (creditCards && creditCards.length === 0)) {
      return (
        <div className="message is-success has-text-centered">
          <div className="message-header"><p>{t('cardList.noCards.title')}</p></div>
          <div className="message-body">{t('cardList.noCards.body')}</div>
        </div>
      )
    }

    // const card = {
    //   ...creditCards[0]
    // }
    //
    // var creditCards2 = [card, card, card ,card]

    return (
      <div>
        {loading && <Loading wait={0}/>}
        {this.changeDriverPopup()}
        {this.changeAlertPopup()}

        <div className="columns is-multiline">
          {creditCards.map(((card, index) => (
            <div className="column is-half" key={'c1'+index}>
              <div className="card">
                <div className="card-content">
                  <div className="columns">

                    {/* card image */}
                    <div className="column is-half">
                      <figure className="image is-3by2">
                        <img src={card.photo_url} alt={card.number}/>
                      </figure>
                    </div>

                    {/* alerts config */}
                    <div className="column is-half">
                      <p className="title is-6">
                        {this.getHolderName(card)}
                        <span className="icon" onClick={() => this.setState({card, changeDriver:true})}>
                          <i className="fa fa-edit" />
                        </span>
                      </p>
                      {/*<p className="subtitle is-6">{t('cardList.alertTitle')}</p>*/}
                      { this.getExpenseAlertConfig(card).map(((c, index2) => (
                        <div className="level no-margin-bottom" style={{marginBotton:"0px"}} key={'c2'+index2}>
                          <div className="level-left">
                            <p><strong>{t('expensesConfig.alert.'+ c.type)}</strong> { c.amount > 0 ? " $ " + c.amount: "sin configurar"}</p>
                          </div>

                          <div className="level-right">
                            <p>
                              <span className="icon" onClick={() => this.editCardAlert(card, index2, c)}>
                                <i className="fa fa-edit" />
                              </span>
                            </p>
                          </div>

                        </div>
                      )))}

                    </div>

                  </div>
                </div>
              </div>
            </div>


        )))}
        </div>


      </div>

    );
  }
}

export default withTranslation('translation')(CardsTable);
