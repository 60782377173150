import {connect} from 'react-redux';
import MarkersTable from "../components/RoadMaps/MarkersTable";
import {fetchMarkers, createMarker, deleteMarker, updateMarker} from "../redux/markers";


function mapStateToProps(state) {
  const {markers} = state.markers
  return { markers };
}

const mapDispatchToProps = dispatch => {
  return {
    fetchMarkers: (fleetId) => {
      dispatch(fetchMarkers(fleetId));
    },
    saveMarker: (fleetId, marker) => {
      if (marker.id === 0) {
        dispatch(createMarker(fleetId,marker))
      } else {
        dispatch(updateMarker(fleetId,marker))
      }
    },
    deleteMarker: (fleetId, marker) => {
      dispatch(deleteMarker(fleetId,marker))
    }
  }
};

const MarkersTableContainer = connect(mapStateToProps, mapDispatchToProps)(MarkersTable);

export { MarkersTableContainer }