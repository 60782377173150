import React, {Component} from 'react';
import Loading from '../components/Loading'
import {withTranslation} from "react-i18next";
import {CrtsTableContainer} from '../containers/CrtsContainer'
import _ from "lodash";

export const CRT_OPEN = 1;
export const CRT_CLOSED = 2;
export const CRT_DELETED = 3;

class Crts extends Component {

  state = {
    saytInput: "",
    radioSelected: "all"
  };

  componentWillMount() {
    const {fleetDetail} = this.props;
    this.props.fetchCRTs(fleetDetail.id);
  }


  getFilteredCRTs() {
    const {fleetCrts} = this.props;
    const {saytInput, radioSelected} = this.state;

    let result;

    switch (radioSelected) {
      case "all":
        result = fleetCrts;
        break;
      case "open":
        result = fleetCrts.filter(c => {return c.status === CRT_OPEN});
        break;
       case "closed":
        result = fleetCrts.filter(c => {return c.status === CRT_CLOSED});
        break;
    }

    if (saytInput.length < 3) {
      return result;
    }

    return result.filter(c => {
      let drivers_text = "";
      _.each(c.drivers, d => {drivers_text += d.name + d.email});
      return (c.crt_id + drivers_text).toLocaleLowerCase().includes(saytInput.toLocaleLowerCase());
    });
  }

  render() {
    const { fleetCrts, t} = this.props;

    if (!fleetCrts) {
      return (<Loading wait={0}/>);
    }

    return (
      <div className="is-fullwidth">
        <div className="columns">
          <div className="column">
            <div className="field">
              <div className="control">
                <input className="input" type="text" placeholder={t('crts.sayt')}
                       onChange={(e) => this.setState({saytInput: e.target.value})} />
              </div>
            </div>
          </div>

          <div className="column is-one-third">
            <div className="control vehicle-filter has-text-centered">
              <label className="radio">
                <input type="radio" name="answer"
                       onChange={(e) => this.setState({radioSelected: 'all'})}
                       checked={this.state.radioSelected === 'all'} /> {t('commons.filter.all')}
              </label>
              <label className="radio">
                <input type="radio" name="answer"
                       onChange={(e) => this.setState({radioSelected: 'open'})}
                       checked={this.state.radioSelected === 'open'} /> {t('commons.filter.open')}
              </label>
              <label className="radio">
                <input type="radio" name="answer"
                       onChange={(e) => this.setState({radioSelected: 'closed'})}
                       checked={this.state.radioSelected === 'closed'} /> {t('commons.filter.closed')}
              </label>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <CrtsTableContainer crts={this.getFilteredCRTs()} />
          </div>
        </div>

      </div>
    );
  }
}

export default withTranslation('translation')(Crts);