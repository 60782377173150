import {connect} from 'react-redux'
import DriverSuggestion from "../components/autosuggest/DriverSuggestion";


function mapStateToProps(state) {
  const { fleetDetail } = state.fleet;
  return { fleetDetail };
}

const mapDispatchToProps = dispatch => {
  return {}
};


export default connect(mapStateToProps, mapDispatchToProps)(DriverSuggestion);
