import React from 'react'
import PropTypes from 'prop-types'
import {Line} from 'react-chartjs-2';
import {withTranslation} from 'react-i18next';

import CollapsableCard from '../../components/CollapsableCard'

const getConfig = (data) => ({
  labels: data.map((obj) => (obj.date)),
  datasets:[{
    label: 'Puntaje Total',
    fill: false,
    lineTension: 0.1,
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
    backgroundColor: '#00C6A8',
    borderColor: '#00C6A8',
    pointBorderColor: '#00C6A8',
    pointHoverBackgroundColor: '#00C6A8',
    pointHoverBorderColor: '#00C6A8',
    pointBackgroundColor: '#fff',
    data: data.map((obj) => (Math.round(obj.total)))
  }]
});

const options = {
  legend: {
    display: false
  },
  maintainAspectRatio: false,
  scales: {
    yAxes: [{
        stacked: true
    }]
  }
};

const Evolution = (props) => (
  <CollapsableCard title={props.t('commons.performance')}>
    <Line data={getConfig(props.data)} options={options} width={300} height={200}/>
  </CollapsableCard>
);

Evolution.propTypes = {
  data: PropTypes.array.isRequired
};

export default withTranslation('translation')(Evolution);