import {checkStatus, parseJson} from '../api/utils'
import {BASE_URL} from '../config'

const SET_VEHICLE_DASHBOARD = 'woo/vehicle/SET_VEHICLE_DASHBOARD';
const SET_VEHICLE_TRIPS = 'woo/vehicle/SET_VEHICLE_TRIPS';

const MAKES_FETCH_REQUEST = 'woo/fleet/MAKES_FETCH_REQUEST';
const MAKES_FETCH_REQUEST_SUCCESS = 'woo/fleet/MAKES_FETCH_REQUEST_SUCCESS';

const MODELS_FETCH_REQUEST = 'woo/fleet/MODELS_FETCH_REQUEST';
const MODELS_FETCH_REQUEST_SUCCESS = 'woo/fleet/MODELS_FETCH_REQUEST_SUCCESS';


const initialState = {
  makes: [],
  models: [],
  modelsLoading: false,
  makesLoading: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_VEHICLE_DASHBOARD:
      return {
        ...state,
        vehicleDashboardData: action.vehicleDashboardData
      };
    case SET_VEHICLE_TRIPS:
      return {
        ...state,
        vehicleTrips: action.trips
      };
    case MAKES_FETCH_REQUEST:
      return {
        ...state,
        makesLoading: true
      };
    case MAKES_FETCH_REQUEST_SUCCESS:
      return {
        ...state,
          makesLoading: false,
          makes: action.makes
      };
    case MODELS_FETCH_REQUEST:
      return {
        ...state,
        modelsLoading: true
      };

    case MODELS_FETCH_REQUEST_SUCCESS:
      return {
        ...state,
          modelsLoading: false,
          models: action.models
      };


    default:
      return state;
  }
}

// Action Creators
const setVehicleDashboard = (vehicleDashboardData) => ({ type: SET_VEHICLE_DASHBOARD, vehicleDashboardData });
const setVehicleTrips = (trips) => ({ type: SET_VEHICLE_TRIPS, trips });
const fetchMakesRequested = () => ({ type: MAKES_FETCH_REQUEST });
const fetchMakesRequestedSuccess = (makes) => ({ type: MAKES_FETCH_REQUEST_SUCCESS, makes});
const fetchModelsRequested = () => ({ type: MODELS_FETCH_REQUEST });
const fetchModelsRequestedSuccess = (models) => ({ type: MODELS_FETCH_REQUEST_SUCCESS, models });


export const fetchVehicleDashboard = (fleetId, vehicleId, fromDate, toDate, driver) => {

  return dispatch => {
    dispatch(setVehicleDashboard(null));

    let tz = new Date().getTimezoneOffset() * 60 * -1;
    let token = localStorage.getItem("token");
    let url = `${BASE_URL}/api/fleet/${fleetId}/vehicle/${vehicleId}/dashboard`;
    let params = [`tzOffset=${tz}`];

    if (driver) {
      params.push(`driverId=${driver.uid}`);
    }
    if (fromDate) {
      params.push(`from=${fromDate.unix()}`);
    }
    if (toDate) {
      params.push(`to=${toDate.unix()}`);
    }

    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }

    return fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {
      dispatch(setVehicleDashboard(json));
    });

  }
};

export const getVehicleTrips = (fleetId, vehicleId, date=new Date()) => {
  return dispatch => {
    dispatch(setVehicleTrips(null));

    let token = localStorage.getItem("token");
    let tz = new Date().getTimezoneOffset() * 60 * -1; // server needs this value in seconds, -1 because getTimezoneOffset is negative
    let today = Math.floor(date.getTime() / 1000); // server needs this value in seconds
    const url = `${BASE_URL}/api/fleet/${fleetId}/vehicle/${vehicleId}/polylines?=tzOffset=${tz}&date=${today}`;

    return fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {
      dispatch(setVehicleTrips(json));
    })
  }
};

export const fetchMakes = () => {
  return dispatch => {
  	// const local = localStorage.getItem("makes");
  	// if (local) {
  	// 	dispatch(fetchMakesRequestedSuccess(local));
  	// 	console.log("Fetched makes from cache")
  	// 	return
  	// }

    dispatch(fetchMakesRequested());
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/vehicle/make' ;

    return fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer '+ token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {

	  // localStorage.setItem("makes", JSON.stringify(json));

    dispatch(fetchMakesRequestedSuccess(json));

    }).catch(error =>{
      // console.log("fetchMakes error", error.name, error.message)
    })
  }
};

export const fetchModels = (makeId) => {
  return dispatch => {
    dispatch(fetchModelsRequested());
    let token = localStorage.getItem("token");
    const url = `${BASE_URL}/api/vehicle/make/${makeId}/model`;

    return fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer '+ token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {

    dispatch(fetchModelsRequestedSuccess(json));

    }).catch(error =>{
      // console.log("fetchModels error", error.name, error.message)
    })
  }
};