/**
 * Created by jje-personal on 9/4/17.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import {withTranslation} from "react-i18next";
import { secondsToHms } from '../../utils/functions'

import CollapsableCard from '../CollapsableCard'

class DriverStatsTable extends Component {

  constructor(props) {
    super(props);
    this.state = {width: props.width};
  }

  componentWillMount(){
    this.setState({width: window.innerWidth});
  }

  render() {
    const { drivers, full, t, showST, fleetDetail, showIEVandT } = this.props;
    
    return (
      <CollapsableCard title={this.props.title}>
        <div>

          { this.state.width < 710 &&
            drivers.map(((d, index) => (
              <div key={index} className="mobile-table">
                <div className="pull-right">
                  <Link to={"/fleet/" + fleetDetail.id + "/driver/"+d.uid}><a className="fa fa-external-link fa-1"></a></Link>
                </div>
                <div>
                  <Link to={"/fleet/" + fleetDetail.id + "/driver/"+d.uid}>{d.name}</Link>
                </div>
                <div><strong>{t('driverStatsTable.total')}: </strong> {Math.round(d.stats.total)}</div>
                <div><strong>{t('commons.control')}: </strong>{Math.round(d.stats.control)}</div>
                <div><strong>{t('commons.caution')}: </strong>{Math.round(d.stats.caution)}</div>
                <div><strong>{t('commons.focus')}: </strong>{Math.round(d.stats.focus)}</div>
                <div><strong>{t('commons.eco')}: </strong>{Math.round(d.stats.eco)}</div>
                { full && showIEVandT && <div><strong>{t('driverStatsTable.ive')}: </strong>{d.stats.year_speed_alerts_index.toFixed(2)}</div> }
                { full && showIEVandT && <div><strong>{t('driverStatsTable.iet')}: </strong>{d.stats.year_phone_alerts_index.toFixed(2)}</div> }
                { full && showST && <div><strong>{t('driverStatsTable.status')}: </strong>{d.stats.year_phone_alerts_index.toFixed(2)}</div> }

              </div>)))
          }

          { this.state.width >= 710 &&

            <table className="table">
              <thead>
              <tr>
                <th>{t('driverStatsTable.driver')}</th>
                <th><abbr title={t('driverStatsTable.tooltip.total')}>{t('driverStatsTable.total')}</abbr></th>
                <th><abbr title={t('driverStatsTable.tooltip.control')}>{t('commons.control')}</abbr></th>
                <th><abbr title={t('driverStatsTable.tooltip.caution')}>{t('commons.caution')}</abbr></th>
                <th><abbr title={t('driverStatsTable.tooltip.focus')}>{t('commons.focus')}</abbr></th>
                <th><abbr title={t('driverStatsTable.tooltip.eco')}>{t('commons.eco')}</abbr></th>
                
                { full && <th><abbr title={t('driverStatsTable.tooltip.distance')}>{t('driverStatsTable.distance')}</abbr></th> }
                { full && <th><abbr title={t('driverStatsTable.tooltip.time')}>{t('driverStatsTable.time')}</abbr></th> }
                { full && showIEVandT && <th><abbr title={t('driverStatsTable.tooltip.ive')}>{t('driverStatsTable.ive')}</abbr></th> }
                { full && showIEVandT && <th><abbr title={t('driverStatsTable.tooltip.iet')}>{t('driverStatsTable.iet')}</abbr></th> }
                { full && showST && <th><abbr title={t('driverStatsTable.tooltip.status')}>{t('driverStatsTable.status')}</abbr></th> }
              </tr>
              </thead>
              <tbody>
              {drivers.map(((d, index) => (
                <tr key={index}>
                  <td><Link to={"/fleet/" + fleetDetail.id + "/driver/" + d.uid}>{d.name}</Link></td>
                  <td>{Math.round(d.stats.total)}</td>
                  <td>{Math.round(d.stats.control)}</td>
                  <td>{Math.round(d.stats.caution)}</td>
                  <td>{Math.round(d.stats.focus)}</td>
                  <td>{Math.round(d.stats.eco)}</td>
                  { full && <td>{d.stats.kms.toFixed(2)}</td> }
                  { full && <td>{secondsToHms(d.stats.duration / 1000 )}</td> }
                  { full && showIEVandT && <td>{d.stats.year_speed_alerts_index.toFixed(2)}</td> }
                  { full && showIEVandT && <td>{d.stats.year_phone_alerts_index.toFixed(2)}</td> }
                  { full && showST && d.stats.status === 'unknown' &&
                    <td>
                      <span className="icon has-text-warning tooltip" data-tooltip={d.stats.message}><i className="fa fa-truck"></i></span>
                    </td>
                  }
                  { full && showST && (d.stats.status === true && d.stats.status !== 'unkown') &&
                    <td><span className="icon has-text-success"><i className="fa fa-truck"></i></span></td>
                  }
                  { full && showST && (d.stats.status === false && d.stats.status !== 'unkown') &&
                    <td>
                      <span className="icon has-text-danger tooltip" data-tooltip={d.stats.message}><i className="fa fa-truck"></i></span>
                    </td>
                  }
                </tr>
              )))}
              </tbody>
            </table>
          }
        </div>
      </CollapsableCard>
    );
  }
}

DriverStatsTable.propTypes = {
  drivers: PropTypes.array.isRequired,
  full: PropTypes.bool.isRequired,
  showST: PropTypes.bool.isRequired
};

export default withTranslation('translation')(DriverStatsTable);
