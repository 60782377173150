import {connect} from 'react-redux'
import Expenses from "../pages/Expenses";
import {getExpenses} from '../redux/fleet'


function mapStateToProps(state) {
  const { selected, fleetDetail, expenses, loading } = state.fleet;
  return { selected, fleetDetail, expenses, loading};
}

const mapDispatchToProps = dispatch => {
  return {
    getExpenses: (fleetId, fromDate, toDate, driverId, creditCardId, vehicleId) => {
      dispatch(getExpenses(fleetId, fromDate, toDate, driverId, creditCardId, vehicleId))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Expenses);
