import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {RoadMapTableContainer} from '../containers/RoadMapsContainer'
import {StopsOversTableContainer} from "../containers/StopsContainer";
import {MarkersTableContainer} from "../containers/MarkersContainer";
import {PolygonMarkersTableContainer} from "../containers/PolygonMarkersContainer";
import TabSelector from "../components/TabSelector";
import Loading from "../components/Loading";

const ROAD_MAP_TAB = "RoadMaps"
const STOP_OVERS_TAB = "StopOvers"
const MARKERS_TAB = "Markers"
const POLYGON_MARKERS_TAB = "Polygons"
const TABS = [ROAD_MAP_TAB, STOP_OVERS_TAB, MARKERS_TAB, POLYGON_MARKERS_TAB]

class RoadMaps extends Component {

  state = {
    tabs: TABS,
    selectedTab: TABS[0]
  }

  componentDidMount() {
    const { fetchRoadMaps, fetchStops, fetchVehicles, fetchMarkers, fetchPolygonMarkers } = this.props
    const {fleetId} = this.props.match.params;
    fetchRoadMaps(fleetId)
    fetchStops(fleetId)
    fetchMarkers(fleetId)
    fetchVehicles(fleetId)
    fetchPolygonMarkers(fleetId)
  }

  onSelectTab = (tab) => {
    this.setState({selectedTab: tab})
  }

  render() {
    const {tabs, selectedTab} = this.state
    const {loading} = this.props
    const {fleetId} = this.props.match.params;
    return (
      <div className="is-fullwidth">

        <TabSelector tabs={tabs} selectedTab={selectedTab} onSelectTab={this.onSelectTab}/>
        <div className="columns">
          <div className="column">
            {selectedTab === ROAD_MAP_TAB && <RoadMapTableContainer fleetId={fleetId} />}
            {selectedTab === STOP_OVERS_TAB && <StopsOversTableContainer fleetId={fleetId} />}
            {selectedTab === MARKERS_TAB && <MarkersTableContainer fleetId={fleetId} />}
            {selectedTab === POLYGON_MARKERS_TAB && <PolygonMarkersTableContainer fleetId={fleetId} />}
          </div>
        </div>
        {loading && <Loading wait={0}/>}
      </div>
    );
  }
}

export default withTranslation('translation')(RoadMaps);