/**
 * Created by jje-personal on 9/4/17.
 */
import React, { Component } from 'react';
import emptyLogo from '../../images/w-logo.png'
import PropTypes from 'prop-types'
import {withTranslation} from "react-i18next";

class FleetDetailSettings extends Component {

  state = {
    editing: false
  };

  componentDidUpdate ()
  {
    if (this.props.selected && this.setCurrentValues) {
      this.setCurrentValues = false;
      this.refs.name.value = this.props.selected.name;
      this.refs.logo.value = this.props.selected.logo;
    }
  }

  onClickSave  = () => {
    const { selected } = this.props;
    const name = this.refs.name.value.trim();
    const logo = this.refs.logo.value.trim();

    let data = { name, logo};

    this.props.updateFleet(selected.id, data);

    this.setState({editing:false, errorMessage:""});
  };


  render() {
    const { selected, t } = this.props;
    const { editing } = this.state;
    var l = selected.logo;
    if (l === "img/no-image-box.png") {
      l = emptyLogo;
    }
    return (
      <div className="card">
        <div className="card-content">
          <div className="media">
            <div className="media-left">
              <figure className="image is-128x128">
                <img src={l} alt={selected.name} ref={img => this.img = img} onError={()=>{
                  this.img.src = emptyLogo;
                  this.img.alt = t('fleetDetailSettings.invalidImageUrl');
                }}/>
              </figure>
            </div>
            <div className="media-content">
              {!editing &&
                <p>
                  <strong>{t('fleetDetailSettings.fleetName')}</strong><br/><small>{selected.name}</small>
                  <br/>
                  <strong>{t('fleetDetailSettings.fleetLogoUrl')}</strong><br/><small>{selected.logo}</small>
                  <br/>
                </p>
                }
                {editing &&
                  <div>
                  <p>
                    <strong>{t('fleetDetailSettings.fleetName')}</strong><br/>
                    <input className="input" type="text" ref="name" placeholder="Nombre de la flota"/>
                    <strong>{t('fleetDetailSettings.fleetLogoUrl')}</strong><br/>
                    <input className="input" type="text" ref="logo" placeholder="url"/>
                  </p>
                </div>
                }
            </div>
            <div className="media-right">
              {!editing && !this.props.updatingFleet &&
                  <button className="button is-primary is-outlined"
                    onClick={()=>{
                      this.setState({editing:true});
                      this.setCurrentValues = true}}>
                      {t('fleetDetailSettings.edit')}
                  </button>
              }
              {(editing || this.props.updatingFleet) &&
                <button className={this.props.updatingFleet? "button is-primary is-loading": "button is-primary" }
                  onClick={this.onClickSave}>
                  {t('fleetDetailSettings.save')}
                </button>
              }
            </div>
          </div>
        </div>

      </div>
    );
  }
}

FleetDetailSettings.propTypes = {
  fleetDetail: PropTypes.object.isRequired,
  selected: PropTypes.object.isRequired,
  updatingFleet: PropTypes.bool.isRequired,
  updateFleet: PropTypes.func.isRequired
};

export default withTranslation('translation')(FleetDetailSettings);
