/* global google */
import React, {Component} from 'react';
import {GoogleMap, Marker, OverlayView, Polyline, withGoogleMap} from "react-google-maps";
import {decodePath} from '../../utils/polyline'
import racing from '../../images/racing.png'
import speedAlertIcon from '../../images/speedAlert.png'
import brakeAlertIcon from '../../images/brakeAlert.png'
import accelerationAlertIcon from '../../images/accelerationAlert.png'
import phoneAlertIcon from '../../images/phoneAlert.png'
import alertIcon from '../../images/alert.png'
import corneringAlertIcon from '../../images/corneringAlert.png'
import Timestamp from 'react-timestamp'
import {secondsToHms} from '../../utils/functions'
import {withTranslation} from "react-i18next";
import positionIcon from "../../images/position-icon-2.png";
import _ from 'lodash';
import { ROLE_ADMIN } from '../../model/Role';

const AlertTitle = withTranslation('translation')((props) => {
  switch (props.type) {
    case 2:
      return props.t('tripPathMap.alertTitle.speedExceed', {count: 60});
    case 3:
      return props.t('tripPathMap.alertTitle.speedExceed', {count: 130});
    case 6:
      return props.t('tripPathMap.alertTitle.speedExceedGeneric');
    case 5:
      return props.t('tripPathMap.alertTitle.harshCornering');
    case 200:
      return props.t('tripPathMap.alertTitle.speedExceedGeneric');
    case 4:
    case 100:
    case 110:
    case 300:
    case 310:
      return props.t('tripPathMap.alertTitle.abruptAceleration');
    case 120:
    case 320:
      return props.t('tripPathMap.alertTitle.abruptBraking');
    case 600:
      return props.t('tripPathMap.alertTitle.receivedCall');
    case 610:
      return props.t('tripPathMap.alertTitle.rejectedCall');
    case 620:
      return props.t('tripPathMap.alertTitle.phoneUsage');
    case 700:
      return props.t('tripPathMap.alertTitle.lowBatery');
    case 710:
      return props.t('tripPathMap.alertTitle.okBatery');
    case 720:
      return props.t('tripPathMap.alertTitle.chargingPhone');
    case 730:
      return props.t('tripPathMap.alertTitle.noChargingPhone');
    case 800:
      return props.t('tripPathMap.alertTitle.gpsOff');
    default:
      return props.t('tripPathMap.alertTitle.unknown', {type: props.type});
  }
});

const getAlertIcon = (type) => {
  switch (type) {
    case 2:
      return speedAlertIcon;
    case 3:
      return speedAlertIcon;
    case 200:
      return speedAlertIcon;
    case 5:
      return corneringAlertIcon;
    case 4:
    case 6:
      return speedAlertIcon;
    case 100:
    case 110:
    case 300:
    case 310:
      return accelerationAlertIcon;
    case 120:
    case 320:
      return brakeAlertIcon;
    case 600:
    case 610:
    case 620:
      return phoneAlertIcon;
    case 700:
    case 710:
    case 720:
    case 730:
      return alertIcon;
    case 800:
      return alertIcon;
    default:
      return alertIcon;
  }
};

const MAP_CONTAINER_STYLE = {position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'flex-end', alignItems: 'center'};
const MAP_ELEMENT_STYLE = { height: `100%`, width:  `100%`};

const DEFAULT_OPTIONS = {
  streetViewControl: false, scaleControl: false, mapTypeControl: false,
  panControl: false, rotateControl: false, fullscreenControl: true
};

const DEFAULT_CENTER = {
  lat: -34.591551, lng: -58.450422
};

const DEFAULT_ZOOM = 12;

class TripDetail extends Component {

  getPixelPositionOffset2 = (width, height) => {
    return { x: (50), y: -(height/2) };
  };

  render() {
    const { trip, t } = this.props;

    return(
      <OverlayView
        // key={index + '-trip-detail-div'}
        position={{ lat: trip.popup.lat, lng: trip.popup.lng }}
        mapPaneName={OverlayView.LOAT_PANE}
        getPixelPositionOffset={this.getPixelPositionOffset2}>

          <div className="box" style={{minWidth:'250px', 'zIndex':1000 }}>
            <div className="content">
              <p className="title is-6"><strong>{trip.username}</strong></p>
              <hr/>
              <p className="subtitle is-6"><strong>{t('tripPathMap.totalScore')}: </strong>{trip.total}</p>
              <p className="subtitle is-6"><strong>{t('tripPathMap.distance-1')}: </strong>{t('tripPathMap.distance-2', {count: trip.distance.toFixed(2)})}</p>
              <p className="subtitle is-6"><strong>{t('commons.duration')}: </strong>{secondsToHms(trip.duration)} </p>
              <p className="subtitle is-6"><strong>{t('commons.date')}: </strong> <Timestamp time={trip.timestamp/1000} format="full"/></p>
            </div>
          </div>

      </OverlayView>
    )
  }
}

class AlertDetail extends Component {

  isSpeed = (type) => {
    return type === 6;
  };

  isPhoneCall = (type) => {
    return type === 600;
  };

  isNoPhoneCall = (type) => {
    return type === 610;
  };

  isPhoneUse = (type) => {
    return type === 620;
  };

  getPixelPositionOffset2 = (width, height) => {
    return { x: (50), y: -(height/2) };
  };

  render() {
    const { alert, t } = this.props;

    return(
      <OverlayView
        // key={index + '-alert-detail-div'}
        position={{ lat: alert.position.lat, lng: alert.position.lng }}
        mapPaneName={OverlayView.LOAT_PANE}
        getPixelPositionOffset={this.getPixelPositionOffset2}>

          <div className="box" style={{minWidth:'250px', 'zIndex':1000000000000}}>
            <div className="content">
              <p className="title is-6"><strong><AlertTitle type={alert.type}/></strong></p>
              <hr/>
              <p className="subtitle is-6"><strong>{t('commons.date')}: </strong> <Timestamp time={alert.ts/1000} format="full"/></p>

              {(this.isPhoneCall(alert.type) || this.isPhoneUse(alert.type)) &&
                <p className="subtitle is-6">
                  <strong>{t('tripPathMap.speed-1')}: </strong>{t('tripPathMap.speed-2', {count: alert.speed})}<br/>
                  <strong>{t('commons.duration')}: </strong>{secondsToHms(alert.duration/1000000000)}<br/><br/>
                  {t('tripPathMap.youHaveDrive-1')} <b>{t('tripPathMap.youHaveDrive-2', {count: Math.round(((alert.speed*1000)/3600)*(alert.duration/1000000000))})}</b> {t('tripPathMap.youHaveDrive-3')}
                </p>
              }

              {this.isNoPhoneCall(alert.type) &&
                <p className="subtitle is-6">
                  <strong>{t('tripPathMap.speed-1')}: </strong>{t('tripPathMap.speed-2', {count: alert.speed})}<br/>
                  <b>{t('tripPathMap.congrats')}</b>
                </p>
              }

              {this.isSpeed(alert.type) &&
                <p className="subtitle is-6">
                  <strong>{t('tripPathMap.speed-1')}: </strong>{t('tripPathMap.speed-2', {count: alert.speed})}<br/>
                  <strong>{t('tripPathMap.maxSpeed')}: </strong>{t('tripPathMap.speed-2', {count: alert.speed_limit_kmh})}<br/>
                  <strong>{t('commons.position')}: </strong>{alert.location_str}<br/>
                  <strong>{t('commons.duration')}: </strong>{secondsToHms(alert.duration/1000000000)}
                </p>
              }
            </div>
          </div>

      </OverlayView>
    )
  }
}

const WrappedMap = withGoogleMap((
    {showTrips, showLastLocation, computedTrips, computedLastLocation, alertTargeted, tripTargeted,  driverMapColor, showAlerts, that}) => (

    <GoogleMap ref={that.handleMapMounted}
               defaultZoom={DEFAULT_ZOOM} defaultCenter={DEFAULT_CENTER} defaultOptions={DEFAULT_OPTIONS} disableDefaultUI>

      {showTrips && computedTrips.map((trip, index) => (
        <div key={index + '-trip-div'}>

          <Polyline key={index + '-poly-div'} path={trip.latlngs} options={{strokeColor: driverMapColor[trip.userId] || '#000'}}/>

          {trip === tripTargeted && <WrappedTripDetail trip={tripTargeted} />}
          {alertTargeted && <WrappedAlertDetail alert={alertTargeted}/>}


          {trip.latlngs.length > 0 &&
            <OverlayView
              key={index + '-start-div'}
              position={{ lat: trip.latlngs[0].lat, lng: trip.latlngs[0].lng }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              getPixelPositionOffset={that.getPixelPositionOffset}>

              <div className="circleIcon" style={{background: driverMapColor[trip.userId] || '#000'}}
                   onMouseEnter={() => that.showTripInfo(trip, trip.latlngs[0])}
                   onMouseLeave={() => that.hideTripInfo(trip)}>
                <p className="iconText">{trip.index}</p>
              </div>

            </OverlayView>
          }

          {trip.latlngs.length > 0 &&
            <Marker
              position={{ lat: _.last(trip.latlngs).lat, lng: _.last(trip.latlngs).lng }}
              key={index + 'end'}
              icon={racing}
              onMouseOver={() => that.showPopup(trip, _.last(trip.latlngs))}
              onMouseOut={() => that.closePopup(trip)}>
            </Marker>
          }

          {showAlerts && trip.alerts &&
            trip.alerts.map((alert, aIndex) => {
              let user = JSON.parse(localStorage.getItem("user"));
              if ((user.role === ROLE_ADMIN) || (user.role !== ROLE_ADMIN && alert.type !== 2 && alert.type !== 310)) {
                return <Marker
                  position={{ lat: alert.position.lat, lng: alert.position.lng }}
                  key={index + '-alert-' + aIndex}
                  icon={getAlertIcon(alert.type)}
                  onMouseOver={() => that.showAlertInfo(alert)}
                  onMouseOut={() => that.hideAlertInfo(alert)}>
                </Marker>
              }
            })
          }

        </div>))
      }

      {showLastLocation && computedLastLocation &&
        <Marker position={{ lat: computedLastLocation.lat, lng: computedLastLocation.lng }} icon={positionIcon}></Marker>
      }

    </GoogleMap>
  )
);

const WrappedTripDetail = withTranslation('translation')(TripDetail);
const WrappedAlertDetail = withTranslation('translation')(AlertDetail);


class ComplexMap extends Component {

  state = {
    tripTargeted: null,
    alertTargeted: null,
    computedTrips: [],
    computedLastLocation: null
  };

  componentDidUpdate(prevProps, prevState) {
    const {trips} = this.props;

    if (trips && prevProps.trips !== trips){

      let autozoomPoints = [];
      let tripsMap = {};
      let computedLastLocation = null;

      trips.sort((a,b) => {
        if (a.timestamp < b.timestamp) return -1;
        if (a.timestamp > b.timestamp) return 1;
        return 0
      });

      if (trips.length > 0) {
        computedLastLocation = _.last(trips).last_location;
      }

      trips.map((trip)=> {

        if (tripsMap[trip.userId] === undefined) {
          tripsMap[trip.userId] = [trip]
        } else {
          tripsMap[trip.userId] = [...tripsMap[trip.userId], trip];
        }

        let latlngs = [];
        if (typeof trip.polylines !== "undefined" && trip.polylines.length > 0) {
          trip.polylines.map(chunk => latlngs = [...latlngs, ...decodePath(chunk, 5)])
        }
        trip.latlngs = latlngs;

        if (latlngs.length === 0) {
          // use last known location
          latlngs = [...this.latlngs, trip.last_location.lat, trip.last_location.lon];
        }

        if (latlngs.length > 0) {
          // use first and last point to autozoom
          // autozoomPoints = [_.head(latlngs), _.last(latlngs)]

          // use all points availables to autozoom
          autozoomPoints = [...latlngs];
        }
      });

      // Added trip number. Trips was already sorted
      for (var key in tripsMap) {
        tripsMap[key].map((trip, index)=> {
          trip.index = index + 1
        });
      }

      if (trips && autozoomPoints.length > 0) {
        this.autoZoomMap(autozoomPoints);
      }

      this.setState({computedTrips: trips, computedLastLocation});
    }
  }


  showAlertInfo = (alert) => {
    this.setState({alertTargeted: alert});
  };

  hideAlertInfo = (alert) => {
    this.setState({alertTargeted: null});
  };

  showTripInfo = (trip, position) => {
    trip.popup = position;
    this.setState({tripTargeted: trip});
  };

  hideTripInfo = (trip) => {
    this.setState({tripTargeted: null});
  };

  autoZoomMap = (points) => {
    if (!this._map || !points || points.length === 0) return;

    var bound = new google.maps.LatLngBounds();
    for (let i of points) {
      bound.extend(new google.maps.LatLng(i.lat, i.lng));
    }
    this._map.fitBounds(bound);
  };

  handleMapMounted = (map) => {
    this._map = map;
    this.autoZoomMap(this.refPoints)
  };

  getPixelPositionOffset = (width, height) => {
    return { x: -(width/2), y: -(height/2) }
  };

  render() {
    return(
      <WrappedMap {...this.props} {...this.state} that={this}
        containerElement={<div style={MAP_CONTAINER_STYLE} />}
        mapElement={<div style={MAP_ELEMENT_STYLE} />}
      />
    )
  }
}

export default withTranslation('translation')(ComplexMap);
