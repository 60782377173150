/**
 * Created by jje-personal on 9/4/17.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { secondsToHms } from '../../utils/functions'
import {withTranslation} from "react-i18next";

class DriverDescription extends Component {

  render() {
    const { name, average, durationAverage, kms, totalTrips, phone, t} = this.props;

    return (
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">{t('driverDescription.title')}</p>
        </header>
        <div className="card-content">
          <div className="media">
            <div className="media-content">
              <p className="title is-4">{name}</p>
              <p className="text is-6">{t('driverDescription.tripsAverage', {count: average})}</p>
              <p className="text is-6">{t('driverDescription.duration', {count: secondsToHms(durationAverage)})}</p>
              <p className="text is-6">{t('driverDescription.distance', {count: parseInt(kms, 10)})}</p>
              <p className="text is-6">{t('driverDescription.tripsAmount', {count: totalTrips})}</p>
              <p className="text is-6">{phone}</p>
            </div>
          </div>
        </div>

      </div>
    );
  }
}


DriverDescription.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  average: PropTypes.number.isRequired,
  durationAverage: PropTypes.number.isRequired,
  kms: PropTypes.number.isRequired,
  totalTrips: PropTypes.number.isRequired,
  phone: PropTypes.string.isRequired

};

export default withTranslation('translation')(DriverDescription);
