import React, {Component} from 'react';
import {withTranslation} from "react-i18next";


class ScoreSelector extends Component {

  render() {
    const {t, onChangeScoreType} = this.props;

    return (
      <div className="select is-small">
        <select onChange={(ev) => onChangeScoreType(ev.target.value)}>
          <option value="eco">{t("commons.eco")}</option>
          <option value="total">{t("commons.total")}</option>
          <option value="control">{t("commons.control")}</option>
          <option value="caution">{t("commons.caution")}</option>
          <option value="focus">{t("commons.focus")}</option>
        </select>
      </div>
    );
  }
}

export default withTranslation('translation')(ScoreSelector);