import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router'
import { ROLE_DRIVER } from '../model/Role'


const PrivateRoute = ({ component: Component, isAuthenticated, user, ...rest }) => (

  <Route {...rest} render={props => {

    if (isAuthenticated) {
      if (user && user.role === ROLE_DRIVER) {
        return (<Redirect to={{pathname: '/notAllowed', state: { from: props.location }}}/>)
      } else {
        if (user && user.fleets.length === 0) {
          return (<Redirect to={{pathname: '/noFleets', state: { from: props.location }}}/>)
        }
        return (<Component {...props}/>)
      }
    } else {
      return (<Redirect to={{pathname: '/login', state: { from: props.location }}}/>)
    }
  }}/>
);

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

// These props come from the application's
// state when it is started
function mapStateToProps(state) {

  const { isAuthenticated, user } = state.auth;

  return {
    isAuthenticated,
    user
  }
}

export default connect(mapStateToProps)(PrivateRoute)