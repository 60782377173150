
import { connect } from 'react-redux'
import { getDriverTrips, getFleetDetail } from '../redux/fleet'
import TripPathFilterMap from '../pages/TripPathFilterMap'


// These props come from the application's
// state when it is started
function mapStateToProps(state) {

  const { driverTrips, tripsLoading, fleetDetail, selected, dateFilter } = state.fleet;

  return {
    driverTrips,
    tripsLoading,
    fleetDetail,
    selected,
    dateFilter
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getDriverTrips : (fleetId, driverId, date) => {
      dispatch(getDriverTrips(fleetId, driverId, date))
    },
    getFleetDetail: (fleetId, dateFilter) => {
      dispatch(getFleetDetail(fleetId, dateFilter))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(TripPathFilterMap);