import React, {Component, createRef} from 'react';
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";

class SignInWithEmail extends Component {
    constructor(props) {
        super(props);

        this.email = createRef();
        this.password = createRef();
    }

    state = {
        showPassword: false,
    }

    handlePasswordEnter = (e) => {
        if (e.key === 'Enter') {
            this.loginWithEmail(e)
        }
    };

    loginWithEmail = (e) => {
        e.preventDefault();
        const email = this.email.current.value.trim();
        const password = this.password.current.value.trim();

        if (email.length === 0 || password.length === 0) {
            return;
        }

        this.props.onEmailLoginHandler(email, password);
    };

    translationErrorCodePath(code) {
        // See 'authentication.firebaseAuthError' in translation.json
        switch (code) {
            case "internal-error":
                return "internalError";

            case "invalid-email":
                return "invalidEmail";

            case "invalid-password":
            case "wrong-password":
                return "invalidPassword";

            case "too-many-requests":
                return "tooManyRequests";

            case "user-not-found":
                return "userNotFound";

            default:
                return "other"
        }
    }

    translatedErrorMessage = (error) => {
        if (!error || !error.code) {
            return;
        }

        const errorCode = error.code;
        const code = errorCode.split("/")[1];

        const errorMessagePath = this.translationErrorCodePath(code);

        const errorMessage = this.props.t(`authentication.firebaseAuthError.${errorMessagePath}.message`);
        const errorCodeMessage = this.props.t(`authentication.firebaseAuthError.${errorMessagePath}.errorCode`);
        const errorCodeAux = errorCode === "other" ? errorCode.toString() : "";

        return `${errorMessage} [${errorCodeMessage + errorCodeAux}]`;
    }

    togglePasswordVisibility = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));
    };

    render() {
        const {loginError} = this.props;
        const {showPassword} = this.state;

        return (
            <div className="box">
                <div className="column">
                    <label className="label has-text-left">{this.props.t('authentication.email')}</label>
                    <p className="control has-icons-left">
                        <input className="input" ref={this.email} type="email" placeholder="user@example.org"/>
                        <span className="icon is-left">
                    <i className="fa fa-envelope"></i>
                  </span>
                    </p>
                </div>
                <div className="column">
                    <label className="label has-text-left">{this.props.t('authentication.password')}</label>

                    <div className='columns mx-0 px-0'>
                    <div className='column pl-0'>
                    <p className="control has-icons-left">
                        <input className="input" ref={this.password} type={showPassword ? "text" : "password"}
                               placeholder="●●●●●●●"
                               onKeyPress={this.handlePasswordEnter}/>
                        <span className="icon is-left">
                        <i className="fa fa-lock"></i>
                      </span>
                    </p>
                </div>

                <div className='column is-1 px-0 mx-0 my-auto'>
                    <button className="button px-3 is-pulled-right is-white"
                            aria-label={showPassword ? this.props.t('authentication.hidePassword') : this.props.t('authentication.showPassword')}
                            onClick={this.togglePasswordVisibility}>
                        <i className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                    </button>
                </div>
                </div>
                </div>

            {loginError && <span className="help is-danger">{this.translatedErrorMessage(loginError)}</span>}
            <hr/>
            <div>
                <div className="block">
                        <span className="is-size-6">{this.props.t("authentication.passwordReset.forgotPassword")} <a
                            href={"/resetPass"}>{this.props.t("authentication.passwordReset.forgotPasswordReset")}</a></span>
                </div>

                <div className="field is-grouped">
                    <p className="control">
                        <button className="button is-primary is-outlined"
                                onClick={() => this.props.onBackHandler()}>
                            {this.props.t('authentication.back')}
                        </button>
                    </p>
                    <p className="control">
                        <button className="button is-primary"
                                onClick={this.loginWithEmail}>
                            {this.props.t('authentication.login')}
                        </button>
                    </p>
                </div>
            </div>
        </div>
    )
        ;
    }
}

SignInWithEmail.propTypes = {
    onEmailLoginHandler: PropTypes.func.isRequired,
    onBackHandler: PropTypes.func.isRequired,
}

export default withTranslation('translation')(SignInWithEmail);