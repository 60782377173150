import React, {Component, createRef} from 'react';
import Loading from '../components/Loading'
import { verifyPasswordResetCode } from 'firebase/auth';
import { firebaseAuth } from '../firebase/config';
import queryString from 'query-string';
import { checkStatus } from '../api/utils'
import { BASE_URL } from '../config';
import { withTranslation } from "react-i18next";
import {emailPasswordReset} from "../redux/auth";

class PasswordReset extends Component {

  constructor(props) {
    super(props);

    this.email = createRef();
  }

  state = {
    showPassword: false,
    accountEmail: null,

    hasCode: false,
    actionCode: null,
    validCode: null,
    verifyCallMade: false,

    disableRequestPasswordResetButton: false,

    matchError: false,
    lenghtError: false,
    digitError: false,
    symbolError: false,
    upperError: false,
    lowerError: false,
    passwordChanged: false
  };


  verifyCode = () => {
    // Verify the firebase action code is valid
    const search = this.props.location.search
    const { oobCode } = queryString.parse(search)

    if (search === "") {
      this.setState({hasCode: false, validCode: false})
      return;
    }

    verifyPasswordResetCode(firebaseAuth, oobCode).then((email) => {
      this.setState({hasCode: true, accountEmail: email, actionCode: oobCode, validCode: true})
    }).catch((error) => {
      this.setState({hasCode: true, validCode: false})
      console.log('Could not validate action code: ', error)
    })

  };

  validateEmail = (email) => {
    // We can have an incredibly complex regex or simply look after `@` and at least one dot
    // and let the backend do the rest (i.e.: send the email).
    // If it's real and it's registered in our platform, it will arrive to them.
    return String(email)
        .toLowerCase()
        .match(
            /^\S+@\S+\.\S+$/
        );
  };

  requestPasswordReset = () => {
    const email = this.email.current.value.trim();

    if (!this.validateEmail(email)) {
      alert("Invalid email");
      return;
    }

    this.setState({disableRequestPasswordResetButton: true});
    emailPasswordReset(email)
        .then(() => {
          alert(`Correo de reestablecimiento enviado\nReset email sent\nE-mail de redefinição enviado\nE-mail de réinitialisation envoyé`);
          this.email.current.value = "";
        })
        .catch((error) => {
          alert(`Error: ${error}`);
          this.setState({disableRequestPasswordResetButton: false});
        });
  }

  changePassword = () => {
    // checks the password against all the conditions and performs the reset with firebase
    const password = this.refs.password.value
    const confirmPassword = this.refs.confirmPassword.value

    const search = this.props.location.search
    const { apiKey } = queryString.parse(search)

    const password_ok = this.checkPassword(password, confirmPassword)
    if (!password_ok) {
      console.log("The password you entered doesn't satisfy all conditions.")
      return
    }

    const url = BASE_URL + '/api/resetPass';
    const payload = { "oob_code": this.state.actionCode, "new_password": password, "apiKey": apiKey }

    return fetch(url, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(payload),
      headers: {
        'Accept': 'application/json',
      }
    })
      .then(checkStatus)
      .then((json) => {
        this.setState({ passwordChanged: true })
        console.log('password changed succesfully');
      }).catch(error => {
        this.setState({ validCode: false })
        console.log('something went wrong reseting your password: ', error)
      })

  }

  checkPassword = (password, confirmPassword) => {
    /*
    Checks that the password has:
    8 characters length or more
    1 digit or more
    1 symbol or more
    1 uppercase letter or more
    1 lowercase letter or more
    and that the password and "confirm password" fields match
    */
    const length_check = (pass) => pass.length >= 8;
    const digit_check = new RegExp('(?=[0-9])')
    // Do not treat '_' as symbol due to Android client (uses \W)
    const symbol_check = new RegExp('(?=[^a-zA-Z0-9_])')
    const lower_check = new RegExp('(?=[a-z])')
    const upper_check = new RegExp('(?=[A-Z])')

    this.setState({
      matchError: false,
      lenghtError: false,
      digitError: false,
      symbolError: false,
      lowerError: false,
      upperError: false
    })

    const length_ok = length_check(password)
    const digit_ok = digit_check.test(password)
    const symbol_ok = symbol_check.test(password)
    const lower_ok = lower_check.test(password)
    const upper_ok = upper_check.test(password)

    const match_ok = (password === confirmPassword)

    const password_ok = (length_ok && digit_ok && symbol_ok && lower_ok && upper_ok && match_ok)

    this.setState({
      matchError: !match_ok,
      lenghtError: !length_ok,
      digitError: !digit_ok,
      symbolError: !symbol_ok,
      lowerError: !lower_ok,
      upperError: !upper_ok
    })
    return password_ok
  }

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  render() {
    const { showPassword, hasCode, disableRequestPasswordResetButton, validCode, matchError, lenghtError, digitError, symbolError, lowerError, upperError, passwordChanged } = this.state

    if (!this.state.verifyCallMade) {
      this.verifyCode()
      this.setState({ verifyCallMade: true })
    }

    return (
      <div className="columns is-vcentered is-fullwidth">
        {(validCode == null) && <Loading wait="0" />}
        {!hasCode &&
            <div className="box column is-4 is-offset-4">
              <div className="column">
                <label className="label has-text-left">{this.props.t('authentication.email')}</label>
                <p className="control has-icons-left">
                  <input className="input" ref={this.email} type="email" placeholder="user@example.org"/>
                  <span className="icon is-left">
                    <i className="fa fa-envelope"></i>
                  </span>
                </p>
              </div>

              {/*TODO: Improve error handling*/}
              {/*{loginError && <span className="help is-danger">{this.translatedErrorMessage(loginError)}</span>}*/}
              <hr/>
                <div className="field is-grouped">
                  <p className="control">
                    <button className="button is-primary"
                            onClick={this.requestPasswordReset}
                            disabled={disableRequestPasswordResetButton}
                    >
                      {this.props.t('authentication.passwordReset.sendResetEmail')}
                    </button>
                  </p>
              </div>
            </div>
        }
        {hasCode && (validCode === false) && !passwordChanged &&
          <div className="column is-6 is-offset-3">
            <div className="box">
              <h1 className="subtitle px-2">{this.props.t('authentication.passwordReset.invalidLink')}</h1>
              <p className="px-2 py-0">{this.props.t('authentication.passwordReset.invalidLinkHint')}</p>
            </div>
          </div>
        }
        {passwordChanged &&
          <div className="column is-4 is-offset-4">
            <div className="box">
              <h1 className="subtitle p-2">{this.props.t('authentication.passwordReset.success')}</h1>
            </div>
          </div>
        }
        {validCode && !passwordChanged &&
          <div className="column is-4 is-offset-4">
            <h1 className="title">
              {this.props.t('authentication.passwordReset.title')}
            </h1>
            <h1 className="subtitle">
              {this.props.t('authentication.passwordReset.subtitle')} {this.state.accountEmail}
            </h1>
            <div className="box">
              <div className="column">
                <label className="label has-text-left">{this.props.t('authentication.passwordReset.newPassword')}</label>

                <div className='columns mx-0 px-0'>

                  <div className='column'>
                    <p className="control has-icons-left">
                      <input className="input" ref="password" type={showPassword ? "text" : "password"}
                        placeholder={this.props.t('authentication.passwordReset.passwordPlaceholder')} />
                      <span className="icon is-left">
                        <i className="fa fa-lock"></i>
                      </span>
                    </p>
                  </div>

                  <div className='column is-1 px-0 mx-0 my-auto'>
                    <button className="button px-3 is-pulled-right is-white"
                      aria-label={showPassword ? this.props.t('authentication.hidePassword') : this.props.t('authentication.showPassword')}
                      onClick={this.togglePasswordVisibility} >
                      <i className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                    </button>
                  </div>

                </div>

              </div>
              <div className="column">
                <label className="label has-text-left">{this.props.t('authentication.passwordReset.repeatPassword')}</label>

                <div className='columns mx-0 px-0'>

                  <div className='column'>
                    <p className="control has-icons-left has-icons-right">
                      <input className="input" ref="confirmPassword" type={showPassword ? "text" : "password"}
                        placeholder={this.props.t('authentication.passwordReset.repeatPasswordPlaceholder')} />
                      <span className="icon is-left">
                        <i className="fa fa-lock"></i>
                      </span>
                    </p>
                  </div>

                  <div className='column is-1 px-0 mx-0 my-auto'>
                    <button className="button px-3 is-pulled-right is-white"
                      aria-label={showPassword ? this.props.t('authentication.hidePassword') : this.props.t('authentication.showPassword')}
                      onClick={this.togglePasswordVisibility} >
                      <i className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                    </button>
                  </div>
                </div>
              </div>
              {matchError && <span className="help is-danger">{this.props.t('authentication.passwordReset.hintPasswordsDoNotMatch')}</span>}
              {lenghtError && <span className="help is-danger">{this.props.t('authentication.passwordReset.hintPasswordLength')}</span>}
              {digitError && <span className="help is-danger">{this.props.t('authentication.passwordReset.hintPasswordNumber')}</span>}
              {symbolError && <span className="help is-danger">{this.props.t('authentication.passwordReset.hintPasswordSymbol')}</span>}
              {lowerError && <span className="help is-danger">{this.props.t('authentication.passwordReset.hintPasswordMinus')}</span>}
              {upperError && <span className="help is-danger">{this.props.t('authentication.passwordReset.hintPasswordMayus')}</span>}
              <hr />
              <div className="field is-grouped">
                <p className="control">

                  <button className="button is-primary" onClick={() => { this.changePassword() }}>
                    {this.props.t('authentication.passwordReset.changePasswordButton')}
                  </button>
                </p>
              </div>
            </div>
          </div>
        }
      </div>
    );

  }
}


export default withTranslation('translation')(PasswordReset);