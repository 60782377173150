import {connect} from 'react-redux'
import {getDriverLastLocation, resetLastLocation} from '../redux/fleet'
import DriverLocation from '../pages/DriverLocation'


// These props come from the application's
// state when it is started
function mapStateToProps(state) {

  const { lastLocation, selected } = state.fleet;
  return {
    lastLocation,
    selected
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getDriverLastLocation: (fleetId, driverId) => {
      dispatch(getDriverLastLocation(fleetId, driverId))
    },
    resetLastLocation: () => {
      dispatch(resetLastLocation())
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DriverLocation)
// <DriverLocation lastLocation={state.fleet.lastLocation} selected=... >