import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import Autosuggest from "react-autosuggest";
import _ from "lodash";
import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";
import classNames from "classnames";

class DriverSuggestion extends Component {

  state = {
    suggestions: [],
    suggestedInputValue: "",
  };

  onChange = (event, { newValue }) => {
    this.setState({suggestedInputValue: newValue});
    if (_.isEmpty(newValue) || newValue === "") {
      this.props.onSelected(event, null);
    }
  };

  getSuggestionValue = suggestion => `${suggestion.name} - ${suggestion.email}`;

  renderSuggestion = (driver, { query }) =>{
    const suggestionText = `${driver.name} - ${driver.email}`;
    const matches = AutosuggestHighlightMatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);

    return (
      <span className={'suggestion-content'}>
        <span className="name">
          {parts.map((part, index) => {
            const className = part.highlight ? 'highlight' : null;
            return (<span className={className} key={index}>{part.text}</span>);
          })}
        </span>
      </span>
    );
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const { fleetDetail, selected } = this.props;
    let available = _.cloneDeep(fleetDetail.drivers);

    _.each(selected, d => {
      _.remove(available, ad => ad.email === d.email);
    });

    const result = inputValue.length < 1 ? available : available.filter(d => {
      return (d.name + d.email).toLowerCase().includes(inputValue);
    });

    this.setState({ suggestions: result });
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  onSuggestionSelected = (event, {suggestion}) => {
    this.props.onSelected(event, suggestion);
    if (this.props.cleanOnSelected) {
      this.setState({suggestedInputValue: ""});
    }
  };

  shouldRenderSuggestions = () => {
    return true;
  }

  render() {
    const { fleetDetail, t } = this.props;
    const { suggestions, suggestedInputValue } = this.state;

    const STYLE = {
      input: classNames('input', this.props.className),
      suggestion: 'autosuggest-item',
      containerOpen: 'autosuggest-container',
      suggestionHighlighted: 'autosuggest-item-focused',
      suggestionsContainer: 'autosuggest-container-suggest',
      suggestionsContainerOpen: 'autosuggest-container-suggest-open'
    };

    if (!fleetDetail || !fleetDetail.drivers) {
      return (
        <div className="control is-loading">
          <input className="input" disabled={true}/>
        </div>
      );
    }

    const inputProps = {
      placeholder: t('driverSuggestion.placeholder'),
      value: suggestedInputValue,
      onChange: this.onChange
    };

    return (
      <div className="control">
        <div className="select is-fullwidth">
          <Autosuggest suggestions={suggestions} inputProps={inputProps} theme={STYLE}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            onSuggestionSelected={this.onSuggestionSelected}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            shouldRenderSuggestions={this.shouldRenderSuggestions}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation('translation')(DriverSuggestion);
