import React, {Component} from 'react';
import Loading from '../components/Loading'
import VehicleDetail from "../containers/VehiclesDetailContainer";
import {withTranslation} from "react-i18next";
import TabVehicleDashboard from "../components/vehicles/TabVehicleDashboard";
import TabVehicleLive from "../components/vehicles/TabVehicleLive";
import classNames from "classnames";
import TabVehicleExpenses from "../components/vehicles/TabVehicleExpenses";


class Tabs extends Component {

  state = {
    selected: 0
  };

  tabSelectedChanged = (tab) => {
    const {onTabSelected} = this.props;
    onTabSelected && onTabSelected(tab);
    this.setState({selected: tab});
  };

  render() {
    const {selected} = this.state;
    const {t} = this.props;

    return(
      <div className="tabs is-boxed is-centered">
        <ul>
          <li className={classNames({"is-active": selected === 0})} onClick={() => this.tabSelectedChanged(0)}>
            <a>
              <span className="icon is-small"><i className="fa fa-line-chart" aria-hidden="true"></i></span>
              <span>{t("vehicle.tab.dashboard")}</span>
            </a>
          </li>
          <li className={classNames({"is-active": selected === 1})} onClick={() => this.tabSelectedChanged(1)}>
            <a>
              <span className="icon is-small"><i className="fa fa-car" aria-hidden="true"></i></span>
              <span>{t("vehicle.tab.live")}</span>
            </a>
          </li>
          <li className={classNames({"is-active": selected === 2})} onClick={() => this.tabSelectedChanged(2)}>
            <a>
              <span className="icon is-small"><i className="fa fa-money" aria-hidden="true"></i></span>
              <span>{t("vehicle.tab.fuel")}</span>
            </a>
          </li>
        </ul>
      </div>
    );
  }
};

const WrapperTabs = withTranslation('translation')(Tabs);


class Vehicle extends Component {

  state = {
    tabSelected: 0
  };

  componentWillMount() {
    const {fleetId, vehicleId} = this.props.match.params;
    this.props.fetchVehicle(fleetId, vehicleId);
  }

  render() {
    const {fleetId, vehicleId} = this.props.match.params;
    const { vehicle, selected } = this.props;
    const { tabSelected } = this.state;

    if (!vehicle || !selected) {
      return (<Loading wait={0}/>);
    }

    return (
      <div className="is-fullwidth">
        {!vehicle && <Loading wait={0}/>}

        <div className="columns">

          <div className="column is-2">
            <VehicleDetail vehicle={vehicle}/>
          </div>

          {/*DASHBOARD*/}
          <div className="column">
            <WrapperTabs onTabSelected={(tab) => this.setState({tabSelected: tab})}/>

            {tabSelected === 0 && <TabVehicleDashboard fleetId={fleetId} vehicleId={vehicleId} />}
            {tabSelected === 1 && <TabVehicleLive fleetId={fleetId} vehicleId={vehicleId} />}
            {tabSelected === 2 && <TabVehicleExpenses fleetId={fleetId} vehicleId={vehicleId} />}

          </div>

        </div>
      </div>
    );
  }
}

export default withTranslation('translation')(Vehicle);