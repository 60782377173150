import React, {Component} from "react";
import {withRouter} from "react-router";

class SendPNConfirmModal extends Component {

    render() {
        const { onClose, pnData } = this.props
        return (
            <div className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Seguro que quieres enviar la Push Notification?</p>
                    </header>

                    <section className="modal-card-body">
                        <h1>Asegúrate de que todos los datos sean correctos antes de enviarla</h1>
                        <h3>titulo: {pnData.title}</h3>
                        <h3>cuerpo: {pnData.body}</h3>
                        <h3>objetivo: {pnData.target}</h3>
                    </section>
                    <footer className="modal-card-foot">
                        <div className="buttons">
                            <button className="button is-danger"
                                    onClick={() => onClose(true)}>Enviar</button>
                            <button className="button"
                                    onClick={() => onClose(false)}>Cancelar</button>
                        </div>
                    </footer>
                </div>
            </div>
        );
    }
}

export default withRouter((SendPNConfirmModal));