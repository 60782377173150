import React from 'react';
import { connect } from 'react-redux'
import {getFleetDetail, selectFleet} from "../../redux/fleet";
import _ from 'lodash';

class LoadRoutObserver extends React.Component {

  hasFleetId(link){
    let linkArray = link.split("/");
    let pos = linkArray.indexOf("fleet");
    if (pos < 0){
      return false;
    }
    return true;
  }

  getFleetId(link){
    let linkArray = link.split("/");
    let pos = linkArray.indexOf("fleet");
    return linkArray[pos+1];
  }

  getFleet(fleetId){
    const {fleets} = this.props;
    return _.find(fleets, fleet => fleet.id === parseInt(fleetId));
  }

  render() {
    const {fleetDetail, dateFilter, fleets} = this.props;
    const link = this.props.link;

    console.log("LoadRoutObserver");

    if (!fleetDetail && this.hasFleetId(link) && fleets.length > 0) {
      const fleetId = this.getFleetId(link);
      this.props.onSelectFleet(this.getFleet(fleetId));
      this.props.getFleetDetail(fleetId, dateFilter, true);
    }

    this.props.onLoad();
    return (
        <div></div>
    );
  }
}

function mapStateToProps(state, ownProps) {

  const { fleetDetail, dateFilter, fleets } = state.fleet;

  return {
      fleetDetail,
      fleets,
      dateFilter,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getFleetDetail: (fleetId, dateFilter, isObservable) => {
      dispatch(getFleetDetail(fleetId, dateFilter, isObservable))
    },
    onSelectFleet: fleet => {
      dispatch(selectFleet(fleet))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoadRoutObserver)
