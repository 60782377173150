import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import DeleteModal from "./MarkerDeleteModal"
import EditModal from "./MarkerEditModal";
import _ from "lodash";
import Filters from "./MarkersFilters";
import plusIcon from "../../images/plus.png";
import MarkerRow from "./MarkerRow";
import {EMPTY_MARKER} from "./constants";

class MarkersTable extends Component {

    state = {
        showDeleteModal: false,
        showEditModal: false,
        currentMarker: null,
        saytInput: "",
    };

    onClickDelete = (marker) => {
        this.setState({currentMarker: _.cloneDeep(marker), showDeleteModal: true});
    };

    getFilteredMarkers() {
        const {markers} = this.props;
        const {saytInput} = this.state;

        if (!markers) return []

        if (saytInput.length < 3) {
            return markers.sort((a, b) => b.creation_date - a.creation_date);
        }
        return markers.filter(s => {
            return (s.address + s.name + s.description).toLocaleLowerCase().includes(saytInput.toLocaleLowerCase());
        })
        .sort((a, b) => b.creation_date - a.creation_date);
    }

    onChangeSearchFilter = (value) => {
        this.setState({saytInput: value})
    }

    onCloseDeleteModal = (shouldDelete, marker) => {
        let {markers, deleteMarker, fleetId} = this.props;

        if (shouldDelete) {
            _.remove(markers, v => v.id === marker.id);
            // server call
            deleteMarker(fleetId, marker);
        }

        this.setState({showDeleteModal: false});
    };

    onCloseEditModal = (shouldEdit, marker) => {
        let {markers, fleetId, saveMarker } = this.props;
        if (shouldEdit) {
            if (marker.id !== 0) {
                _.remove(markers, v => v.id === marker.id);
            }

            markers.unshift(marker);

            // server call
            saveMarker(fleetId, marker);
        }

        this.setState({showEditModal: false});
    };

    onClickEdit = (marker) => {
        this.setState({currentMarker: _.cloneDeep(marker), showEditModal: true});
    };

    onClickNewMarker = () => {
        this.setState({currentMarker: _.cloneDeep(EMPTY_MARKER), showEditModal: true});
    };

    onClickDuplicateRoadMap = (marker) => {
        let auxMarker = _.cloneDeep(EMPTY_MARKER)
        auxMarker.location.lat = marker.location.lat
        auxMarker.location.lon = marker.location.lon
        auxMarker.address = marker.address
        auxMarker.marker_type = marker.marker_type
        this.setState({currentMarker: _.cloneDeep(auxMarker), showEditModal: true});
    };

    render() {
        const {showDeleteModal, currentMarker, showEditModal} = this.state
        return (
            <div>
                {showDeleteModal && <DeleteModal onClose={this.onCloseDeleteModal} marker={currentMarker} />}
                {showEditModal && <EditModal onClose={this.onCloseEditModal} marker={currentMarker} />}
                <Filters onChange={this.onChangeSearchFilter}/>
                {this.getFilteredMarkers().map(((so) => (
                    <MarkerRow  onDelete={this.onClickDelete} marker={so} onEdit={this.onClickEdit}
                                 onDuplicate={this.onClickDuplicateRoadMap} key={so.id}/>
                )))}
                <a className="fab button is-success is-pulled-right" onClick={() => {this.onClickNewMarker()}}><img src={plusIcon}/></a>
            </div>
        );
    }
}

export default withRouter(withTranslation('translation')(MarkersTable));