import React, { Component } from 'react';
import PropTypes from 'prop-types'
import Loading from '../components/Loading'

import {withTranslation} from "react-i18next";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

class FleetReports extends Component {

  render() {

    const { loading, selected, t } = this.props;

    const REPORT_TYPE = [t('fleetReports.reports'), t('fleetReports.regionalReports')];
    const DATASTUDIO_URL = "https://datastudio.google.com";

    const noReportFound = () => (
      <TabPanel>
        <div style={{position:"relative", paddingTop:"40%"}}>
          <div style={{position:"absolute",top:0,left:0, width:"100%", height:"100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <p>{t("fleetReports.noReportsFound")}</p>
          </div>
        </div>
      </TabPanel>
    )

    if (loading || !selected) {
      return (<Loading/>)
    }
    return (
      <div className="reports">
        <Tabs>
          <TabList>
            {REPORT_TYPE.map(report => {
              return <Tab>{report}</Tab>
            })}
          </TabList>
          {selected.dashboard_reports && selected.dashboard_reports.length !== 0 ? selected.dashboard_reports.map((url) => {
            if (url.slice(0,29) === DATASTUDIO_URL) {
              return (
                <TabPanel>
                  <div style={{position:"relative", paddingTop:"56.25%"}}>
                    <iframe style={{position:"absolute",top:0,left:0, width:"100%", height:"100%"}} src={url}></iframe>
                  </div>
                </TabPanel>
              )
            } else return noReportFound();
          }) : REPORT_TYPE.map(() => noReportFound())}
        </Tabs>
      </div>
    );
  }
}

FleetReports.propTypes = {
  selected: PropTypes.object
};

export default withTranslation('translation')(FleetReports);
