import {FIREBASE_CONFIG} from '../config'
import {initializeApp} from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  sendPasswordResetEmail
} from "firebase/auth";


const app = initializeApp(FIREBASE_CONFIG);

export const firebaseAuth = getAuth(app);

export function logout() {
  return firebaseAuth.signOut()
}

export function firebaseGoogleLogin(onErrorCallback) {
  const provider = new GoogleAuthProvider();
  signInWithPopup(firebaseAuth, provider).catch(onErrorCallback);
}

export function firebaseEmailLogin(email, password, onErrorCallback) {
  signInWithEmailAndPassword(firebaseAuth, email, password).catch(onErrorCallback);
}

export async function firebaseEmailPasswordReset(email) {
  return sendPasswordResetEmail(firebaseAuth, email);
}

export async function firebaseLoginWithCustomToken(email) {
  return await signInWithCustomToken(firebaseAuth, email);
}

export function currentUser() {
  return firebaseAuth.currentUser;
}

export function firebaseLogout () {
  return firebaseAuth.signOut()
}
