import React, {Component} from "react";
import {withRouter} from "react-router";

class SchedulePNModal extends Component {

    state = {
        day: null,
        hour: '00',
        minute: '00'
    }

    onChangeDay = ev => {
        this.setState({day: ev.target.value})
    }

    onChangeHour = ev => {
        this.setState({hour: ev.target.value})
    }

    onChangeMinute = ev => {
        this.setState({minute: ev.target.value})
    }

    onSchedule = () => {
        const {onClose} = this.props;
        if (Object.values(this.state).every(value => value !== null)) {
            const time = this.state.hour + this.state.minute
            const pn_schedule = {
                "day": this.state.day,
                "time": time
            }

            console.log(pn_schedule)
            onClose(true, pn_schedule)
        }
    }

    render() {
        const { onClose, pnData } = this.props
        const hours = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"]
        return (
            <div className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card" >
                    <header className="modal-card-head">
                        <p className="modal-card-title">Programar Notificación</p>
                    </header>

                    <div className="modal-card-body">
                        <div className="columns is-vcentered">
                            {/* <label className="is-6" htmlFor="weekday">Día de la semana:</label> */}
                            <div className="column is-6">
                                <label className="label is-6">Día de la semana:</label>
                            </div>

                            {/* <label className="is-6" htmlFor="time-input">Día de la semana:</label> */}
                            <div className="column is-6">
                                <label className="label is-6 is-vcentered">Hora:</label>
                            </div>
                        </div>
                        <div className="columns is-vcentered">
                            {/* <label htmlFor="weekday">Día de la semana:</label> */}
                            <div className="column">
                                <select className="input margin-right-5x"
                                    name="weekday"
                                    id="weekday"
                                    onChange={this.onChangeDay}
                                    >
                                        <option></option>
                                        <option value="mon">Lunes</option>
                                        <option value="tue">Martes</option>
                                        <option value="wed">Miercoles</option>
                                        <option value="thu">Jueves</option>
                                        <option value="fri">Viernes</option>
                                        <option value="sat">Sábado</option>
                                        <option value="sun">Domingo</option>
                                </select>
                            </div>

                            {/* <label htmlFor="time-input">Día de la semana:</label> */}
                            <div className="column is-6 columns is-vcentered">
                                <div className="column is-4 is-offset-2">
                                    <select className="input" 
                                        name="hours" id="hours"
                                        onChange={this.onChangeHour}>
                                        {hours.map((hour)=> (
                                            <option value={hour}>{hour}</option>
                                        ))}
                                    </select>
                                </div>
                                <h1>:</h1>
                                <div className="column is-4">
                                    <select className="input" 
                                        name="minutes" id="minutes"
                                        onChange={this.onChangeMinute}>
                                        <option value="00">00</option>
                                        <option value="30">30</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                    </div>

                    <footer className="modal-card-foot">
                        <div className="buttons">
                            <button className="button"
                                    onClick={() => this.onSchedule()}>Programar</button>
                            <button className="button is-danger"
                                    onClick={() => onClose(false)}>Cancelar</button>
                        </div>
                    </footer>
                </div>
            </div>
        );
    }
}

export default withRouter((SchedulePNModal));