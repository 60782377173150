/* global google */
import React, {Component} from 'react';
import {GoogleMap, Marker, withGoogleMap} from "react-google-maps";
import positionIcon from '../../images/position-icon-2.png'
import {withTranslation} from 'react-i18next';


const MAP_CONTAINER_STYLE = {position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'flex-end', alignItems: 'center'};

const SimpleMapWithLocation = withGoogleMap(withTranslation('translation')(props => {
  const renderMarkers = markers => {
    if (markers.length === 1) {
      return markers.map((marker, index) => (
        <Marker
          key={index}
          position={new window.google.maps.LatLng(marker.lat, marker.lon)}
          icon={positionIcon}
        />
      ))     
    } else {
      let stopNumber = 0;
      return markers.map((marker, index) => {
        stopNumber++;
        return (
          <Marker
            key={index}
            position={new window.google.maps.LatLng(marker.lat, marker.lon)}
            label={stopNumber.toString()}
          />
        );
      }
      )
    }
  }
  return (
  <GoogleMap ref={props.onMapMounted} defaultZoom={14} defaultCenter={props.center}
            defaultOptions={{streetViewControl: false, scaleControl: false, mapTypeControl: false,
                                  panControl: false, rotateControl: false, fullscreenControl: true}} disableDefaultUI>
    {renderMarkers(props.markers)}
  </GoogleMap>
)
}));

class SimpleMap extends Component {

  state = {
    center: { lat: -34.603722, lng: -58.381592, id: 1 },
    centered: false
  };

  componentWillMount() {
    const { location } = this.props;
      location.lng = location.lng || location.lon;
      this.setState({ location });
  }

  componentDidUpdate(props, prevProps) {
    if (prevProps.location !== this.props.location) {
      this.autoPan({ lat: this.props.location.lat, lng: this.props.location.lon });
      this.setState({ location: props.location });
    }
  }

  autoPan = (lastLocation) => {
    this._map && this._map.panTo(new google.maps.LatLng(lastLocation.lat, lastLocation.lng)) && this._map && this._map.setZoom(5);
  };

  handleMapMounted = (map) => {
    this._map = map;
    const { location } = this.state;
    const { markers } = this.props;
    this.autoPan(location);
    const bounds = new window.google.maps.LatLngBounds();
    if (this._map && markers) {
      this._map.props.children.forEach((child) => {
        if (child.type === Marker) {
            bounds.extend(new window.google.maps.LatLng(child.props.position.lat(), child.props.position.lng()));
        }
      })
      this._map.fitBounds(bounds);
    }
  };

  autoPan = this.autoPan.bind(this);
  handleMapMounted = this.handleMapMounted.bind(this);

  render() {
    const { centered, location } = this.state;
    const { markers } = this.props;

    if (location && !centered) {
      this.autoPan(location);
      this.setState({centered:true});
    }

    const listOfMarkers = markers ? markers : [location];

    return (
      <SimpleMapWithLocation
        onMapMounted={this.handleMapMounted}
        containerElement={<div style={MAP_CONTAINER_STYLE} />}
        mapElement={<div style={{ height: `100%`, width:  `100%`}} />}
        center={location}
        markers={listOfMarkers}/>
      );
  }
}


export default SimpleMap;
