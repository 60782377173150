import React, { Component } from "react";

class CollapsableCard extends Component {
    state = {
        isHidden: false
    }

    onCollapse = () => {
        const { isHidden } = this.state;
        this.setState({ isHidden: !isHidden });
    }

    render() {
        const { isHidden } = this.state;
        const { title, children } = this.props;
        return (
            <div className="card">
                <header className="card-header" onClick={this.onCollapse}>
                    <p className="card-header-title">{title}</p>
                    <a className="card-header-icon card-toggle">
                        {isHidden 
                            ? <i className="fa fa-angle-down"></i>
                            : <i className="fa fa-angle-up"></i>
                        }
                    </a>
                </header>
                <div className={["card-content", isHidden && "is-hidden"].join(" ")}>
                    {children}
                </div>
            </div>
        );
    }
}

export default CollapsableCard;