import {connect} from 'react-redux'
import CrtDetail from "../../components/crt/CrtDetail";
import {updateCrt} from "../../redux/fleet";

function mapStateToProps(state) {
  const { selected, fleetDetail } = state.fleet;
  return { selected, fleetDetail };
}

const mapDispatchToProps = dispatch => {
  return {
    saveCrt: (fleetId, crt) => {
      dispatch(updateCrt(fleetId, crt));
    }
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(CrtDetail);
