import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import SimpleMap from "../maps/SimpleMap";
import EditCrtModal from "./EditCrtModal";
import Timestamp from 'react-timestamp'
import CrtStatus from "./CrtStatus"

const DEFAULT_LOC = { lat: -34.603722, lng: -58.381592 };

class CrtDetail extends Component {

  state = {
    showEditModal: false
  };

  // componentWillMount() {
  //   const {fleetDetail, crt, selected} = this.props;
  //   if (selected && selected.rt_tracking) {
  //     this.props.getLastLocation(fleetDetail.id, crt.id)
  //   }
  // }

  onClickEdit = () => {
    this.setState({showEditModal: true});
  };

 onCloseEditModal = (shouldEdit, crt) => {
    let { fleetDetail } = this.props;

    if (shouldEdit) {
      // server call
      this.props.saveCrt(fleetDetail.id, crt);
    }

    this.setState({showEditModal: false});
  };

  render() {
    const { crt, selected, fleetDetail, t } = this.props;
    const { showEditModal } = this.state;

    const crtLocation = crt.last_known_location || DEFAULT_LOC;

    return (
      <div className="is-fullheight">

        {showEditModal &&
          <EditCrtModal onClose={this.onCloseEditModal} crt={crt}/>
        }

        <div className="column">
          <a className="button is-primary is-outlined is-small" disabled={crt.id === 0} onClick={() => {this.onClickEdit()}}>
            <span className="icon"><i className="fa fa-pencil is-small"></i></span>
            <span>{crt.crt_id}</span>
          </a>
        </div>

        <div className="column is-12">
          <div className="field is-grouped is-grouped-multiline">
            {crt.drivers.map(((driver, index) => (
              <div className="control" key={'dirvertag' + index}>
                <span className="tag is-light">{driver.name}</span>
              </div>
            )))}
          </div>
        </div>

        <div className="column is-12">
          <div><strong>{t('commons.from')}: </strong><br/><Timestamp time={crt.start_date / 1000} format="full"/></div><br/>
          <div><strong>{t('commons.to')}: </strong><br/>
              {crt.end_date ?
                <Timestamp time={crt.end_date/1000} format="full"/>
              :
                t('commons.never_date')
              }
          </div><br/>
          <div><strong>{t('crtDetail.status.label')}: </strong><br/><CrtStatus crt={crt}/></div>
        </div>

        {selected && selected.rt_tracking &&
          <div className="column is-12">
            <h2 className="subtitle">{t('crtDetail.lastLocation')}</h2>
            <div className="map-wrapper">
              <SimpleMap location={crtLocation}/>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default withTranslation('translation')(CrtDetail);
