import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import TripPathMap from '../../pages/TripPathMap';
import { BASE_URL } from '../../config'
import { checkStatus, parseJson } from '../../api/utils'
import Timestamp from 'react-timestamp'      
import { secondsToHms } from '../../utils/functions'


const styles = {
    marginRight: "10px"
};

class RoadmapRealTrips extends Component {

    state = {trips: null,
             loading: false,
             showingDetails: false,
             tripData: true,
             
    };

    getTrips = (fleetId, roadMapId) => {

        this.setState({ loading: true });

        let token = localStorage.getItem("token");
        const url = BASE_URL + '/api/fleet/' + fleetId + '/roadmap/' + roadMapId + '/stopover_trips_details';

        return fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ token
        }
        })
        .then(checkStatus)
        .then(parseJson)
        .then((json) => {
          const sorted_trips = json.sort((a, b) => a.timestamp - b.timestamp);
          this.setState({ loading: false, showingDetails: true, tripData: true, trips: sorted_trips });
        }).catch(error =>{
          console.log('Error fetching trips. Returning [].', error);
          this.setState({ loading: false, tripData: false, trips: [] });
        })

    };


    render() {

        const { fleetId, roadMapId, t } = this.props;

        if (this.state.trips == null && this.state.loading == false) {
          this.getTrips(fleetId, roadMapId);
        }

        const trips = this.state.trips;
       
        return (
            <div>
            { this.state.showingDetails && <div className="card">
                <div className="card-content">
                    <div className="columns">
                        <div className="column is-half">
                          <table className="table">
                            <thead>
                              <tr>
                                <th className="has-text-centered" style={{width: 0.1}}>{t('commons.trip_n')}</th>
                                <th className="has-text-centered">{t('commons.date')}</th>
                                <th className="has-text-centered">{t('commons.duration') + " [hh:mm:ss]"}</th>
                                <th className="has-text-centered">{t('commons.distance') + " [km]"}</th>
                                <th className="has-text-centered">{t('commons.speed') + " [km/h]"}</th>
                                <th className="has-text-centered">{t('tripsTable.score')}</th>
                              </tr>
                            </thead> 
                            <tbody>
                            {this.state.trips.map((selected, index) => (
                              <tr key={index}>
                                <td style={{width: 0.1}}>{index + 1}</td>
                                <td className="has-text-centered">{<Timestamp time={selected.timestamp/1000} format="full"/>}</td>
                                <td className="has-text-centered">{secondsToHms(selected.duration)}</td>
                                <td className="has-text-centered">{selected.distance.toFixed(2)}</td>
                                <td className="has-text-centered">{selected.speed.toFixed(2)}</td>
                                <td className="has-text-centered">{selected.total}</td>
                              </tr>
                            ))}
                            </tbody>

                          </table> 
                        </div>
                        <div className="column is-half map-container-on-modal">
                            <div className="column mapContainer">
                                <TripPathMap driverTrips={(trips == null || trips.length == 0) ? []:trips}
                                            tripsLoading={this.state.loading}
                                            mapTopPosition={0} mapBottomPosition={0}
                                            enableMessage={true}
                                            noDataMessage={t("driverEngagementTable.noTrips")}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div> }
          { !this.state.tripData && <h1>{t("driverEngagementTable.noTrips")}</h1>}
          </div>
        );
    }
}

export default withRouter(withTranslation('translation')(RoadmapRealTrips));