import React from 'react';
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom';
import { logoutUser } from '../redux/auth';
import logo from '../images/logo.png'
import { ROLE_DRIVER, ROLE_ADMIN } from '../model/Role'
import { selectFleet } from '../redux/fleet'
import { Redirect } from 'react-router';
import { withTranslation } from "react-i18next";
import DropdownSelect from '../components/DropdownSelect';

const ChiefDropdown = withTranslation('translation')((props) => {
  return (
    <div className="navbar-item has-dropdown is-hoverable nico">
      <NavLink className="navbar-link  is-active" activeClassName="link-active" to="/">{props.t('header.dropdown.title')}</NavLink>

      <div className="navbar-dropdown">
        <a className="navbar-item" onClick={() => (props.onSelectSection("/fleet/" + props.fleetDetail.id))}>{props.t('header.dropdown.dashboard')}</a>
        {props.fleet && props.fleet.rt_tracking &&
          <a className="navbar-item" onClick={() => (props.onSelectSection("/fleet/" + props.fleetDetail.id + "/fleetLocation"))}>{props.t('header.dropdown.live')}</a>
        }
        {props.fleet && props.fleet.show_routes &&
          <a className="navbar-item" onClick={() => (props.onSelectSection("/fleet/" + props.fleetDetail.id + "/trip"))}>{props.t('header.dropdown.routes')}</a>
        }
        {props.fleet && props.fleet.show_vehicles &&
          <a className="navbar-item" onClick={() => (props.onSelectSection("/fleet/" + props.fleetDetail.id + "/vehicle"))}>{props.t('header.dropdown.vehicles')}</a>
        }
        {props.fleet && props.fleet.crt_enabled &&
          <a className="navbar-item" onClick={() => (props.onSelectSection("/fleet/" + props.fleetDetail.id + "/crt"))}>{props.t('header.dropdown.crt')}</a>
        }
        {props.fleet && props.fleet.show_expense_management &&
          <a className="navbar-item" onClick={() => (props.onSelectSection("/fleet/" + props.fleetDetail.id + "/expenses"))}>{props.t('header.dropdown.expenses')}</a>
        }
        {props.fleet && props.fleet.show_expense_configuration &&
          <a className="navbar-item" onClick={() => (props.onSelectSection("/fleet/" + props.fleetDetail.id + "/expenses/config"))}>{props.t('header.dropdown.expenses_configuration')}</a>
        }
        <a className="navbar-item" onClick={() => (props.onSelectSection("/fleet/" + props.fleetDetail.id + "/reports"))}>{props.t('header.dropdown.reports')}</a>
        <a className="navbar-item" onClick={() => (props.onSelectSection("/fleet/" + props.fleetDetail.id + "/settings"))}>{props.t('header.dropdown.settings')}</a>
        {props.fleet && props.fleet.show_roadmaps &&
          <a className="navbar-item" onClick={() => (props.onSelectSection("/fleet/" + props.fleetDetail.id + "/roadMaps"))}>{props.t('header.dropdown.road_maps')}</a>
        }
        {props.fleet &&
          <a className="navbar-item" onClick={() => (props.onSelectSection("/fleet/" + props.fleetDetail.id + "/sendPushNotification"))}>{props.t('header.dropdown.pn_sender')}</a>
        }

      </div>
    </div>
  )
});

class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      toogle: false,
      navigate: false,
      redirectUrl: null
    }
  }

  handleBurguerClick = () => {
    this.setState({ toggle: !this.state.toggle });
  };

  onSelectFleet = (fleet, section) => {
    this.props.onSelectFleet(fleet);
    this.setState({ toggle: !this.state.toggle, redirect: section, navigate: true });
  };

  onSelectSection = (section) => {
    this.setState({ toggle: !this.state.toggle, redirect: section, navigate: true });
  };

  changeLanguage = (lng) => {
    this.props.i18n.changeLanguage(lng);
  };

  render() {
    const { isAuthenticated, user, selected, fleets, fleetDetail } = this.props;
    const { redirect, navigate } = this.state;

    const getLanguageString = (lang) => {
      switch (lang.substring(0, 2).toLowerCase()) {
        case "es":
          return "ES";
        case "en":
          return "EN";
        case "pt":
          return "PT";
        case "fr":
          return "FR";
      }
    }

    if (navigate) {
      this.setState({ redirect: null, navigate: false });
      return (<Redirect to={redirect} push={true} />)
    }

    // object with all the fleets to pass to the fleet selection dropdown
    const fleetOptions = fleets.map(fleet => ({ label: fleet.name, value: fleet }))

    return (
      <div className="hero-head">
        <nav className="navbar header card">
          <div className="navbar-brand">
            <a className="navbar-item">
              <img src={logo} alt="Woocar" onClick={() => this.onSelectSection('/')} />
            </a>
            <div className={this.state.toggle ? "navbar-burger is-active" : "navbar-burger"}
              data-target="navMenuEnd"
              onClick={this.handleBurguerClick}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

          <div id="navMenuEnd" className={this.state.toggle ? "navbar-menu is-active" : "navbar-menu"}>

            {<div className="navbar-end">
              {isAuthenticated && user.role !== ROLE_DRIVER && user.fleets.length > 0 && <DropdownSelect placeholder={selected ? selected.name : "Mis flotas"}
                options={fleetOptions}
                onChange={opt => this.onSelectFleet(opt.value, '/fleet/' + opt.value.id)}
                maxMenuHeight={600}
                className='navbar-item'
              />}
              {isAuthenticated && user.role !== ROLE_DRIVER && user.fleets.length > 0 && <ChiefDropdown onSelectSection={this.onSelectSection} fleet={this.props.selected} fleetDetail={fleetDetail} />}
              {/* Language and user profile */}
              <div className="navbar-item has-dropdown is-hoverable">
                <NavLink className="navbar-link  is-active" to="/">
                  {isAuthenticated && user.email}
                  {!isAuthenticated && <span>{getLanguageString(this.props.i18n.language)}</span>}
                </NavLink>
                <div className="navbar-dropdown">
                  {isAuthenticated && <a className="navbar-item is-active" onClick={this.props.onLogoutClick}>{this.props.t('authentication.logout')}</a>}
                  <a className="navbar-item is-active" onClick={() => this.changeLanguage('es')}>ES</a>
                  <a className="navbar-item is-active" onClick={() => this.changeLanguage('en-us')}>EN</a>
                  <a className="navbar-item is-active" onClick={() => this.changeLanguage('pt')}>PT</a>
                  <a className="navbar-item is-active" onClick={() => this.changeLanguage('fr')}>FR</a>
                </div>
              </div>

              {!isAuthenticated &&
                <NavLink className="navbar-item" to="/login">{this.props.t('authentication.login')}</NavLink>
              }
            </div>
            }
          </div>

        </nav>
      </div>
    );
  }
}

// These props come from the application's
// state when it is started
function mapStateToProps(state) {

  const { isAuthenticated, user } = state.auth;
  const { selected, fleets, fleetDetail } = state.fleet;
  const { location } = state;

  return {
    isAuthenticated,
    location,
    user,
    selected,
    fleets,
    fleetDetail
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLogoutClick: () => {
      dispatch(logoutUser())
    },
    onSelectFleet: fleet => {
      dispatch(selectFleet(fleet))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(withTranslation('translation')(Header))
