export const STOP_OVER_TYPE = [
  {value: 1, textKey: "stopOverTable.type.client", className: "fa-map-marker"},
  {value: 2, textKey: "stopOverTable.type.security", className: "fa-shield"},
  {value: 3, textKey: "stopOverTable.type.rest", className: "fa-bed"},
  {value: 4, textKey: "stopOverTable.type.base", className: "fa-building-o"}
];

export const MARKER_TYPE = [
    {value: 1, textKey: "markerTable.type.roadRisk"},
    {value: 2, textKey: "markerTable.type.pointOfInterest"}
  ];

export const getTextFromStopValue = (value) => {
    const found = STOP_OVER_TYPE.find(so => so.value === value);
    return found.textKey
}

export const getTextFromMarkerValue = (value) => {
    const found = MARKER_TYPE.find(so => so.value === value);
    return found.textKey
}

export const getClassNameFromStopValue = (value) => {
    const found = STOP_OVER_TYPE.find(so => so.value === value);
    return found.className
}

export const EMPTY_STOP_OVER= {
    "name": "",
    "description": "",
    "duration": 0,
    "stopover_type": 1,
    "address": "",
    "client": "",
    "location": {
      "lat": null,
      "lon": null
    },
    id: 0,
    "client_id":null,
    "external_id": null 
};

export const EMPTY_MARKER={
    "name": "",
    "description": "",
    "marker_type": 1,
    "address": "",
    "location": {
      "lat": null,
      "lon": null
    },
    id: 0,
};

export const EMPTY_POLYGON_MARKER={
    "name": "",
    "description": "",
    "marker_type": 1,
    "points": [],
    id: 0,
};

export const ROAD_MAP_TYPE = [
    {value: 1, textKey: "roadMapTable.status.waiting"},
    {value: 2, textKey: "roadMapTable.status.inProgress"},
    {value: 3, textKey: "roadMapTable.status.finished"},
    {value: 4, textKey: "roadMapTable.status.cancelled"}
];

export const getTextFromRoadMapValue = (value) => {
    if (!value) {
        return ROAD_MAP_TYPE[0].textKey
    }
    const found = ROAD_MAP_TYPE.find( rm => rm.value === value);
    return found.textKey
}

export const getDate = (dateTimestamp) => {
    //timestamp to millisecond
    const dateObject = dateTimestamp ? new Date (dateTimestamp * 1000) : new Date()
    return dateObject.toLocaleString()
}

export const getLocalDatetimeFromTimestamp = (dateTimestamp) => {
    const dateObject = new Date (dateTimestamp * 1000)
    const year = dateObject.getFullYear()
    const month = (dateObject.getMonth() + 1) >= 10 ? (dateObject.getMonth() + 1) : "0" + (dateObject.getMonth() + 1)
    const day = dateObject.getDate() >= 10 ? dateObject.getDate() : "0" + dateObject.getDate()
    const hour = dateObject.getHours() >= 10 ? dateObject.getHours() : "0" + dateObject.getHours()
    const minutes = dateObject.getMinutes() >= 10 ? dateObject.getMinutes() : "0" + dateObject.getMinutes()
    const localDateTime = year + "-" + month + "-" + day + "T" + hour + ":" + minutes
    return localDateTime
}

export const getTodayLocalDateTime = () => {
    const today = new Date()
    const ts = today.getTime()
    return getLocalDatetimeFromTimestamp(ts / 1000)
}

export const getTimestampFromLocalDateTime = (dateTime) => {
    const dateObject = new Date(dateTime)
    const ts = dateObject.getTime()
    return ts / 1000
}

export const getTodayTs = () => {
    const today = new Date()
    const ts = today.getTime()
    return parseInt(ts / 1000)
}

export const EMPTY_ROAD_MAP = {
    "id": 0,
    "description": "",
    "drivers": [],
    "name": "",
    "stopovers": [],
    "start_date": getTodayTs(),
    "vehicle": null
};