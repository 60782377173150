import React, {Component} from 'react';
import PropTypes from 'prop-types'
import Timestamp from 'react-timestamp'
import moment from 'moment'
import {withTranslation} from "react-i18next";

const TagByState = withTranslation('translation')((props) => {
  switch(props.state){
    case 1:
      return ( <span className="tag is-success">{props.t('alertsDescription.tag.success')}</span>);
    case 2:
      return ( <span className="tag is-warning">{props.t('alertsDescription.tag.warning')}</span>);
    case 3:
      return ( <span className="tag is-danger">{props.t('alertsDescription.tag.danger')}</span>);
    default:
      return "";
  }
});

const getDaysSinceDate = (date) => {
  const then = moment(date);
  const ms = moment(Date()).diff(then);
  const d = moment.duration(ms);

  if (d.asDays() < 3 ) {
    return 1;
  } else if (d.asDays() < 7) {
    return 2;
  } else {
    return 3;
  }
};

const InvalidDate = withTranslation('translation')((props) => {
  return <span>{props.t('alertsDescription.non')}</span>
});

const ValidDate= withTranslation('translation')((props) => {
  return <Timestamp time={props.ts / 1000} format="full"/>
});

const DatePill = withTranslation('translation')((props) => {
  const date = props.ts;
  if (date === 0) {
    return <InvalidDate />;
  }
  return <ValidDate ts={date} />;
});

const GPSOn = withTranslation('translation')((props) => {
  return <span className="tag is-success">{props.t('alertsDescription.turnOn')}</span>
});

const GPSOff = withTranslation('translation')((props) => {
  return <span><span>{props.t('alertsDescription.gpsOff')} </span><span className="tag is-danger"><Timestamp time={props.ts / 1000} format="full" /></span></span>
});

const GPSUnknown = withTranslation('translation')((props) => {
  return <span className="tag is-warning">{props.t('alertsDescription.unknown')}</span>
});

const GpsPill = withTranslation('translation')((props) => {
  const gps = props.status;

  if (gps.status.includes('GPS_ON') || gps.status.includes('NETWORK_ON')
    || gps.status.includes('|GPS_ON|') || gps.status.includes('|NETWORK_ON|')) {
    return <GPSOn desc={gps.status} />;
  }

  if (gps.status === "GPS_OFF" || gps.status.includes('NETWORK_ON')
    || gps.status.includes('|GPS_OFF|') || gps.status.includes('|NETWORK_OFF|')) {
    return <GPSOff ts={gps.ts}/>;
  }
  return <GPSUnknown/>;
});

const PrivacyPill = withTranslation('translation')((props) => {
  const type = props.type;

  const bt = props.bt;
  const widget = props.widget;

  var st = 'unkown';
  var ts = 0;

  if ("widget" === type) {
    ts = widget.ts;
    if ("ON_DUTY" === widget.status) {
      st = true;
    } else if ("OFF_DUTY" === widget.status) {
      st = false;
    }
  } else if ("bluetooth" === type) {
    ts = bt.ts;
    if ("GPS_ON" === bt.status) {
      st = true;
    } else if ("GPS_OFF" === bt.status) {
      st = false;
    }
  }

  if (st === true) {
    return <span className="tag is-success">{props.t('alertsDescription.turnOn')}</span>;
  }
  if (st === false) {
    return <span><DatePill ts={ts} /><span className="tag is-danger">{props.t('alertsDescription.turnOff')}</span></span>;
  }
  return <span className="tag is-warning">{props.t('alertsDescription.unknown')}</span>;
});

// const BTPill = withTranslation('translation')((props) => {
//   const bt = props.status;
//   if (bt.status === 'GPS_ON') {
//     return <GPSOn/>;
//   }
//   if (bt.status === "GPS_OFF") {
//     return <GPSOff ts={bt.ts}/>;
//   }
//   return <GPSUnknown/>;
// });

// const AppVersionPill = withTranslation('translation')((props)  => {
//   const version = props.version;
//   return <span className="tag is-success">{version}</span>;
  //
  // if (version === '4448' || version === '2.0.32-46') {
  //   return <span className="tag is-success">{version}</span>;
  // } else if (version === '4408' || version === '2.0.22-37') {
  //   return <span className="tag is-warning">{version}</span>;
  // } else {
  //   return <span className="tag is-danger">{version}</span>;
  // }
// });

class AlertsDescription extends Component {

  render() {
    const {lastLogin, lastTrip, deviceGps, deviceBT, deviceWidget, fleet_privacy, app_version, t} = this.props;

    return (
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">{t('alertsDescription.title')}</p>
        </header>
        <div className="card-content alerts-box">
          <div className="media">
            <div className="media-content">
              <p>
                <strong>{t('alertsDescription.lastLogin')}: </strong><DatePill ts={lastLogin} />
                <TagByState state={getDaysSinceDate(lastLogin)} />
              </p>
              <p>
                <strong>{t('alertsDescription.lastTrip')}: </strong><DatePill ts={lastTrip} />
                <TagByState state={getDaysSinceDate(lastTrip)} />
              </p>
              <p><strong>{t('alertsDescription.gpsState')}: </strong><GpsPill status={deviceGps} /></p>
              {fleet_privacy && fleet_privacy !== 'None' && <p><strong>{t('alertsDescription.privacyControl')}: </strong><PrivacyPill widget={deviceWidget} bt={deviceBT} type={fleet_privacy} /></p>}
              <p><strong>{t('alertsDescription.appVersion')}: </strong>{app_version}</p>
            </div>
          </div>
        </div>

      </div>
    );
  }
}


AlertsDescription.propTypes = {
  lastLogin: PropTypes.number.isRequired,
  deviceGps: PropTypes.object.isRequired,
  deviceBT: PropTypes.object.isRequired,
  deviceWidget: PropTypes.object.isRequired,
  lastTrip: PropTypes.number.isRequired,
  app_version: PropTypes.string.isRequired,
  fleet_privacy: PropTypes.string.isRequired,
};

export default withTranslation('translation')(AlertsDescription);
