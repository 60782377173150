/* global google */
import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {GoogleMap, Marker, OverlayView, withGoogleMap} from "react-google-maps";
import idleIcon from '../images/marker-grey.png'
import drivingIcon from '../images/marker.png'
import positionIcon from '../images/position-icon-2.png'
import Timestamp from 'react-timestamp'
import Loading from '../components/Loading'
import {withTranslation} from 'react-i18next';


const getPixelPositionOffset = (width, height) => {
  return {x:-24, y:-64}
};

const getPixelPositionOffset2 = (width, height) => {
  return {x: (50), y: -(height/2)}
};

const containerStyle = {position: 'absolute', top: 50, left: 0, right: 0, bottom: 0, justifyContent: 'flex-end', alignItems: 'center'};


const MarkerClusteredTag = withGoogleMap(withTranslation('translation')(props => (

  <GoogleMap ref={props.onMapMounted} defaultZoom={5} defaultCenter={props.center}>

      {props.markers.map((marker, index) => (
        <div key={index+'div'}>

        <Marker
          position={{ lat: marker.lat, lng: marker.lng }}
          key={index+'marker'}
          icon={positionIcon}
          onMouseOver={() => props.showPopup(marker)}
          onMouseOut={() => props.closePopup(marker)}>

          <OverlayView key={index+'a'} position={{ lat: marker.lat, lng: marker.lng }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} getPixelPositionOffset={getPixelPositionOffset}>
            <div className="iconContainer"
                 onMouseEnter={() => props.showPopup(marker)}
                 onMouseLeave={() => props.closePopup(marker)}>
              <img src={marker.driving? drivingIcon: idleIcon}/>
              <div style={{position:'absolute', top: '10px', width: '100%'}}>
                <p className="iconText">{marker.name.substr(0,3)}</p>
              </div>
            </div>
          </OverlayView>

          {marker === props.targeted &&
          <OverlayView key={index+'b'} position={{ lat: marker.lat, lng: marker.lng }}
            mapPaneName={OverlayView.OVERLAY_LAYER} getPixelPositionOffset={getPixelPositionOffset2}>
              <div className="box" style={{minWidth:'250px'}}>
                <div className="content">
                  <p className="title is-6"><strong>{marker.name}</strong></p>
                  <hr/>
                  {marker.crt_id &&
                    <p className="subtitle is-6"><strong>{props.t('fleetLocation.crt')}: </strong> {marker.crt_id}</p>
                  }
                  <p className="subtitle is-6"><strong>{props.t('fleetLocation.time')}: </strong> <Timestamp time={marker.ts / 1000} format="full"/></p>
                  <p className="subtitle is-6"><strong>{props.t('fleetLocation.state')}: </strong> {marker.driving? props.t('fleetLocation.driving'): props.t('fleetLocation.stopped')}</p>
                </div>
              </div>
          </OverlayView>
          }
        </Marker>

        </div>
      ))}

  </GoogleMap>
)));

class FleetLocation extends Component {

  state = {
    center: { lat: -34.603722, lng: -58.381592, id:1 },
    centered: false,
    showInfo: null
  };

  componentDidMount = () => {
    if (this.props.selected) {
      this.props.getLastLocation(this.props.selected.id);
    }
    this.interval = setInterval(this.updateLocations, 60000);
  };

  // createIntervals = () => {
  //   if (this.props.selected) {
  //     this.props.getLastLocation(this.props.selected.id);
  //   }
  //   this.interval = setInterval(this.updateLocations, 60000);
  // };

  updateLocations = () => {
    if (this.props.selected) {
      setTimeout(this.props.getLastLocation(this.props.selected.id), 100);
    }
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  showInfo = (targetMarker) => {
    this.setState({targeted: targetMarker});
  };

  hideInfo = (targetMarker) => {
    this.setState({targeted: null});
  };

  autoZoomMap = (lastLocation) => {
    var bound = new google.maps.LatLngBounds();
    for (let i of lastLocation) {
      bound.extend(new google.maps.LatLng(i.lat, i.lng));
    }
    this._map && this._map.fitBounds(bound);
  };

  handleMapMounted = (map) => {
    this._map = map;
    const { lastLocation } = this.props;
    this.autoZoomMap(lastLocation)
  };

  autoZoomMap = this.autoZoomMap.bind(this);
  handleMapMounted = this.handleMapMounted.bind(this);
  showInfo = this.showInfo.bind(this);
  hideInfo = this.hideInfo.bind(this);

  render() {
    const { lastLocation } = this.props;
    const { centered } = this.state;
    const { selected } = this.props;

    if (!selected) {
      return (
        <Loading/>
      )
    }

    if (this.props.selected !== this.selected) {
      this.selected = selected;
      setTimeout(this.updateLocations, 100);
    }

    if (lastLocation.length > 0 && !centered) {
      this.autoZoomMap(lastLocation);
      this.setState({centered:true});
    }

    return (
      <MarkerClusteredTag
        onMapMounted={this.handleMapMounted}
        containerElement={<div style={containerStyle} />}
        mapElement={<div style={{ height: `100%`, width:  `100%`}} />}
        markers={lastLocation}
        showPopup={this.showInfo}
        closePopup={this.hideInfo}
        center={this.state.center}
        targeted={this.state.targeted}/>
      );
    }
  }

FleetLocation.propTypes = {
  lastLocation: PropTypes.array.isRequired,
  getLastLocation: PropTypes.func.isRequired,
  selected: PropTypes.object
};

export default FleetLocation;
