import React, { Component } from 'react';
import emptyLogo from '../images/w-logo.png'
import PropTypes from 'prop-types'
import {withTranslation} from "react-i18next";

class FleetDescription extends Component {

  render() {
    const { fleetName, drivers, kms, t, privacy, day_start, day_end} = this.props;
    let {logo} = this.props;

    if (logo === "img/no-image-box.png") {
      logo = emptyLogo;
    }

    return (
      <div className="card">
        <div className="card-content">
          <div className="media">
            <div className="media-left">
              <figure className="image is-128x128">
                <img src={logo} alt={fleetName} ref={img => this.img = img} onError={()=>{
                  this.img.src = emptyLogo;
                  this.img.alt = t('fleeDescription.invalidImageUrl');
                }}/>
              </figure>
            </div>
            <div className="media-content">
              <p className="title is-4">{fleetName}</p>
              <p className="text is-6">{t('fleeDescription.drivers', {count: drivers.length})}</p>
              <p className="text is-6">{t('fleeDescription.distance', {count: kms})}</p>
              { !privacy && <p className="text is-6" className="card-header-title">{t('fleetDescription.privacy-none')}</p> }
              { privacy === 'widget' && <p className="text is-6">{t('fleetDescription.privacy-widget')}</p> }
              { privacy === 'bluetooth' && <p className="text is-6">{t('fleetDescription.privacy-default')}</p> }
              { privacy === 'custom' && <p className="text is-6">{t('fleetDescription.privacy-custom', {desde:day_start, hasta:day_end})}</p> }
              { privacy === 'None' && <p className="text is-6">{t('fleetDescription.privacy-none')}</p> }
            </div>
          </div>
        </div>

      </div>
    );
  }
}


FleetDescription.propTypes = {
  fleetName: PropTypes.string.isRequired,
  drivers: PropTypes.array.isRequired,
  kms: PropTypes.number.isRequired,
  logo: PropTypes.string.isRequired,
  day_start: PropTypes.string,
  day_end: PropTypes.string,
};

export default withTranslation('translation')(FleetDescription);
