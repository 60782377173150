import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import connect from "react-redux/es/connect/connect";
import ExpensesChart from "../charts/ExpensesChart";
import ExpensesMap from "../expenses/ExpensesMap";
import ExpensesList from "../expenses/ExpensesList";
import ExpensesAlertList from "../expenses/ExpensesAlertList";
import {getExpenses} from "../../redux/fleet";
import Loading from "../Loading";

const EMPTY_EXPENSE = {
  evolution: [],
  items: [],
  alerts: []
};

class TabVehicleExpenses extends Component {

  componentWillMount() {
    const {fleetId, vehicleId} = this.props;
    this.props.getExpenses(fleetId, vehicleId);
  }

  render() {
    const { expenses } = this.props;


    return (
      <div>
        {!expenses && <Loading wait={0}/>}

        <div className="columns">

          <div className="column">
            <div className="columns">
              <div className="column">
                <ExpensesChart data={expenses ? expenses.evolution:EMPTY_EXPENSE.evolution}/>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <ExpensesMap expenses={expenses ? expenses.items:EMPTY_EXPENSE.items}/>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="columns">
              <div className="column is-two-thirds">
                <ExpensesList expenses={expenses ? expenses.items:EMPTY_EXPENSE.items}/>
              </div>
              <div className="column">
                <ExpensesAlertList alerts={expenses ? expenses.alerts:EMPTY_EXPENSE.alerts}/>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { selected, fleetDetail, expenses, loading } = state.fleet;
  return { selected, fleetDetail, expenses, loading};
}

const mapDispatchToProps = dispatch => {
  return {
    getExpenses: (fleetId, vehicleId) => {
      dispatch(getExpenses(fleetId, undefined, undefined, undefined, undefined, vehicleId))
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(TabVehicleExpenses))
