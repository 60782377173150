import React, { Component } from 'react';
import Timesamp from 'react-timestamp'
import { secondsToHms } from '../../utils/functions'
import TripPathMap from "../../pages/TripPathMap";
import 'react-toastify/dist/ReactToastify.min.css'
import {withTranslation} from "react-i18next";


class TripsModal extends Component {

  render() {
    const {onCloseTripDetail,selected,loading,t} = this.props;
    return (
          <div className="modal is-active" style={{zIndex:10000}}>
            <div className="modal-background" onClick={onCloseTripDetail}></div>

            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">
                  {t('tripsTable.modal.title')}<Timesamp time={selected.timestamp/1000} format="date"/>
                </p>
              </header>
              <section className="modal-card-body trip-detail-popup-body">
                <TripPathMap driverTrips={[selected]}
                             tripsLoading={loading}
                             mapTopPosition={65} mapBottomPosition={selected !== undefined ? 161:41}
                             enableMessage={true}
                             noDataMessage={t('driverDetail.tripsDisabled')}
                             customMessage={selected.tags}
                />
              </section>

              <footer className="modal-card-foot">
              { selected !== undefined &&

                <div className="trip-detail-popup-foot">
                  <div className="columns is-mobile">
                    <div className="column is-4">
                      <h1>{t('commons.date')}</h1>
                      <p>{<Timesamp time={selected.timestamp / 1000} format="full"/>}</p>
                    </div>
                    <div className="column">
                      <h1>{t('commons.duration')}</h1>
                      <p>{secondsToHms(selected.duration)}</p>
                    </div>
                    <div className="column">
                      <h1>{t('commons.distance')}</h1>
                      <p>{t('commons.interpolation.distance', {count: selected.distance.toFixed(2)})}</p>
                    </div>
                    <div className="column">
                      <h1>{t('commons.speed')}</h1>
                      <p>{t('commons.interpolation.speed', {count: selected.speed.toFixed(2)})}</p>
                    </div>
                  </div>
                  <div className="columns is-mobile">
                    <div className="column">
                      <h1>{t('tripsTable.score')}</h1>
                      <p>{selected.total}</p>
                    </div>
                    <div className="column">
                      <h1>{t('commons.control')}</h1>
                      <p>{selected.control}</p>
                    </div>
                    <div className="column">
                      <h1>{t('commons.caution')}</h1>
                      <p>{selected.caution}</p>
                    </div>
                    <div className="column">
                      <h1>{t('commons.focus')}</h1>
                      <p>{selected.focus}</p>
                    </div>
                    <div className="column">
                      <h1>{t('commons.eco')}</h1>
                      <p>{selected.eco}</p>
                    </div>
                  </div>
                </div>

              }
              </footer>

            </div>

            <button className="modal-close is-large" onClick={onCloseTripDetail}></button>
          </div>
    );
  }
}

export default withTranslation('translation')(TripsModal);
