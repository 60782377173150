import React, {Component} from "react";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import classNames from "classnames";
import {STOP_OVER_TYPE} from "./constants"
import SimpleMap from "../maps/SimpleMap";
import SearchBoxMap from "../maps/SearchBoxMap";

class StopOverEditModal extends Component {

    state = {
        stopOver: {},
        wasValidated: false
    };

    componentWillMount() {
        const {stopOver} = this.props;
        this.setState({stopOver: stopOver});
    }

    getValidationClassName = (isValid) => {
        const {wasValidated} = this.state;

        if (wasValidated) {
            if (isValid) {
                return 'is-success';
            } else {
                return 'is-danger';
            }
        } else {
            return '';
        }
    };

    onChangeInput = ev => {
        let {stopOver} = this.state;
        if (ev.target.name === "stopover_type" || ev.target.name === "duration") {
            stopOver[ev.target.name] = parseInt(ev.target.value);
            this.setState({stopOver: stopOver});
        } else {
            if (ev.target.name === "lat" || ev.target.name === "lon") {
                stopOver.location[ev.target.name] = ev.target.value;
            } else {
                stopOver[ev.target.name] = ev.target.value;
            }
            this.setState({stopOver: stopOver});
        }
    }

    isFormIncomplete(stopOver) {
        return isNaN(stopOver.location.lat)  || isNaN(stopOver.location.lon) || !stopOver.address || isNaN(stopOver.duration) || !stopOver.name 
    }

    onSave = () => {
        let {stopOver} = this.state;
        let isIncomplete = this.isFormIncomplete(stopOver);
        const {onClose} = this.props;
        if (isIncomplete) {
            this.setState({wasValidated: true})
        } else {
            stopOver.location.lat = parseFloat(stopOver.location.lat)
            stopOver.location.lon = parseFloat(stopOver.location.lon)
            onClose(true, stopOver);
        }
    };

    locationChanged = places => {
        return places.forEach(({ formatted_address, geometry: { location } }) => {
            this.setState({ stopOver: {
                    ...this.state.stopOver,
                    address: formatted_address,
                    location: {
                        lat: parseFloat(location.lat()),
                        lon: parseFloat(location.lng()),
                    },
                } });
        });
    }

    render() {
        const {onClose, t, shouldShowExternalIds} = this.props
        const {stopOver} = this.state
        return (
            <div className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card width-in-four-fifths">
                    <header className="modal-card-head">
                        {stopOver.id ?
                            <p className="modal-card-title">{t('stopOverTable.edit.editTitle', {name: stopOver.name})}</p> :
                            <p className="modal-card-title">{t('stopOverTable.edit.newTitle')}</p>
                        }
                    </header>

                    <section className="modal-card-body has-text-left">

                        <div className="columns">
                            <div className="column is-half">
                                <div className="columns">
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">{t('stopOverTable.edit.fields.name')}</label>
                                            <div className="control">
                                                <input
                                                    className={classNames('input', this.getValidationClassName(stopOver.name !== ""))}
                                                    type="text" value={stopOver.name} name="name"
                                                    onChange={this.onChangeInput}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">{t('stopOverTable.edit.fields.description')}</label>
                                            <div className="control">
                                                <input
                                                    className={classNames('input', this.getValidationClassName(true))}
                                                    type="text" value={stopOver.description} name="description"
                                                    onChange={this.onChangeInput}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">{t('stopOverTable.edit.fields.address')}</label>
                                            <div className="control">
                                                <SearchBoxMap locationChanged={this.locationChanged}>
                                                    <input
                                                        className={classNames('input', this.getValidationClassName(stopOver.address !== ""))}
                                                        placeholder=""
                                                        type="text" value={stopOver.address} name="address"
                                                        onChange={this.onChangeInput}/>
                                                </SearchBoxMap>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column is-half">
                                        <div className="field">
                                            <label className="label">{t('stopOverTable.edit.fields.lat')}</label>
                                            <div className="control">
                                                <input
                                                    disabled
                                                    className={classNames('input', this.getValidationClassName(stopOver.location.lat != ""))}
                                                    value={stopOver.location.lat} name="lat"
                                                    onChange={this.onChangeInput} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column is-half">
                                        <div className="field">
                                            <label className="label">{t('stopOverTable.edit.fields.lon')}</label>
                                            <div className="control">
                                                <input
                                                    disabled
                                                    className={classNames('input', this.getValidationClassName(stopOver.location.lon != ""))}
                                                    value={stopOver.location.lon} name="lon"
                                                    onChange={this.onChangeInput} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column is-half">
                                        <div className="field">
                                            <label className="label">{t('stopOverTable.edit.fields.duration')}</label>
                                            <div className="control">
                                                <input
                                                    className={classNames('input', this.getValidationClassName(!isNaN(stopOver.duration) && stopOver.duration))}
                                                    type="number" value={stopOver.duration} name="duration"
                                                    onChange={this.onChangeInput} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column is-half">
                                        <div className="field">
                                            <label className="label">{t('stopOverTable.edit.fields.type')}</label>
                                            <div className="control">
                                                <div className="select is-fullwidth">
                                                    <select value={stopOver.stopover_type} name="stopover_type"
                                                        onChange={this.onChangeInput}>
                                                        {
                                                            STOP_OVER_TYPE.map(((type) => (
                                                                <option key={'stop_over-type-' + type.value}
                                                                    value={type.value}>{t(type.textKey)}</option>
                                                            )))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {stopOver.stopover_type === 1 && <div className="columns">
                                    <div className="column is-fullwidth">
                                        <div className="field">
                                            <label className="label">{t('stopOverTable.edit.fields.client')}</label>
                                            <div className="control">
                                                <input
                                                    className={classNames('input', this.getValidationClassName(stopOver.client !== ""))}
                                                    type="text" value={stopOver.client} name="client"
                                                    onChange={this.onChangeInput}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {shouldShowExternalIds && (
                                    <div className="columns">
                                        <div className="column is-half">
                                            <div className="field">
                                                <label className="label">{t('stopOverTable.edit.fields.clientId')}</label>
                                                <div className="control">
                                                    <input
                                                        className={classNames('input')}
                                                        value={stopOver.client_id} name="client_id"
                                                        onChange={this.onChangeInput} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column is-half">
                                            <div className="field">
                                                <label className="label">{t('stopOverTable.edit.fields.externalId')}</label>
                                                <div className="control">
                                                    <input
                                                        className={classNames('input')}
                                                        value={stopOver.external_id} name="external_id"
                                                        onChange={this.onChangeInput} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="column is-half map-container-on-modal">
                                <div className="column mapContainer">
                                    <SimpleMap location={this.state.stopOver.location} />
                                </div>
                            </div>
                        </div>
                    </section>

                    <footer className="modal-card-foot">
                        <div className="buttons">
                            <button className="button is-primary"
                                    onClick={() => this.onSave()}>{t('commons.save')}
                            </button>
                            <button className="button"
                                    onClick={() => onClose(false, stopOver)}>{t('commons.cancel')}
                            </button>
                        </div>
                    </footer>
                </div>
            </div>
        );
    }
}

export default withRouter(withTranslation('translation')(StopOverEditModal));