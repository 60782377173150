import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import { getTextFromStopValue } from './constants'

class StopOverRow extends Component {

  render() {
      const { onDelete ,stopOver, onEdit, onDuplicate, t} = this.props
      return (
          <div>
            <div className="card" style={{background: '#f8f8f8'}}>
                <header className="card-header">
                    <div className="column is-9">
                        <p className="card-header-title">{stopOver.name}</p>
                    </div>
                    <div className="column is-3">
                        <div className="is-pulled-right">
                            <button className="button is-primary is-outlined margin-right-5x"
                                    onClick={() => {onDuplicate(stopOver)}}><i className="fa fa-clone"></i></button>
                            <button className="button is-primary is-outlined margin-right-5x"
                                    onClick={() => {onEdit(stopOver)}}><i className="fa fa-pencil"></i></button>
                            <button className="button is-primary is-outlined"
                                    onClick={() => {onDelete(stopOver)}}><i className="fa fa-times"></i></button>
                        </div>
                    </div>
                </header>
                <div className="card-content">
                    <div className="columns is-vcentered">
                        <div className="column is-2">
                            <span className="tag is-primary">{t(getTextFromStopValue(stopOver.stopover_type))}</span>
                        </div>
                        <div className="column is-2">
                            {stopOver.address}
                        </div>
                        <div className="column is-2">
                            {"duración: " + stopOver.duration + " M" }
                        </div>
                        <div className="column">
                            {stopOver.description}
                        </div>
                    </div>
                </div>
            </div>
            <br></br>
          </div>
      );
  }
}

export default withRouter(withTranslation('translation')(StopOverRow));