import {combineReducers} from 'redux'

import auth from './auth'
import fleet from './fleet'
import vehicle from './vehicle'
import fleetSettings from './fleetSettings'
import roadMap from "./roadMap";
import stops from "./stops"
import markers from "./markers"
import polygonMarkers from "./polygonMarkers"

export default combineReducers({
  auth,
  fleet,
  fleetSettings,
  vehicle,
  roadMap,
  stops,
  markers,
  polygonMarkers
})