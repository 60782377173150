import React, { Component } from "react";
import { withGoogleMap, GoogleMap, Polygon } from "react-google-maps";
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
import _ from "lodash";

const MAP_CONTAINER_STYLE = {position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'flex-end', alignItems: 'center'};

let latestPolygon;

const Map = withGoogleMap(
(({ isEdition, onEdit, onMapMounted, onPolygonComplete, onPolygonMounted, polygon }) =>
  <GoogleMap
    defaultZoom={14}
    defaultCenter={new window.google.maps.LatLng(-34.603722, -58.381592)}
    ref={onMapMounted}
  >
    {!isEdition ? (
      <DrawingManager
        defaultOptions={{
          drawingControl: true,
          drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [
              window.google.maps.drawing.OverlayType.POLYGON
            ],
          },
        }}
        onPolygonComplete={onPolygonComplete}
      />
    ) : (
      <Polygon
        ref={onPolygonMounted}
        editable
        draggable
        path={polygon}
        onMouseUp={onEdit}
        onDragEnd={onEdit}
      />
    )}
  </GoogleMap>
));

class DrawableMap extends Component {

  constructor(props) {
    super(props)

    this.polygonRef = React.createRef();
    this.listenersRef = React.createRef();
    this.mapRef = React.createRef();

    this.state = {
      path: []
    }
  }

  componentDidMount() {
    const { points } = this.props;
    this.setState({ path: points });
  }

  autoZoomMap = (points) => {
    if (!this.mapRef.current || _.isEmpty(points)) return;

    var bound = new window.google.maps.LatLngBounds();
    for (let i of points) {
      bound.extend(new window.google.maps.LatLng(i.lat, i.lng));
    }
    this.mapRef.current.fitBounds(bound);
  };

  autoPan = (location) => {
    this.mapRef.current && this.mapRef.current.panTo(new window.google.maps.LatLng(location.lat, location.lng)) && this.mapRef.current && this.mapRef.current.setZoom(8);
    this.setState({ zoom: 10 })
  };

  handleMapMounted = (map) => {
    const { path } = this.state;
    this.mapRef.current = map;
    if (_.isEmpty(path)) {
      navigator.geolocation.getCurrentPosition(position => {
        if (position) {
          const currentPosition = {lat: position.coords.latitude, lng: position.coords.longitude};
          this.autoPan(currentPosition);
        }
      });
    } else {
      this.autoZoomMap(path)
    }
  }

  //Polygon methods

  onPolygonMounted = polygon => {
      if (polygon) {
        this.polygonRef.current = polygon;
        const path = polygon.getPath();
    
        this.listenersRef.current = [
          path.addListener(polygon.getPath(), "set_at", this.onEdit),
          path.addListener(polygon.getPath(), "insert_at", this.onEdit),
          path.addListener(polygon.getPath(), "remove_at", this.onEdit)
        ];
      }
  };

  onEdit = () => {
    const { getPoints } = this.props;
    if (this.polygonRef.current) {
      const nextPath = this.polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      getPoints(nextPath);
      this.setState({ path: nextPath })
    }
  };

  // Drawing manager methods

  getPolygonBounds = polygon => {
    let polygonBounds = polygon.getPath();
    let bounds = [];
    for (let i = 0; i < polygonBounds.length; i++) {
      let point = {
        lat: polygonBounds.getAt(i).lat(),
        lng: polygonBounds.getAt(i).lng()
      };
      bounds.push(point);
    }
    return bounds
  }

  onPolygonComplete = (polygon) => {
    const { getPoints } = this.props;
    const bounds = this.getPolygonBounds(polygon);
    latestPolygon && latestPolygon.setMap(null); 
    polygon.setEditable(true);

    getPoints(this.getPolygonBounds(polygon));
    window.google.maps.event.addListener(polygon.getPath(), 'insert_at', () => {
        getPoints(this.getPolygonBounds(polygon));
    });
    window.google.maps.event.addListener(polygon.getPath(), 'set_at', () => {
        getPoints(this.getPolygonBounds(polygon));
    });
    this.setState({ path: bounds });
    latestPolygon = polygon;
  }

  autoPan = this.autoPan.bind(this);

  render(){
    const { path } = this.state;
    const { isEdition } = this.props;
    return (
      <Map
        containerElement={<div style={MAP_CONTAINER_STYLE} />}
        mapElement={<div style={{ height: `100%`, width:  `100%`}} />}
        onMapMounted={this.handleMapMounted}
        onPolygonComplete={this.onPolygonComplete}
        getPolygonBounds={this.getPolygonBounds}
        polygon={path}
        onEdit={this.onEdit}
        onPolygonMounted={this.onPolygonMounted}
        isEdition={isEdition}
      />
    );
  }
}

export default DrawableMap;