import {connect} from 'react-redux'
import {fetchCreditCards} from '../redux/fleet'
import CreditCardSuggestion from "../components/autosuggest/CreditCardSuggestion";


function mapStateToProps(state) {
  const { fleetDetail, creditCards } = state.fleet;
  return { fleetDetail, creditCards};
}

const mapDispatchToProps = dispatch => {
  return {
    fetchCreditCards: (fleetId) => {
      dispatch(fetchCreditCards(fleetId));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardSuggestion);


