import React, {Component} from "react";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import classNames from "classnames";
import {MARKER_TYPE} from "./constants"
import DrawableMap from "../maps/DrawableMap";

class PolygonMarkerEditModal extends Component {

    state = {
        polygonMarker: {},
        wasValidated: false
    };

    componentWillMount() {
        const {polygonMarker} = this.props;
        this.setState({polygonMarker: polygonMarker});
    }

    getValidationClassName = (isValid) => {
        const {wasValidated} = this.state;

        if (wasValidated) {
            if (isValid) {
                return 'is-success';
            } else {
                return 'is-danger';
            }
        } else {
            return '';
        }
    };

    onChangeInput = ev => {
        let {polygonMarker} = this.state;
        if (ev.target.name === "marker_type") {
            polygonMarker[ev.target.name] = parseInt(ev.target.value);
            this.setState({polygonMarker: polygonMarker});
        } else {
            polygonMarker[ev.target.name] = ev.target.value;
            this.setState({polygonMarker: polygonMarker});
        }
    }

    isFormIncomplete(polygonMarker) {
        return polygonMarker.length >= 3 || !polygonMarker.name 
    }

    onSave = () => {
        let {polygonMarker} = this.state;
        let isIncomplete = this.isFormIncomplete(polygonMarker);
        const {onClose} = this.props;
        if (isIncomplete) {
            this.setState({wasValidated: true})
        } else {
            onClose(true, polygonMarker);
        }
    };

    getPoints = points => {
        const { polygonMarker } = this.state;
        polygonMarker.points = points;
        this.setState({ polygonMarker });
    }

    render() {
        const {onClose, t} = this.props
        const {polygonMarker} = this.state
        return (
            <div className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card width-in-four-fifths">
                    <header className="modal-card-head">
                        {polygonMarker.id ?
                            <p className="modal-card-title">{t('polygonMarkerTable.edit.editTitle', {name: polygonMarker.name})}</p> :
                            <p className="modal-card-title">{t('polygonMarkerTable.edit.newTitle')}</p>
                        }
                    </header>

                    <section className="modal-card-body has-text-left">

                        <div className="columns">
                            <div className="column is-half">
                                <div className="columns">
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">{t('polygonMarkerTable.edit.fields.name')}</label>
                                            <div className="control">
                                                <input
                                                    className={classNames('input', this.getValidationClassName(polygonMarker.name !== ""))}
                                                    type="text" value={polygonMarker.name} name="name"
                                                    onChange={this.onChangeInput}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">{t('polygonMarkerTable.edit.fields.description')}</label>
                                            <div className="control">
                                                <input
                                                    className={classNames('input', this.getValidationClassName(true))}
                                                    type="text" value={polygonMarker.description} name="description"
                                                    onChange={this.onChangeInput}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">{t('polygonMarkerTable.edit.fields.type')}</label>
                                            <div className="control">
                                                <div className="select is-fullwidth">
                                                    <select value={polygonMarker.marker_type} name="marker_type"
                                                        onChange={this.onChangeInput}>
                                                        {
                                                            MARKER_TYPE.map(((type) => (
                                                                <option key={'marker-type-' + type.value}
                                                                    value={type.value}>{t(type.textKey)}</option>
                                                            )))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-half map-container-on-modal">
                                <div className="column mapContainer">
                                    <DrawableMap isEdition={!!polygonMarker.id} points={polygonMarker.points} getPoints={this.getPoints} />
                                </div>
                            </div>
                        </div>
                    </section>

                    <footer className="modal-card-foot">
                        <div className="buttons">
                            <button className="button is-primary"
                                    onClick={this.onSave}>{t('commons.save')}
                            </button>
                            <button className="button"
                                    onClick={() => onClose(false, polygonMarker)}>{t('commons.cancel')}
                            </button>
                        </div>
                    </footer>
                </div>
            </div>
        );
    }
}

export default withRouter(withTranslation('translation')(PolygonMarkerEditModal));