import React, {Component} from "react";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import classNames from "classnames";
import {MARKER_TYPE} from "./constants"
import SimpleMap from "../maps/SimpleMap";
import SearchBoxMap from "../maps/SearchBoxMap";

class MarkerEditModal extends Component {

    state = {
        marker: {},
        wasValidated: false
    };

    componentWillMount() {
        const {marker} = this.props;
        this.setState({marker: marker});
    }

    getValidationClassName = (isValid) => {
        const {wasValidated} = this.state;

        if (wasValidated) {
            if (isValid) {
                return 'is-success';
            } else {
                return 'is-danger';
            }
        } else {
            return '';
        }
    };

    onChangeInput = ev => {
        let {marker} = this.state;
        if (ev.target.name === "marker_type") {
            marker[ev.target.name] = parseInt(ev.target.value);
            this.setState({marker: marker});
        } else {
            if (ev.target.name === "lat" || ev.target.name === "lon") {
                marker.location[ev.target.name] = ev.target.value;
            } else {
                marker[ev.target.name] = ev.target.value;
            }
            this.setState({marker: marker});
        }
    }

    isFormIncomplete(marker) {
        return Number.isNaN(marker.location.lat)  || Number.isNaN(marker.location.lon) || !marker.address || !marker.name 
    }

    onSave = () => {
        let {marker} = this.state;
        let isIncomplete = this.isFormIncomplete(marker);
        const {onClose} = this.props;
        if (isIncomplete) {
            this.setState({wasValidated: true})
        } else {
            marker.location.lat = parseFloat(marker.location.lat)
            marker.location.lon = parseFloat(marker.location.lon)
            onClose(true, marker);
        }
    };

    locationChanged = places => {
        return places.forEach(({ formatted_address, geometry: { location } }) => {
            this.setState({ marker: {
                    ...this.state.marker,
                    address: formatted_address,
                    location: {
                        lat: parseFloat(location.lat()),
                        lon: parseFloat(location.lng()),
                    },
                } });
        });
    }

    render() {
        const {onClose, t} = this.props
        const {marker} = this.state
        return (
            <div className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card width-in-four-fifths">
                    <header className="modal-card-head">
                        {marker.id ?
                            <p className="modal-card-title">{t('markerTable.edit.editTitle', {name: marker.name})}</p> :
                            <p className="modal-card-title">{t('markerTable.edit.newTitle')}</p>
                        }
                    </header>

                    <section className="modal-card-body has-text-left">

                        <div className="columns">
                            <div className="column is-half">
                                <div className="columns">
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">{t('markerTable.edit.fields.name')}</label>
                                            <div className="control">
                                                <input
                                                    className={classNames('input', this.getValidationClassName(marker.name !== ""))}
                                                    type="text" value={marker.name} name="name"
                                                    onChange={this.onChangeInput}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">{t('markerTable.edit.fields.description')}</label>
                                            <div className="control">
                                                <input
                                                    className={classNames('input', this.getValidationClassName(true))}
                                                    type="text" value={marker.description} name="description"
                                                    onChange={this.onChangeInput}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">{t('markerTable.edit.fields.address')}</label>
                                            <div className="control">
                                                <SearchBoxMap locationChanged={this.locationChanged}>
                                                    <input
                                                        className={classNames('input', this.getValidationClassName(marker.address !== ""))}
                                                        placeholder=""
                                                        type="text" value={marker.address} name="address"
                                                        onChange={this.onChangeInput}/>
                                                </SearchBoxMap>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column is-half">
                                        <div className="field">
                                            <label className="label">{t('markerTable.edit.fields.lat')}</label>
                                            <div className="control">
                                                <input
                                                    disabled
                                                    className={classNames('input', this.getValidationClassName(marker.location.lat != ""))}
                                                    value={marker.location.lat} name="lat"
                                                    onChange={this.onChangeInput} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column is-half">
                                        <div className="field">
                                            <label className="label">{t('markerTable.edit.fields.lon')}</label>
                                            <div className="control">
                                                <input
                                                    disabled
                                                    className={classNames('input', this.getValidationClassName(marker.location.lon != ""))}
                                                    value={marker.location.lon} name="lon"
                                                    onChange={this.onChangeInput} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">{t('markerTable.edit.fields.type')}</label>
                                            <div className="control">
                                                <div className="select is-fullwidth">
                                                    <select value={marker.marker_type} name="marker_type"
                                                        onChange={this.onChangeInput}>
                                                        {
                                                            MARKER_TYPE.map(((type) => (
                                                                <option key={'marker-type-' + type.value}
                                                                    value={type.value}>{t(type.textKey)}</option>
                                                            )))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-half map-container-on-modal">
                                <div className="column mapContainer">
                                    <SimpleMap location={this.state.marker.location} />
                                </div>
                            </div>
                        </div>
                    </section>

                    <footer className="modal-card-foot">
                        <div className="buttons">
                            <button className="button is-primary"
                                    onClick={() => this.onSave()}>{t('commons.save')}
                            </button>
                            <button className="button"
                                    onClick={() => onClose(false, marker)}>{t('commons.cancel')}
                            </button>
                        </div>
                    </footer>
                </div>
            </div>
        );
    }
}

export default withRouter(withTranslation('translation')(MarkerEditModal));