import {connect} from 'react-redux';
import PolygonMarkersTable from "../components/RoadMaps/PolygonMarkersTable";
import {fetchPolygonMarkers, createPolygonMarker, deletePolygonMarker, updatePolygonMarker} from "../redux/polygonMarkers";


function mapStateToProps(state) {
  const {polygonMarkers} = state.polygonMarkers
  return { polygonMarkers };
}

const mapDispatchToProps = dispatch => {
  return {
    fetchPolygonMarkers: (fleetId) => {
      dispatch(fetchPolygonMarkers(fleetId));
    },
    savePolygonMarker: (fleetId, polygonMarker) => {
      if (polygonMarker.id === 0) {
        dispatch(createPolygonMarker(fleetId, polygonMarker))
      } else {
        dispatch(updatePolygonMarker(fleetId, polygonMarker))
      }
    },
    deletePolygonMarker: (fleetId, polygonMarker) => {
      dispatch(deletePolygonMarker(fleetId, polygonMarker))
    }
  }
};

const PolygonMarkersTableContainer = connect(mapStateToProps, mapDispatchToProps)(PolygonMarkersTable);

export { PolygonMarkersTableContainer }