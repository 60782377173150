import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import DeleteModal from "./PolygonMarkerDeleteModal"
import EditModal from "./PolygonMarkerEditModal";
import _ from "lodash";
import Filters from "./PolygonMarkersFilters";
import plusIcon from "../../images/plus.png";
import PolygonMarkerRow from "./PolygonMarkerRow";
import {EMPTY_POLYGON_MARKER} from "./constants";

class PolygonMarkersTable extends Component {

    state = {
        showDeleteModal: false,
        showEditModal: false,
        currentPolygonMarker: null,
        saytInput: ""
    };

    onClickDelete = (polygonMarker) => {
        this.setState({currentPolygonMarker: _.cloneDeep(polygonMarker), showDeleteModal: true});
    };

    getFilteredPolygonMarkers() {
        const {polygonMarkers} = this.props;
        const {saytInput} = this.state;

        if (!polygonMarkers) return []

        if (saytInput.length < 3) {
            return polygonMarkers.sort((a, b) => b.creation_date - a.creation_date);
        }
        return polygonMarkers.filter(s => {
            return (s.name + s.description).toLocaleLowerCase().includes(saytInput.toLocaleLowerCase());
        })
        .sort((a, b) => b.creation_date - a.creation_date);
    }

    onChangeSearchFilter = (value) => {
        this.setState({saytInput: value})
    }

    onCloseDeleteModal = (shouldDelete, polygonMarker) => {
        let {polygonMarkers, deletePolygonMarker, fleetId} = this.props;

        if (shouldDelete) {
            _.remove(polygonMarkers, v => v.id === polygonMarker.id);
            // server call
            deletePolygonMarker(fleetId, polygonMarker);
        }

        this.setState({showDeleteModal: false});
    };

    onCloseEditModal = (shouldEdit, polygonMarker) => {
        let {polygonMarkers, fleetId, savePolygonMarker } = this.props;
        if (shouldEdit) {
            if (polygonMarker.id !== 0) {
                _.remove(polygonMarkers, v => v.id === polygonMarker.id);
            }

            polygonMarkers.unshift(polygonMarker);

            // server call
            savePolygonMarker(fleetId, polygonMarker);
        }

        this.setState({showEditModal: false});
    };

    onClickEdit = (polygonMarker) => {
        this.setState({currentPolygonMarker: _.cloneDeep(polygonMarker), showEditModal: true});
    };

    onClickNewPolygonMarker = () => {
        this.setState({currentPolygonMarker: _.cloneDeep(EMPTY_POLYGON_MARKER), showEditModal: true});
    };

    onClickDuplicateRoadMap = (polygonMarker) => {
        let auxPolygonMarker = _.cloneDeep(EMPTY_POLYGON_MARKER)
        auxPolygonMarker.points = polygonMarker.points
        auxPolygonMarker.marker_type = polygonMarker.marker_type
        this.setState({currentPolygonMarker: _.cloneDeep(auxPolygonMarker), showEditModal: true});
    };

    render() {
        const {showDeleteModal, currentPolygonMarker, showEditModal} = this.state
        return (
            <div>
                {showDeleteModal && <DeleteModal onClose={this.onCloseDeleteModal} polygonMarker={currentPolygonMarker} />}
                {showEditModal && <EditModal onClose={this.onCloseEditModal} polygonMarker={currentPolygonMarker} />}
                <Filters onChange={this.onChangeSearchFilter}/>
                {this.getFilteredPolygonMarkers().map(((so) => (
                    <PolygonMarkerRow onDelete={this.onClickDelete} polygonMarker={so} onEdit={this.onClickEdit}
                                 onDuplicate={this.onClickDuplicateRoadMap} key={so.id}/>
                )))}
                <a className="fab button is-success is-pulled-right" onClick={() => {this.onClickNewPolygonMarker()}}><img src={plusIcon}/></a>
            </div>
        );
    }
}

export default withRouter(withTranslation('translation')(PolygonMarkersTable));