import React, {Component} from "react";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import classNames from "classnames";
import _ from "lodash";
import DriverSuggestionContainer from "../../containers/DriverSuggestionContainer";
import {getLocalDatetimeFromTimestamp, getTodayLocalDateTime, getTimestampFromLocalDateTime, getCoefficient} from "./constants";
import RoutesMap from "../maps/RoutesMap";
import DropdownSelect from "../DropdownSelect"

class RoadMapEditModal extends Component {

    state = {
        roadMap: {},
        wasValidated: false,
        isIncomplete: true,
        selectedStopovers: []
    };

    componentWillMount() {
        const {roadMap} = this.props;
        let isIncomplete = this.isFormIncomplete(roadMap);
        const selectedStopovers = roadMap.stopovers.map(stopOver => {
            return {...stopOver.location, name: stopOver.name}
        })
        this.setState({roadMap: roadMap, isIncomplete, selectedStopovers});
    };

    getValidationClassName = (isValid) => {
        const {wasValidated} = this.state;

        if (wasValidated) {
            if (isValid) {
                return 'is-success';
            } else {
                return 'is-danger';
            }
        } else {
            return '';
        }
    };

    onChangeInput = ev => {
        let {roadMap, isIncomplete} = this.state;
        const {vehicles} = this.props;
        if (ev.target.name === "start_date") {
            const ts = getTimestampFromLocalDateTime(ev.target.value)
            roadMap[ev.target.name] = parseInt(ts);
        } else if (ev.target.name === "vehicle") {
            const vehicleFound = vehicles.find(vehicle => vehicle.id === parseInt(ev.target.value));
            roadMap[ev.target.name] = vehicleFound
        } else {
            roadMap[ev.target.name] = ev.target.value;
        }
        isIncomplete = this.isFormIncomplete(roadMap)
        this.setState({roadMap: roadMap, isIncomplete});
    };

    onSelectStopOver = stopOver => {
        const { stopsOvers } = this.props

        let {roadMap, selectedStopovers, isIncomplete} = this.state
        roadMap.stopovers.push(stopOver)
        isIncomplete = this.isFormIncomplete(roadMap)
        this.setState({
            roadMap,
            selectedStopovers: [...selectedStopovers, {...stopOver.location, name: stopOver.name}],
            isIncomplete
        })
    };

    isFormIncomplete(roadMap) {
        const {name, drivers, stopovers, vehicle, start_date} = roadMap
        return _.isEmpty(name) || !vehicle || _.isEmpty(drivers) || stopovers.length < 2 || !start_date
    };

    onSave = () => {
        const {roadMap, isIncomplete} = this.state;
        const {onClose} = this.props;
        if (isIncomplete) {
            this.setState({wasValidated: true})
        } else {
            onClose(true, roadMap);
        }
    };

    onDriverSuggestionSelected = (event, suggestion) => {
        const {vehicles} = this.props;
        if (suggestion) {
            let {roadMap, isIncomplete} = this.state
            roadMap.drivers.push(suggestion)
            const vehicleFound = vehicles.find(vehicle => vehicle.drivers.find(driver => driver.uid === suggestion.uid))
            if (vehicleFound) {
                roadMap.vehicle = vehicleFound
            }
            isIncomplete = this.isFormIncomplete(roadMap)
            this.setState({roadMap: roadMap, isIncomplete})
        }
    };

    onRemoveDriver = (index) => {
        let {roadMap} = this.state
        roadMap.drivers.splice(index, 1);
        this.setState({roadMap: roadMap})
    };

    onRemoveStop = (index) => {
        let {roadMap} = this.state
        const selectedStopovers = [...this.state.selectedStopovers]
        roadMap.stopovers.splice(index, 1);
        selectedStopovers.splice(index, 1);
        this.setState({roadMap, selectedStopovers})
    };

    onMoveStopLeft = (index) => {
        if (index == 0) {
            return;
        }
        let {roadMap} = this.state
        const selectedStopovers = [...this.state.selectedStopovers]
         
        // order selectedStopovers
        let selectedSo = selectedStopovers.at(index)
        selectedStopovers.splice(index, 1, selectedStopovers.at(index - 1))
        selectedStopovers.splice(index - 1, 1, selectedSo)
        
        // order roadMap.stopovers
        let so = roadMap.stopovers.at(index)
        roadMap.stopovers.splice(index, 1, roadMap.stopovers.at(index - 1))
        roadMap.stopovers.splice(index - 1, 1, so)
        
        this.setState({roadMap, selectedStopovers})
    }

    onMoveStopRight = (index) => {
        const selectedStopovers = [...this.state.selectedStopovers]
        if (index >= selectedStopovers.length - 1){
            return;
        }else{
            this.onMoveStopLeft(index + 1)
        } 
    }

    render() {
        const {onClose, stopsOvers, t, vehicles} = this.props
        const {roadMap, selectedStopovers} = this.state

        return (
            <div className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        {roadMap.id ? 
                            <p className="modal-card-title">{t('roadMapTable.edit.editTitle', {name: roadMap.name})}</p> :
                            <p className="modal-card-title">{t('roadMapTable.edit.newTitle')}</p>
                        }
                    </header>

                    <section className="modal-card-body has-text-left">
                        <div className="columns">
                            <div className="column">
                                <div className="field">
                                    <label className="label">{t('roadMapTable.edit.fields.name')}</label>
                                    <div className="control">
                                        <input
                                            className={classNames('input', this.getValidationClassName(roadMap.name !== ""))}
                                            type="text" value={roadMap.name} name="name"
                                            onChange={this.onChangeInput}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-6">
                                <div className="field">
                                    <label className="label">{t('roadMapTable.edit.fields.startDate')} </label>
                                    <div className="control">
                                        <input
                                            className={classNames('input', this.getValidationClassName(roadMap.start_date !== ""))}
                                            type="datetime-local"
                                            value={roadMap.start_date ? getLocalDatetimeFromTimestamp(roadMap.start_date) : getTodayLocalDateTime()}
                                            onChange={this.onChangeInput} name="start_date"/>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-6">
                                <div className="field">
                                    <label className="label">{t('roadMapTable.edit.fields.description')}</label>
                                    <div className="control">
                                        <input
                                            className={classNames('input', this.getValidationClassName(true))}
                                            type="text" value={roadMap.description} name="description"
                                            onChange={this.onChangeInput}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="columns">
                            <div className="column">
                                <div className="field">
                                    <label className="label">{t('roadMapTable.edit.fields.drivers')}</label>

                                    <div className="control">
                                        <DriverSuggestionContainer
                                            className={this.getValidationClassName(!_.isEmpty(roadMap.drivers))}
                                            selected={roadMap.driver}
                                            onSelected={this.onDriverSuggestionSelected}
                                            cleanOnSelected={true}/>
                                    </div>
                                </div>
                                <div className="field is-grouped is-grouped-multiline">
                                    {roadMap.drivers.map(((dv, index) => (
                                        <div key={index} className="control">
                                            <div className="tags has-addons">
                                                <span className="tag is-light">{dv.name}</span>
                                                <a className="tag is-delete is-danger" onClick={() => this.onRemoveDriver(index)}></a>
                                            </div>
                                        </div>
                                    )))}
                                </div>
                            </div>
                        </div>

                        <div className="columns">
                            <div className="column is-half">
                                <div className="field">
                                    <label className="label">{t('roadMapTable.edit.fields.vehicle')}</label>
                                    <div className="control">
                                        <div className={classNames('select is-fullwidth', this.getValidationClassName(roadMap.vehicle))}>
                                            <select
                                                name="vehicle"
                                                value={roadMap.vehicle ? roadMap.vehicle.id : roadMap.vehicle}
                                                onChange={this.onChangeInput}>
                                                    <option></option>
                                                    {
                                                        vehicles.map(((vehicle) => (
                                                            <option key={vehicle.id}
                                                                value={vehicle.id}>{vehicle.name}</option>
                                                        )))
                                                    }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-half">
                                <div className="field">
                                    <label className="label">{t('roadMapTable.edit.fields.stops')}</label>
                                    <div className="control">
                                        <div className={classNames('is-fullwidth', this.getValidationClassName(roadMap.stopovers.length > 1))}>
                                            <DropdownSelect options={stopsOvers.map(so => ({ label: so.name, value: so }))}
                                                            onChange={opt => this.onSelectStopOver(opt.value)}
                                                            placeholder=' '
                                                            accentColor='#3273dc'
                                                            width={288}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="field is-grouped is-grouped-multiline">
                                    {roadMap.stopovers.map(((so, index) => (
                                        <div key={index} className="control is-fullwidth">
                                            <div className="tags has-addons">
                                                {index!=0 && <a className="tag fa fa-angle-up"
                                                    onClick={() => this.onMoveStopLeft(index)}></a>}
                                                {index != roadMap.stopovers.length - 1 && <a className="tag fa fa-angle-down"
                                                    onClick={() => this.onMoveStopRight(index)}></a>}
                                                <span className="tag is-light">{so.name}</span>
                                                <a className="tag is-delete is-danger"
                                                    onClick={() => this.onRemoveStop(index)}></a>
                                            </div>
                                        </div>
                                    )))}
                                </div>
                            </div>
                        </div>
                        
                        <div className="columns map-container-on-modal">
                            <div className="column">
                                <div className="mapContainer">
                                    <RoutesMap destinations={selectedStopovers} t={t}/>
                                </div>
                            </div>
                        </div>

                    </section>

                    <footer className="modal-card-foot">
                        <div className="buttons">
                            <button className="button is-primary"
                                    onClick={() => this.onSave()}>{t('commons.save')}
                            </button>
                            <button className="button"
                                    onClick={() => onClose(false, roadMap)}>{t('commons.cancel')}
                            </button>
                        </div>
                    </footer>
                </div>
            </div>
        );
    }
}

export default withRouter(withTranslation('translation')(RoadMapEditModal));