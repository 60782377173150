import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TripPathMap from "./TripPathMap"
import Loading from '../components/Loading'
import {withTranslation} from "react-i18next";

var colors = ["#000000",
              "#FF0000", "#0000FF", "#FFFF00",
              "#FF9900", "#808080", "#00CC00",
              "#660099", "#800000", "#DA70D6",
              "#B03060", "#808000", "#008000",
              "#800080", "#008080", "#000080",
              "#C0C0C0"];

class TripPathFilterMap extends Component {

  state = {
    driversSelected: [],
    date: new Date().toISOString().substr(0,10),
    invalidDate: false
  };

  driversMap = {};

  selectDriver = (driver) => {
    let index = this.state.driversSelected.indexOf(driver.uid);
    if (index === -1) {
      this.state.driversSelected = [...this.state.driversSelected, driver.uid];
    } else {
      this.state.driversSelected.splice(index, 1);
    }

    this.setState(this.state.driversSelected);
  };

  onDateChange = (e) => {
    this.setState({ date: e.target.value });
  };

  onClick = () => {
    let date = new Date(this.state.date);
    let drivers = this.state.driversSelected.join(",");

    if (Number.isNaN(date.getTime())) {
      this.setState({invalidDate: true});
    } else {
      this.setState({invalidDate: false});
      this.props.getDriverTrips(this.props.fleetDetail.id, drivers, date);
    }
  };

  render() {

    const { tripsLoading, driverTrips, fleetDetail, selected, dateFilter, t } = this.props;
    const { driversSelected, invalidDate } = this.state;

    fleetDetail.drivers.map((driver, index) => {
      if (colors.length > index) {
        this.driversMap[driver.uid] = colors[index];
      } else {
        this.driversMap[driver.uid] = '#' + Math.floor(Math.random() * 16777215).toString(16);
      }
    });

    return (
      <div className="columns is-fullwidth">

        {/* map is not affected by column because has position absolute */}
        <TripPathMap driverTrips={driverTrips} tripsLoading={tripsLoading}
                     enableMessage={false} driverMapColor={this.driversMap}
                     mapTopPosition={53} mapBottomPosition={0}/>

        {/* filters */}
        <div className="column is-3 card">
          <nav className="panel">
            <p className="panel-heading">{t('tripPathFilterMap.filters')}</p>
            <div className="panel-block">
              <p className="control has-icons-left">
                {
                  (invalidDate &&
                  <input className="input is-small is-danger" type="date" value={this.state.date} onChange={this.onDateChange}/>) ||
                  <input className="input is-small" type="date" value={this.state.date} onChange={this.onDateChange}/>
                }
                <span className="icon is-small is-left">
                  <i className="fa fa-calendar"></i>
                </span>
              </p>
            </div>

            {fleetDetail && fleetDetail.drivers.map((driver, index) => (
              <a className={driversSelected.indexOf(driver.uid) > -1 ? "panel-block is-active":"panel-block"}
                  onClick={()=>(this.selectDriver(driver))} key={index}>
                <span className="panel-icon"><i className="fa fa-car"></i></span>
                { driver.name }
                <div className="driver-color" style={{'backgroundColor': this.driversMap[driver.uid]}}></div>
              </a>
            ))}

          </nav>
          <button className='button is-primary is-fullwidth' onClick={this.onClick}>
            <b>{t('tripPathFilterMap.apply')}</b>
          </button>
        </div>

        {
          (invalidDate &&
            <div className="column is-6 is-top-paddingless">
              <div className="notification is-danger">
                <strong>{t('tripPathFilterMap.invalidDate-1')}</strong>{t('tripPathFilterMap.invalidDate-2')}
              </div>
            </div>)

          ||

          (driverTrips.length === 0 &&
            <div className="column is-6 is-top-paddingless">
              <div className="notification is-danger">
                {t('tripPathFilterMap.noResult-1')}<strong>{t('tripPathFilterMap.noResult-2')}</strong>
              </div>
            </div>)
        }

        { tripsLoading && <Loading/> }

      </div>
    );
  }
}

TripPathFilterMap.propTypes = {
  getDriverTrips: PropTypes.func.isRequired,
  getFleetDetail: PropTypes.func.isRequired,
  tripsLoading: PropTypes.bool.isRequired,
  fleetDetail: PropTypes.object.isRequired,
  driverTrips: PropTypes.array,
  selected: PropTypes.object.isRequired,
};

export default withTranslation('translation')(TripPathFilterMap);
