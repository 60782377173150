import React, { Component } from 'react';
import { connect } from 'react-redux'
import Evolution from '../components/charts/Evolution'
import RadarChart from '../components/charts/RadarChart'
import FleetDescription from '../components/FleetDescription'
import DriverStatsTable from '../components/driver/DriverStatsTable'
import DriverEngagementTable from '../components/driver/DriverEngagementTable'
import Loading from '../components/Loading'
import { getFleetDetail, getDriversFromEngagementTable } from '../redux/fleet'
import DateFilter from "../components/fleet/DateFilter";
import {withTranslation} from "react-i18next";

class Home extends Component {

  initialData = {
    kms: 0,
    stats: {
      total: { value: 0 },
      eco: { value: 0 },
      focus: { value: 0 },
      caution: { value: 0 },
      control: { value: 0 },
      kms: { value : 0}
    }, evolution: [
      {label: '1/9', value: 80},
      {label: '2/9', value: 85},
      {label: '3/9', value: 93},
      {label: '4/9', value: 70},
      {label: '5/9', value: 99},
      {label: '6/9', value: 70},
      {label: '7/9', value: 88}],
    allDrivers:[],
    bestDrivers:[],
    worstDrivers:[]
  };

  updateState = (detail) => {
    if (detail.evolution.length === 0) return;
    var kms=0, total=0, eco=0, focus=0, caution=0, control=0;
    var evol = [];
    var totalSafe = 0; // ignore zero counters on averagge. See (day.km > 0)
    for (var day of detail.evolution){
      if (day.km > 0) {
        kms = kms + day.km;
        total = total + day.total;
        eco = eco + day.eco;
        focus = focus + day.focus;
        caution = caution + day.caution;
        control = control + day.control;
        totalSafe++;
      }

      evol = [...evol, {date: day.date, total: day.total}]
    }

    totalSafe = totalSafe || 1;

    total = total / totalSafe;
    eco = eco / totalSafe;
    caution = caution / totalSafe;
    focus = focus / totalSafe;
    control = control / totalSafe;

    var bestDrivers=[], worstDrivers=[], allDrivers=[];
    // process drivers
    if (detail.drivers.length > 0) {

      detail.drivers.sort((a, b)=> {

        if (a.stats.total > b.stats.total) {
          return -1;
        }
        if (a.stats.total === b.stats.total) {
          return 0;
        }
        return 1;

      });
      var sorted = [...detail.drivers];
      allDrivers = [...sorted];

      // 5 betters -> bestDrivers
      // 5 worst -> worstDrivers
      var d;
      for (var i = 0; i < 5 && sorted.length; i++){
        d = sorted.shift();
        if (d.stats.total > 0) {
          bestDrivers = [...bestDrivers, d]
        }
      }

      var count = 0;

      for(i = sorted.length-1; i >= 0 && count < 5; i--) {
        d = sorted[i];
        if (d.stats.total > 0) {
          worstDrivers = [d, ...worstDrivers];
          count ++
        }
      }
    }

    this.initialData = {
      ...this.initialData,
      kms: parseInt(kms, 10),
      stats: {
        total: { value: Math.round(total) },
        eco: { value: Math.round(eco) },
        focus: { value: Math.round(focus) },
        caution: { value: Math.round(caution) },
        control: { value: Math.round(control) },
        kms:  { value: Math.round(kms) },
      },
      evolution: evol,
      allDrivers: allDrivers,
      bestDrivers: bestDrivers,
      worstDrivers: worstDrivers
    }
  };

  getRadarChartData = () => {
    const { total, eco, control, focus, caution } = this.initialData.stats;
    return [
      total.value,
      eco.value,
      control.value,
      focus.value,
      caution.value
    ];
  }

  render() {
    const { selected, fleetDetail, loading, dateFilter, t, engagementDrivers} = this.props;
    const fleet = selected;


    if (!fleet) {
      return (
        <Loading wait={0}/>
      )
    }

    var showST = false
    if (fleet.privacy_configuration === 'widget') {
      showST = true
    }

    // if fleetDetail is null or is not the same that was showed latest
    if (!fleetDetail || !engagementDrivers || fleet.id !== this.lastFleetId) {
      this.lastFleetId = fleet.id;
      this.props.getFleet(fleet.id, dateFilter, false);
      this.props.getEngagementDrivers(fleet.id);
      return (<Loading wait={0}/>)
    }

    this.updateState(fleetDetail);

    return (
      <div className="is-fullwidth">
        {loading && <Loading/>}

        <div>
          <div className="columns">
            <div className="column is-half">
              <FleetDescription logo={fleet.logo} fleetName={fleet.name} kms={this.initialData.kms}
                drivers={fleetDetail.drivers} privacy={fleet.privacy_configuration}
                day_start={fleet.day_start} day_end={fleet.day_end}
              />
            </div>
            <div className="column is-half">
              <DateFilter />
            </div>
          </div>

          <div className="columns">
            <div className="column is-two-fifths">
              <RadarChart values={this.getRadarChartData()} />
            </div>

            <div className="column is-three-fifths">
              <Evolution data={this.initialData.evolution}/>
            </div>
          </div>

          <div className="columns">
            <div className="column">
              <DriverEngagementTable drivers={engagementDrivers.drivers} fleetDetail={fleetDetail} />
            </div>
          </div>

          <div className="columns">
            <div className="column is-half-desktop is-full-tablet">
              <DriverStatsTable drivers={this.initialData.bestDrivers} full={false} showST={showST}
                fleetDetail={fleetDetail} showIEVandT={false} title={t('home.bestDrivers')} />
            </div>
            <div className="column is-half-desktop is-full-tablet">
              <DriverStatsTable drivers={this.initialData.worstDrivers} full={false} showST={showST}
                fleetDetail={fleetDetail} showIEVandT={false} title={t('home.worstDrivers')} />
            </div>
          </div>

          <div className="columns">
            <div className="column">
              <DriverStatsTable drivers={this.initialData.allDrivers} full={true} showST={showST}
                fleetDetail={fleetDetail} showIEVandT={fleet.name.toLowerCase().search("zarcam") !== -1} title={t('home.allDrivers')} />
            </div>
          </div>

        </div>
      </div>
    );
  }
}

// These props come from the application's
// state when it is started
function mapStateToProps(state) {
  const { fleets, selected, fleetDetail, loading, dateFilter, engagementDrivers, engagementDriversLoading } = state.fleet;
  return { selected, fleets, fleetDetail, loading, dateFilter, engagementDrivers, engagementDriversLoading };
}

const mapDispatchToProps = dispatch => {
  return {
    getFleet : (fleetId, dateFilter, isObserver) => {
      dispatch(getFleetDetail(fleetId, dateFilter, isObserver))
    },
    getEngagementDrivers: (fleetId) => {
      dispatch(getDriversFromEngagementTable(fleetId))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(Home));