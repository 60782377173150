import { checkStatus, parseJson } from '../api/utils'
import {fleetListUpdated, fleetListUpdatedStarted} from './fleet'
import {
  firebaseGoogleLogin,
  firebaseFacebookLogin,
  firebaseEmailLogin,
  firebaseAuth,
  firebaseLogout,
  firebaseEmailPasswordReset
} from '../firebase/config'
import { auth0MicrosoftLogin, auth0MicrosoftIsAuthenticated } from '../auth0/config'
import { BASE_URL } from '../config'
import { ROLE_DRIVER } from '../model/Role'

const LOGIN = 'woo/auth/LOGIN';
const LOGIN_SUCCESS = 'woo/auth/LOGIN_SUCCESS';
const LOGIN_FAIL = 'woo/auth/LOGIN_FAIL';
export const LOGOUT = 'woo/auth/LOGOUT';
const CAPTCHA_TOKEN_KEY = "googleCaptchaV3Token"

const initialState = {
  isAuthenticated: !!firebaseAuth.currentUser || !!auth0MicrosoftIsAuthenticated() || !!localStorage.getItem("token"),
  loggingIn: false,
  user: JSON.parse(localStorage.getItem("user")) || {role:ROLE_DRIVER}
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loggingIn: true,
        loginError: ""
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        isAuthenticated: true,
        loginError: "",
        user: action.user
      };
    case LOGIN_FAIL:
      localStorage.removeItem("token");
      return {
        ...state,
        loggingIn: false,
        isAuthenticated: false,
        loginError: action.error
      };
    case LOGOUT:
      localStorage.removeItem("token");
      return {
        ...state,
        isAuthenticated: false
      };
    default:
      return state;
  }
};

// Action Creators
const loginRequested = () => {
  return {
    type: LOGIN
  }
};

// Action Creators
export const loginSucceeded = (user) => {
  return {
    type: LOGIN_SUCCESS,
    user
  }
};

// Action Creators
const loginFailed = (error) => {
  return {
    type: LOGIN_FAIL,
    error
  }
};

// Action Creators
export const logoutRequested = (error) => {
  return {
    type: LOGOUT
  }
};

export const googleLogin = () => {
  return dispatch => {
    dispatch(loginRequested());
    return firebaseGoogleLogin((error)=>(dispatch(loginFailed(error))));
  }
};

export const emailLogin = (email, password) => {
  return dispatch => {
    dispatch(loginRequested());
    return firebaseEmailLogin(email, password, (error)=>(dispatch(loginFailed(error))))
  }
};

export const emailPasswordReset = (email) => {
    return firebaseEmailPasswordReset(email)
}

export const microsoftLogin = () => {
  return dispatch => {
    dispatch(loginRequested());
    return auth0MicrosoftLogin()
  }
};

export const storeCaptchaToken = (value) => {
  localStorage.setItem(CAPTCHA_TOKEN_KEY, value);
}

const getCaptchaToken = () => {
  const token = localStorage.getItem(CAPTCHA_TOKEN_KEY);
  if (typeof token === 'string' || token instanceof String) {
    return token.toString();
  }

  return "";
}

export const woocarLogin = () => {
  const uid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const captchaToken = getCaptchaToken();

  return dispatch => {
    return fetch(BASE_URL + '/api/login', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token,
        'g-recaptcha-response': captchaToken,
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((jsonUser) => {
      jsonUser.uid = uid;
      jsonUser.email = email;
      localStorage.setItem("user", JSON.stringify(jsonUser));
      dispatch(fleetListUpdatedStarted(jsonUser.fleets));
      dispatch(fleetListUpdated(jsonUser.fleets));
      dispatch(loginSucceeded(jsonUser));
    }).catch(error =>{
      dispatch(loginFailed(error.message));
    })
  }
};

export const woocarGetTokenCustom = (email) => {
  return fetch(`${BASE_URL}/api/token/custom?id=${email}`, {
    method: 'GET',
    headers: {
      'Accept': 'text/html'
    }
  })
  .then(response => response.text());
};

export const logoutUser = () => {
  return dispatch => {
    firebaseLogout().then(() => {
      woocarLogout();
      localStorage.removeItem("uid");
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      localStorage.removeItem("user");
    });
    dispatch(logoutRequested())
  }
};

export const woocarLogout = () => {
  return fetch(`${BASE_URL}/api/logout`, {
    method: 'GET',
    headers: {
      'Accept': 'text/html'
    }
  })
  .then(response => response.text());
};

