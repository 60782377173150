import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import _ from 'lodash';
import {withTranslation} from "react-i18next";
import ReadMoreReact from 'read-more-react';

/**
 * Created by jje-personal on 9/4/17.
 */
const DeleteDriverModal = withTranslation('translation')(props => (

  <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">{props.t('driverTable.delete.title')}</p>
      </header>
      <section className="modal-card-body">
        {props.t('driverTable.delete.body', {mail: props.driver.email})}
      </section>
      <footer className="modal-card-foot">
        <div className="buttons">
          <button className="button is-danger" onClick={() => props.onClose(true, props.driver)}>{props.t('commons.delete')}</button>
          <button className="button" onClick={() => props.onClose(false, props.driver)}>{props.t('commons.cancel')}</button>
        </div>
      </footer>
    </div>
  </div>
));

class DriverTable extends Component {

  state = {
    showDeleteModal: false,
    currentDriver: null
  };

  constructor(props) {
    super(props);
    this.state.width = props.width;
  }

  componentWillMount(){
    this.setState({width: window.innerWidth});
  }

  onClickDelete = (driver) => {
    this.setState({currentDriver: _.cloneDeep(driver), showDeleteModal: true});
  };

  onCloseDeleteModal = (shouldDelete, driver) => {
    let { drivers } = this.props;

    if (shouldDelete) {
      // remove driver if exists
      _.remove(drivers, d => d.uid === driver.uid);
      // server call
      this.props.onRemoveDriver(driver.uid);
    }

    this.setState({showDeleteModal: false, currentDriver: null});
  };

  render() {
    const { fleetDetail, drivers, t } = this.props;

    const { showDeleteModal, currentDriver } = this.state;

    return (

      <div>
        {showDeleteModal &&
          <DeleteDriverModal onClose={this.onCloseDeleteModal} driver={currentDriver}/>
        }


        { this.state.width < 710 &&
          drivers.map(((d, index) => (
            <div className="mobile-table">
              <div className="pull-right">
                <a className="fa fa-remove" onClick={() => (this.onClickDelete(d))}></a>
              </div>

              <div>
                <Link to={`/fleet/${fleetDetail.id}/driver/${d.uid}`}>{d.name} <a className="fa fa-external-link fa-1"></a></Link>
              </div>
              <div><strong>{t('driverTable.email')}: </strong>{d.email}</div>
              <div><strong>{t('driverTable.id')}: </strong>{d.uid}</div>
            </div>)))
        }

				{this.state.width >= 710 &&
          <table className="table">
            <thead>
              <tr>
                <th>{t('driverTable.name')}</th>
                <th>{t('driverTable.email')}</th>
                <th>{t('driverTable.id')}</th>
                <th>{t('driverTable.delete.delete')}</th>
              </tr>
            </thead>
            <tbody>
            {drivers.map(((d, index) => (
              <tr key={index}>
                <td><Link to={`/fleet/${fleetDetail.id}/driver/${d.uid}`}>{d.name}</Link> {d.current_fleet_member === false && t("driverTable.driver_status_" + d.current_fleet_member)}</td>
                <td>{d.email}</td>
                <td>
                  <ReadMoreReact text={d.uid}
                    min={10}
                    ideal={40}
                    max={50}
                    readMoreText="..." />
                </td>
                <td>{d.current_fleet_member === true  && <a className="fa fa-remove" onClick={() => (this.onClickDelete(d))}></a>}</td>
              </tr>
            )))}
            </tbody>
          </table>
				}
      </div>
    );
  }
}

DriverTable.propTypes = {
  drivers: PropTypes.array.isRequired,
  onRemoveDriver: PropTypes.func.isRequired
};

export default withTranslation('translation')(DriverTable);
