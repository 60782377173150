import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import {withTranslation} from "react-i18next";
import moment from "moment";

import Checkbox from "../Checkbox";
import SendEmail from "../SendEmail";
import CollapsableCard from "../../components/CollapsableCard";

class DriverEngagementTable extends Component {

  state = { engagementDrivers: [] }

  formatDrivers(){
    const { drivers } = this.props;
    if (drivers) {
      const formattedDrivers = drivers.map(driver => {
        return {
          ...driver,
          isChecked: false 
        }
      });
      this.setState({ engagementDrivers: formattedDrivers });
    }
  }

  componentWillMount(){
    this.formatDrivers();
  }

  componentDidUpdate(prevProps){
    if (prevProps.drivers !== this.props.drivers){
      this.formatDrivers();
    }
  }

  getStatusClassname = (statusToVerify, status, message) => {
    if (status) return "icon has-text-success";
    else {
      if (message.includes(statusToVerify)) return "icon has-text-danger";
      else return "icon has-text-success";
    }
  }

  getLastTripAsDate = (lastTrip) => {
    const { t } = this.props;
    const noTripsMessage = t("driverEngagementTable.noTrips");
    if(!lastTrip) return noTripsMessage;
    else return moment.unix(lastTrip /1000).format("DD-MM-YYYY HH:mm");
  }

  handleAllChecked = event => {
    const engagementDrivers = this.state.engagementDrivers;
    engagementDrivers.forEach(driver => (driver.isChecked = event.target.checked));
    this.setState({ engagementDrivers });
  };

  checkIfAreAllChecked = () => {
    const engagementDrivers = this.state.engagementDrivers;
    return engagementDrivers.every(driver => driver.isChecked);
  }

  onSelectDriver = event => {
    let engagementDrivers = this.state.engagementDrivers;
    engagementDrivers.forEach(driver => {
      if (driver.email === event.target.value)
        driver.isChecked = event.target.checked;
    });
    this.setState({ engagementDrivers });
  };

  getAppVersion = appVersion => {
    const { t } = this.props;
    if (!appVersion) return t("driverEngagementTable.notAvailable");
    else return appVersion;
  }

  render() {
    const { t, fleetDetail } = this.props;
    const { engagementDrivers } = this.state;
    const subject = t("driverEngagementTable.mailSubject");
    const body = t("driverEngagementTable.mailBody")
    let emailList = engagementDrivers.filter(driver => driver.isChecked).map(driver => driver.email);
    const to = [emailList[0]];
    const cco = emailList.slice(1);
    
    return (
      <CollapsableCard title={t('home.engagement')}>
        <div>
          <div className="is-flex">
            <div className="column fit-content is-flex is-align-items-center">
              <input
                type="checkbox"
                onClick={this.handleAllChecked}
                value="checkedall"
              />
              {t("driverEngagementTable.selectAll")}
            </div>

            <div className="column">
              <SendEmail
                to={to}
                cco={cco}
                subject={subject}
                body={body}
              >
                <button
                  disabled={!(engagementDrivers.some(driver => driver.isChecked))}
                  className="button is-primary"
                >
                  {t("driverEngagementTable.notifyDrivers")}
                </button>
              </SendEmail>
            </div>
          </div>
        </div>

        <table className="table">
          <thead>
            <tr>
              <th>
                {t('driverEngagementTable.driver')}
              </th>
              <th><abbr title={t("driverEngagementTable.lastTrip")}>{t("driverEngagementTable.lastTrip")}</abbr></th>
              <th><abbr title={t("driverEngagementTable.truckStatus")}>{t("driverEngagementTable.truckStatus")}</abbr></th>
              <th><abbr title={t("driverEngagementTable.GPSStatus")}>{t("driverEngagementTable.GPSStatus")}</abbr></th>
              {/* <th><abbr title={t("driverEngagementTable.motionStatus")}>{t("driverEngagementTable.motionStatus")}</abbr></th> */}
              <th><abbr title={t("driverEngagementTable.appVersion")}>{t("driverEngagementTable.appVersion")}</abbr></th>
            </tr>
          </thead>

          <tbody>
            {engagementDrivers.map((d, index) => (
              <tr key={index}>
                <td>
                  <label className="checkbox">
                    <Checkbox key={index} isChecked={d.isChecked} value={d.email} onChange={this.onSelectDriver} />
                    <Link to={"/fleet/" + fleetDetail.id + "/driver/" + d.uid}>
                      {d.username}
                    </Link>
                  </label>
                </td>
                <td><span>{this.getLastTripAsDate(d.last_trip)}</span></td>
                <td><span className={this.getStatusClassname("Widget OFF", d.status, d.message)}><i className="fa fa-truck"></i></span></td>
                <td><span className={this.getStatusClassname("GPS OFF", d.status, d.message)}><i className="fa fa-circle"></i></span></td>
                {/* <td><span className="icon has-text-danger"><i className="fa fa-circle"></i></span></td> */}
                <td><span>{this.getAppVersion(d.app_version)}</span></td>
              </tr>
            ))}
          </tbody>
        </table>
    </CollapsableCard>
    );
  }
}

DriverEngagementTable.defaultProps = {
  drivers: [],
};

DriverEngagementTable.propTypes = {
  drivers: PropTypes.array,
  fleetDetail: PropTypes.object.isRequired
};

export default withTranslation('translation')(DriverEngagementTable);
