import React, {Component} from "react";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";

class StopoverCommentsModal extends Component {

    render() {
        const {comments, loading, onClose, t} = this.props
        return (
            <div className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">{t('stopOverTable.comments.title')}</p>
                    </header>

                    <section className="modal-card-body">
                        { !loading && comments.images.map((url, index) => (
                            <img key={index} src={url} />
                            )
                        )}
                        { !loading && comments.images.length == 0 && <p>{t('stopOverTable.comments.noImage')}</p>}

                    </section>
                    <footer className="modal-card-foot">
                        <div className="buttons">
                            <button className="button"
                                    onClick={() => onClose()}>{t('commons.cancel')}</button>
                        </div>
                    </footer>
                </div>
            </div>
        );
    }
}

export default withRouter(withTranslation('translation')(StopoverCommentsModal));