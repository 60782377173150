import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import RoadMapTimeline from "./RoadMapTImeline";
import RoadMapRealTrips from "./RoadMapRealTrips";
//import TripsTable from "../driver/TripsTable";
import {getTextFromRoadMapValue, getDate} from "./constants";
import { minutesToHoursAndMinutes } from "../../utils/functions";
import { NavLink } from 'react-router-dom';


class RoadMapRow extends Component {

  state = {
    showSummary: false,
    showRealTrip: false
  };

  toggleSummary = () => {
      this.setState({showSummary: !this.state.showSummary})
  }

  toggleRealTrip = () => {
    this.setState({showRealTrip: !this.state.showRealTrip})
}

  render() {
      const { onDelete ,roadMap, onEdit, onDuplicate, onComments, t, fleetId} = this.props
      const { showSummary, showRealTrip } = this.state
      

      return (
          <div>
          <div className="card" style={{background: '#f8f8f8'}} >
              <header className="card-header">
                  <div className="column is-9">
                      <p className="card-header-title">{roadMap.name}</p>
                  </div>
                  <div className="column is-3">
                      <div className="is-pulled-right">
                          <button className="button is-primary is-outlined margin-right-5x"
                                  onClick={() => {onDuplicate(roadMap)}}><i className="fa fa-clone"></i></button>
                          <button className="button is-primary is-outlined margin-right-5x"
                                  onClick={() => {onEdit(roadMap)}}><i className="fa fa-pencil"></i></button>
                          <button className="button is-primary is-outlined"
                                  onClick={() => {onDelete(roadMap)}}><i className="fa fa-times"></i></button>
                      </div>
                  </div>
              </header>
              <div className="card-content">
                  <div className="columns is-vcentered">
                      <div className="column">
                          <span className="tag" style={roadMap.status == 3 ? {background: "#00C6A8", color: "white"} : roadMap.status == 2 ? {background: "#3886bb", color: "white"} : {background: "#bdbdbd", color: "white"}}>{t(getTextFromRoadMapValue(roadMap.status))}</span>
                      </div>
                      <div className="column">
                            {roadMap.status == 2 &&
                                <NavLink className="is-active" to={"/fleet/" + fleetId + "/driver/" + roadMap.drivers[0].id + "/locations"}> 
                                <button className="button">
                                    <span>{t('header.dropdown.live')} </span>
                                 </button> 
                                </NavLink>
                            }                          
                      </div>
                      <div className="column is-2">
                          {roadMap.drivers.map(((dv) => (
                              <div key={dv.name}>{dv.name}</div>
                          )))}
                      </div>
                      <div className="column is-2">
                          {getDate(roadMap.start_date)}
                      </div>
                      <div className="column is-2">
                          {getDate(roadMap.end_date)}
                      </div>
                      <div className="column is-2">
                          {roadMap.eta && `${minutesToHoursAndMinutes(roadMap.eta, this.props.t)}`}
                      </div>
                      <div className="column is-1">
                          {roadMap.distance && `${roadMap.distance} kms`}
                      </div>
                      <div className="column is-1">
                          <div className="is-pulled-right">
                              <p className="control">
                                  {roadMap.stopovers.length > 0 &&
                                      <button className="button is-primary" onClick={this.toggleSummary}>
                                          <span className="icon is-small">
                                              <i className={showSummary ? "fa fa-arrow-up" : "fa fa-arrow-down"}></i>
                                          </span>
                                          <span>{t('roadMapTable.summary')}</span>
                                      </button> }
                              </p>
                          </div>
                      </div>
                      <div className="column">
                          <div>
                              <p className="control">
                                  {roadMap.stopovers.length > 0 &&
                                      <button className="button" onClick={this.toggleRealTrip}>
                                          <span className="icon is-small">
                                              <i className={showRealTrip ? "fa fa-arrow-up" : "fa fa-arrow-down"}></i>
                                          </span>
                                          <span>{t('roadMapTable.roadmap_trips')}</span>
                                      </button> }
                              </p>
                          </div>
                      </div> 

                  </div>
                  {showSummary && <RoadMapTimeline roadmap_id={roadMap.id} stops={roadMap.stopovers} roadmap_status={roadMap.status} onComments={onComments}/>}
                  {showRealTrip && <RoadMapRealTrips fleetId={fleetId} roadMapId={roadMap.id}/>}
              </div>
          </div>
          <br></br>
          </div>
      );
  }
}

export default withRouter(withTranslation('translation')(RoadMapRow));