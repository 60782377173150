import {connect} from 'react-redux';
import StopOversTable from "../components/RoadMaps/StopOversTable";
import {fetchStops, createStopOver, deleteStopOver, updateStopOver} from "../redux/stops";


function mapStateToProps(state) {
  const {stopsOvers} = state.stops
  const {selected} = state.fleet;
  const should_show_external_ids = selected ? selected.should_show_external_ids : false;
  return { stopsOvers, should_show_external_ids };
}

const mapDispatchToProps = dispatch => {
  return {
    fetchStops: (fleetId) => {
      dispatch(fetchStops(fleetId));
    },
    saveStopOver: (fleetId, stopOver) => {
      if (stopOver.id === 0) {
        dispatch(createStopOver(fleetId,stopOver))
      } else {
        dispatch(updateStopOver(fleetId,stopOver))
      }
    },
    deleteStopOver: (fleetId, stopOver) => {
      dispatch(deleteStopOver(fleetId,stopOver))
    }
  }
};

const StopsOversTableContainer = connect(mapStateToProps, mapDispatchToProps)(StopOversTable);

export { StopsOversTableContainer }