import React, { Component } from 'react';
import { withTranslation } from "react-i18next";


import 'react-tabs/style/react-tabs.css';

import Warning from '../components/Warning';
import ButtonLinkPrimary from '../components/buttons/ButtonLinkPrimary';

class NotAllowed extends Component {

    render() {
        const { t } = this.props;

        return (
            <div className='container my-auto'>
                <div className='rows'>
                    <div className='row my-5'>
                        <Warning
                            title={t('app.sorry')}
                            message={t('app.notAllowed')}
                        />
                    </div>
                    <div className='row my-5'>
                        <div className='columns is-flex is-vcentered is-centered is-fullwidth'>
                            <ButtonLinkPrimary to="/login">
                                {t('authentication.login')}
                            </ButtonLinkPrimary>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default withTranslation('translation')(NotAllowed);