import React, { Component } from 'react';
import { Route, Switch } from 'react-router'
import './App.css';
import { firebaseAuth } from './firebase/config'
import { onAuthStateChanged } from 'firebase/auth';
import PrivateRoute from './components/PrivateRoute'
import Header from './containers/Header'
import {logoutUser, storeCaptchaToken, woocarLogin} from './redux/auth'
import Login from './containers/LoginContainer'
import Home from './pages/Home'
import FleetLocation from './containers/FleetLocationContainer'
import DriverLocation from './containers/DriverLocationContainer'
import FleetSettingsContainer from './containers/FleetSettingsContainer'
import ReportsContainer from './containers/FleetReportsContainer'
import DriverDetail from './containers/DriverDetailContainer'
import TripPathFilterMap from './containers/TripPathFilterMapContainer'
import { VehiclesContainer } from './containers/VehiclesContainer'
import { RoadMapsContainer } from './containers/RoadMapsContainer'
import VehicleContainer from './containers/VehicleContainer'
import ExpensesContainer from './containers/ExpensesContainer'
import ExpensesConfigContainer from './containers/ExpensesConfigContainer'
import { CrtsContainer } from "./containers/CrtsContainer";
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import CrtContainer from "./containers/crt/CrtContainer";
import LoadRoutObserver from "./containers/crt/LoadRoutObserver";
import Loading from "./components/Loading";
import PasswordReset from './pages/PasswordReset';
import PushNotificationSender from './pages/PushNotificationSender';
import Warning from './components/Warning';
import NotAllowed from './pages/NotAllowed';
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";

const NotFleetPage = withTranslation('translation')(({ t }) => <Warning title={t('app.sorry')} message={t('app.noFleet')} />);

class App extends Component {

  constructor(props) {
    super(props);
    this.onload = this.onLoad.bind(this);
  }

  onLoad = () => {
    this.setState({ loaded: true });
  };

  handleVerifyRecaptcha = async () => {
    const { executeRecaptcha } = this.props.googleReCaptchaProps;

    if (!executeRecaptcha) {
      console.error('Recaptcha has not been loaded');
      return;
    }

    storeCaptchaToken(await executeRecaptcha('login'));
  };

  componentDidMount = () => {
    onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        this.handleVerifyRecaptcha().then(() => {
          user.getIdToken(true).then(data => {
            let old = localStorage.getItem("token");
            if (data !== old) {
              localStorage.setItem("token", data);
              this.props.login();
            }
          })
        });

        localStorage.setItem("uid", user.uid);
        localStorage.setItem("email", user.email);

      } else {
        this.props.logout();
      }
    })
  };

  render() {
    const link = this.props.location.pathname;
    const { observerLoading, loadingSelected, fleetsLoading } = this.props;

    return (
      <div className="hero is-fullheight">

        <Header />
        {!fleetsLoading &&
          <LoadRoutObserver
            link={link}
            onLoad={this.onLoad}
          />
        }
        {observerLoading && loadingSelected && fleetsLoading && <Loading wait={0}/>}
        {!observerLoading && !loadingSelected && !fleetsLoading &&
          <div className="hero-body">
            <Switch>
              <PrivateRoute exact path='/' component={Home} />
              <PrivateRoute exact path='/fleet/:fleetId/' component={Home} />
              <PrivateRoute exact path='/fleet/:fleetId/fleetLocation' component={FleetLocation} />
              <PrivateRoute exact path='/fleet/:fleetId/driver/:driverId/locations' component={DriverLocation} />
              <PrivateRoute exact path='/fleet/:fleetId/trip' component={TripPathFilterMap} />
              <PrivateRoute exact path='/fleet/:fleetId/settings' component={FleetSettingsContainer} />
              <PrivateRoute exact path='/fleet/:fleetId/driver/:id' component={DriverDetail} />
              <PrivateRoute exact path='/fleet/:fleetId/reports' component={ReportsContainer} />
              <PrivateRoute exact path='/fleet/:fleetId/vehicle' component={VehiclesContainer} />
              <PrivateRoute exact path='/fleet/:fleetId/vehicle/:vehicleId' component={VehicleContainer} />
              <PrivateRoute exact path='/fleet/:fleetId/crt' component={CrtsContainer} />
              <PrivateRoute exact path='/fleet/:fleetId/crt/:crtId' component={CrtContainer} />
              <PrivateRoute exact path='/fleet/:fleetId/expenses/config' component={ExpensesConfigContainer} />
              <PrivateRoute exact path='/fleet/:fleetId/expenses' component={ExpensesContainer} />
              <PrivateRoute exact path='/fleet/:fleetId/roadMaps' component={RoadMapsContainer} />
              <PrivateRoute exact path="/fleet/:fleetId/sendPushNotification" component={PushNotificationSender} />

              {/* <Route exact path='/.well-known/microsoft-identity-association.json' component={NotAllowedPage} /> */}
              {/* <Route exact path='/notAllowed' component={NotAllowedPage} /> */}
              <Route exact path='/.well-known/microsoft-identity-association.json' component={NotAllowed} />
              <Route exact path='/notAllowed' component={NotAllowed} />
              <Route exact path='/noFleets' component={NotFleetPage} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/resetPass" component={PasswordReset} />
            </Switch>

          </div>
        }
        {/*<Footer/>*/}
      </div>
    );
  }
}

function mapStateToProps(state) {

  const { observerLoading, loadingSelected, fleetsLoading } = state.fleet;

  return {
    observerLoading,
    loadingSelected,
    fleetsLoading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    login: () => {
      dispatch(woocarLogin())
    },
    logout: () => {
      dispatch(logoutUser())
    }
  }
};

export default withGoogleReCaptcha(connect(mapStateToProps, mapDispatchToProps)(App));

