import React, {Component} from 'react';
import DatePicker from "../DatePicker";
import DriverSuggestionContainer from "../../containers/DriverSuggestionContainer";
import {withTranslation} from "react-i18next";
import connect from "react-redux/es/connect/connect";
import {fetchVehicleDashboard} from '../../redux/vehicle';
import moment from "moment";
import ActivityStream from "./ActivityStream";
import VehicleScoreConsumptionChart from "../charts/VehicleScoreConsumptionChart";
import VehicleEcoConsumptionChart from "../charts/VehicleEcoConsumptionChart";
import Loading from "../Loading";


const EMPTY_DATA = {
  evolution: [],
  activities: []
};


class TabVehicleDashboard extends Component {

  state = {
    dateRangeSelected: {startDate: moment(new Date()).startOf('day'), endDate: moment(new Date()).endOf('day')},
    driverSelected : null,
  };

  componentWillMount() {
    this.fetchData();
  }

  fetchData = () => {
    const {fleetId, vehicleId} = this.props;
    const {startDate, endDate} = this.state.dateRangeSelected;
    this.props.fetchVehicleDashboard(fleetId, vehicleId, startDate, endDate);
  };

  onChangeDriver = (driver) => {
    this.setState({driverSelected: driver})
  };

  onChangeDateRange = (range) => {
    this.setState({dateRangeSelected: range})
  };

  onClickSearch = () => {
    this.fetchData();
  };

  render() {
    const {t, vehicleDashboardData} = this.props;
    const {dateRangeSelected, scoreSelected} = this.state;
    const safeData = vehicleDashboardData || EMPTY_DATA;

    return (
      <div>
        {!vehicleDashboardData && <Loading wait={0}/>}

        <div className="columns">
          <div className="column">
            <DatePicker onChange={this.onChangeDateRange} dateRangeSelected={dateRangeSelected}/>
          </div>

          <div className="column">
            <div className="field">
              <label className="label">{t('commons.filter.drivers')}</label>
              <div className="control">
                <DriverSuggestionContainer selected={[]} onSelected={this.onChangeDriver} cleanOnSelected={false}/>
              </div>
            </div>
          </div>

          <div className="column is-1">
            <button className="button is-primary expense-search-button pull-right" onClick={this.onClickSearch}>
              <i className="fa fa-search"></i>
            </button>
          </div>
        </div>

        <div className="columns fix-margins">

          <div className="column">
            <div className="columns">
              <div className="column">
                <VehicleScoreConsumptionChart property={scoreSelected} data={safeData.evolution}/>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <VehicleEcoConsumptionChart property={scoreSelected} data={safeData.evolution}/>
              </div>
            </div>
          </div>

          <div className="column is-one-third">
            <ActivityStream activities={safeData.activities}/>
          </div>

        </div>

      </div>
    );
  }
}


const mapDispatchToProps = dispatch => {
  return  {
    fetchVehicleDashboard: (fleetId, vehicleId, fromDate, toDate, driver) => {
      dispatch(fetchVehicleDashboard(fleetId, vehicleId, fromDate, toDate, driver))
    },
  }
};

function mapStateToProps(state) {
  const { vehicleDashboardData } = state.vehicle;
  return { vehicleDashboardData };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(TabVehicleDashboard))
