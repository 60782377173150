import React, { Component } from 'react';

class Loading extends Component {

  state = {
    show : false
  };

  componentDidMount() {
    let that = this;
    this.timerId = setTimeout(function() {
        that.show();
    }, that.props.wait || 900);
  }

  componentWillUnmount() {
    clearInterval(this.timerId)
  }

  show() {
    this.setState({show : true})
  }

  render() {

    return (
      <div>
      { this.state.show &&

        <div className="modal is-active">
          <div className="modal-background-brosoft"></div>
          <i className="loader" aria-hidden="true"></i>
        </div>
      }
      </div>
    )
  }

}

// const Loading = () => (
//     <div className="modal is-active">
//       <div className="modal-background-brosoft"></div>
//       <i className="loader" aria-hidden="true"></i>
//     </div>
// )

export default Loading