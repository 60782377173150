/**
 * Created by jje-personal on 9/4/17.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {withTranslation} from "react-i18next";

class InvitesTable extends Component {

  render() {
    const { invites, t } = this.props;

    return (
      <table className="table">
        <thead>
          <tr>
            <th>{t('invitesTable.delete')}</th>
            <th>{t('invitesTable.cancel')}</th>
          </tr>
        </thead>
        <tbody>
        { invites.map(((d, index) => (
          <tr key={index}>
            <td>{d.email}</td>
            <td><a className="fa fa-remove" onClick={()=>(this.props.onRemoveInvite(d.email))}></a></td>
          </tr>
        )))}
        </tbody>
      </table>
    );
  }
}

InvitesTable.propTypes = {
  invites: PropTypes.array.isRequired,
  onRemoveInvite: PropTypes.func.isRequired
};

export default withTranslation('translation')(InvitesTable);
