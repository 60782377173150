import React from "react";
//to and cco are email lists
const SendEmail = ({ to, cco, subject, body, children }) => (
    <a
        href={`mailto:${to.join()}?bcc=${cco.join()}&subject=${subject}&body=${body}`}
        rel="noopener noreferrer"
        target="_blank"
    >
        {children}
    </a>    
);

export default SendEmail;