import React, {Component} from "react";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";

class RoadMapDeleteModal extends Component {

    weekdays = {
        'mon': 'Lunes',
        'tue': 'Martes',
        'wed': 'Miercoles',
        'thu': 'Jueves',
        'fri': 'Viernes',
        'sat': 'Sábado',
        'sun': 'Domingo',
    }

    render() {
        const {pn, onClose, t} = this.props
        return (
            <div className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">{t('roadMapTable.delete.title')}</p>
                    </header>
                    {pn &&
                    <section className="modal-card-body">
                        <label className="label has-text-left">¿Seguro que quieres borrar la siguiente notificacion programada?</label>
                        <div className="card">
                            <div className="card-content">
                                <label className="label has-text-left">{pn.title}</label>
                                <div>{pn.body}</div>
                                <div>{this.weekdays[pn.day]} - {pn.time.slice(0,2)}:{pn.time.slice(2)}</div>
                            </div>
                        </div>
                    </section>
                    }
                    <footer className="modal-card-foot">
                        <div className="buttons">
                            <button className="button is-danger"
                                    onClick={() => onClose(true, pn)}>{t('commons.delete')}</button>
                            <button className="button"
                                    onClick={() => onClose(false, pn)}>{t('commons.cancel')}</button>
                        </div>
                    </footer>
                </div>
            </div>
        );
    }
}

export default withRouter(withTranslation('translation')(RoadMapDeleteModal));