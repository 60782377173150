import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import classNames from "classnames";
import Timestamp from "react-timestamp";
import _ from "lodash";
import {secondsToHms} from "../../utils/functions";
import {BASE_URL, STATIC_MAP} from '../../config'
import SimpleMap from "../maps/SimpleMap";
import {checkStatus, parseJson} from "../../api/utils";
import {toast} from "react-toastify";
import TripsModal from "../driver/TripsModal";


const EmptyCard = withTranslation('translation')(props => (
  <div className="timeline-content is-danger">
    <p className="heading"><Timestamp time={props.act.timestamp / 1000} format="full"/></p>
    <p>{props.t(`activityStream.content.empty`, {count: props.act.extra})}</p>
  </div>
));

class EventCard extends Component {

  state = {
  };

  render() {
    const {event, t} = this.props;
    const {showRealMap} = this.state;

    return (
      <div className="card vechicle-card" key={'event' + event.id}>
        <div className="card-contet is-paddingless-top is-paddingless-bottom">
          <div className="columns is-vcentered has-text-centered">
            <div className="column is-2">
              <p className="heading"><Timestamp time={event.timestamp / 1000} format="full"/></p>
            </div>
            <div className="column is-4">
              <p className="heading">{t(`activityStream.content.${event.type}`, {count: event.extra})}</p>
            </div>
            {showRealMap ?
              <div className="column is-5">
                <div className="map-wrapper">
                  <SimpleMap location={event.location} />
                </div>
              </div>
            :
              <div className="column hand-cursor" onClick={() => this.setState({showRealMap: true})}>
                <img style={{'WebkitUserSelect': 'none'}}
                     src={`${STATIC_MAP}&center=${event.location.lat},${event.location.lon}&markers=size:tiny|color:red|${event.location.lat},${event.location.lon}`}/>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }

}
const EventCardWrapper = withTranslation('translation')(EventCard);


class TripCard extends Component {

  state = {loading: false, showingDetails: false, tripWithPolylines:null}

  componentWillMount() {
    const {fleeDrivers, trip} = this.props;
    trip.driver = _.find(fleeDrivers, d => d.uid  === trip.userId);
  }

  onClickViewTrip = (tripId) => {
    // let { fleetDetail } = this.props;
    // this.props.history.push(`/fleet/${fleetDetail.id}/crt/${crt.id}`);
  };

  onCloseTripDetail = () => {
    this.setState({ showingDetails: false, tripWithPolylines: null })
  };

    onOpenTripDetail = (trip) => {
    this.setState({ loading: true });
    let token = localStorage.getItem("token");
    const {fleetId} =  this.props;
    const url = BASE_URL + '/api/fleet/' + fleetId + '/trip/' + trip.id + '/detail';
    return fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer '+ token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {
      this.setState({ loading: false, showingDetails: true, tripWithPolylines:json })
    }).catch(error =>{
      this.setState({ loading: false });
      toast.error("Error");
    })
  };

  render() {
    const {trip, t} = this.props;
     const selected = this.state.tripWithPolylines;

    return(
      <div className="card vechicle-card" key={'trip' + trip.id}>
        <div className="card-contet is-paddingless-top is-paddingless-bottom">
          <div className="columns is-vcentered has-text-centered">
            <div className="column is-2">
              <p className="heading"><Timestamp time={trip.timestamp / 1000} format="full"/></p>
            </div>
            <div className="column is-2">
              <p className="heading"><strong>{t('tripsTable.duration')}: </strong><br/>{secondsToHms(trip.duration)}</p>
            </div>
            <div className="column is-2">
              <p className="heading"><strong>{t('tripsTable.distance')}: </strong><br/>{t('commons.interpolation.distance', {count: trip.distance.toFixed(2)})}</p>
            </div>
            <div className="column is-2">
              <p className="heading"><strong>{t('commons.total')}: </strong><br/>{trip.total}</p>
            </div>
            <div className="column is-2">
              <p className="heading"><strong>{t('commons.driver')}: </strong><br/>{trip.driver.name}</p>
            </div>

            <div className="column column is-2">

              <div className="field is-grouped">
                {/*<button className="button is-primary is-outlined margin-right-5x"*/}
                {/*   onClick={() => {this.onClickViewTrip(trip.id)}}><i className="fa fa-line-chart"></i></button>*/}
                <button className="button is-primary is-outlined"
                   onClick={() => {this.props.onClickDeleteTrip(trip.id)}}><i className="fa fa-times"></i></button>
                <button className="button is-primary is-outlined"
                onClick={() => (this.onOpenTripDetail(trip))}><i className="fa fa-expand"></i></button>
              </div>
            </div>

          </div>
        </div>
        { this.state.showingDetails &&
                <TripsModal
                    onCloseTripDetail = {this.onCloseTripDetail}
                    selected = {selected}
                    loading = {this.state.loading}/>
        }
      </div>
    )
  }
}
const TripCardWrapper = withTranslation('translation')(TripCard);


class CrtDashboard extends Component {

  state = {
    activities: []
  };

  componentWillMount() {
    let {trips, events} = this.props.crt;

    _.map(trips, (t, key) => {
      t.type = 'trip';
      t.id = key;
    });
    trips = _.values(trips);

    _.each(events, e => {
      e.type = 'tagEvent';
      e.extra = e.tags.join(', ');
    });

    let activities = _.concat(trips, events);
    activities.sort((a,b) => {
      if (a.timestamp < b.timestamp) return 1;
      if (a.timestamp > b.timestamp) return -1;
      return 0;
    });

    this.setState({activities: activities});
  }

  getMarker = (act) => {
    switch (act.type) {
      case 'trip': return 'fa-truck';
      case 'tagEvent': return 'fa-building';
      case 'empty': return 'fa-times'
    }
  };

  deleteTripFromCrt = (tripId) => {
    const {fleetId, crt} = this.props;
    const {activities} = this.state;

    _.remove(activities, a => a.type === 'trip' && a.id === tripId);
    this.setState({activities});
    this.props.deleteTripFromCrt(fleetId, crt.id, tripId);
  };

  getCard = (act) => {
    const {drivers} = this.props.fleetDetail;
    const {fleetId} = this.props;
    switch (act.type) {
      case 'trip': return <TripCardWrapper trip={act} fleeDrivers={drivers} onClickDeleteTrip={this.deleteTripFromCrt} fleetId = {fleetId} />;
      case 'tagEvent': return <EventCardWrapper event={act}  />;
      case 'empty': return <EmptyCard act={act} />;
    }
  };

  render() {
    const {t} = this.props;
    const {activities} = this.state;

    if (activities && activities.length === 0) {
      activities.push({type: "empty", timestamp: new Date().getTime()});
    }

    return (
      <div className="card crt">
        <header className="card-header">
          <div className="card-header-title">
            <div className="media-right">{t('activityStream.title')}</div>
          </div>
        </header>
        <div className="card-content">
          <div className="timeline full is-paddingless">
            <header className="timeline-header">
              <span className="tag is-medium is-primary">{t('commons.now')}</span>
            </header>
            {
              activities.map((act, index) => (
                <div className="timeline-item" key={index + '-div'}>
                  <div className="timeline-marker is-icon is-48x48">
                    <i className={classNames("fa", this.getMarker(act), "fa-2x")}></i>
                  </div>

                  <div className={classNames("timeline-content is-fullwidth", {"is-danger": act.type === "empty"})}>
                    {this.getCard(act)}
                  </div>
                </div>
                )
              )
            }
            <div className="timeline-header">
              <span className="tag is-medium is-primary">{t('commons.start')}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('translation')(CrtDashboard);
