import { AUTH0_CONFIG } from '../config';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { firebaseLoginWithCustomToken, currentUser } from '../firebase/config'
import {logoutUser, woocarLogin, woocarGetTokenCustom} from '../redux/auth'

export const auth0 = new Auth0Client(AUTH0_CONFIG);

export function logout() {
  auth0.logout();
  logoutUser();
}

export async function auth0MicrosoftLogin() {
  await auth0.loginWithPopup();
  const user = await auth0.getUser();
  localStorage.setItem("token", user.sub);
  localStorage.setItem("email", user.email);
  localStorage.setItem("uid", user.email);

  woocarGetTokenCustom(user.email)
  .then(function(token) {  
    firebaseLoginWithCustomToken(token)
    .then(function(response) {
      currentUser().updateEmail(user.email);
      woocarLogin();
    });
  });
}

export async function auth0MicrosoftGetUser() {
  await auth0.getUser();
}

export async function auth0MicrosoftIsAuthenticated() {
  return await auth0.isAuthenticated();
}