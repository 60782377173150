import moment from 'moment'
import {checkStatus, parseJson} from '../api/utils'
import {BASE_URL} from '../config'
import _ from 'lodash';

const RESET_LAST_LOCATION = 'woo/fleet/RESET_LAST_LOCATION';
const LAST_LOCATION = 'woo/fleet/LAST_LOCATION';
const LAST_LOCATION_SUCCESS = 'woo/fleet/LAST_LOCATION_SUCCESS';
const FLEET_SELECTED_REQUESTED = 'woo/fleet/FLEET_SELECTED_REQUESTED';
const FLEET_SELECTED_SUCCESS = 'woo/fleet/FLEET_SELECTED_SUCCESS';
const FLEET_LIST_UPDATED_STARTED = 'woo/fleet/FLEET_LIST_UPDATED_STARTED';
const FLEET_LIST_UPDATED = 'woo/fleet/FLEET_LIST_UPDATED';
const UPDATE_SELECTED = 'woo/fleet/UPDATE_SELECTED';


const FLEET_DETAIL = 'woo/fleet/FLEET_DETAIL';
const FLEET_DETAIL_OBSERVABLE = 'woo/fleet/FLEET_DETAIL_OBSERVABLE';
const FLEET_DETAIL_SUCCESS = 'woo/fleet/FLEET_DETAIL_SUCCESS';
const FLEET_DETAIL_SUCCESS_OBSERVABLE = 'woo/fleet/FLEET_DETAIL_SUCCESS_OBSERVABLE';

const DRIVER_DETAIL = 'woo/fleet/DRIVER_DETAIL';
const DRIVER_DETAIL_SUCCESS = 'woo/fleet/DRIVER_DETAIL_SUCCESS';

const DRIVER_TRIPS = 'woo/fleet/DRIVER_TRIPS';
const DRIVER_TRIPS_SUCCESS = 'woo/fleet/DRIVER_TRIPS_SUCCESS';

const UPDATE_DATE_FILTERS = 'woo/fleet/UPDATE_DATE_FILTERS';

const SAVE_VEHICLE_REQUEST = 'woo/flle/SAVE_VEHICLE_REQUEST';
const SAVE_VEHICLE_REQUEST_SUCCESS = 'woo/flle/SAVE_VEHICLE_REQUEST_SUCCESS';

const DELETE_VEHICLE_REQUEST = 'woo/flle/DELETE_VEHICLE_REQUEST';
const DELETE_VEHICLE_REQUEST_SUCCESS = 'woo/flle/DELETE_VEHICLE_SUCCESS';

const FETCH_VEHICLES_REQUEST = 'woo/flle/FETCH_VEHICLES_REQUEST';
const FETCH_VEHICLES_REQUEST_SUCCESS = 'woo/flle/FETCH_VEHICLES_REQUEST_SUCCESS';

const FETCH_VEHICLE_REQUEST = 'woo/flle/FETCH_VEHICLE_REQUEST';
const FETCH_VEHICLE_REQUEST_SUCCESS = 'woo/flle/FETCH_VEHICLE_REQUEST_SUCCESS';

const SAVE_CRT_REQUEST = 'woo/flle/SAVE_CRT_REQUEST';
const SAVE_CRT_REQUEST_SUCCESS = 'woo/flle/SAVE_CRT_REQUEST_SUCCESS';

const DELETE_CRT_REQUEST = 'woo/flle/DELETE_CRT_REQUEST';
const DELETE_CRT_REQUEST_SUCCESS = 'woo/flle/DELETE_CRT_SUCCESS';

const DELETE_TRIP_FROM_CRT_REQUEST = 'woo/flle/DELETE_TRIP_FROM_CRT_REQUEST';
const DELETE_TRIP_FROM_CRT_REQUEST_SUCCESS = 'woo/flle/DELETE_TRIP_FROM_CRT_REQUEST_SUCCESS';

const FETCH_CRT_REQUEST = 'woo/flle/FETCH_CRT_REQUEST';
const FETCH_CRT_REQUEST_SUCCESS = 'woo/flle/FETCH_CRT_REQUEST_SUCCESS';

const FETCH_CRTS_REQUEST = 'woo/flle/FETCH_CRTS_REQUEST';
const FETCH_CRTS_REQUEST_SUCCESS = 'woo/flle/FETCH_CRTS_REQUEST_SUCCESS';

const FETCH_CREDIT_CARD_REQUEST = 'woo/flle/FETCH_CREDIT_CARD_REQUEST';
const FETCH_CREDIT_CARD_REQUEST_SUCCESS = 'woo/flle/FETCH_CREDIT_CARD_REQUEST_SUCCESS';

const GET_EXPENSES_REQUEST = 'woo/flle/GET_EXPENSES_REQUEST';
const GET_EXPENSES_REQUEST_SUCCESS = 'woo/flle/GET_EXPENSES_REQUEST_SUCCESS';

const ADD_EXPENSE_ALERT_CONFIGURATION_REQUEST = 'woo/fleet/ADD_EXPENSE_ALERT_CONFIGURATION_REQUEST';
const ADD_EXPENSE_ALERT_CONFIGURATION_SUCCESS = 'woo/fleet/ADD_EXPENSE_ALERT_CONFIGURATION_SUCCESS';
const DELETE_EXPENSE_ALERT_CONFIGURATION_REQUEST = 'woo/fleet/DELETE_EXPENSE_ALERT_CONFIGURATION_REQUEST';
const DELETE_EXPENSE_ALERT_CONFIGURATION_SUCCESS = 'woo/fleet/DELETE_EXPENSE_ALERT_CONFIGURATION_SUCCESS';

const CHANGE_CARD_HOLDER_REQUESTED = 'woo/fleet/CHANGE_CARD_HOLDER_REQUESTED';
const CHANGE_CARD_HOLDER_SUCCESS = 'woo/fleet/CHANGE_CARD_HOLDER_SUCCESS';

const UPDATE_FLEET_EXPENSE_ALERT_CONFIGURATION_REQUEST = 'woo/fleet/UPDATE_FLEET_EXPENSE_ALERT_CONFIGURATION_REQUEST';
const UPDATE_FLEET_EXPENSE_ALERT_CONFIGURATION_SUCCESS = 'woo/fleet/UPDATE_FLEET_EXPENSE_ALERT_CONFIGURATION_SUCCESS';

const GET_EXPENSES_ALERTS_REQUEST = 'woo/flle/GET_EXPENSES_REQUEST_ALERTS';
const GET_EXPENSES_ALERTS_REQUEST_SUCCESS = 'woo/flle/GET_EXPENSES_ALERTS_REQUEST_SUCCESS';

const GET_DRIVERS_FROM_ENGAGEMENT_TABLE_REQUEST = 'woo/flle/GET_DRIVERS_FROM_ENGAGEMENT_TABLE_REQUEST';
const GET_DRIVERS_FROM_ENGAGEMENT_TABLE_REQUEST_SUCCESS = 'woo/flle/GET_DRIVERS_FROM_ENGAGEMENT_TABLE_REQUEST_SUCCESS';

const initialState = {
  lastLocation: [],
  fleets: [],
  selected: null,
  fleetDetail: null,
  loading: false,
  observerLoading: false,
  loadingSelected: false,
  fleetsLoading: false,
  tripsLoading: false,
  driverTrips: [],
  polylines: [],
  dateFilter: {from: moment(new Date()).startOf('day'), to: moment(new Date()), selected: 0},
  expensesAlertsConfig:{},
  engagementDrivers: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_LAST_LOCATION:
      return {
        ...state,
        lastLocation: null
      }
    case LAST_LOCATION:
      return {
        ...state,
        loading: true,
      };
    case LAST_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        lastLocation: action.lastLocation
      };
    case FLEET_SELECTED_REQUESTED:
      return {
        ...state,
        loadingSelected: true,
      };
    case FLEET_SELECTED_SUCCESS:
      return {
        ...state,
        loadingSelected: false,
        selected: action.fleet,
      };
    case FLEET_LIST_UPDATED_STARTED:
      return {
        ...state,
        fleetsLoading:true,
      };
    case FLEET_LIST_UPDATED:
      let currentSelected;
      if (state.fleetDetail) {
        for (let fleet of action.fleets) {
          if (state.fleetDetail.id === fleet.id) {
            currentSelected = fleet;
            break
          }
        }
      }
      return {
        ...state,
        fleets: action.fleets,
        selected: currentSelected || action.fleets[0],
        fleetsLoading:false,
      };
    case UPDATE_SELECTED:
      if (state.selected.id === action.values.id) {
        let newSelected = {
          ...state.selected,
          ...action.values
        };

        let newList = [];
        for (let fleet of state.fleets){
          if (fleet.id === newSelected.id) {
            newList = [...newList, newSelected]
          } else {
            newList = [...newList, fleet]
          }
        }
        return {
          ...state,
          fleets: newList,
          selected: newSelected,
        };
      }
      return state;
    case FLEET_DETAIL:
      return {
        ...state,
        loading: true,
      };
      case FLEET_DETAIL_OBSERVABLE:
      return {
        ...state,
        observerLoading: true,
      };
    case FLEET_DETAIL_SUCCESS:
      return {
        ...state,
        fleetDetail: action.fleet,
        loading:false,
      };
      case FLEET_DETAIL_SUCCESS_OBSERVABLE:
      return {
        ...state,
        fleetDetail: action.fleet,
        observerLoading:false,
      };

    // VEHICLES
    case FETCH_VEHICLES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_VEHICLES_REQUEST_SUCCESS:
      return {
        ...state,
        vehicles: action.vehicles,
        loading: false
      };
    case FETCH_VEHICLE_REQUEST_SUCCESS:
      return {
        ...state,
        vehicle: action.vehicle
      };
    case SAVE_VEHICLE_REQUEST_SUCCESS:
      let { vehicles } = state;
      let newVehicle = _.find(vehicles, v => v.id === 0);

      // put assigned vehicle's id to un updated instance
      newVehicle.id = action.vehicle.id;

      // force view render
      return {
        ...state,
        vehicles: _.cloneDeep(vehicles),
        loading: false
      };
    // END VEHICLES

    // CRT
    case FETCH_CRTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_CRT_REQUEST:
      return {
        ...state,
        loading: true,
        crt: null
      };
    case FETCH_CRTS_REQUEST_SUCCESS:
      return {
        ...state,
        crts: action.crts,
        loading: false
      };
    case FETCH_CRT_REQUEST_SUCCESS:
      return {
        ...state,
        crt: action.crt
      };
    case SAVE_CRT_REQUEST_SUCCESS:
      let { crts } = state;
      let newCrt = _.find(crts, c => c.id === 0);

      // put assigned crt's id to un updated instance
      if (newCrt) {
        newCrt.id = action.crt.id;
      }

      // force view render
      return {
        ...state,
        crts: _.cloneDeep(crts),
        loading: false
      };
    // END CRT

    case FETCH_CREDIT_CARD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_CREDIT_CARD_REQUEST_SUCCESS:
      localStorage.setItem("creditCards", JSON.stringify(action.creditCards));
      return {
        ...state,
        creditCards: action.creditCards,
        loading: false
      };

    case GET_EXPENSES_REQUEST:
      return {
        ...state,
        expenses: null,
        loading: true
      };
    case GET_EXPENSES_REQUEST_SUCCESS:
      localStorage.setItem("expenses", JSON.stringify(action.expenses));
      return {
        ...state,
        expenses: action.expenses,
        loading: false
      };

    case DRIVER_DETAIL:
      return {
        ...state,
        loading: true
      };
    case DRIVER_DETAIL_SUCCESS:
      return {
        ...state,
        driverDetail: action.driver,
        loading: false
      };
    case DRIVER_TRIPS:
      return {
        ...state,
        tripsLoading: true
      };
    case DRIVER_TRIPS_SUCCESS:
      return {
        ...state,
        driverTrips: action.trips,
        tripsLoading: false
      };
    case UPDATE_DATE_FILTERS:
      return {
        ...state,
        dateFilter: action.dateFilter,
        loading: true
      };
    case UPDATE_FLEET_EXPENSE_ALERT_CONFIGURATION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case UPDATE_FLEET_EXPENSE_ALERT_CONFIGURATION_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case ADD_EXPENSE_ALERT_CONFIGURATION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case ADD_EXPENSE_ALERT_CONFIGURATION_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case DELETE_EXPENSE_ALERT_CONFIGURATION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case DELETE_EXPENSE_ALERT_CONFIGURATION_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case CHANGE_CARD_HOLDER_REQUESTED:
      return {
        ...state,
        loading: true
      }
    case CHANGE_CARD_HOLDER_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case GET_EXPENSES_ALERTS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_EXPENSES_ALERTS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        expensesAlertsConfig: action.alerts
      }
    case GET_DRIVERS_FROM_ENGAGEMENT_TABLE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_DRIVERS_FROM_ENGAGEMENT_TABLE_REQUEST_SUCCESS:
      return {
        ...state,
        engagementDrivers: action.engagementDrivers,
        loading: false
      }
    default:
      return state;
  }
}

// Action Creators
const lastLocationRequested = () => {
  return {
    type: LAST_LOCATION
  }
};

export const resetLastLocation = () => {
  return {
    type: RESET_LAST_LOCATION
  }
};

export const lastLocationSucceeded = (lastLocation) => {
  return {
    type: LAST_LOCATION_SUCCESS,
    lastLocation
  }
};

export const selectFleetRequested = () => {
  return {
    type: FLEET_SELECTED_REQUESTED
  }
};

export const selectFleetSuccess = (fleet) => {
  return {
    type: FLEET_SELECTED_SUCCESS,
    fleet
  }
};

export const fleetListUpdatedStarted = () => {
  return {
    type: FLEET_LIST_UPDATED_STARTED,
  }
};

export const fleetListUpdated = (fleets) => {
  return {
    type: FLEET_LIST_UPDATED,
    fleets
  }
};

export const fleetDetailRequested = () => {
  return {
    type: FLEET_DETAIL,
  }
};

export const fleetDetailObservableRequested = () => {
  return {
    type: FLEET_DETAIL_OBSERVABLE,
  }
};

export const fleetDetailSucceeded = (fleet) => {
  return {
    type: FLEET_DETAIL_SUCCESS,
    fleet
  }
};

export const fleetDetailSucceededObservable = (fleet) => {
  return {
    type: FLEET_DETAIL_SUCCESS_OBSERVABLE,
    fleet
  }
};

const driverDetailRequested = () => {
  return {
    type: DRIVER_DETAIL
  }
};

export const driverDetailSucceeded = (driver) => {
  return {
    type: DRIVER_DETAIL_SUCCESS,
    driver
  }
};

// chunks
const driverTripsRequested = () => {
  return {
    type: DRIVER_TRIPS
  }
};

export const driverTripsSucceeded = (trips) => {
  return {
    type: DRIVER_TRIPS_SUCCESS,
    trips
  }
};

export const updateSelectedValues = (values) => {
  return {
    type: UPDATE_SELECTED,
    values
  }
};

const updateDateFilter = (dateFilter) => {
  return {
    type: UPDATE_DATE_FILTERS,
    dateFilter
  }
};

const saveVehicleRequested = () => {
  return {
    type: SAVE_VEHICLE_REQUEST
  }
};

export const saveVehicleRequestSuccess = (vehicle) => {
  return {
    type: SAVE_VEHICLE_REQUEST_SUCCESS,
    vehicle
  }
};

const deleteVehicleRequested = () => {
  return {
    type: DELETE_VEHICLE_REQUEST
  }
};

export const deleteVehicleRequestSuccess = () => {
  return {
    type: DELETE_VEHICLE_REQUEST_SUCCESS
  }
};

const fetchVehiclesRequested = () => {
  return {
    type: FETCH_VEHICLES_REQUEST
  }
};

export const fetchVehiclesRequestedSuccess = (vehicles) => {
  return {
    type: FETCH_VEHICLES_REQUEST_SUCCESS,
    vehicles
  }
};

const fetchVehicleRequested = () => {
  return {
    type: FETCH_VEHICLE_REQUEST
  }
};

export const fetchVehicleRequestedSuccess = (vehicle) => {
  return {
    type: FETCH_VEHICLE_REQUEST_SUCCESS,
    vehicle
  }
};

const fetchCreditCardRequested = () => {
  return {
    type: FETCH_CREDIT_CARD_REQUEST
  }
};

export const fetchCreditCardRequestedSuccess = (creditCards) => {
  return {
    type: FETCH_CREDIT_CARD_REQUEST_SUCCESS,
    creditCards
  }
};

const getExpensesRequested = () => {
  return {
    type: GET_EXPENSES_REQUEST
  }
};

export const getExpensesRequestedSuccess = (expenses) => {
  return {
    type: GET_EXPENSES_REQUEST_SUCCESS,
    expenses
  }
};

export const getExpensesAlertsConfigurationRequested = () => {
  return {
    type: GET_EXPENSES_ALERTS_REQUEST
  }
};

export const getExpensesAlertsConfigurationSuccess = (alerts) => {
  return {
    type: GET_EXPENSES_ALERTS_REQUEST_SUCCESS,
    alerts
  }
};

export const updateFleetExpenseAlertConfigRequested = () => {
  return {
    type: UPDATE_FLEET_EXPENSE_ALERT_CONFIGURATION_REQUEST
  }
};

export const updateFleetExpenseAlertConfigSuccess = () => {
  return {
    type: UPDATE_FLEET_EXPENSE_ALERT_CONFIGURATION_SUCCESS
  }
};

const addExpenseAlertConfigurationRequested = () => {
  return {
    type: ADD_EXPENSE_ALERT_CONFIGURATION_REQUEST
  }
};

export const addExpenseAlertConfigurationSuccess = (alert) => {
  return {
    type: ADD_EXPENSE_ALERT_CONFIGURATION_SUCCESS,
    alert
  }
};

export const deleteExpenseAlertConfigurationRequested = (alert) => {
  return {
    type: DELETE_EXPENSE_ALERT_CONFIGURATION_REQUEST,
    alert
  }
};

export const deleteExpenseAlertConfigurationSuccess = (alert) => {
  return {
    type: DELETE_EXPENSE_ALERT_CONFIGURATION_SUCCESS,
    alert
  }
};


export const changeCardHolderRequested = () => {
  return {
    type: CHANGE_CARD_HOLDER_REQUESTED
  }
};

export const changeCardHolderSuccess = () => {
  return {
    type: CHANGE_CARD_HOLDER_SUCCESS
  }
};

export const fetchCrtsRequested = () => {
  return {
    type: FETCH_CRTS_REQUEST
  }

};

export const fetchCrtsRequestedSuccess = (crts) => {
  return {
    type: FETCH_CRTS_REQUEST_SUCCESS,
    crts
  }
};

const saveCrtRequested = () => {
  return {
    type: SAVE_CRT_REQUEST
  }
};

export const saveCrtRequestSuccess = (crt) => {
  return {
    type: SAVE_CRT_REQUEST_SUCCESS,
    crt
  }
};

const deleteCrtRequested = () => {
  return {
    type: DELETE_CRT_REQUEST
  }
};

export const deleteCrtRequestSuccess = () => {
  return {
    type: DELETE_CRT_REQUEST_SUCCESS
  }
};

const deleteTripFromCrtRequested = () => {
  return {
    type: DELETE_TRIP_FROM_CRT_REQUEST
  }
};

export const deleteTripFromCrtRequestedSuccess = () => {
  return {
    type: DELETE_TRIP_FROM_CRT_REQUEST_SUCCESS
  }
};

const fetchCrtRequested = () => {
  return {
    type: FETCH_CRT_REQUEST,
  }
};

export const fetchCrtRequestedSuccess = (crt) => {
  return {
    type: FETCH_CRT_REQUEST_SUCCESS,
    crt
  }
};

export const getDriversFromEngagementTableRequested = () => {
  return {
    type: GET_DRIVERS_FROM_ENGAGEMENT_TABLE_REQUEST
  }
};

export const getDriversFromEngagementTableRequestedSuccess = (engagementDrivers) => {
  return {
    type: GET_DRIVERS_FROM_ENGAGEMENT_TABLE_REQUEST_SUCCESS,
    engagementDrivers
  }
};

export const changeDateFilter = (fleetId, from, to, selected) => {
  return dispatch => {
    const dateFilter = {from, to, selected};
    dispatch(updateDateFilter(dateFilter));
    dispatch(getFleetDetail(fleetId, dateFilter))
  }
};

export const getFleetDetail = (fleetId, dateFilter = {from: moment(new Date()).startOf('day'), to: moment(new Date()), selected: 0}, isObservable) => {
  return dispatch => {
    if (isObservable){
      dispatch(fleetDetailObservableRequested())
    }else{
      dispatch(fleetDetailRequested())
    }
    let token = localStorage.getItem("token");
    let from = dateFilter.from; // server needs this value in seconds
    let to = dateFilter.to; // server needs this value in seconds

    const url = BASE_URL + '/api/fleet/' + fleetId + "?from=" + from.unix() + "&to=" + to.unix();
    return fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {
      //save token in localStorage
      if (isObservable){
        dispatch(fleetDetailSucceededObservable(json))
      }else{
        dispatch(fleetDetailSucceeded(json))
      }
    }).catch(error =>{
    })
  }
};

// export const getDriverDetail = (fleetId, driverId) => {
//   return dispatch => {
//     dispatch(driverDetailRequested())
//     setTimeout(1000, dispatch(driverDetailSucceeded({
//       fleetId, uid:driverId, name: "NombreTest", phone : "5555555555",
//       lastTrip: 1503422271478,
//       lastLogin: 1503422271478,
//       deviceGps: {status:"off", ts: 1503422271478},
//       trips:[],
//       stats:{kms:1000, caution: 40, control:50, focus:70, eco:90, total:100},
//       weekStats: {durationAverage: 10, totalAverage:10, total: 70, evolution:[]}})))
//   }
// }

export const getDriverTrips = (fleetId, driversId, date) => {
  return dispatch => {
    dispatch(driverTripsRequested());
    let token = localStorage.getItem("token");
    let tz = new Date().getTimezoneOffset() * 60 * -1; // server needs this value in seconds, -1 because getTimezoneOffset is negative
    let today = Math.floor(date.getTime() / 1000); // server needs this value in seconds
    const url = BASE_URL + '/api/fleet/' + fleetId + '/polylines?drivers=' + driversId  + "&tzOffset=" + tz + "&date=" + today;

    return fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {
      //save token in localStorage
      dispatch(driverTripsSucceeded(json))
    });
  }
};

export const getDriverDetail = (fleetId, driverId) => {
  return dispatch => {
    dispatch(driverDetailRequested());
    let token = localStorage.getItem("token");
    let tz = new Date().getTimezoneOffset() * 60 * -1; // server needs this value in seconds, -1 because getTimezoneOffset is negative
    let date = Math.floor(new Date().getTime() / 1000); // server needs this value in seconds
    const url = BASE_URL + '/api/fleet/' + fleetId + '/driver/' + driverId  + "?tzOffset=" + tz + "&date=" + date;

    return fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {
      //save token in localStorage
      dispatch(driverDetailSucceeded(json))
    });
  }
};

export const getLastLocation = (fleetId, vehicleId) => {
  return dispatch => {
    dispatch(lastLocationRequested());
    let token = localStorage.getItem("token");
    // let user = JSON.parse(localStorage.getItem("user"))

    let url = BASE_URL + '/api/fleet/' + fleetId + '/locations';

    if (vehicleId) {
      url += `?vehicleId=${vehicleId}`;
    }

    return fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {
      //save token in localStorage
      dispatch(lastLocationSucceeded(json));
    });
  }
};

export const getDriverLastLocation = (fleetId, driverId) => {
  return dispatch => {
    dispatch(lastLocationRequested());
    let token = localStorage.getItem("token");
    // let user = JSON.parse(localStorage.getItem("user"))

    let url = BASE_URL + '/api/fleet/' + fleetId + '/driver/' + driverId + "/locations";

    return fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {
      //save token in localStorage
      dispatch(lastLocationSucceeded(json));
    });
  }
};

export const createVehicle = (fleetId, vehicle) => {
  return dispatch => {
    dispatch(saveVehicleRequested());
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/vehicle' ;

    return fetch(url, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(vehicle),
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {
      dispatch(saveVehicleRequestSuccess(json));
    });
  }
};

export const updateVehicle = (fleetId, vehicle) => {
  return dispatch => {
    dispatch(saveVehicleRequested());
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/vehicle/' + vehicle.id ;

    return fetch(url, {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(vehicle),
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus);
  }
};

export const deleteVehicle = (fleetId, vehicle) => {
  return dispatch => {
    dispatch(deleteVehicleRequested());
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/vehicle/' + vehicle.id ;

    return fetch(url, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {

      dispatch(deleteVehicleRequestSuccess());

    }).catch(error =>{
      // console.log("deleteVehicle error", error.name, error.message)
    })
  }
};

export const fetchVehicles = (fleetId) => {
  return dispatch => {
    dispatch(fetchVehiclesRequested());
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/vehicle' ;

    return fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {

      dispatch(fetchVehiclesRequestedSuccess(json));

    }).catch(error =>{
      // console.log("fetchVehicles error", error.name, error.message)
    })
  }
};

export const fetchVehicle = (fleetId, vehicleId) => {
  return dispatch => {
    dispatch(fetchVehicleRequested());
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/vehicle/' + vehicleId ;

    return fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {

      dispatch(fetchVehicleRequestedSuccess(json));

    }).catch(error =>{
      // console.log("fetchVehicles error", error.name, error.message);
    })
  }
};

export const fetchCreditCards = (fleetId) => {
  return dispatch => {
    dispatch(fetchCreditCardRequested());
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/creditcard' ;

    return fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {

      dispatch(fetchCreditCardRequestedSuccess(json));

    }).catch(error =>{
      // console.log("fetchVehicles error", error.name, error.message);
    })
  }
};


export const getExpenses = (fleetId, fromDate, toDate, driverId, creditCardId, vehicleId) => {
  return dispatch => {
      dispatch(getExpensesRequested());
      let token = localStorage.getItem("token");
      let url = BASE_URL + '/api/fleet/' + fleetId + '/expenses';
      let params = [];

      if (driverId) {
        params.push(`driverId=${driverId}`);
      }
      if (creditCardId) {
        params.push(`creditCardId=${creditCardId}`);
      }
      if (vehicleId) {
        params.push(`vehicleId=${vehicleId}`);
      }
      if (fromDate) {
        params.push(`from=${fromDate.unix()}`);
      }
      if (toDate) {
        params.push(`to=${toDate.unix()}`);
      }

      if (params.length > 0) {
        url += `?${params.join('&')}`;
      }

      return fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .then(checkStatus)
      .then(parseJson)
      .then((json) => {
        dispatch(getExpensesRequestedSuccess(json));
      }).catch(error =>{
        // console.log("fetchVehicles error", error.name, error.message)
      })
  }
};

export const getExpensesAlertsConfiguration = (fleetId) => {
  return dispatch => {
    dispatch(getExpensesAlertsConfigurationRequested());
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/expenses/alertconfiguration' ;

    return fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {

      dispatch(getExpensesAlertsConfigurationSuccess(json));

    }).catch(error =>{
      // console.log("getExpensesAlertsConfiguration error", error.name, error.message);
    })
  }
};

export const updateFleetExpenseAlertConfig = (fleetId, type, amount) => {
  return dispatch => {
    dispatch(updateFleetExpenseAlertConfigRequested());
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/expenses/alertconfiguration/' + type ;

    return fetch(url, {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify({"type": type, "amount": amount?amount:0 }),
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {

      dispatch(updateFleetExpenseAlertConfigSuccess(json));
      dispatch(getExpensesAlertsConfiguration(fleetId));

    }).catch(error =>{
      // console.log("addCreditAlertConfiguration error", error.name, error.message);
    })
  }
};

export const addExpenseAlertConfiguration = (fleetId, cardId, type, amount) => {
  return dispatch => {
    dispatch(addExpenseAlertConfigurationRequested());
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/creditcard/' + cardId + '/alertconfiguration/' + type ;

    return fetch(url, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({"type": type, "amount": amount?amount:0}),
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {

      dispatch(addExpenseAlertConfigurationSuccess(json));
      dispatch(getExpensesAlertsConfiguration(fleetId));

    }).catch(error =>{
      // console.log("addCreditAlertConfiguration error", error.name, error.message);
    })
  }
};

export const changeCardHolder = (fleetId, cardId, driverId) => {
  return dispatch => {
    dispatch(changeCardHolderRequested());
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/creditcard/' + cardId ;

    return fetch(url, {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify({"driverId": driverId}),
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {

      dispatch(changeCardHolderSuccess(json));
      dispatch(fetchCreditCards(fleetId));

    }).catch(error =>{
      // console.log("changeCardHolder error", error.name, error.message);
    })
  }
};

export const createCrt = (fleetId, crt) => {
  return dispatch => {
    dispatch(saveCrtRequested());
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/crt' ;

    return fetch(url, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(crt),
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {

      dispatch(saveCrtRequestSuccess(json));

    }).catch(error =>{
      // console.log("createCrt error", error.name, error.message)
    })
  }
};

export const selectFleet = (fleet) => {
  return dispatch => {
    dispatch(selectFleetRequested());
    dispatch(selectFleetSuccess(fleet));
  }
};

export const updateCrt = (fleetId, crt) => {
  return dispatch => {
    dispatch(saveCrtRequested());
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/crt/' + crt.id ;

    return fetch(url, {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(crt),
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {

      dispatch(saveCrtRequestSuccess(json));

    }).catch(error =>{
      // console.log("updateCrt error", error.name, error.message)
    })
  }
};

export const deleteCrt = (fleetId, crt) => {
  return dispatch => {
    dispatch(deleteCrtRequested());
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/crt/' + crt.id ;

    return fetch(url, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {

      dispatch(deleteCrtRequestSuccess());

    }).catch(error =>{
      // console.log("deleteCrt error", error.name, error.message)
    })
  }
};

export const fetchCrts = (fleetId) => {
  return dispatch => {
    dispatch(fetchCrtsRequested());
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/crt?lazy_props=False' ;

    return fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {
      dispatch(fetchCrtsRequestedSuccess(json));
    }).catch(error =>{
      // console.log("fetchCrts error", error.name, error.message)
    })
  }
};

export const fetchCrt = (fleetId, crtId) => {
  return dispatch => {
    dispatch(fetchCrtRequested());
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/crt/' + crtId ;

    return fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {

      dispatch(fetchCrtRequestedSuccess(json));

    }).catch(error =>{
      // console.log("fetchCRT error", error.name, error.message);
    })
  }
};

export const deleteTripFromCrt = (fleetId, crtId, tripId) => {
  return dispatch => {
    dispatch(deleteTripFromCrtRequested());
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/crt/' + crtId + '/trip/' + tripId;

    return fetch(url, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {

      dispatch(deleteTripFromCrtRequestedSuccess(json));

    }).catch(error =>{
      // console.log("fetchCRT error", error.name, error.message);
    })
  }
};

export const getDriversFromEngagementTable = (fleetId) => {
  return dispatch => {
    dispatch(getDriversFromEngagementTableRequested());
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/engagement';

    return fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {

      dispatch(getDriversFromEngagementTableRequestedSuccess(json));

    })
  }
};