/**
 * Created by jje-personal on 9/4/17.
 */
import React, {Component} from 'react';
import {connect} from 'react-redux'
import {changeDateFilter} from '../../redux/fleet'
import moment from 'moment'
import {withTranslation} from "react-i18next";

class DateFilter extends Component {

  state = {
    custom: false,
    invalidDate: false,
  };

  componentDidMount = () => {
    const { dateFilter } = this.props;
    this.setState({from: dateFilter.from, to: dateFilter.to});
  };

  onDateFromChange = (e) => {
    this.setState({ from: moment(e.target.value)});
  };

  onDateToChange = (e) => {
    this.setState({ to: moment(e.target.value) });
  };

  onSelectRange = (range) => {
    let to = moment(new Date());
    let from = moment(new Date()).startOf('day');
    switch (range) {
      case 0:
        // nothing: default value
        break;
      case 1: // yesterday
        from = from.subtract(1, 'days').startOf('day');
        break;
      case 2: // last week
        let day = from.day() - 1;
        if (day < 0) {
          day = 6;
        }
        from = from.subtract(day, 'days').startOf('day');
        break;
      case 3: // last month
        from = from.startOf('month');
        break;
      case 4: // custom
        this.setState({custom:true})
        break;
      default:
        break;
    }

    if (range !== 4) {
      this.setState({custom:false});
      // this.setState({custom, selected: range, from: from.format().substr(0,10), to:to.format().substr(0,10)})
      this.props.changeDateFilter(this.props.fleetSelected.id, from, to, range)
    }
  };

  onClick = () => {
    let from = this.state.from;
    let to = this.state.to;

    if (Number.isNaN(from.unix()) || Number.isNaN(to.unix())) {
      this.setState({invalidDate: true})
    } else {
      this.setState({invalidDate: false});
      this.props.changeDateFilter(this.props.fleetSelected.id, from, to, 4)
    }
  };

  render() {
    let { custom, from, to } = this.state;
    const { dateFilter, t } = this.props;
    const { selected } = dateFilter;

    if (from === undefined) {
      from = dateFilter.from
    }
    if (to === undefined) {
      to = dateFilter.to
    }

    return (
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">{t('dateFilter.filters')}</p>
        </header>
        <div className="card-content">
          <div className="media">
            <div className="media-content">

              <div className="field has-addons has-text-centered filterField">
                <button className={selected === 0 && !custom? "button is-primary is-small": "button is-small"}
                        onClick={()=>(this.onSelectRange(0))}>
                  {t('dateFilter.today')}
                </button>
                <button className={selected === 1 && !custom? "button is-primary is-small": "button is-small"}
                        onClick={()=>(this.onSelectRange(1))}>
                  {t('dateFilter.yesterday')}
                </button>
                <button className={selected === 2 && !custom? "button is-primary is-small": "button is-small"}
                        onClick={()=>(this.onSelectRange(2))}>
                  {t('dateFilter.thisWeek')}
                </button>
                <button className={selected === 3 && !custom? "button is-primary is-small": "button is-small"}
                        onClick={()=>(this.onSelectRange(3))}>
                  {t('dateFilter.thisMonth')}
                </button>
                <button className={custom || selected === 4? "button is-primary is-small": "button is-small"}
                        onClick={()=>(this.onSelectRange(4))}>
                  {t('dateFilter.select')}
                </button>
              </div>
              {(custom || selected === 4) &&
                <div className="field">
                  <div className="columns">
                    <div className="column is-2 filterField">
                      <p>{t('dateFilter.from')}: </p>
                    </div>
                    <div className="column is-6 filterField" >
                      <input className="input is-small" type="date" value={from.format().substr(0,10)}
                             onChange={this.onDateFromChange}/>
                    </div>
                  </div>
                </div>}
              {(custom || selected === 4) &&
                <div className="field">
                  <div className="columns">
                    <div className="column  is-2 filterField">
                      <p>{t('dateFilter.to')}: </p>
                    </div>
                    <div className="column is-6 filterField">
                      <input className="input is-small" type="date" value={to.format().substr(0,10)}
                             onChange={this.onDateToChange}/>
                    </div>
                    <div className="column filterField">
                      <button className="button is-small" onClick={this.onClick}>{t('dateFilter.apply')}</button>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}


// These props come from the application's
// state when it is started
function mapStateToProps(state) {
  const { selected, dateFilter} = state.fleet;
  return {
    fleetSelected: selected,
    dateFilter
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeDateFilter : (fleetId, from, to, selected) => {
      dispatch(changeDateFilter(fleetId, from, to, selected))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(DateFilter))

