import React, {Component} from 'react';
import Loading from '../components/Loading'
import {withTranslation} from "react-i18next";
import DriverSuggestionContainer from "../containers/DriverSuggestionContainer";
import VehicleSuggestionContainer from "../containers/VehicleSuggestionContainer";
import CreditCardSuggestionContainer from "../containers/CreditCardSuggestionContainer";
import VehicleDetail from "../containers/VehiclesDetailContainer";
import ExpensesChart from "../components/charts/ExpensesChart";
import ExpensesMap from "../components/expenses/ExpensesMap";
import ExpensesList from "../components/expenses/ExpensesList";
import ExpensesAlertList from "../components/expenses/ExpensesAlertList";
import DatePicker from "../components/DatePicker";

const EMPTY_EXPENSE = {
  evolution: [],
  items: [],
  alerts: []
};


class Expenses extends Component {

  state = {
    driverSelected: null,
    creditCardSelected: null,
    vehicleSelected: null
  };

  componentWillMount() {
    const { fleetDetail } = this.props;
    this.props.getExpenses(fleetDetail.id);
  }

  onDriverSelected = (event, driver) => {
    this.setState({driverSelected: driver});
  };

  onCreditCardSelected = (event, creditCard) => {
    this.setState({creditCardSelected: creditCard});
  };

  onVehicleSelected = (event, vehicle) => {
    this.setState({vehicleSelected: vehicle});
  };

  handleSelect = (range) => {
    this.setState({dateRangeSelected: range});
  };

  onClickSearch = () => {
    const { fleetDetail } = this.props;
    const { dateRangeSelected, driverSelected, creditCardSelected, vehicleSelected } = this.state;
    const { startDate, endDate } = dateRangeSelected;

    this.props.getExpenses(fleetDetail.id, startDate, endDate,
                            driverSelected ? driverSelected.uid:null,
                            creditCardSelected ? creditCardSelected.id:null,
                            vehicleSelected ? vehicleSelected.id:null);
  };

  render() {
    const { vehicleSelected } = this.state;
    const { expenses, t } = this.props;

    return (
      <div className="columns is-fullwidth">
        {!expenses && <Loading wait={0}/>}

        {vehicleSelected &&
          <div className="column is-2">
            <VehicleDetail vehicle={vehicleSelected}/>
          </div>
        }

        <div className="column">

          <div className="columns">

            <div className="column">
              <DatePicker onChange={this.handleSelect} />
            </div>

            <div className="column">
              <div className="field">
                <label className="label">{t('commons.filter.drivers')}</label>
                <div className="control">
                  <DriverSuggestionContainer selected={[]} onSelected={this.onDriverSelected} cleanOnSelected={false}/>
                </div>
              </div>
            </div>

            <div className="column">
              <div className="field">
                <label className="label">{t('commons.filter.creditCards')}</label>
                <div className="control">
                  <CreditCardSuggestionContainer selected={[]} onSelected={this.onCreditCardSelected} cleanOnSelected={false}/>
                </div>
              </div>
            </div>

            <div className="column">
              <div className="field">
                <label className="label">{t('commons.filter.vehicles')}</label>
                <div className="control">
                  <VehicleSuggestionContainer selected={[]} onSelected={this.onVehicleSelected} cleanOnSelected={false}/>
                </div>
              </div>
            </div>

            <div className="column is-1">
              <button className="button is-primary expense-search-button pull-right" onClick={this.onClickSearch}>
                {/*{t('expenses.filter.apply')}*/}
                <i className="fa fa-search"></i>
              </button>
            </div>

          </div>

          <div className="columns">

            <div className="column">
              <div className="columns">
                <div className="column">
                  <ExpensesChart data={expenses ? expenses.evolution:EMPTY_EXPENSE.evolution}/>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <ExpensesMap expenses={expenses ? expenses.items:EMPTY_EXPENSE.items}/>
                </div>
              </div>
            </div>

            <div className="column">
              <div className="columns">
                <div className="column is-two-thirds">
                  <ExpensesList expenses={expenses ? expenses.items:EMPTY_EXPENSE.items}/>
                </div>
                <div className="column">
                  <ExpensesAlertList alerts={expenses ? expenses.alerts:EMPTY_EXPENSE.alerts}/>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    );
  }
}

export default withTranslation('translation')(Expenses);