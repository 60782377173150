/**
 * Created by jje-personal on 9/4/17.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {withTranslation} from "react-i18next";

class ChiefDriverTable extends Component {

  constructor(props) {
    super(props);
    this.state = {width: props.width};
  }

  componentWillMount(){
    this.setState({width: window.innerWidth});
  }

  render() {
    const { drivers, t } = this.props;

     return (

      <div>

        { this.state.width < 710 &&
          drivers.map(((d, index) => (
            <div className="mobile-table">
              <div><strong>{t('chiefDriverTable.name')}: </strong>{d.name}</div>
              <div><strong>{t('chiefDriverTable.email')}: </strong>{d.email}</div>
              <div><strong>{t('chiefDriverTable.role')}: </strong>{d.role}</div>
              <div><strong>{t('chiefDriverTable.id')}: </strong>{d.uid}</div>
            </div>)))
        }

				{ this.state.width >= 710 &&

          <table className="table">
            <thead>
            <tr>
              <th>{t('chiefDriverTable.name')}</th>
              <th>{t('chiefDriverTable.email')}</th>
              <th>{t('chiefDriverTable.role')}</th>
              <th>{t('chiefDriverTable.id')}</th>
            </tr>
            </thead>
            <tbody>
            {drivers.map(((d, index) => (
              <tr key={index}>
                <td>{d.name}</td>
                <td>{d.email}</td>
                <td>{d.role}</td>
                <td>{d.uid}</td>
              </tr>
            )))}
            </tbody>
          </table>
				}
      </div>
    );
  }
}

ChiefDriverTable.propTypes = {
  drivers: PropTypes.array.isRequired
};

export default withTranslation('translation')(ChiefDriverTable);
