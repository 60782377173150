import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import Autosuggest from "react-autosuggest";
import _ from "lodash";
import AutosuggestHighlightMatch from "../../utils/autosuggest-highlight-match-fixed";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";


const STYLE = {
  input: 'input',
  suggestion: 'autosuggest-item',
  containerOpen: 'autosuggest-container',
  suggestionHighlighted: 'autosuggest-item-focused',
  suggestionsContainer: 'autosuggest-container-suggest',
  suggestionsContainerOpen: 'autosuggest-container-suggest-open'
};

class VehicleSuggestion extends Component {

  state = {
    suggestions: [],
    suggestedInputValue: "",
  };

  componentWillMount() {
    this.props.fetchVehicles(this.props.fleetDetail.id);
  }

  onChange = (event, { newValue }) => {
    this.setState({suggestedInputValue: newValue});
    if (_.isEmpty(newValue) || newValue === "") {
      this.props.onSelected(event, null);
    }
  };

  getSuggestionValue = vehicle => `${vehicle.name} - ${vehicle.plate} - ${vehicle.vin}`;

  renderSuggestion = (vehicle, { query }) =>{
    const suggestionText = `${vehicle.name} - ${vehicle.plate} - ${vehicle.vin}`;
    const matches = AutosuggestHighlightMatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);

    return (
      <span className={'suggestion-content'}>
        <span className="name">
          {parts.map((part, index) => {
            const className = part.highlight ? 'highlight' : null;
            return (<span className={className} key={index}>{part.text}</span>);
          })}
        </span>
      </span>
    );
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const { selected, vehicles } = this.props;
    let available = _.cloneDeep(vehicles);

    _.each(selected, v => {
      _.remove(available, vc => vc.id === v.id);
    });

    const result = inputValue.length < 1 ? available : available.filter(v => {
      return (v.name + v.plate + v.vin).toLowerCase().includes(inputValue);
    });

    this.setState({ suggestions: result });
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  onSuggestionSelected = (event, {suggestion}) => {
    this.props.onSelected(event, suggestion);
    if (this.props.cleanOnSelected) {
      this.setState({suggestedInputValue: ""});
    }
  };

  shouldRenderSuggestions = () => {
    return true;
  }

  render() {
    const { vehicles, t } = this.props;
    const { suggestions, suggestedInputValue } = this.state;

    if (!vehicles) {
      return (
        <div className="control is-loading">
          <input className="input" disabled={true}/>
        </div>
      );
    }

    const inputProps = {
      placeholder: t('vehicleCardSuggestion.placeholder'),
      value: suggestedInputValue,
      onChange: this.onChange
    };

    return (
      <div className="control">
        <div className="select is-fullwidth">
          <Autosuggest suggestions={suggestions} inputProps={inputProps} theme={STYLE}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            onSuggestionSelected={this.onSuggestionSelected}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            shouldRenderSuggestions={this.shouldRenderSuggestions}/>
        </div>
      </div>
    );
  }
}

export default withTranslation('translation')(VehicleSuggestion);
