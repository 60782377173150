import {connect} from 'react-redux'
import {getLastLocation} from '../redux/fleet'
import FleetLocation from '../pages/FleetLocation'


// These props come from the application's
// state when it is started
function mapStateToProps(state) {

  const { lastLocation, fleetDetail,selected } = state.fleet;
  return {
    lastLocation,
    fleetDetail,
    selected
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getLastLocation: (fleetId) => {
      dispatch(getLastLocation(fleetId))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FleetLocation)