import {connect} from 'react-redux'
import VehicleDetail from "../components/vehicles/VehicleDetail";
import {getLastLocation, updateVehicle} from "../redux/fleet";

function mapStateToProps(state) {
  const { selected, fleetDetail, lastLocation } = state.fleet;
  return { selected, fleetDetail, lastLocation };
}

const mapDispatchToProps = dispatch => {
  return {
    getLastLocation: (fleetId, vehicleId) => {
      dispatch(getLastLocation(fleetId, vehicleId))
    },
    saveVehicle: (fleetId, vehicle) => {
      dispatch(updateVehicle(fleetId, vehicle));
    }
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(VehicleDetail);


