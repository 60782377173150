import {microsoftLogin, emailLogin, facebookLogin, googleLogin, logoutUser, storeCaptchaToken} from '../redux/auth'
import {connect} from 'react-redux'
import Login from '../pages/Login'


// These props come from the application's
// state when it is started
function mapStateToProps(state) {

  const { loggingIn, loginError, isAuthenticated} = state.auth;

  return {
    isAuthenticated,
    loggingIn,
    loginError
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGoogleLogin : () => {
      dispatch(googleLogin())
    },
    onEmailLogin : (email, password) => {
      dispatch(emailLogin(email, password))
    },
    onMicrosoftLogin : () => {
      dispatch(microsoftLogin())
    },
    logout : () => {
      dispatch(logoutUser())
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login)