import {connect} from 'react-redux'
import {addExpenseAlertConfiguration, changeCardHolder} from '../../redux/fleet'
import CardsTable from "../../components/expenses/CardsTable";


function mapStateToProps(state) {
  const { fleetDetail, loading, expensesAlertsConfig } = state.fleet;
  return { fleetDetail, loading, expensesAlertsConfig};
}

const mapDispatchToProps = dispatch => {
  return {
    addCreditAlertConfiguration: (fleetId, cardId, type, amount) => {
      dispatch(addExpenseAlertConfiguration(fleetId, cardId, type, amount));
    },
    // deleteCreditAlertConfiguration: (fleetId, cardId, type, amount) => {
    //   dispatch(deleteExpenseAlertConfiguration(cardId, type, amount));
    // },
    changeCardHolder: (fleetId, cardId, driverId) => {
      dispatch(changeCardHolder(fleetId, cardId, driverId));
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CardsTable);
