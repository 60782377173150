import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";

class RoadMapFilters extends Component {

  render() {
      const { onChange, t } = this.props
      return (
          <div className="columns">
              <div className="column">
                  <div className="field">
                      <div className="control">
                          <input className="input" type="text" placeholder={t('roadMapTable.sayt')}
                                 onChange={(e) => onChange(e.target.value)}/>
                      </div>
                  </div>
              </div>
          </div>
      );
  }
}

export default withRouter(withTranslation('translation')(RoadMapFilters));