import React, {Component} from 'react';

class TabSelector extends Component {

  render() {
    const {tabs, selectedTab, onSelectTab} = this.props
    return (
        <div className="tabs is-centered">
            <ul>
                {tabs.map(((t) => (
                    <li className={selectedTab === t ? "is-active" : ""} onClick={() => onSelectTab(t)} key={t}>
                        <a>{t}</a>
                    </li>
                )))}
            </ul>
        </div>
    );
  }
}

export default TabSelector
