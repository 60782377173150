import React, {Component} from 'react';
import {withTranslation} from "react-i18next";


class CostTypeSelector extends Component {

  render() {
    const {t, onChangeScoreType} = this.props;

    return (
      <div className="select is-small">
        <select onChange={(ev) => onChangeScoreType(ev.target.value)}>
          <option value="cost">{t('commons.cost')}</option>
          <option value="costDistance">{t('commons.costDistance')}</option>
        </select>
      </div>
    );
  }
}

export default withTranslation('translation')(CostTypeSelector);