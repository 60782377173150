import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ButtonLinkPrimary extends Component {

    render() {
        const { to, children } = this.props;

        return (
            <Link className="button is-primary" to={to}>
                {children}
            </Link>
        );
    }
}

export default ButtonLinkPrimary;