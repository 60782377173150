import React from 'react'
import {Chart, Doughnut} from 'react-chartjs-2';
import {withTranslation} from "react-i18next";

const options = {
  legend: {
    display: false
  },
  maintainAspectRatio: false,
  cutoutPercentage: 80
};

const getConfig = (props) => {
  var label = props.t("radialChart.bad");
  let points = props.points[0];

  if (points < 50){
    label = props.t("radialChart.regular");
  } else if (points >= 50 && points < 75){
    label = props.t("radialChart.good");
  } else if (points >= 75 && points < 90){
    label = props.t("radialChart.veryGood");
  } else {
    label = props.t("radialChart.excelent");
  }

  return {
    labels: [props.title, props.t("radialChart.toImprove")],
    middleText: [props.points[0], label],
    datasets: [{
      backgroundColor: ["#00C6A8", "#000"],
      data: props.points
    }]
  }
};

// some of this code is a variation on https://jsfiddle.net/cmyker/u6rr5moq/
var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw: function() {
    originalDoughnutDraw.apply(this, arguments);

    var chart = this.chart;
    var width = chart.chart.width,
        height = chart.chart.height,
        ctx = chart.chart.ctx;

    var fontSize = (height / 60).toFixed(2);
    ctx.font = fontSize + "em sans-serif";
    ctx.textBaseline = "middle";

    var text = chart.config.data.middleText[0],
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = (height / 2) - 20;

    ctx.fillText(text, textX, textY);

    fontSize = (height / 114).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "middle";
    var text2 = chart.config.data.middleText[1],
        text2X = Math.round((width - ctx.measureText(text2).width) / 2),
        text2Y = (height / 2) + 20;
    ctx.fillText(text2, text2X, text2Y);
  }
});

const RadialChart = (props) => (
  <div className="card">
    <header className="card-header">
      <p className="card-header-title">{props.title}</p>
    </header>
    <div className="card-content">
      <div>
        <Doughnut data={getConfig(props)} options={options} width={200} height={200}/>
      </div>
    </div>
  </div>
);

export default withTranslation('translation')(RadialChart);