import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import _ from "lodash";

import {fetchModels} from "../../redux/vehicle"
import {connect} from 'react-redux'
import classNames from "classnames";

class ModelSelector extends Component {

  state = {
  };

  componentWillMount() {
    const currentMake = this.props.makeSelected;
    if (currentMake) {
      this.props.fetchModels(currentMake.id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const prevMakeId = prevProps.makeSelected ? prevProps.makeSelected.id:0;
    const currentMake = this.props.makeSelected;

    if (!currentMake) {
      // this.setState({models: undefined});
    } else if (currentMake.id !== prevMakeId) {
      this.props.fetchModels(currentMake.id);
    }
  }

  onSelected = (ev) => {
    const id = ev.target.value;
    const { models } = this.props;
    this.props.onModelSelected(_.find(models, m => m.id === parseInt(id)));
  };

  render() {
    const { models, t, makeSelected, modelSelected, modelsLoading } = this.props;
    const modelSelectedId = modelSelected ? modelSelected.id:0;

    if (makeSelected && modelsLoading) {
      return (
        <div className="control is-loading">
          <input className="input" disabled={true} />
        </div>
      );
    }

    return (
      <div className={classNames('select', this.props.className)}>
        <select onChange={this.onSelected} value={modelSelectedId} disabled={!makeSelected}>
          <option value={0}>{t('vehiclesTable.modelSelector.default')}</option>
          {
            models.map(((model, index) => (
              <option value={model.id} key={'div-model-' + model.id}>{model.name}</option>
            )))
          }
        </select>
      </div>
    );
  }
}

// These props come from the application's
// state when it is started
function mapStateToProps(state) {
  const { models, modelsLoading } = state.vehicle;
  return { models, modelsLoading };
}

const mapDispatchToProps = dispatch => {
  return {
    fetchModels : (makeId) => {
      dispatch(fetchModels(makeId));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(ModelSelector))
