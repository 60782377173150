import React, {Component} from 'react';
import PropTypes from 'prop-types'
import { Redirect } from 'react-router'
import Loading from '../components/Loading'
import { withTranslation } from "react-i18next";

import SignInWithEmail from '../components/SignInWithEmail';


class Login extends Component {

  state = {
    withEmail: false,
    captchaValue: '',
  };

  onPressEmailLogin = () => this.setState({ withEmail: true })

  onPressBackEmailLogin = () => this.setState({ withEmail: false })

  forceDisplayCaptchaBadge = (display) => {
    const element = document.getElementById('recaptcha');
    const badgeClass = "captcha-badge-hide";
    if (display === true) {
      element.classList.remove(badgeClass);
    } else {
      element.classList.add(badgeClass);
    }
  }

  render() {
    const { isAuthenticated, loggingIn, loginError } = this.props;
    const { from } = this.props.location.state || { from: { pathname: '/' } };

    if (isAuthenticated) {
      this.forceDisplayCaptchaBadge(false);
      return (
        <Redirect to={from} />
      )
    }

    this.forceDisplayCaptchaBadge(true);

    return (
      <div className="columns is-vcentered is-fullwidth">
        {loggingIn && <Loading wait="0" />}
        <div className="column is-4 is-offset-4">
          <h1 className="title">
            {this.props.t('authentication.welcome')}
          </h1>
          {!this.state.withEmail &&
            <div>
              <div className="column is-12">
                <div className="field">
                    <button className="button is-google is-fullwidth" onClick={this.props.onGoogleLogin}>
                    <span className="icon">
                      <i className="fa fa-google"></i>
                    </span>
                    <span>Google</span>
                  </button>
                </div>
              </div>
              <div className="column is-12">
                <div className="field">
                    <button className="button is-fb is-fullwidth" onClick={this.props.onMicrosoftLogin}>
                    <span className="icon">
                      <i className="fa fa-windows"></i>
                    </span>
                    <span>Microsoft</span>
                  </button>
                </div>
              </div>
              <div className="column is-12">
                <div className="field">
                    <button className="button is-email is-fullwidth" onClick={this.onPressEmailLogin}>
                    <span className="icon">
                      <i className="fa fa-envelope"></i>
                    </span>
                    <span>Email</span>
                  </button>
                </div>
              </div>
            </div>
          }
          {this.state.withEmail &&
              <SignInWithEmail
                  loginError={this.props.loginError}
                  onEmailLoginHandler={this.props.onEmailLogin}
                  onBackHandler={this.onPressBackEmailLogin}
              />
          }
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  loggingIn: PropTypes.bool.isRequired,
  onGoogleLogin: PropTypes.func.isRequired,
  onEmailLogin: PropTypes.func.isRequired,
  onMicrosoftLogin: PropTypes.func.isRequired,
}

export default withTranslation('translation')(Login);