import React, {Component} from 'react';
import {Bar} from 'react-chartjs-2';
import {withTranslation} from "react-i18next";
import CostTypeSelector from "../CostTypeSelector";


const options = {
  responsive: true,
  tooltips: {
    mode: 'label'
  },
  elements: {
    line: {
      fill: false
    }
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: false
        },
        ticks: {
          display: true
        }
      }
    ],
    yAxes: [
      {
        type: 'linear',
        display: true,
        position: 'left',
        id: 'y-axis-1',
        gridLines: {
          display: false
        },
        ticks: {
          display: true
        }
      },
      {
        type: 'linear',
        display: true,
        position: 'right',
        id: 'y-axis-2',
        gridLines: {
          display: false
        },
        ticks: {
          display: true
        }
      }
    ]
  }
};

class VehicleEcoConsumptionChart extends Component {

  state = {
    costTypeSelected: 'cost'
  };

  onChangeScoreType = (value) => {
    this.setState({costTypeSelected: value})
  };

  getData = () => {
    const {data, t} = this.props;
    const {costTypeSelected} = this.state;

    return {
      labels: data.map((obj) => (obj.date)),
      datasets: [
        {
          label: t('commons.eco'),
          type:'line',
          fill: false,
          lineTension: 0.1,
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          backgroundColor: '#00C6A8',
          borderColor: '#00C6A8',
          pointBorderColor: '#00C6A8',
          pointHoverBackgroundColor: '#00C6A8',
          pointHoverBorderColor: '#00C6A8',
          pointBackgroundColor: '#fff',
          yAxisID: 'y-axis-2',
          data: data.map((obj) => (Math.round(obj.eco)))
        },
        {
          label: costTypeSelected === 'cost' ? t('commons.cost') : t('commons.costDistance'),
          type: 'bar',
          fill: false,
          backgroundColor: '#3777C6',
          borderColor: '#3777C6',
          hoverBackgroundColor: '#3777C6',
          hoverBorderColor: '#3777C6',
          yAxisID: 'y-axis-1',
          data: data.map((obj) => {
            const value = costTypeSelected === 'cost' ? (obj.expenses).toFixed(2) : (obj.expenses / obj.km).toFixed(2);
            return t('commons.interpolation.distance', {count: value})
          })
        }
      ]
    }
  };

  render() {
    const {t} = this.props;

      return (
        <div className="card">
          <header className="card-header">
            <div className="card-header-title">
              <div className="media-left">{t('commons.fuelConsumption')}</div>
              <div className="media-content"></div>
              <div className="media-right"><CostTypeSelector onChangeScoreType={this.onChangeScoreType}/></div>
            </div>
          </header>
          <div className="card-content">
            <Bar data={this.getData()} options={options} height={70} />
          </div>
        </div>
      )
  }

}

export default withTranslation('translation')(VehicleEcoConsumptionChart);