import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import classNames from "classnames";

class ExpensesAlertList extends Component {

  getAlertSeverity = (alert) => {
    const overcharge = (alert.amount - alert.limit) / alert.limit * 100;
    if (overcharge <= 10) {
      return "has-background-success";
    } else if (overcharge <= 20) {
      return "has-background-warning";
    } else {
      return "has-background-danger";
    }
  };

  render() {
    const { alerts, t } = this.props;

    if (alerts && alerts.length === 0) {
      return (
        <div className="message is-success has-text-centered">
          <div className="message-header"><p>{t('expensesAlertList.noAlerts.title')}</p></div>
          <div className="message-body">{t('expensesAlertList.noAlerts.body')}</div>
        </div>
      )
    }

    return (
      <div>
        {alerts.map(((alert, index) => (
          <div className={classNames('card', 'vechicle-card', 'has-text-centered', this.getAlertSeverity(alert))} key={'exp' + index}>
            <div className="card-content">
              <p className="subtitle has-text-white">{alert.driver.name}</p>
              <p className="title has-text-white">${alert.amount} / {alert.limit}</p>
            </div>
          </div>
        )))}
      </div>

    );
  }
}

export default withTranslation('translation')(ExpensesAlertList);
