import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import emptyCar from '../../images/car-avatar.png'
import SimpleMap from "../maps/SimpleMap";
import _ from "lodash";
import EditVehicleModal from "./EditVehicleModal";

const DEFAULT_LOC = { lat: -34.603722, lng: -58.381592 };

class VehicleDetail extends Component {

  state = {
    showEditModal: false
  };

  componentWillMount() {
    const {fleetDetail, vehicle, selected} = this.props;
    if (selected && selected.rt_tracking) {
      this.props.getLastLocation(fleetDetail.id, vehicle.id)
    }
  }

  onClickEdit = () => {
    this.setState({showEditModal: true});
  };

 onCloseEditModal = (shouldEdit, vehicle) => {
    let { fleetDetail } = this.props;

    if (shouldEdit) {

      // convert miles to kms if needs
      // miles will be converted to kms if locale is en-us. @SEE /i18n.js
      let stringValue = this.props.t('commons.interpolation.toKms', {count: vehicle.odometer});
      vehicle.odometer = parseInt(stringValue);

      // server call
      this.props.saveVehicle(fleetDetail.id, vehicle);
    }

    this.setState({showEditModal: false});
  };

  render() {
    const { vehicle, lastLocation, selected, fleetDetail, t } = this.props;
    const { showEditModal } = this.state;

    let vehicleLocation = DEFAULT_LOC;

    if (lastLocation && lastLocation.length > 0) {
      vehicleLocation = _.maxBy(lastLocation, function(l) { return l.ts; });
    }

    return (
      <div className="is-fullheight">

        {showEditModal &&
          <EditVehicleModal onClose={this.onCloseEditModal} vehicle={vehicle} fleeDrivers={fleetDetail.drivers} />
        }

        <div className="column is-12">
          <figure className="image is-fluid">
            <img src={vehicle.photo_url} ref={img => this['img' + vehicle.id] = img} onError={()=>{this['img' + vehicle.id].src = emptyCar}}/>
          </figure>
        </div>

        <div className="column">
          <a className="button is-primary is-outlined is-small" disabled={vehicle.id === 0}
                  onClick={() => {this.onClickEdit()}}>
            <span className="icon"><i className="fa fa-pencil is-small"></i></span>
            <span>{vehicle.name}</span>
          </a>
        </div>


        <div className="column is-12">
          <div className="field is-grouped is-grouped-multiline">
            {vehicle.drivers.map(((driver, index) => (
              <div className="control" key={'dirvertag' + index}>
                <span className="tag is-light">{driver.name}</span>
              </div>
            )))}
          </div>
        </div>

        <div className="column is-12">
          {/*<h2 className="subtitle">{t('vehicleDetail.info')}</h2>*/}

          <div className="columns">
            <div className="column is-6">
              <strong>{t('vehicleDetail.vin')}</strong><br/>
              <strong>{t('vehicleDetail.plate')}</strong><br/>
              <strong>{t('vehicleDetail.odometer')}</strong><br/>
              <strong>{t('vehicleDetail.make')}</strong><br/>
              <strong>{t('vehicleDetail.model')}</strong><br/>
              <strong>{t('vehicleDetail.year')}</strong>
            </div>
            <div className="column is-6">
              {vehicle.vin}<br/>
              {vehicle.plate}<br/>
              {t('commons.interpolation.toKms', {count: vehicle.odometer})}<br/>
              {vehicle.make ? vehicle.make.name:""}<br/>
              {vehicle.model ? vehicle.model.name:""}<br/>
              {vehicle.year}
            </div>

          </div>
        </div>

        {selected && selected.rt_tracking &&
          <div className="column is-12">
            <h2 className="subtitle">{t('vehicleDetail.lastLocation')}</h2>

            <div className="map-wrapper">
              <SimpleMap location={vehicleLocation}/>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default withTranslation('translation')(VehicleDetail);
