import React, { Component } from "react";
import {
  withGoogleMap,
  GoogleMap,
  Polyline,
  Marker,
  InfoWindow
} from "react-google-maps";
import _ from "lodash";

const MAP_CONTAINER_STYLE = {position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'flex-end', alignItems: 'center'};
const COLORS = ["blue", "red", "green", "violet", "orange", "black"];
const renderDirections = directions => {
  let index = -1;
  const hasDirections = directions.length !== 0;
  return hasDirections &&
    directions.map(direction => {
      index++
      return (
        <Polyline
          key={index}
          path={direction.routes[0].overview_path}
          geodesic={false}
          options={{
              strokeColor: COLORS[index],
          }}
        />
      )
    })
}
const renderMarkers = (markers, onClickMarker, selectedMarkers) => 
  markers.map((marker, index) =>
    <Marker
      key={index}
      position={new window.google.maps.LatLng(marker.lat, marker.lon)}
      onClick={() => onClickMarker(marker.name)}>
      {selectedMarkers[marker.name] && (
        <InfoWindow onCloseClick={() => onClickMarker(marker.name)}>
          <p>{marker.name}</p>
        </InfoWindow>
      )}
    </Marker>
  );

const MapWithADirectionsRenderer = withGoogleMap((({onMapMounted, directions, markers, onClickMarker, selectedMarkers}) =>
  <GoogleMap
    ref={onMapMounted}
    defaultZoom={14}
    defaultCenter={new window.google.maps.LatLng(-34.603722, -58.381592)}
    defaultOptions={{streetViewControl: true, scaleControl: false, mapTypeControl: false,
      panControl: false, rotateControl: false, fullscreenControl: true}} disableDefaultUI>
    {renderDirections(directions)}
    {renderMarkers(markers, onClickMarker, selectedMarkers)}
  </GoogleMap>
))
class RoutesMap extends Component {
    state = {
        directions: [],
        selectedMarkers: {}
    };

    componentDidMount() {
      this.renderDestinations()
    }

    componentDidUpdate(prevProps){
      const {destinations} = this.props
      const destinationsHasChanged = !_.isEqual(destinations, prevProps.destinations);
      const hasDestinations = destinations.length > 0;
      if(destinationsHasChanged && hasDestinations) {
        const lastDestination = destinations[destinations.length - 1]
        this.autoPan({ lat: lastDestination.lat, lng: lastDestination.lon });
        this.renderDestinations()
      }
    }

    autoZoomMap = (points) => {
      if (!this._map || _.isEmpty(points)) return;
  
      var bound = new window.google.maps.LatLngBounds();
      for (let i of points) {
        bound.extend(new window.google.maps.LatLng(i.lat, i.lon));
      }
      this._map.fitBounds(bound);
    };

    autoPan = (lastLocation) => {
      this._map && this._map.panTo(new window.google.maps.LatLng(lastLocation.lat, lastLocation.lng)) && this.mapRef.current && this.mapRef.current.setZoom(8);
      this.setState({ zoom: 10 })
    };

    renderDestinations() {
      let directions = [];
      const {destinations} = this.props;
      const DirectionsService = new window.google.maps.DirectionsService();
      destinations.map((origin, index) => {
        const destination = destinations[index + 1];
        if (destination) {
          return DirectionsService.route({
            origin: new window.google.maps.LatLng(origin.lat, origin.lon),
            destination: new window.google.maps.LatLng(destination.lat, destination.lon),
            travelMode: window.google.maps.TravelMode.DRIVING,
          }, (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              directions.push(result);
              this.setState({directions});
            } else {
              console.error(`error fetching directions ${result}`);
            }
          })
        } else return null
      });
    }

    onClickMarker = markerName => {
      let {selectedMarkers} = this.state;
      this.setState({selectedMarkers: {...selectedMarkers, [markerName]: !selectedMarkers[markerName] }})
    }

    handleMapMounted = (map) => {
      this._map = map;
      const { destinations } = this.props;
      if (this._map) {
        if (destinations.length > 0) this.autoZoomMap(destinations)
        else {
          navigator.geolocation.getCurrentPosition(position => {
            if (position) {
              const currentPosition = {lat: position.coords.latitude, lng: position.coords.longitude};
              this.autoPan(currentPosition);
            }
          });
        }
      }
    };

    autoPan = this.autoPan.bind(this);
    handleMapMounted = this.handleMapMounted.bind(this);

    render() {
        const { directions, zoom, selectedMarkers } = this.state
        const { destinations } = this.props

        return (
            <MapWithADirectionsRenderer
              directions={directions}
              markers={destinations}
              zoom={zoom}
              containerElement={<div style={MAP_CONTAINER_STYLE} />}
              mapElement={<div style={{ height: `100%`, width:  `100%`}} />}
              onClickMarker={this.onClickMarker}
              onMapMounted={this.handleMapMounted}
              selectedMarkers={selectedMarkers}
            />
        );

    }
}

export default RoutesMap;