import {checkStatus, parseJson} from '../api/utils'
import {BASE_URL} from '../config'
import _ from "lodash";

const SET_STOPS_OVERS = 'woo/roadMaps/SET_STOPS_OVERS';
const SAVE_STOP_REQUEST = 'woo/roadMaps/SAVE_STOP_REQUEST';
const SAVE_STOP_REQUEST_SUCCESS = 'woo/roadMaps/SAVE_STOP_REQUEST_SUCCESS';

const initialState = {
  stopsOvers: null,
  stopLoading: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_STOPS_OVERS:
      const filteredStopOvers = action.stopsOvers.filter(so => so.deleted === false)
      return {
        ...state,
        stopsOvers: filteredStopOvers
      };
    case SAVE_STOP_REQUEST:
      return {
        ...state,
        stopLoading: true
      };
    case SAVE_STOP_REQUEST_SUCCESS:
      const stopsOversWithoutNewOneWithBadIndex = state.stopsOvers.filter(so => so.id !== 0)
      const newStopsOversWithProperIndex = action.stopOver
      return {
        ...state,
        stopsOvers: [
          ...stopsOversWithoutNewOneWithBadIndex,
          newStopsOversWithProperIndex
        ],
        stopLoading: false
      };
    default:
      return state;
  }
}

// action creators

const setStopsOvers = (stopsOvers) => ({ type: SET_STOPS_OVERS, stopsOvers });
const saveStopRequested = () => {
  return {
    type: SAVE_STOP_REQUEST
  }
};
export const saveStopRequestSuccess = (stopOver) => {
  return {
    type: SAVE_STOP_REQUEST_SUCCESS,
    stopOver
  }
};


export const fetchStops = (fleetId) => {

  return dispatch => {

    let token = localStorage.getItem("token");
    let url = `${BASE_URL}/api/fleet/${fleetId}/stopover`;

    return fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {
      dispatch(setStopsOvers(json))
    });

  }
};

function getFetchStopOver(stopOver) {
  return {
    "name": stopOver.name,
    "description": stopOver.description,
    "duration": stopOver.duration,
    "stopover_type": stopOver.stopover_type,
    "address": stopOver.address,
    "lat": stopOver.location.lat,
    "lng": stopOver.location.lon,
    "client": stopOver.client? stopOver.client : "",
    "external_id": stopOver.external_id,
    "client_id": stopOver.client_id,
  }
}

export const createStopOver = (fleetId, stopOver) => {
  return dispatch => {
    dispatch(saveStopRequested());
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/stopover' ;

    return fetch(url, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(getFetchStopOver(stopOver)),
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {
      dispatch(saveStopRequestSuccess(json));
    }).catch(error =>{
      console.log("error", error)
    })
  }
};

export const updateStopOver = (fleetId, stopOver) => {
  return dispatch => {
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/stopover/' + stopOver.id;

    return fetch(url, {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(getFetchStopOver(stopOver)),
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus).then((json) => {
    }).catch(error =>{
      console.log("error", error)
    })
  }
};

export const deleteStopOver = (fleetId, stopOver) => {
  return dispatch => {
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/stopover/' + stopOver.id ;

    return fetch(url, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {
    }).catch(error =>{
    })
  }
};

