import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import _ from "lodash";

import {fetchMakes} from "../../redux/vehicle"
import {connect} from 'react-redux'
import classNames from "classnames";


class MakesSelector extends Component {

  state = {
  };

  componentWillMount() {
    this.props.fetchMakes();
  }

  onSelected = (ev) => {
    const id = ev.target.value;
    const { makes } = this.props;
    this.props.onMakeSelected(_.find(makes, m => m.id === parseInt(id)));
  };

  render() {
    const { makes, t, makeSelected, makesLoading } = this.props;
    const makeSelectedId = makeSelected ? makeSelected.id:0;

    if (makesLoading) {
      return (
        <div className="control is-loading">
          <input className="input" disabled={true}/>
        </div>
      );
    }

    return (
      <div className={classNames('select', this.props.className)}>
        <select onChange={this.onSelected} value={makeSelectedId}>
          <option value={0}>{t('vehiclesTable.makeSelector.default')}</option>
          {
            makes.map(((make, index) => (
              <option value={make.id} key={'div-make-' + make.id}>{make.name}</option>
            )))
          }
        </select>
      </div>
    );
  }
}

// These props come from the application's
// state when it is started
function mapStateToProps(state) {
  const { makes, makesLoading } = state.vehicle;
  return { makes, makesLoading }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchMakes : () => {
      dispatch(fetchMakes());
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(MakesSelector))
