import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {withTranslation} from "react-i18next";

class SuggestionDescription extends Component {

  getTagClassName = (points) => {
    let base = "tag is-large suggestionTag ";

    if (points < 50) {
      return base + "is-danger";
    }
    if (points < 70) {
      return base + "is-warning";
    }

    return base + "is-success";
  };

  getScoreMsg = (tag, score) => {
    let key = 'suggestionDescription.' + tag;

    if (score < 50) {
      key += ".1";
    } else if (score < 75) {
      key += ".2";
    } else {
      key += ".3";
    }
    return this.props.t(key);
  };

  render() {
    const { stats, t } = this.props;

    return (
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">Recomendaciones</p>
        </header>
        <div className="card-content">
          <div className="media">
            <div className="media-content">

              <div className="columns">
                <p className="column is-4"><span className={this.getTagClassName(stats.control)}>{t('commons.control')}</span></p>
                <p className="column has-text-centered">{this.getScoreMsg("control", stats.control)}</p>
              </div>
              <div className="columns">
                <p className="column is-4"><span className={this.getTagClassName(stats.caution)}>{t('commons.caution')}</span></p>
                <p className="column has-text-centered">{this.getScoreMsg("caution", stats.caution)}</p>
              </div>
              <div className="columns">
                <p className="column is-4"><span className={this.getTagClassName(stats.focus)}>{t('commons.focus')}</span></p>
                <p className="column has-text-centered">{this.getScoreMsg("focus", stats.focus)}</p>
              </div>
              <div className="columns">
                <p className="column is-4"><span className={this.getTagClassName(stats.eco)}>{t('commons.eco')}</span></p>
                <p className="column has-text-centered">{this.getScoreMsg("eco", stats.eco)}</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}


SuggestionDescription.propTypes = {
  stats: PropTypes.object.isRequired
};

export default withTranslation('translation')(SuggestionDescription);
