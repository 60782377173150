import {connect} from 'react-redux'
import RoadMaps from "../pages/RoadMaps";
import RoadMapsTable from "../components/RoadMaps/RoadMapsTable";
import _ from "lodash";
import {fetchRoadMaps, createRoadMap, updateRoadMap, deleteRoadMap, fetchMoreRoadMaps} from "../redux/roadMap";
import {fetchStops} from "../redux/stops";
import {fetchMarkers} from "../redux/markers";
import {fetchVehicles} from "../redux/fleet";
import {fetchPolygonMarkers} from "../redux/polygonMarkers";


function mapStateToProps(state) {
  const {roadMaps, roadMapLoading, rmCursor} = state.roadMap
  const {stopsOvers, stopLoading} = state.stops
  const {markers, markersLoading} = state.markers
  const {polygonMarkers, polygonMarkersLoading} = state.markers
  const { vehicles } = state.fleet;
  const loading = roadMapLoading || stopLoading || markersLoading || polygonMarkersLoading

  const drivers = _.get(state, 'fleet.fleetDetail.drivers')
  return { roadMaps, drivers, stopsOvers, loading, vehicles, markers, polygonMarkers, rmCursor };
}

const mapDispatchToProps = dispatch => {
  return {
    fetchRoadMaps: (fleetId) => {
      dispatch(fetchRoadMaps(fleetId));
    },
    fetchMoreRoadMaps: (fleetId, rmCursor) => {
      dispatch(fetchMoreRoadMaps(fleetId, rmCursor));
    },
    fetchStops: (fleetId) => {
      dispatch(fetchStops(fleetId));
    },
    fetchMarkers: (fleetId) => {
      dispatch(fetchMarkers(fleetId));
    },
    fetchVehicles: (fleetId) => {
      dispatch(fetchVehicles(fleetId));
    },
    fetchPolygonMarkers: (fleetId) => {
      dispatch(fetchPolygonMarkers(fleetId));
    },
    saveRoadMap: (fleetId, roadMap) => {
      if (roadMap.id === 0) {
        dispatch(createRoadMap(fleetId,roadMap))
      } else {
        dispatch(updateRoadMap(fleetId,roadMap))
      }
    },
    deleteRoadMap: (fleetId, roadMap) => {
      dispatch(deleteRoadMap(fleetId,roadMap))
    },
  }
};


const RoadMapsContainer = connect(mapStateToProps, mapDispatchToProps)(RoadMaps);
const RoadMapTableContainer = connect(mapStateToProps, mapDispatchToProps)(RoadMapsTable);

export { RoadMapsContainer, RoadMapTableContainer}