import {checkStatus, parseJson} from '../api/utils'
import {BASE_URL} from '../config'
import _ from "lodash";

const SET_MARKERS = 'woo/roadMaps/SET_MARKERS';
const SAVE_MARKER_REQUEST = 'woo/roadMaps/SAVE_MARKER_REQUEST';
const SAVE_MARKER_REQUEST_SUCCESS = 'woo/roadMaps/SAVE_MARKER_REQUEST_SUCCESS';

const initialState = {
  markers: null,
  markerLoading: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_MARKERS:
      const filteredMarkers = action.markers.filter(so => so.deleted === false)
      return {
        ...state,
        markers: filteredMarkers
      };
    case SAVE_MARKER_REQUEST:
      return {
        ...state,
        markerLoading: true
      };
    case SAVE_MARKER_REQUEST_SUCCESS:
      const markersWithoutNewOneWithBadIndex = state.markers.filter(m => m.id !== 0)
      const newMarkerWithProperIndex = action.marker
      return {
        ...state,
        markers: [
          ...markersWithoutNewOneWithBadIndex,
          newMarkerWithProperIndex
        ],
        markerLoading: false
      };
    default:
      return state;
  }
}

// action creators

const setMarkers = (markers) => ({ type: SET_MARKERS, markers });
const saveMarkerRequested = () => {
  return {
    type: SAVE_MARKER_REQUEST
  }
};
export const saveMarkerRequestSuccess = (marker) => {
  return {
    type: SAVE_MARKER_REQUEST_SUCCESS,
    marker
  }
};


export const fetchMarkers = (fleetId) => {

  return dispatch => {

    let token = localStorage.getItem("token");
    let url = `${BASE_URL}/api/fleet/${fleetId}/marker`;

    return fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {
      dispatch(setMarkers(json))
    });

  }
};

function getFetchMarker(marker) {
  return {
    "name": marker.name,
    "lat": marker.location.lat,
    "lng": marker.location.lon,
    "description": marker.description,
    "address": marker.address,
    "url_photo": "",
    "marker_type": marker.marker_type
  }
}

export const createMarker = (fleetId, marker) => {
  return dispatch => {
    dispatch(saveMarkerRequested());
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/marker' ;

    return fetch(url, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(getFetchMarker(marker)),
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {
      dispatch(saveMarkerRequestSuccess(json));
    }).catch(error =>{
      console.log("error", error)
    })
  }
};

export const updateMarker = (fleetId, marker) => {
  return dispatch => {
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/marker/' + marker.id;

    return fetch(url, {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(getFetchMarker(marker)),
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus).then((json) => {
    }).catch(error =>{
      console.log("error", error)
    })
  }
};

export const deleteMarker = (fleetId, marker) => {
  return dispatch => {
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/marker/' + marker.id ;

    return fetch(url, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {
    }).catch(error =>{
    })
  }
};

