import React, {Component} from 'react';
import Loading from '../components/Loading'
import {withTranslation} from "react-i18next";
import CrtDetailContainer from "../containers/crt/CrtDetailContainer";
import CrtDashboardContainer from "../containers/crt/CrtDashboardContainer";

class Crt extends Component {

  state = {
    tabSelected: 0
  };

  componentWillMount() {
    const {fleetId, crtId} = this.props.match.params;
    this.props.fetchCrt(fleetId, crtId);
  }

  render() {
    const { crt, selected } = this.props;
    const { fleetId } = this.props.match.params;

    if (!crt || !selected) {
      return (<Loading wait={0}/>);
    }

    return (
      <div className="is-fullwidth">
        {!crt && <Loading wait={0}/>}

        <div className="columns">
          <div className="column is-2">
            <CrtDetailContainer crt={crt}/>
          </div>

          <div className="column">
            <CrtDashboardContainer fleetId={fleetId} crt={crt} />
          </div>

        </div>
      </div>
    );
  }
}

export default withTranslation('translation')(Crt);