import { checkStatus, parseJson } from '../api/utils'
import { getFleetDetail, updateSelectedValues } from './fleet'
import { BASE_URL } from '../config'

const FLEET_UPDATE = 'woo/fleet/FLEET_UPDATE';
const FLEET_UPDATE_SUCCESS = 'woo/fleet/FLEET_UPDATE_SUCCESS';
const FLEET_INVITES = 'woo/fleet/FLEET_INVITES';
const FLEET_INVITES_SUCCESS = 'woo/fleet/FLEET_INVITES_SUCCESS';

const INVITES_BY_EMAIL = 'woo/fleet/INVITES_BY_EMAIL';
const INVITES_BY_EMAIL_SUCCESS = 'woo/fleet/INVITES_BY_EMAIL_SUCCESS';

const REMOVE_DRIVER = 'woo/fleet/REMOVE_DRIVER';
const REMOVE_DRIVER_SUCCESS = 'woo/fleet/REMOVE_DRIVER_SUCCESS';

const REMOVE_INVITE = 'woo/fleet/REMOVE_INVITE';
const REMOVE_INVITE_SUCCESS = 'woo/fleet/REMOVE_INVITE_SUCCESS';

const ANSWER_REQUEST = 'woo/fleet/ANSWER_REQUEST';
const ANSWER_REQUEST_SUCCESS = 'woo/fleet/ANSWER_REQUEST_SUCCESS';


const initialState = {
  updatingFleet: false,
  invitesLoading: false,
  invites: [],
  requests:[]
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FLEET_UPDATE:
      return {
        ...state,
        updatingFleet: true
      };
    case FLEET_UPDATE_SUCCESS:
      return {
        ...state,
        updatingFleet: false,
        fleet: action.fleet
      };
    case FLEET_INVITES:
      return {
        ...state,
        invitesLoading: true
      };
    case FLEET_INVITES_SUCCESS:
      return {
        ...state,
        invitesLoading: false,
        invites: action.invites.invites,
        requests: action.invites.requests
      };
    case INVITES_BY_EMAIL:
      return {
        ...state,
        invitesLoading: true
      };
    case INVITES_BY_EMAIL_SUCCESS:
      return {
        ...state,
        invitesLoading: false
      };
    case REMOVE_DRIVER:
      return {
        ...state,
        invitesLoading: true
      };
    case REMOVE_DRIVER_SUCCESS:
      return {
        ...state,
        invitesLoading: false
      };
    case REMOVE_INVITE:
      return {
        ...state,
        invitesLoading: true
      };
    case REMOVE_INVITE_SUCCESS:
      return {
        ...state,
        invitesLoading: false
      };
    case ANSWER_REQUEST:
      return {
        ...state,
        invitesLoading: true
      };
    case ANSWER_REQUEST_SUCCESS:
      return {
        ...state,
        invitesLoading: false
      };
    default:
      return state;
  }
}

const fleetUpdateRequested = () => {
  return {
    type: FLEET_UPDATE
  }
};

export const fleetUpdateSuccess = (fleet) => {
  return {
    type: FLEET_UPDATE_SUCCESS,
    fleet
  }
};

const fleetInvitesRequested = () => {
  return {
    type: FLEET_INVITES
  }
};

export const fleetInvitesSuccess = (invites) => {
  return {
    type: FLEET_INVITES_SUCCESS,
    invites
  }
};

const inviteByEmailRequested = () => {
  return {
    type: INVITES_BY_EMAIL
  }
};

export const inviteByEmailSuccess = (invites) => {
  return {
    type: INVITES_BY_EMAIL_SUCCESS,
    invites
  }
};

const removeInviteRequested = () => {
  return {
    type: REMOVE_INVITE
  }
};

export const removeInviteSuccess = () => {
  return {
    type: REMOVE_INVITE_SUCCESS
  }
};

const removeDriverRequested = () => {
  return {
    type: REMOVE_DRIVER
  }
};

export const removeDriverSuccess = (driver) => {
  return {
    type: REMOVE_DRIVER_SUCCESS,
    driver
  }
};

const answerRequestRequested = () => {
  return {
    type: ANSWER_REQUEST
  }
};

export const answerRequestSuccess = () => {
  return {
    type: ANSWER_REQUEST_SUCCESS
  }
};


export const updateFleet = (fleetId, values) => {
  return dispatch => {
    dispatch(fleetUpdateRequested());
    let token = localStorage.getItem("token");
    // let user = JSON.parse(localStorage.getItem("user"))
    const url = BASE_URL + '/api/fleet/' + fleetId + "?values=" + JSON.stringify(values);

    return fetch(url, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer '+ token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {
      //save token in localStorage
      dispatch(fleetUpdateSuccess(json));
      dispatch(updateSelectedValues(json));

    }).catch(error =>{
      // console.log("update fleet error", error.name, error.message);
    })
  }
};

// export const updateFleet = (fleetId, values) => {
//   return dispatch => {
//     dispatch(fleetUpdateRequested())
//     setTimeout(1000, dispatch(fleetUpdateSuccess(values)))
//   }
// }


export const getFleetInvites = (fleetId) => {
  return dispatch => {
    dispatch(fleetInvitesRequested());
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/invite';

    return fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer '+ token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {

      // console.log(json)
      //save token in localStorage
      dispatch(fleetInvitesSuccess(json))

    }).catch(error =>{
      // console.log("getFleetInvites error", error.name, error.message);
    })
  }
};

export const inviteDriverByEmail = (fleetId, email) => {
  return dispatch => {
    dispatch(inviteByEmailRequested());
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/invite/' + email;

    return fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer '+ token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {

      // console.log(json)
      //save token in localStorage
      dispatch(inviteByEmailSuccess(json));

      // fetch updated list of invites
      dispatch(getFleetInvites(fleetId));

      dispatch(updateSelectedValues({id: fleetId, "privateInviteLink": json.link}));

    }).catch(error =>{
      // console.log("inviteDriverByEmail error", error.name, error.message)
    })
  }
};

export const removeInvite = (fleetId, email) => {
  return dispatch => {
    dispatch(removeInviteRequested());
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/invite/' + email;

    return fetch(url, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer '+ token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {

      // console.log(json)
      //save token in localStorage
      dispatch(removeInviteSuccess());

      // fetch updated list of invites
      dispatch(getFleetInvites(fleetId))
    }).catch(error =>{
      // console.log("removeInvite error", error.name, error.message);
    })
  }
};

export const removeDriver = (fleetId, uid) => {
  return dispatch => {
    dispatch(removeDriverRequested());
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/driver/' + uid;

    return fetch(url, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer '+ token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {

      // console.log(json)
      //save token in localStorage
      dispatch(removeDriverSuccess(json));

      // driverDetail needs to be updated
      dispatch(getFleetDetail(fleetId));

    }).catch(error =>{
      // console.log("removeDriver error", error.name, error.message);
    })
  }
};

export const answerRequest = (fleetId, uid, answer) => {
  return dispatch => {
    dispatch(answerRequestRequested());
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/invite/' + uid + '/' + answer;

    return fetch(url, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer '+ token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {

      // console.log(json)
      //save token in localStorage
      dispatch(answerRequestSuccess());

      // fetch updated list of invites
      dispatch(getFleetInvites(fleetId));

      // driverDetail needs to be updated
      dispatch(getFleetDetail(fleetId));

    }).catch(error =>{
      // console.log("answerRequest error", error.name, error.message)
    })
  }
};

