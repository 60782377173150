import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import classNames from "classnames";
import {getClassNameFromStopValue} from "./constants";
import RoutesMap from "../maps/RoutesMap";
import { BASE_URL } from '../../config';
import { checkStatus, parseJson } from '../../api/utils'

const styles = {
    marginRight: "10px"
};

class RoadMapTimeline extends Component {

    state = {
        stopovers: null,
        data: false
    }

    getStopovers(roadmap_id) {
        
        let token = localStorage.getItem("token");
        const url = BASE_URL + '/api/roadmap/' + roadmap_id + '/stopovers_completed'

        return fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ token
        }
        })
        .then(checkStatus)
        .then(parseJson)
        .then((json) => {
            this.setState({ data: true, stopovers: json})
        }).catch(error =>{
            console.log('Error fetching stopovers completed. ', error);
        })

    };
    

    render() {
        const {roadmap_id, stops, roadmap_status, onComments, t} = this.props;
        const stopsMarkers = stops.map(stop => {
            return {...stop.location, name: stop.name};
        });

        if (!this.state.data) {
            this.getStopovers(roadmap_id)
            
        }

        return (
            <div className="card">
                <div className="card-content">
                    <div className="columns">
                        <div className="column is-half">
                            <div className="timeline is-centered">
                                <header className="timeline-header">
                                    <span className="tag is-medium is-primary" style={roadmap_status == 1 ? {background: '#bdbdbd'} : {} }>Inicio</span>
                                </header>
                                {
                                    stops.map((st, index) => (
                                            <div className="timeline-item" key={index + '-div'}>
                                                <div className="timeline-marker is-icon is-48x48 is-centered" style={this.state.data ? (this.state.stopovers.length > index) ? {background: "#00C6A8", borderColor: "#00C6A8"} : {} : {}}>
                                                    <i className={classNames("fa", getClassNameFromStopValue(st.stopover_type), "fa-2x")}></i>
                                                </div>
                                                <div
                                                    className={classNames("timeline-content")}>
                                                    <p className="heading" style={styles}> {st.name}</p>
                                                    <p style={styles}>{st.address}</p>
                                                    { this.state.data && this.state.stopovers.length > index && <button onClick={() => {onComments(this.state.stopovers[index].id)}}><i class="fa fa-camera"></i></button> }
                                                </div>
                                            </div>
                                        )
                                    )
                                }
                                <div className="timeline-header">
                                    <span className="tag is-medium is-primary" style={roadmap_status != 3 ? {background: '#bdbdbd'} : {} }>Fin</span>
                                </div>
                            </div>
                        </div>
                        <div className="column is-half map-container-on-modal">
                            <div className="column mapContainer">
                                <RoutesMap destinations={stopsMarkers} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withTranslation('translation')(RoadMapTimeline));