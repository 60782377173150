import React, {Component} from "react";
import _ from "lodash";
import {withTranslation} from "react-i18next";
import DriverSuggestionContainer from "../../containers/DriverSuggestionContainer";
import classNames from "classnames";
import {CRT_CLOSED, CRT_OPEN} from "../../pages/Crts";
import moment from "moment";


class EditCrtModal extends Component {

  state = {
    fleeDrivers: [],
    suggestions: [],
    suggestedInputValue: "",
    currentCrt: {
      drivers: []
    },
    isIncomplete: true,
    wasValidated: false
  };

  componentWillMount() {
    const { crt } = this.props;
    let isIncomplete = this.isFormIncomplete(crt);
    this.setState({ currentCrt: crt, isIncomplete });
  }

  onSuggestionSelected = (event, suggestion) => {
    if (suggestion) {
      const { currentCrt } = this.state;
      currentCrt.drivers.push(suggestion);
      this.setState({ currentCrt: currentCrt });
    }
  };

  onRemoveDriver = (removedDriver) => {
    const { currentCrt } = this.state;
    _.remove(currentCrt.drivers, d => d.email === removedDriver.email);
    this.setState({ currentCrt: currentCrt });
  };

  onChangeInput = ev => {
    let { currentCrt, isIncomplete } = this.state;

    if (ev.target.name === 'status') {
      currentCrt[ev.target.name] = parseInt(ev.target.value);
    } else if (ev.target.name === 'start_date' || ev.target.name === 'end_date'){
      let v = moment(ev.target.value);
      currentCrt[ev.target.name] = v.unix() * 1000;
    } else {
      currentCrt[ev.target.name] = ev.target.value;
    }

    isIncomplete = this.isFormIncomplete(currentCrt);
    this.setState({ currentCrt: currentCrt, isIncomplete });
  };


  isFormIncomplete(currentCrt) {
    return !currentCrt.crt_id || !currentCrt.start_date ||  !currentCrt.status;
  }

  onSave = () => {
    const { currentCrt, isIncomplete } = this.state;

    if (isIncomplete) {
      this.setState({wasValidated: true})
    } else {
      this.props.onClose(true, currentCrt);
    }
  };

  initEndDate = () => {
    let { currentCrt } = this.state;
    if (!currentCrt.end_date) {
      currentCrt.end_date = moment(new Date()).startOf('day').endOf('day').unix() * 1000;
    }
    this.setState({currentCrt});
  };

  getValidationClassName = (isValid) => {
    const { wasValidated } = this.state;

    if (isValid) {
      return 'is-success';
    } else if (wasValidated) {
      return 'is-danger';
    } else {
      return '';
    }
  };

  render() {
    const { onClose, t } = this.props;
    const { currentCrt } = this.state;

    let from = currentCrt.start_date ? moment(currentCrt.start_date).toISOString(true).substr(0,16):undefined;
    let to = currentCrt.end_date ? moment(currentCrt.end_date).toISOString(true).substr(0,16):undefined;

    return (
      <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            {currentCrt.crt_id ?
              <p className="modal-card-title">{t('crtsTable.edit.title') + currentCrt.crt_id}</p> :
              <p className="modal-card-title">{t('crtsTable.edit.isNewTitle')}</p>
            }
          </header>

          <section className="modal-card-body has-text-left">
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label">{t('crtsTable.edit.crt_id')} *</label>
                  <div className="control">
                    <input className={classNames('input', this.getValidationClassName(currentCrt.crt_id !== ""))}
                           type="text" value={currentCrt.crt_id} name="crt_id" onChange={this.onChangeInput}/>
                  </div>
                </div>
              </div>

              <div className="column is-one-third">
               <div className="field">
                  <label className="label">{t('crtsTable.edit.status')} *</label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select value={currentCrt.status} name="status" onChange={this.onChangeInput}>
                        <option value={CRT_OPEN}>{t('commons.open')}</option>
                        <option value={CRT_CLOSED}>{t('commons.close')}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label">{t('commons.from')} *</label>
                  <div className="control">
                    <input className={classNames('input', this.getValidationClassName(!_.isEmpty(from)))}
                           type="datetime-local" value={from} name="start_date" onChange={this.onChangeInput}/>
                  </div>
                </div>
              </div>

              <div className="column">
                <div className="field">
                  <label className="label">{t('commons.to')}</label>
                  <div className="control">
                    <input className="input"
                           type="datetime-local" value={to} name="end_date" onChange={this.onChangeInput}
                           onFocus={() => this.initEndDate()}/>
                  </div>
                </div>
              </div>

            </div>

            <div className="field">
              <label className="label">{t('crtsTable.edit.drivers')}</label>

              <div className="control">
                <DriverSuggestionContainer selected={currentCrt.drivers} onSelected={this.onSuggestionSelected} cleanOnSelected={true}/>
              </div>

              <div className="vehicle-drivers-container">
                <div className="field is-grouped is-grouped-multiline">
                  {currentCrt.drivers.map(((driver, index) => (
                    <div className="control" key={index + "-driver-div"}>
                      <div className="tags has-addons">
                        <span className="tag is-light">{driver.name}</span>
                        <a className="tag is-delete is-danger" onClick={() => this.onRemoveDriver(driver)}></a>
                      </div>
                    </div>
                  )))}
                </div>
              </div>
            </div>

          </section>

          <footer className="modal-card-foot">
            <div className="buttons">
              <button className="button is-primary" onClick={() => this.onSave()}>{t('commons.save')}</button>
              <button className="button" onClick={() => onClose(false, currentCrt)}>{t('commons.cancel')}</button>
            </div>
          </footer>
        </div>
      </div>
    )
  }
}

export default withTranslation('translation')(EditCrtModal);