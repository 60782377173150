import React from "react";

export const CheckBox = props => (
    <input
        key={props.id}
        onChange={props.onChange}
        type="checkbox"
        checked={props.isChecked}
        value={props.value}
    />
);

export default CheckBox;