/**
 * Created by jje-personal on 9/4/17.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {withTranslation} from "react-i18next";

class JoinRequestTable extends Component {

  render() {
    const { requests, t } = this.props;

    return (
      <table className="table">
        <thead>
          <tr>
            <th>{t('joinRequestTable.email')}</th>
            <th>{t('joinRequestTable.accept')}</th>
            <th>{t('joinRequestTable.deny')}</th>
          </tr>
        </thead>
        <tbody>
        { requests.map(((d, index) => (
          <tr key={index}>
            <td>{d.email}</td>
            <td><a className="fa fa-thumbs-up" onClick={()=>(this.props.onAcceptRequest(d.uid))}></a></td>
            <td><a className="fa fa-thumbs-down" onClick={()=>(this.props.onRejectRequest(d.uid))}></a></td>
          </tr>
        )))}
        </tbody>
      </table>
    );
  }
}

JoinRequestTable.propTypes = {
  requests: PropTypes.array.isRequired,
  onAcceptRequest: PropTypes.func.isRequired,
  onRejectRequest: PropTypes.func.isRequired
};

export default withTranslation('translation')(JoinRequestTable);
