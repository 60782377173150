import {checkStatus, parseJson} from '../api/utils'
import {BASE_URL} from '../config'

const SET_ROAD_MAPS = 'woo/roadMaps/SET_ROAD_MAPS';
const ADD_ROAD_MAPS = 'woo/roadMaps/ADD_ROAD_MAPS';
const SAVE_ROAD_MAP_REQUEST = 'woo/roadMaps/SAVE_ROAD_MAP_REQUEST';
const SAVE_ROAD_MAP_REQUEST_SUCCESS = 'woo/roadMaps/SAVE_ROAD_MAP_REQUEST_SUCCESS';
const UPDATE_ROAD_MAP_REQUEST_SUCCESS = 'woo/roadMaps/UPDATE_ROAD_MAP_REQUEST_SUCCESS';

const initialState = {
  roadMaps: null,
  roadMapLoading: false
};


export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_ROAD_MAPS:
      const filteredRoadMaps = action.roadMaps.filter(rm => rm.deleted === false)
      return {
        ...state,
        roadMaps: filteredRoadMaps,
        rmCursor: action.cursor
      };
    case ADD_ROAD_MAPS:
      const newRoadMaps = action.roadMaps.filter(rm => rm.deleted === false)
      const roadMaps = state.roadMaps;
      return {
        ...state,
        roadMaps: [...roadMaps, ...newRoadMaps],
        rmCursor: action.cursor
      };
    case SAVE_ROAD_MAP_REQUEST_SUCCESS:
      const roadMapsWithoutNewOneWithBadIndex = state.roadMaps.filter(rm => rm.id !== 0)
      const newRoadMapWithProperIndex = action.roadMap
      return {
        ...state,
        roadMaps: [
          ...roadMapsWithoutNewOneWithBadIndex,
          newRoadMapWithProperIndex
        ]
      };
    case UPDATE_ROAD_MAP_REQUEST_SUCCESS:
      const roadMapsWithoutOldVersion = state.roadMaps.filter(rm => action.roadMap.id !== rm.id)
      return {
        ...state,
        roadMaps: [
          ...roadMapsWithoutOldVersion,
          action.roadMap
        ]
      };
    default:
      return state;
  }
}

// action creators

const setRoadMaps = (roadMaps, cursor) => ({ type: SET_ROAD_MAPS, roadMaps, cursor });
const addRoadMaps = (roadMaps, cursor) => ({ type: ADD_ROAD_MAPS, roadMaps, cursor });
const saveRoadMapRequested = () => {
  return {
    type: SAVE_ROAD_MAP_REQUEST
  }
};
export const saveRoadMapRequestSuccess = (roadMap) => {
  return {
    type: SAVE_ROAD_MAP_REQUEST_SUCCESS,
    roadMap
  }
};

export const updateRoadMapRequestSuccess = (roadMap) => {
  return {
    type: UPDATE_ROAD_MAP_REQUEST_SUCCESS,
    roadMap
  }
};

export const setRoadMaps_ = (roadMaps) => {
  return dispatch => {
    dispatch(setRoadMaps(roadMaps));
  }
}

export const fetchRoadMaps = (fleetId) => {

  return dispatch => {

    let token = localStorage.getItem("token");
    let url = `${BASE_URL}/api/fleet/${fleetId}/roadmap?n=9`;

    return fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {
      dispatch(setRoadMaps(json.roadmaps, json.next_cursor))
    });

  }
};

export const fetchMoreRoadMaps = (fleetId, rmCursor) => {

  return dispatch => {

    let token = localStorage.getItem("token");
    let url = `${BASE_URL}/api/fleet/${fleetId}/roadmap?cursor=${rmCursor}&n=9`;

    return fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {
      dispatch(addRoadMaps(json.roadmaps, json.next_cursor))
    });

  }
};

function getFetchRoadMap(roadMap) {
  return {
    "name": roadMap.name,
    "description": roadMap.description,
    "driver_ids": roadMap.drivers.map(dv => dv.uid),
    "stopover_ids": roadMap.stopovers.map(dv => dv.id),
    "start_date": roadMap.start_date,
    "status": roadMap.status,
    "eta": roadMap.eta,
    "distance": roadMap.distance,
    "vehicle_id": roadMap.vehicle.id
  }
}

export const createRoadMap = (fleetId, roadMap) => {
  return dispatch => {
    dispatch(saveRoadMapRequested());
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/roadmap' ;

    return fetch(url, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(getFetchRoadMap(roadMap)),
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {
      dispatch(saveRoadMapRequestSuccess(json));
    }).catch(error =>{
      console.log("error", error)
    })
  }
};

export const updateRoadMap = (fleetId, roadMap) => {
  return dispatch => {
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/roadmap/' + roadMap.id;

    return fetch(url, {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(getFetchRoadMap(roadMap)),
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {
      dispatch(updateRoadMapRequestSuccess(json));
    }).catch(error =>{
      console.log("error", error)
    })
  }
};

export const deleteRoadMap = (fleetId, roadMap) => {
  return dispatch => {
    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + fleetId + '/roadmap/' + roadMap.id ;

    return fetch(url, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {
    }).catch(error =>{
    })
  }
};