import React, {Component} from 'react';
import {DateRange} from "react-date-range";
import moment from "moment";
import {withTranslation} from "react-i18next";
import i18next from 'i18next';
import classNames from "classnames";


class DatePicker extends Component {

  state = {
    rangeTypeSelected: 0
  };

  componentWillMount() {
    const dateRangeSelected = this.props.dateRangeSelected || {startDate: moment(new Date()).startOf('day'), endDate: moment(new Date())};
    this.setState({dateRangeSelected})
  }

  handleSelectCustomRange = (range) => {
    const { datesToSelect } = this.state;
    const { onChange } = this.props;
    onChange && onChange(range);
    this.setState({dateRangeSelected: range, datesToSelect: datesToSelect - 1});
  };

  getSelectedRangeText = () => {
    const {dateRangeSelected} = this.state;
    const {t} = this.props;
    return `${dateRangeSelected.startDate.format(t('commons.dateFormat'))} - ${dateRangeSelected.endDate.format(t('commons.dateFormat'))}`;
  };


  onSelectRange = (rangeType) => {
    let startDate  = moment(new Date()).startOf('day');

    switch (rangeType) {
      case 0:
        // nothing: default value
        break;
      case 1: // yesterday
        startDate = startDate.subtract(1, 'days').startOf('day');
        break;
      case 2: // last week
        startDate = startDate.subtract(7, 'days').startOf('day');
        break;
      case 3: // last month
        startDate = startDate.subtract(30, 'days').startOf('day');
        break;
    }

    this.setState({rangeTypeSelected: rangeType});

    this.handleSelectCustomRange({startDate: startDate, endDate: moment(new Date())});
  };

  render() {
    const { datesToSelect, rangeTypeSelected } = this.state;
    const { t } = this.props;
    const currentLang = i18next.languages[0].substr(0, 2);

    return(
      <div>
        <label className="label">{t('commons.filter.dates')}</label>

        {rangeTypeSelected !== 4 &&
          <div className="field">
            <div className="buttons has-addons has-text-centered">
              <button className={classNames("button", {"is-selected is-primary":  rangeTypeSelected === 0})}
                      onClick={()=>(this.onSelectRange(0))}>
                {t('datePicker.today')}
              </button>
              <button className={classNames("button", {"is-selected is-primary":  rangeTypeSelected === 1})}
                      onClick={()=>(this.onSelectRange(1))}>
                {t('datePicker.yesterday')}
              </button>
              <button className={classNames("button", {"is-selected is-primary":  rangeTypeSelected === 2})}
                      onClick={()=>(this.onSelectRange(2))}>
                {t('datePicker.thisWeek')}
              </button>
              <button className={classNames("button", {"is-selected is-primary":  rangeTypeSelected === 3})}
                      onClick={()=>(this.onSelectRange(3))}>
                {t('datePicker.thisMonth')}
              </button>
              <button className={classNames("button", {"is-selected is-primary":  rangeTypeSelected === 4})}
                      onClick={()=>(this.onSelectRange(4))}>
                {t('datePicker.select')}
              </button>
            </div>
          </div>
        }

        {rangeTypeSelected === 4 &&
          <div className="field has-addons">
            <div className="control is-expanded">
              <input className="input" value={this.getSelectedRangeText()}
                     onFocus={() => this.setState({datesToSelect: 2})}
                     onClick={() => this.setState({datesToSelect: 2})}
                     // onBlur={}
                     readOnly={true}/>

              { datesToSelect > 0 &&
                <div>
                  <div className="datepicker-range-wrapper" onClick={() => this.setState({datesToSelect: 0})}></div>
                  <div className="datepicker-range autosuggest-container-suggest-open">
                    <DateRange calendars="1" onChange={this.handleSelectCustomRange} maxDate={moment(new Date())} lang={currentLang}/>
                  </div>
                </div>
              }
            </div>
            <div className="control">
              <a className="button is-primary" onClick={()=>(this.onSelectRange(0))}><i className="fa fa-times"></i></a>
            </div>
          </div>
        }
      </div>
    );

  }

}

export default withTranslation('translation')(DatePicker);