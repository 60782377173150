import React, { Component } from 'react';
import Timestamp from 'react-timestamp'
import { secondsToHms } from '../../utils/functions'
import Loading from '../Loading'
import { BASE_URL } from '../../config'
import { checkStatus, parseJson } from '../../api/utils'
import PropTypes from 'prop-types'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css'
import {withTranslation} from "react-i18next";
import TripsModal from "./TripsModal";

class TripsTable extends Component {

  componentWillMount(){
    this.setState({width: window.innerWidth});
  }

  state = { loading: false, showingDetails: false, tripWithPolylines: null, width: window.innerWidth, user: this.props.user };

  onChangePrivacy = (trip, index) => {

    this.setState({ loading: true });

    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + this.props.fleet.id + '/trip/' + trip.id + '/priv';
    return fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer '+ token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {

      // remove from list
      //this.props.trips.delete(index)
      this.props.trips.splice(index, 1);

      this.setState({ loading: false });
      toast.success("Viaje marcado como privado");

    }).catch(error =>{
      this.setState({ loading: false });
      toast.error("Error");
    })

  };

  onOpenTripDetail = (trip) => {

    this.setState({ loading: true });

    let token = localStorage.getItem("token");
    const url = BASE_URL + '/api/fleet/' + this.props.fleet.id + '/trip/' + trip.id + '/detail';

    return fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer '+ token
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {
      this.setState({ loading: false, showingDetails: true, tripWithPolylines: json })
    }).catch(error =>{
      this.setState({ loading: false });
      toast.error("Error");
    })
  };

  notify = () => {
    toast.info(this.props.t("tripsTable.copyToClipboard"));
  };

  onCloseTripDetail = () => {
    this.setState({ showingDetails: false, tripWithPolylines: null })
  };

  render() {
    const { trips, t, fleet } = this.props;

    var showCRT = false
    if (fleet.crt_enabled === true) {
      showCRT = true
    }

    return (
      <div>
        <ToastContainer autoClose={3000}/>

         { this.state.width < 710 &&
            trips.map(((d, index) => (
            <div className="mobile-table">

              { this.props.fleet
                && this.props.fleet.show_routes === true
                &&
                <div className="pull-right">
                  <a className="fa fa-expand" onClick={() => (this.onOpenTripDetail(d))}></a>
                </div>
              }

              <div><strong>{t('commons.date')}: </strong>{<Timestamp time={d.timestamp/1000} format="full"/>}</div>
              <div><strong>{t('tripsTable.duration')}: </strong>{secondsToHms(d.duration)}</div>
              <div><strong>{t('tripsTable.distance')}: </strong>{t('commons.interpolation.distance', {count: d.distance.toFixed(2)})}</div>
              <div><strong>{t('tripsTable.mediaSpeed')}: </strong>{t('commons.interpolation.speed', {count: d.speed.toFixed(2)})}</div>
              <div><strong>{t('commons.total')}: </strong>{d.total}</div>
              <div><strong>{t('commons.control')}: </strong>{d.control}</div>
              <div><strong>{t('commons.caution')}: </strong>{d.caution}</div>
              <div><strong>{t('commons.focus')}: </strong>{d.focus}</div>
              <div><strong>{t('commons.eco')}: </strong>{d.eco}</div>
              { showCRT &&
                <div><strong>{t('commons.crt')}: </strong>{d.crt_id}</div>
              }

            </div>)))
        }

				{ this.state.width >= 710 &&
          <table className="table">
            <thead>
              <tr>
                <th className="has-text-centered">{t('commons.date')}</th>
                <th className="has-text-centered">{t('tripsTable.duration')}</th>
                <th className="has-text-centered">{t('tripsTable.distance')}</th>
                <th className="has-text-centered">{t('tripsTable.mediaSpeed')}</th>
                <th className="has-text-centered">{t('commons.total')}</th>
                <th className="has-text-centered">{t('commons.control')}</th>
                <th className="has-text-centered">{t('commons.caution')}</th>
                <th className="has-text-centered">{t('commons.focus')}</th>
                <th className="has-text-centered">{t('commons.eco')}</th>
                {/* <th className="has-text-centered">{t('commons.priv')}</th> */}
                { showCRT &&
                  <th className="has-text-centered">{t('commons.crt')}</th>
                }
                <th className="has-text-centered">{t('commons.actions')}</th>
              </tr>
            </thead>
            <tbody>
            { trips.map(((d, index) => (
              <tr key={index}>
                <td className="has-text-centered">{<Timestamp time={d.timestamp/1000} format="full"/>}</td>
                <td className="has-text-centered">{secondsToHms(d.duration)}</td>
                <td className="has-text-centered">{t('commons.interpolation.distance', {count: d.distance.toFixed(2)})}</td>
                <td className="has-text-centered">{t('commons.interpolation.speed', {count: d.speed.toFixed(2)})}</td>

                <td className="has-text-centered">{d.total}</td>
                <td className="has-text-centered">{d.control}</td>
                <td className="has-text-centered">{d.caution}</td>
                <td className="has-text-centered">{d.focus}</td>
                <td className="has-text-centered">{d.eco}</td>
                {/* <td className="has-text-centered">{d.privacy}</td> */}
                { showCRT &&
                  <td className="has-text-centered">{d.crt_id}</td>
                }
                <td className="has-text-centered hand-cursor">

                  { this.props.fleet
                    && this.props.fleet.show_routes === true
                    &&
                    <i className="fa fa-expand" style={{ marginRight: 5 }} onClick={() => this.onOpenTripDetail(d) }></i>
                  }

                  { this.props.fleet
                      && this.props.fleet.privacy_configuration !== 'None'
                      &&
                    <i className="fa fa-eye-slash" style={{ marginRight: 5 }} onClick={() => this.onChangePrivacy(d, index) }></i>
                  }
                  <CopyToClipboard text={d.id}>
                      <i className="fa fa-clone" onClick={() => this.notify() }></i>
                  </CopyToClipboard>
                </td>
              </tr>
            )))}
            </tbody>
          </table>
				}

				{ this.state.showingDetails && this.state.tripWithPolylines  &&
            <TripsModal
                onCloseTripDetail = {this.onCloseTripDetail}
                selected = {this.state.tripWithPolylines}
                loading = {this.state.loading}
            />}

        { this.state.loading && <Loading/> }

      </div>
    );
  }
}

TripsTable.propTypes = {
  fleet: PropTypes.object,
  trips: PropTypes.array,
  alerts: PropTypes.array
};

export default withTranslation('translation')(TripsTable);
