
import { connect } from 'react-redux'

import { getDriverDetail, getDriverTrips } from '../redux/fleet'
import DriverDetail from '../pages/DriverDetail'

// These props come from the application's
// state when it is started
function mapStateToProps(state) {

  const { selected, driverDetail, loading, driverTrips, tripsLoading } = state.fleet;
  return {
    selected,
    driverDetail,
    loading,
    driverTrips,
    tripsLoading
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getDriverDetail : (fleetId, driverId) => {
      dispatch(getDriverDetail(fleetId, driverId))
    },
    getDriverTrips : (fleetId, driverId, date) => {
      dispatch(getDriverTrips(fleetId, driverId, date))
    }
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(DriverDetail)