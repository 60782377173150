/* global google */
import React, {Component} from 'react';
import {GoogleMap, Marker, OverlayView, withGoogleMap} from "react-google-maps";
import idleIcon from '../../images/marker-grey.png'
import drivingIcon from '../../images/marker.png'
import positionIcon from '../../images/position-icon-2.png'
import {withTranslation} from 'react-i18next';

const getPixelPositionOffset = (width, height) => {
  return {x:-24, y:-64}
};

const MAP_CONTAINER_STYLE = {position: 'absolute', top: 49, left: 0, right: 0, bottom: 0, justifyContent: 'flex-end', alignItems: 'center'};

const MarkerClusteredTag = withGoogleMap(withTranslation('translation')(props => (

  <GoogleMap ref={props.onMapMounted} defaultZoom={5} defaultCenter={props.center}
             defaultOptions={{streetViewControl: false, scaleControl: false, mapTypeControl: false,
                        panControl: false, rotateControl: false, fullscreenControl: true}} disableDefaultUI>

      {props.expenses.map((exp, index) => (
        <div key={index + 'exp-div'}>
          <Marker
            position={{ lat: exp.location.lat, lng: exp.location.lng }}
            key={index + 'exp-marker'}
            icon={positionIcon}
            // onMouseOver={() => props.showPopup(exp)}
            // onMouseOut={() => props.closePopup(exp)}
          >

            <OverlayView key={index + 'exp-a'} position={{ lat: exp.location.lat, lng: exp.location.lng}}
              mapPaneName={OverlayView.MAP_PANE} getPixelPositionOffset={getPixelPositionOffset}>
              <div className="iconContainer"
                   // onMouseEnter={() => props.showPopup(exp)}
                   // onMouseLeave={() => props.closePopup(exp)}
              >
                <img src={exp.driving? drivingIcon: idleIcon}/>
                <div style={{position:'absolute', top: '10px', width: '100%'}}>
                  <p className="iconText">{`$${exp.billing_amount}`}</p>
                </div>
              </div>
            </OverlayView>

            {/*{marker === props.targeted &&*/}
            {/*<OverlayView key={index+'b'} position={{ lat: marker.lat, lng: marker.lng }}*/}
              {/*mapPaneName={OverlayView.OVERLAY_LAYER} getPixelPositionOffset={getPixelPositionOffset2}>*/}
                {/*<div className="box" style={{minWidth:'250px'}}>*/}
                  {/*<div className="content">*/}
                    {/*<p className="title is-6"><strong>{marker.name}</strong></p>*/}
                    {/*<hr/>*/}
                    {/*<p className="subtitle is-6"><strong>{props.t('fleetLocation.time')}: </strong> <Timestamp time={marker.ts / 1000} format="full"/></p>*/}
                    {/*<p className="subtitle is-6"><strong>{props.t('fleetLocation.state')}: </strong> {marker.driving? props.t('fleetLocation.driving'): props.t('fleetLocation.stopped')}</p>*/}
                  {/*</div>*/}
                {/*</div>*/}
            {/*</OverlayView>*/}
            {/*}*/}
          </Marker>

        </div>
      ))}

  </GoogleMap>
)));


class ExpensesMap extends Component {

  state = {
    center: { lat: -34.603722, lng: -58.381592, id: 1 },
    centered: false,
    showInfo: null
  };

  // showInfo = (targetMarker) => {
  //   this.setState({targeted: targetMarker});
  // };
  //
  // hideInfo = (targetMarker) => {
  //   this.setState({targeted: null});
  // };

  autoZoomMap = (expenses) => {
    if (!this._map || !expenses || expenses.length === 0) return;

    var bound = new google.maps.LatLngBounds();
    for (let ex of expenses) {
      bound.extend(new google.maps.LatLng(ex.location.lat, ex.location.lng));
    }
    this._map && this._map.fitBounds(bound);
  };

  handleMapMounted = (map) => {
    this._map = map;
    const { expenses } = this.props;
    this.autoZoomMap(expenses)
  };

  autoZoomMap = this.autoZoomMap.bind(this);
  handleMapMounted = this.handleMapMounted.bind(this);
  // showInfo = this.showInfo.bind(this);
  // hideInfo = this.hideInfo.bind(this);

  render() {
    const { expenses, t } = this.props;
    const { centered } = this.state;

    if (expenses.length > 0 && !centered) {
      this.autoZoomMap(expenses);
      this.setState({centered:true});
    }

    return (
      <div className="card">
        <div className="card-header">
          <p className="card-header-title">{t('expensesMap.title')}</p>
        </div>
        <div className="card-content" style={{ height: `300px` }}>
          <div className="media">
            <div className="media-content">
              <MarkerClusteredTag
                onMapMounted={this.handleMapMounted}
                containerElement={<div style={MAP_CONTAINER_STYLE} />}
                mapElement={<div style={{ height: `100%`, width:  `100%`}} />}
                expenses={expenses}
                // showPopup={this.showInfo}
                // closePopup={this.hideInfo}
                center={this.state.center}
                targeted={this.state.targeted}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('translation')(ExpensesMap);
