import {connect} from 'react-redux'
import {fetchVehicles} from '../redux/fleet'
import VehicleSuggestion from "../components/autosuggest/VehicleSuggestion";

function mapStateToProps(state) {
  const { fleetDetail, vehicles } = state.fleet;
  return { fleetDetail, vehicles};
}

const mapDispatchToProps = dispatch => {
  return {
    fetchVehicles: (fleetId) => {
      dispatch(fetchVehicles(fleetId));
    }
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(VehicleSuggestion);


