import connect from "react-redux/es/connect/connect";
import {withTranslation} from "react-i18next";
import CrtDashboard from "../../components/crt/CrtDashboard";
import {deleteTripFromCrt} from "../../redux/fleet";

const mapDispatchToProps = dispatch => {
  return  {
    deleteTripFromCrt: (fleetId, crtId, tripId) => {
      dispatch(deleteTripFromCrt(fleetId, crtId, tripId));
    }
  }
};

function mapStateToProps(state) {
  const { selected, fleetDetail } = state.fleet;
  return { selected, fleetDetail };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(CrtDashboard))
