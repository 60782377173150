import React, { Component } from 'react';
import Select from 'react-select';
import chroma from 'chroma-js';

class DropdownSelect extends Component {

    render () {
        const { placeholder, options, onChange, maxMenuHeight, className, accentColor, width } = this.props;
        const woo_color = chroma('#00C6A8')

        const customStyles = {
        container: (provided_styles) => ({
            ...provided_styles,
            "minWidth": 250,
            "justifyContent": "end",
        }),
        placeholder: (provided_styles) => ({
            ...provided_styles,
            color: 'black',
        }),
        option: (provided_styles, { value, isFocused }) => ({
            ...provided_styles,
            color: value.active ? 'black' : 'gray', 
            backgroundColor: isFocused ? woo_color.alpha(0.25).css() : undefined, 
        }),
        dropdownIndicator: (provided_styles) => ({
            ...provided_styles,
            color: accentColor ? accentColor : woo_color.css()
        })
        }

        const dropdownTheme = (theme) => ({
        ...theme,
        borderRadius: 4,
        colors: {
            ...theme.colors,
            primary: woo_color.css(),
        }
        })

        return (
            <Select placeholder={placeholder} 
                 options={options} 
                 onChange={onChange} 
                 isSearchable={true}
                 styles={customStyles}
                 theme={dropdownTheme}
                 maxMenuHeight={maxMenuHeight}
                 className={className}
            /> 
        )
    }
}

export default DropdownSelect