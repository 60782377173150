import {connect} from 'react-redux'
import Vehicles from "../pages/Vehicles";
import { fetchVehicles, createVehicle, updateVehicle, deleteVehicle } from '../redux/fleet'
import VehiclesTable from "../components/vehicles/VehiclesTable";


function mapStateToProps(state) {
  const { selected, fleetDetail, vehicles } = state.fleet;
  return { selected, fleetDetail, fleetVehicles: vehicles };
}

const mapDispatchToProps = dispatch => {
  return {
    fetchVehicles: (fleetId) => {
      dispatch(fetchVehicles(fleetId));
    },
    saveVehicle: (fleetId, vehicle) => {
      if (vehicle.id === 0) {
        dispatch(createVehicle(fleetId, vehicle));
      } else {
        dispatch(updateVehicle(fleetId, vehicle));
      }
    },
    deleteVehicle: (fleetId, vehicle) => {
      dispatch(deleteVehicle(fleetId, vehicle));
    }
  }
};


const VehiclesContainer = connect(mapStateToProps, mapDispatchToProps)(Vehicles);
const VehiclesTableContainer = connect(mapStateToProps, mapDispatchToProps)(VehiclesTable);

export { VehiclesContainer, VehiclesTableContainer}
