import {connect} from 'react-redux'
import Crts, {CRT_CLOSED} from "../pages/Crts";
import { fetchCrts, createCrt, updateCrt, deleteCrt } from '../redux/fleet'
import CrtsTable from "../components/crt/CrtsTable";


function mapStateToProps(state) {
  const { selected, fleetDetail, crts } = state.fleet;
  return { selected, fleetDetail, fleetCrts: crts };
}

const mapDispatchToProps = dispatch => {
  return {
    fetchCRTs: (fleetId) => {
      dispatch(fetchCrts(fleetId));
    },
    saveCRT: (fleetId, crt) => {
      if (crt.id === 0) {
        dispatch(createCrt(fleetId, crt));
      } else {
        dispatch(updateCrt(fleetId, crt));
      }
    },
    deleteCRT: (fleetId, crt) => {
      dispatch(deleteCrt(fleetId, crt));
    },
    closeCRT: (fleetId, crt) => {
      crt.status = CRT_CLOSED;
      dispatch(updateCrt(fleetId, crt));
    }
  }
};


const CrtsContainer = connect(mapStateToProps, mapDispatchToProps)(Crts);
const CrtsTableContainer = connect(mapStateToProps, mapDispatchToProps)(CrtsTable);

export { CrtsContainer, CrtsTableContainer}
