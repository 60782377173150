import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import Timestamp from "react-timestamp";
import {STATIC_MAP} from '../../config'


class ExpensesList extends Component {

  getLocationImgPath = (exp) => {
    const {lat, lng} = exp.location;
    return `${STATIC_MAP}&center=${lat},${lng}&markers=size:tiny|color:red|${lat},${lng}`;
  };

  render() {
    const { expenses, t } = this.props;

    if (expenses && expenses.length === 0) {
      return (
        <div className="message is-warning has-text-centered">
          <div className="message-header"><p>{t('expensesList.noExpenses.title')}</p></div>
          <div className="message-body">{t('expensesList.noExpenses.body')}</div>
        </div>
      )
    }

    return (
      <div>
        {expenses.map(((exp, index) => (
          <div className="card vechicle-card" key={'exp' + index}>
            <div className="card-header">
              <div className="card-header-title">
                <div className="media-left"><Timestamp time={exp.ts / 1000} format="full"/></div>
                <div className="media-content"></div>
                <div className="media-right">{exp.driver.name}</div>
              </div>
            </div>

            <div className="card-image">
              <div className="card-image">
                <figure className="image is-3by1">
                  <img style={{'WebkitUserSelect': 'none'}} src={this.getLocationImgPath(exp)}/>
                </figure>
              </div>
            </div>

            <div className="card-footer">
              <div className="media-left"><p className="subtitle">${exp.billing_amount}</p></div>
              <div className="media-content has-text-centered">{exp.vehicle.plate}</div>
              <div className="media-right"><p className="subtitle">{exp.vehicle.name}</p></div>
            </div>
          </div>
        )))}
      </div>

    );
  }
}

export default withTranslation('translation')(ExpensesList);
