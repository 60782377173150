import {connect} from 'react-redux'
import {fetchCrt} from '../../redux/fleet'
import Crt from "../../pages/Crt";


function mapStateToProps(state) {
  const { crt, selected } = state.fleet;
  return { crt, selected };
}

const mapDispatchToProps = dispatch => {
  return {
    fetchCrt: (fleetId, crtId) => {
      dispatch(fetchCrt(fleetId, crtId));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Crt);
