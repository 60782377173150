import React, { Component } from 'react';
import { checkStatus, parseJson } from '../api/utils'
import { BASE_URL } from '../config';
import DropdownSelect from '../components/DropdownSelect';
import { connect } from 'react-redux'
import {withTranslation} from "react-i18next";
import SendPNConfirmModal from '../components/admin/SendPNConfirmModal';
import DeleteModal from '../components/admin/PNDeleteModal';
import SchedulePNModal from '../components/admin/SchedulePNModal';


class PushNotificationSender extends Component {

  state = {
    pushResponse: null,
    selectedFleetId: null,
    selectedFleetName: null,
    mode: 'fleet',
    showConfirmModal: false,
    showScheduleModal: false,
    showDeleteModal: false,
    currentPN: null,
    loading: false,
    scheduledPNs: [],
    loadingPNs: true
  };

  weekdays = {
    'mon': 'Lunes',
    'tue': 'Martes',
    'wed': 'Miercoles',
    'thu': 'Jueves',
    'fri': 'Viernes',
    'sat': 'Sábado',
    'sun': 'Domingo',
  }

  componentDidMount() {
    // TODO: implement getScheduledPNs
    const {fleetId} = this.props.match.params
    console.log('component did mount', fleetId)
    this.setState({selectedFleetId: fleetId}, () => {
      // this.setState({selectedFleetId: 4894595776249856, selectedFleetName: 'Woocar_New'})
      console.log(this.state.selectedFleetId + ' ' + this.state.selectedFleetName)
      this.getScheduledPNs()
      const {fleets} = this.props
      console.log('fleets.legth', fleets.length)
      if (fleets.length > 0) {
        const fleetName = fleets.find(fleet => fleet.id == fleetId).name
        this.setState({selectedFleetName: fleetName})
      }
    });
    
  };

  onSelectMode = (new_mode) => {
    this.setState({mode: new_mode, selectedFleetId: null, selectedFleetName: null, pushResponse: null})
  }

  onSelectFleet = (fleet_name, fleet_id) => {
    this.setState({selectedFleetId: fleet_id, selectedFleetName: fleet_name, pushResponse: null, scheduledPNs: []}, () => {
      this.getScheduledPNs()
    });
  };

  onClickSend = () => {
    this.setState({showConfirmModal: true})
  }

  onClickSchedule = () => {
    this.setState({showScheduleModal: true})
  }

  onClickDelete = (push_notification) => {
    this.setState({currentPN: push_notification, showDeleteModal: true})
  }

  onCloseDeleteModal = (shouldDeletePN, push_notification) => {
    if (shouldDeletePN) {
      this.deleteScheduledPN(push_notification)
    }
    this.setState({currentPN: null, showDeleteModal: false})
  }

  onCloseConfirmModal = (shouldSendPN) => {
    if (shouldSendPN) {
      this.sendPushNotification()
    }
    
    this.setState({showConfirmModal: false})
  }

  onCloseScheduleModal = (shouldSchedulePN, pn_schedule) => {
    if (shouldSchedulePN) {
      this.schedulePushNotification(pn_schedule)
    }
    
    this.setState({showScheduleModal: false})
  }
  
  getScheduledPNs = () => {
    console.log('getting PNs')
    this.setState({loadingPNs: true})

    const url = BASE_URL + '/api/fleet/' + this.state.selectedFleetId + '/admin/fleet_scheduled_notifications';

    return fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {
      this.setState({scheduledPNs: json, loadingPNs:false})
      console.log('fetched scheduled notifications successfuly');
    }).catch(error =>{
      this.setState({loadingPNs:false})
      console.log('something went wrong fetching scheduled pns', error)
    })
  }

  // sends a request to the push notification endpoint 
  sendPushNotification = () => {
    const title = this.refs.title.value
    const body = this.refs.body.value
    let payload = null
    if (this.state.mode == 'fleet') {
      const fleet_id = this.state.selectedFleetId
      payload = { "title": title, "body": body, "fleet_id": fleet_id}
    }else{
      const driver_email = this.refs.driverEmail.value
      payload = { "title": title, "body": body, "driver_email": driver_email}
    }
    

    const url = BASE_URL + '/api/admin/send_push_notification';

    this.setState({loading: true})

    return fetch(url, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(payload),
      headers: {
        'Accept': 'application/json',
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {
      console.log('json response: ', json)
      this.setState({loading: false, pushResponse: json})
      console.log('push notification sent succesfully');
    }).catch(error =>{
      // se asigna este valor para mostrar el error, reutilizando la logica que muestra la lista de emails y si tuvieron recibieron la PN
      if (error.status == 400 && this.state.mode =='driver'){
        this.setState({loading: false, pushResponse: {'La dirección de email ingresada no pertenece a un usuario de su flota.': false}})
      }else{
        this.setState({loading: false, pushResponse: {'ERROR enviando notificación': false}})
      }
      console.log('something went wrong sending push notification: ', error)
    })
  }

  schedulePushNotification = (pn_schedule) => {
    const title = this.refs.title.value
    const body = this.refs.body.value
    let payload = null
    if (this.state.mode == 'fleet') {
      const fleet_id = this.state.selectedFleetId
      payload = { "title": title, "body": body, "fleet_id": fleet_id,
                  "day": pn_schedule.day, "time": pn_schedule.time}
    }else{
      // se asigna este valor para mostrar el error, reutilizando la logica que muestra la lista de emails y si tuvieron recibieron la PN
      this.setState({pushResponse: {'No se puede programar notificaciones individuales': false}})
    }
    const url = BASE_URL + '/api/admin/schedule_notifications';

    this.setState({loading: true})

    return fetch(url, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(payload),
      headers: {
        'Accept': 'application/json',
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then((json) => {
      console.log('json response: ', json)
      this.setState({loading: false, scheduledPNs: [...this.state.scheduledPNs, payload]})
      console.log('scheduled notification sent succesfully');
    }).catch(error =>{
      this.setState({loading: false})
      console.log('something went wrong scheduling push notification: ', error)
    })
  }

  deleteScheduledPN = (push_notification) => {
    const url = BASE_URL + '/api/admin/delete_scheduled_notifications';

    return fetch(url, {
      method: 'DELETE',
      credentials: 'include',
      body: JSON.stringify(push_notification),
      headers: {
        'Accept': 'application/json',
      }
    })
    .then(checkStatus)
    .then(parseJson)
    .then(() => {
      let scheduled_pns = this.state.scheduledPNs
      const index_to_delete = scheduled_pns.indexOf(push_notification)
      scheduled_pns.splice(index_to_delete, 1)
      this.setState({showDeleteModal: false, scheduledPNs: scheduled_pns})
      console.log('push notification deleted succesfully');
    }).catch(error =>{
      console.log('something went wrong fetching scheduled pns', error)
    })
  }

  

  render() {
    const { pushResponse, mode, showConfirmModal, showScheduleModal, showDeleteModal, selectedFleetName, scheduledPNs, currentPN, loading, loadingPNs } = this.state
    const { fleets } = this.props
  
    // object with all the fleets to pass to the fleet selection dropdown
    const fleetOptions = fleets.map(fleet => ({ label: fleet.name, value: fleet.id }))

    return (
      <div className="is-vcentered is-fullwidth">
        {showDeleteModal && <DeleteModal onClose={this.onCloseDeleteModal} pn={currentPN} />}
        {showConfirmModal && <SendPNConfirmModal onClose={this.onCloseConfirmModal} 
          pnData={{title: this.refs.title.value, 
                   body: this.refs.body.value, 
                   target: this.state.mode == 'fleet' ? selectedFleetName : this.refs.driverEmail.value}}/>}
        {showScheduleModal && <SchedulePNModal onClose={this.onCloseScheduleModal} />}
        <div className="columns is-vcentered is-fullwidth">
          <div className="column is-6 is-offset-3">
            <h1 className="title">
                Enviar Notificaciones
            </h1>
            <div className="box">
              <div className="column">
                <label className="label has-text-left">Título</label>
                <p className="control">
                  <input className="input" ref="title" type="" placeholder="Título de la notificación"/>
                </p>
              </div>
              <div className="column">
                <label className="label has-text-left">Cuerpo</label>
                <p className="control">
                  <input className="input" ref="body" type="" placeholder="Cuerpo de la notificación" />
                </p>
              </div>
              <div className="column field has-addons has-text-centered filterField">
                <button className={mode == 'fleet' ? "button is-primary": "button"}
                        onClick={()=>(this.onSelectMode('fleet'))}>
                  flota
                </button>
                <button className={mode == 'driver' ? "button is-primary": "button"}
                        onClick={()=>(this.onSelectMode('driver'))}>
                  usuario
                </button>
              </div>
              {mode == 'fleet' && <div className='column'>
                <label className="label has-text-left">Flota</label>
                <div className='is-fullwidth'>
                    <DropdownSelect options={fleetOptions}
                                    onChange={opt => this.onSelectFleet(opt.label, opt.value)}
                                    placeholder='Seleccioná una flota'
                                    accentColor='#3273dc'
                                    width={350}
                    />
                </div>
              </div>}
              {mode == 'driver' && <div className="column">
                <label className="label has-text-left">Email del usuario</label>
                <p className="control">
                  <input className="input" ref="driverEmail" type="" placeholder="ejemplo1@abc.com" />
                </p>
              </div>}
              </div>
              <hr/>
              <div className="field is-grouped">
                <p className="control">
                  <button className={loading ? "button is-info is-loading" : "button is-primary"} onClick={() => {this.onClickSend()}}>Enviar ahora</button>
                </p>
                <p className="control is-pulled-right">
                  <button className="button" onClick={() => {this.onClickSchedule()}}>Programar envío</button>
                </p>
              </div>
            </div>
        </div>

        <div className="columns is-vcentered is-fullwidth">
          {pushResponse &&
          <div className="column is-3 is-offset-3">
            {Object.keys(pushResponse).map((email) => (
              <span className={pushResponse[email]?"help is-primary":"help is-danger"}>{email}</span>
            ))}
          </div>
          }
          <div className={pushResponse ? "column is-3" : "column is-6 is-offset-3"}>
            {scheduledPNs.length > 0 && <div>
              <label className="label">Notificaciones Programadas</label>
              <label className="label">para {selectedFleetName}</label>
              <br/>
              </div>}
            {loadingPNs && <div>Cargando...</div>}
            {scheduledPNs.map((pn) => (
              <div>
              <div className="card">
                <div className="card-content">
                  <div className='columns is-vcentered'>
                    <div className="column is-9">
                      <label className="label has-text-left">{pn.title}</label>
                      <div>{pn.body}</div>
                      <div>{this.weekdays[pn.day]} - {pn.time.slice(0,2)}:{pn.time.slice(2)}</div>
                    </div>
                    <div className='column is-3'>
                      <button className='button is-pulled-right is-danger' onClick={() => {this.onClickDelete(pn)}}><i className="fa fa-times"></i></button>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              </div>
            ))}
          </div>
        </div>
      </div>
    );

  }
}

// These props come from the application's
// state when it is started
function mapStateToProps(state) {

  // const { isAuthenticated, user } = state.auth;
  const { fleets } = state.fleet;

  return {
    // isAuthenticated,
    // user,
    fleets,
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps, null, {pure:false})(withTranslation('translation')(PushNotificationSender))

// export default PushNotificationSender;