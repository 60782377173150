import React from 'react';
import {Bar} from 'react-chartjs-2';
import {withTranslation} from "react-i18next";


const getConfig = ({data, t}) => {
  return {
    labels: data.map((obj) => (obj.date)),
    dataSets: [
      {
        label: t('commons.eco'),
        type:'line',
        fill: false,
        lineTension: 0.1,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        backgroundColor: '#00C6A8',
        borderColor: '#00C6A8',
        pointBorderColor: '#00C6A8',
        pointHoverBackgroundColor: '#00C6A8',
        pointHoverBorderColor: '#00C6A8',
        pointBackgroundColor: '#fff',
        data: data.map((obj) => (Math.round(obj.eco)))
      },
      {
        label: t('commons.distance'),
        type:'line',
        fill: false,
        lineTension: 0.1,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        backgroundColor: '#3777C6',
        borderColor: '#3777C6',
        pointBorderColor: '#3777C6',
        pointHoverBackgroundColor: '#3777C6',
        pointHoverBorderColor: '#3777C6',
        pointBackgroundColor: '#fff',
        yAxisID: 'y-axis-2',
        data: data.map((obj) => (Math.round(obj.km)))
      },
      {
        label: t('commons.expenses'),
        type: 'bar',
        fill: false,
        backgroundColor: '#71B37C',
        borderColor: '#71B37C',
        hoverBackgroundColor: '#71B37C',
        hoverBorderColor: '#71B37C',
        yAxisID: 'y-axis-1',
        data: data.map((obj) => (Math.round(obj.expenses)))
      }
    ]
  }
};

const options = {
  responsive: true,
  tooltips: {
    mode: 'label'
  },
  elements: {
    line: {
      fill: false
    }
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: false
        },
        ticks: {
          display: true
        }
      }
    ],
    yAxes: [
      {
        type: 'linear',
        display: true,
        position: 'left',
        id: 'y-axis-1',
        gridLines: {
          display: false
        },
        ticks: {
          display: true
        }
      },
      {
        type: 'linear',
        display: true,
        position: 'right',
        id: 'y-axis-2',
        gridLines: {
          display: false
        },
        ticks: {
          display: true
        }
      }
    ]
  }
};

const ExpensesChart = (props) => (
  <div className="card">
    <header className="card-header">
      <p className="card-header-title">{props.t('commons.performance')}</p>
    </header>
    <div className="card-content">
      <Bar data={getConfig(props)} options={options} height={120} />
    </div>
  </div>
);

export default withTranslation('translation')(ExpensesChart);