/* global google */
import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { withGoogleMap, GoogleMap, Polyline, Marker, OverlayView } from "react-google-maps";
import { decodePath } from '../utils/polyline'
import racing from '../images/racing.png'
import speedAlertIcon from '../images/speedAlert.png'
import brakeAlertIcon from '../images/brakeAlert.png'
import corneringAlertIcon from '../images/corneringAlert.png'
import accelerationAlertIcon from '../images/accelerationAlert.png'
import phoneAlertIcon from '../images/phoneAlert.png'
import alertIcon from '../images/alert.png'
import Timestamp from 'react-timestamp'
import { secondsToHms } from '../utils/functions'
import {withTranslation} from "react-i18next";
import { ROLE_ADMIN } from '../model/Role';
import _ from 'lodash';


const AlertTitle = withTranslation('translation')((props) => {
  switch (props.type) {
    case 2:
      return props.t('tripPathMap.alertTitle.speedExceed', {count: 60});
    case 3:
      return props.t('tripPathMap.alertTitle.speedExceed', {count: 130});
    case 6:
      return props.t('tripPathMap.alertTitle.speedExceedGeneric');
    case 5:
      return props.t('tripPathMap.alertTitle.harshCornering');
    case 200:
      return props.t('tripPathMap.alertTitle.speedExceedGeneric');
    case 4:
    case 100:
    case 110:
    case 300:
    case 310:
      return props.t('tripPathMap.alertTitle.abruptAceleration');
    case 120:
    case 320:
      return props.t('tripPathMap.alertTitle.abruptBraking');
    case 600:
      return props.t('tripPathMap.alertTitle.receivedCall');
    case 610:
      return props.t('tripPathMap.alertTitle.rejectedCall');
    case 620:
      return props.t('tripPathMap.alertTitle.phoneUsage');
    case 700:
      return props.t('tripPathMap.alertTitle.lowBatery');
    case 710:
      return props.t('tripPathMap.alertTitle.okBatery');
    case 720:
      return props.t('tripPathMap.alertTitle.chargingPhone');
    case 730:
      return props.t('tripPathMap.alertTitle.noChargingPhone');
    case 800:
      return props.t('tripPathMap.alertTitle.gpsOff');
    case 900:
      return props.t('tripPathMap.alertTitle.memory');
    default:
      return props.t('tripPathMap.alertTitle.unknown', {type: props.type});
  }
});

const getAlertIcon = (type) => {
  switch (type) {
    case 2:
      return speedAlertIcon;
    case 3:
      return speedAlertIcon;
    case 200:
      return speedAlertIcon;
    case 5:
      return corneringAlertIcon;
    case 4:
    case 6:
      return speedAlertIcon;
    case 100:
    case 110:
    case 300:
    case 310:
      return accelerationAlertIcon;
    case 120:
    case 320:
      return brakeAlertIcon;
    case 600:
    case 610:
    case 620:
      return phoneAlertIcon;
    case 700:
    case 710:
    case 720:
    case 730:
      return alertIcon;
    case 800:
      return alertIcon;
    default:
      return alertIcon;
  }
};

const isSpeed = (type) => {
  return type === 6;
};

const isPhoneCall = (type) => {
  return type === 600;
};

const isNoPhoneCall = (type) => {
  return type === 610;
};

const isPhoneUse = (type) => {
  return type === 620;
};

const getPixelPositionOffset = (width, height) => {
  return { x: -(width/2), y: -(height/2) }
};

const getPixelPositionOffset2 = (width, height) => {
  return { x: (50), y: -(height/2) };
};

const PolylineMapTag = withGoogleMap(withTranslation('translation')(props => (

  <GoogleMap ref={props.onMapMounted} defaultZoom={10} defaultCenter={{ lat: -34.591551, lng: -58.450422}}
      defaultOptions={{streetViewControl: true, scaleControl: false, mapTypeControl: true,
                        panControl: false, rotateControl: false, fullscreenControl: true}} disableDefaultUI>

      {props.trips.map((trip, index) => (
        <div key={index + 'div1'}>

          {trip === props.targeted &&
            <OverlayView
              key={index+'b'}
              position={{ lat: props.targeted.popup.lat, lng: props.targeted.popup.lng }}
              mapPaneName={OverlayView.FLOAT_PANE}
              getPixelPositionOffset={getPixelPositionOffset2}
              >
                <div className="box" style={{minWidth:'250px', 'zIndex':1000 }}>

                  <div className="content">
                    <p className="title is-6">
                    <strong>{trip.username}</strong>
                    </p>
                    <hr/>
                    <p className="subtitle is-6"><strong>{props.t('tripPathMap.totalScore')}: </strong>{trip.total}</p>
                    <p className="subtitle is-6"><strong>{props.t('tripPathMap.distance-1')}: </strong>{props.t('tripPathMap.distance-2', {count: trip.distance.toFixed(2)})}</p>
                    <p className="subtitle is-6"><strong>{props.t('commons.duration')}: </strong>{secondsToHms(trip.duration)} </p>
                    <p className="subtitle is-6"><strong>{props.t('commons.date')}: </strong> <Timestamp time={trip.timestamp/1000} format="full"/></p>
                  </div>
                </div>

            </OverlayView>
          }

          <Polyline key={index + 'poly'} path={trip.latlngs} options={{strokeColor:trip.color}}/>

          {trip.latlngs.length > 0 &&
            <OverlayView
              key={index + 'start'}
              position={{ lat: trip.latlngs[0].lat, lng: trip.latlngs[0].lng }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              getPixelPositionOffset={getPixelPositionOffset}
              >
              <div className="circleIcon" style={{background:trip.color}}
                   onMouseEnter={() => props.showPopup(trip, trip.latlngs[0])}
                   onMouseLeave={() => props.closePopup(trip)}>
                <p className="iconText">{trip.index}</p>
              </div>
            </OverlayView>
          }

          {trip.latlngs.length > 0 &&
            <Marker
              position={{ lat: trip.latlngs[trip.latlngs.length-1].lat, lng: trip.latlngs[trip.latlngs.length-1].lng }}
              key={index + 'end'}
              icon={racing}
              onMouseOver={() => props.showPopup(trip, trip.latlngs[trip.latlngs.length-1])}
              onMouseOut={() => props.closePopup(trip)}>
            </Marker>
          }

          {trip.alerts && trip.alerts.map((alert, aIndex) => {
            let user = JSON.parse(localStorage.getItem("user"));
            if ((user.role === ROLE_ADMIN) || (user.role !== ROLE_ADMIN && alert.type !== 2 && alert.type !== 310)) {
              return <Marker
                position={{ lat: alert.position.lat, lng: alert.position.lng }}
                key={index + 'alert' + aIndex}
                icon={getAlertIcon(alert.type)}
                onMouseOver={() => props.showAlertPopup(alert)}
                onMouseOut={() => props.closeAlertPopup(alert)}>
              </Marker>
            }
            return <div></div>;
          })}

          {props.alertTargeted &&
            <OverlayView
              key={index + 'b'}
              position={{ lat: props.alertTargeted.position.lat, lng: props.alertTargeted.position.lng }}
              mapPaneName={OverlayView.FLOAT_PANE}
              getPixelPositionOffset={getPixelPositionOffset2}>

                <div className="box" style={{minWidth:'250px', 'zIndex':1000000000000}}>
                  <div className="content">
                    <p className="title is-6"><strong><AlertTitle type={props.alertTargeted.type}/></strong></p>
                    <hr/>
                    <p className="subtitle is-6"><strong>{props.t('commons.date')}: </strong> <Timestamp time={props.alertTargeted.ts/1000} format="full"/></p>

                    {(isPhoneCall(props.alertTargeted.type) || isPhoneUse(props.alertTargeted.type)) &&
                      <p className="subtitle is-6">
                        <strong>{props.t('tripPathMap.speed-1')}: </strong>{props.t('tripPathMap.speed-2', {count: props.alertTargeted.speed})}<br/>
                        <strong>{props.t('commons.duration')}: </strong>{secondsToHms(props.alertTargeted.duration/1000000000)}<br/><br/>
                        {props.t('tripPathMap.youHaveDrive-1')} <b>{props.t('tripPathMap.youHaveDrive-2', {count: Math.round(((props.alertTargeted.speed*1000)/3600)*(props.alertTargeted.duration/1000000000))})}</b> {props.t('tripPathMap.youHaveDrive-3')}
                      </p>
                    }

                    {isNoPhoneCall(props.alertTargeted.type) &&
                      <p className="subtitle is-6">
                        <strong>{props.t('tripPathMap.speed-1')}: </strong>{props.t('tripPathMap.speed-2', {count: props.alertTargeted.speed})}<br/>
                        <b>{props.t('tripPathMap.congrats')}</b>
                      </p>
                    }

                    {isSpeed(props.alertTargeted.type) &&
                      <p className="subtitle is-6">
                        <strong>{props.t('tripPathMap.speed-1')}: </strong>{props.t('tripPathMap.speed-2', {count: props.alertTargeted.speed})}<br/>
                        <strong>{props.t('tripPathMap.maxSpeed')}: </strong>{props.t('tripPathMap.speed-2', {count: props.alertTargeted.speed_limit_kmh})}<br/>
                        <strong>{props.t('commons.position')}: </strong>{props.alertTargeted.location_str}<br/>
                        <strong>{props.t('commons.duration')}: </strong>{secondsToHms(props.alertTargeted.duration/1000000000)}
                      </p>
                    }
                  </div>
                </div>

            </OverlayView>
          }

        </div>

      ))}

  </GoogleMap>
)));


class TripPathMap extends Component {

  state = {
    center: { lat: -34.603722, lng: -58.381592, id:1 },
    centered: false,
    refPoints:[],
    targeted: null
  };

  showAlertInfo = (alert) => {
    this.setState({alertTargeted: alert});
  };

  hideAlertInfo = (trip) => {
    this.setState({alertTargeted: null});
  };

  showInfo = (trip, position) => {
    trip.popup = position;
    this.setState({targeted: trip});
  };

  hideInfo = (trip) => {
    this.setState({targeted: null});
  };

  autoZoomMap = (points) => {
    if (!this._map || !points || points.length === 0) return;

    var bound = new google.maps.LatLngBounds();
    let count = 0
    for (let i of points) {
      bound.extend(new google.maps.LatLng(i.lat, i.lng));
      count++
    }

    this._map.fitBounds(bound)
  };

  handleMapMounted = (map) => {
    this._map = map;
    this.autoZoomMap(this.refPoints)
  };

  refPoints = [];

  autoZoomMap = this.autoZoomMap.bind(this);
  handleMapMounted = this.handleMapMounted.bind(this);
  showInfo = this.showInfo.bind(this);
  hideInfo = this.hideInfo.bind(this);

  render() {
    const { driverTrips, tripsLoading, enableMessage, driverMapColor, t } = this.props;

    this.refPoints = [];
    var tripsMap = {};

    driverTrips.map((trip, index )=>{
      if (tripsMap[trip.userId] === undefined){
        tripsMap[trip.userId] = [trip]
      } else {
        tripsMap[trip.userId] = [...tripsMap[trip.userId], trip];
      }

      var latlngs = [];

      if (typeof trip.polylines !== "undefined" && trip.polylines.length > 0) {
        trip.polylines.map(chunk => latlngs = [...latlngs, ...decodePath(chunk, 5)])
      }

      if (latlngs.length === 0) {
        latlngs = [...latlngs, { "lat" :trip.last_location.lat, "lng" : trip.last_location.lon}];
      }

      trip.latlngs = [...latlngs];
      trip.color = driverMapColor? driverMapColor[trip.userId]: '#000';

      if (latlngs.length > 0) {
        // save first and last point to center map
        // this.refPoints = [...this.refPoints, latlngs[0], latlngs[latlngs.length-1]]

        // use all the points availables to autozoom
        this.refPoints = [...this.refPoints, ...latlngs];
      }
    });

    for (var key in tripsMap) {
      tripsMap[key].sort((a,b)=>{
        if (a.timestamp < b.timestamp) return -1;
        if (a.timestamp > b.timestamp) return 1;
        return 0
      });

      tripsMap[key].map((trip, index)=> {
        trip.index = index + 1
      });
    }

    if (tripsLoading) {
      this.centered = false;
    }

    if (_.isEmpty(this.state.targeted) && !this.centered && !tripsLoading && this.refPoints.length > 0) {
      this.autoZoomMap(this.refPoints);
      this.centered = true;
    }

    return (
      <div>
        <PolylineMapTag
          onMapMounted={this.handleMapMounted}
          containerElement={
            <div style={{
                position: 'absolute',
                top: this.props.mapTopPosition !== undefined ? this.props.mapTopPosition:50,
                left: 0,
                right: 0,
                bottom: this.props.mapBottomPosition !== undefined ? this.props.mapBottomPosition:50,
                justifyContent: 'flex-end',
                alignItems: 'center'
            }} />
          }
          mapElement={<div style={{ height: `100%`, width:  `100%`}} />}
          trips={driverTrips}
          showPopup={this.showInfo}
          closePopup={this.hideInfo}
          showAlertPopup={this.showAlertInfo}
          closeAlertPopup={this.hideAlertInfo}
          center={this.state.center}
          targeted={this.state.targeted}
          alertTargeted={this.state.alertTargeted}/>

      { this.props.customMessage !== undefined && this.props.customMessage.includes('STOP-GPS-OFF') &&
        <div className="notification is-warning" style={{'marginTop': '2rem'}}>
          <p className="modal-card-title">{t('tripPathMap.gpsOff')}</p>
        </div>
      }

      { enableMessage && driverTrips.length === 0 &&
        <div className="notification is-warning" style={{'marginTop': '2rem'}}>
          { this.props.noDataMessage || <p>{t('tripPathMap.noDataMessage-1')}<strong>{t('tripPathMap.noDataMessage-2')}</strong></p> }
        </div>
      }
      </div>
    );
  }
}

TripPathMap.propTypes = {
  driverTrips: PropTypes.array.isRequired,
  tripsLoading: PropTypes.bool.isRequired,
  enableMessage: PropTypes.bool.isRequired,
  driverMapColor: PropTypes.object
};

export default withTranslation('translation')(TripPathMap);
