import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import emptyCar from '../../images/car-avatar.png'
import plusIcon from '../../images/plus.png'
import _ from 'lodash';
import EditVehicleModal from "./EditVehicleModal";
import {withRouter} from "react-router";

const DeleteVehicleModal = withTranslation('translation')(props => (

  <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">{props.t('vehiclesTable.delete.tittle')}</p>
      </header>
      <section className="modal-card-body">
        {props.t('vehiclesTable.delete.body') + props.vehicle.plate}
      </section>
      <footer className="modal-card-foot">
        <div className="buttons">
          <button className="button is-danger" onClick={() => props.onClose(true, props.vehicle)}>{props.t('commons.delete')}</button>
          <button className="button" onClick={() => props.onClose(false, props.vehicle)}>{props.t('commons.cancel')}</button>
        </div>
      </footer>
    </div>
  </div>
));

const EMPTY_VEHICLE = {
  id: 0,
  photo_url: "",
  name: "",
  make: undefined,
  model: undefined,
  year: "",
  vin: "",
  plate: "",
  state: "",
  vehicle_type: 1,
  drivers: [],
  odometer: ""
};

class VehiclesTable extends Component {

  state = {
    showDeleteModal: false,
    showEditModal: false,
    currentVehicle: null
  };

  onClickDelete = (vehicle) => {
    this.setState({currentVehicle: _.cloneDeep(vehicle), showDeleteModal: true});
  };

  onClickEdit = (vehicle) => {
    this.setState({currentVehicle: _.cloneDeep(vehicle), showEditModal: true});
  };

  onClickCreateNew = () => {
    this.setState({currentVehicle: _.cloneDeep(EMPTY_VEHICLE), showEditModal: true});
  };

  onCloseDeleteModal = (shouldDelete, vehicle) => {
    let { fleetDetail, vehicles } = this.props;

    if (shouldDelete) {
      // remove all vehicle if exist
      _.remove(vehicles, v => v.id === vehicle.id);
      // server call
      this.props.deleteVehicle(fleetDetail.id, vehicle);
    }

    this.setState({showDeleteModal: false, vehicles});
  };

  onCloseEditModal = (shouldEdit, vehicle) => {
    let { fleetDetail, vehicles } = this.props;
    if (shouldEdit) {
      // convert miles to kms if needs
      // miles will be converted to kms if locale is en-us. @SEE /i18n.js
      let stringValue = this.props.t('commons.interpolation.toKms', {count: vehicle.odometer});
      vehicle.odometer = parseInt(stringValue);

      // replace edited vehicle
      if (vehicle.id !== 0) {
        _.remove(vehicles, v => v.id === vehicle.id);
      }
      vehicles.unshift(vehicle);
      // server call
      this.props.saveVehicle(fleetDetail.id, vehicle);
    }

    this.setState({showEditModal: false, vehicles});
  };

  onClickDetail = (vehicle) => {
    let { fleetDetail } = this.props;
    this.props.history.push(`/fleet/${fleetDetail.id}/vehicle/${vehicle.id}`);
  };

  render() {
    let { vehicles } = this.props;
    const { fleetDetail, t, selected } = this.props;
    const { showEditModal, showDeleteModal, currentVehicle } = this.state;

    vehicles = _.filter(vehicles, (vehicle) => (!vehicle.deleted));

    return (
      <div>

        {showDeleteModal &&
          <DeleteVehicleModal onClose={this.onCloseDeleteModal} vehicle={currentVehicle}/>
        }

        {showEditModal &&
          <EditVehicleModal onClose={this.onCloseEditModal} vehicle={currentVehicle} fleeDrivers={fleetDetail.drivers} />
        }

        <div className="vehicles-container">

          {vehicles.map(((vehicle, index) => (

            <div className="card vechicle-card" key={'vehicle' + vehicle.id}>

              <div className="card-content">

                <div className="columns is-vcentered">

                  <div className="column is-1">
                    <figure className="image is-64x64">
                      <img alt={''} src={vehicle.photo_url} ref={img => this['img' + index] = img} onError={()=>{this['img' + index].src = emptyCar}}/>
                    </figure>
                  </div>

                  <div className="column">
                      <div className="columns is-vcentered has-text-centered">
                        <div className="column is-1">
                          {vehicle.driving ?
                            <span className="tag is-primary">{t('vehiclesTable.driving')}</span> :
                            <span className="tag is-link">{t('vehiclesTable.notDriving')}</span>
                          }
                        </div>
                        <div className="column is-2">
                          <strong> {vehicle.name}</strong>
                        </div>
                        <div className="column is-1">
                          {t('vehiclesTable.distance', {count: vehicle.odometer})}
                        </div>
                        <div className="column is-1">
                          {t('vehiclesTable.vehicleType.' + vehicle.vehicle_type)}
                        </div>
                        <div className="column is-3">
                          {vehicle.make ? vehicle.make.name:""} {vehicle.model ? vehicle.model.name:""} {vehicle.year}
                          <br/>
                          ({vehicle.plate})
                        </div>

                        <div className="column is-4">
                          {vehicle.drivers.length === 0 &&
                            <span className="tag is-warning">{t('vehiclesTable.withoutDrivers')}</span>
                          }
                          {vehicle.drivers.length > 0 &&
                            <div className="field is-grouped is-grouped-multiline">
                              {vehicle.drivers.map(((driver) => (
                                <div className="control" key={driver.uid}>
                                  <span className="tag is-light">{driver.name}</span>
                                </div>
                              )))}
                            </div>
                          }
                        </div>

                      </div>
                  </div>

                  <div className="column column is-2">
                    <div className="field is-grouped">
                      {selected && selected.show_expense_management &&
                      <button className="button is-primary is-outlined margin-right-5x" disabled={vehicle.id === 0}
                         onClick={() => this.onClickDetail(vehicle)}><i className="fa fa-line-chart"></i></button>
                      }
                      <button className="button is-primary is-outlined margin-right-5x" disabled={vehicle.id === 0}
                         onClick={() => {this.onClickEdit(vehicle)}}><i className="fa fa-pencil"></i></button>
                      <button className="button is-primary is-outlined" disabled={vehicle.id === 0}
                         onClick={() => {this.onClickDelete(vehicle)}}><i className="fa fa-times"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )))}
        </div>

        <a className="fab button is-success is-pulled-right" onClick={() => {this.onClickCreateNew()}}><img src={plusIcon}/></a>

      </div>
    );
  }
}

export default withRouter(withTranslation('translation')(VehiclesTable));
