
import { connect } from 'react-redux'

import FleetReports from '../pages/FleetReports'

// These props come from the application's
// state when it is started
function mapStateToProps(state) {

  const { selected } = state.fleet;

  return {
    selected
  }
}

export default connect(mapStateToProps)(FleetReports)