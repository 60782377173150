import {connect} from 'react-redux'
import {
  answerRequest,
  getFleetInvites,
  inviteDriverByEmail,
  removeDriver,
  removeInvite,
  updateFleet
} from '../redux/fleetSettings'
import FleetSettings from '../pages/FleetSettings'

// These props come from the application's
// state when it is started
function mapStateToProps(state) {

  const { selected, fleetDetail, loading } = state.fleet;
  const { updatingFleet, invites, requests, invitesLoading } = state.fleetSettings;

  return {
    selected,
    fleetDetail,
    loading,
    updatingFleet,
    invites,
    requests,
    invitesLoading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateFleet : (fleetId, values) => {
      dispatch(updateFleet(fleetId, values))
    },
    getFleetInvites : (fleetId) => {
      dispatch(getFleetInvites(fleetId))
    },
    inviteDriverByEmail: (fleetId, email) => {
      dispatch(inviteDriverByEmail(fleetId, email))
    },
    removeInvite: (fleetId, email) => {
      dispatch(removeInvite(fleetId, email))
    },
    removeDriver: (fleetId, uid) => {
      dispatch(removeDriver(fleetId, uid))
    },
    answerRequest: (fleetId, uid, action) => {
      dispatch(answerRequest(fleetId, uid, action))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FleetSettings)