import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Redirect } from 'react-router'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import Loading from '../components/Loading'
import FleetDetailSettings from "../components/fleet/FleetDetailSettings";
import DriverTable from "../components/driver/DriverTable";
import ChiefDriverTable from "../components/driver/ChiefDriverTable";
import InvitesTable from "../components/fleet/InvitesTable";
import JoinRequestTable from "../components/fleet/JoinRequestTable";
import {withTranslation} from "react-i18next";
import _ from "lodash";


class FleetSettings extends Component {

  componentDidMount = () => {
    const { selected } = this.props;
    if (selected) {
      this.props.getFleetInvites(selected.id);
    }
  };

  onAcceptRequest  = (uid) => {
    const { selected } = this.props;
    this.props.answerRequest(selected.id, uid, 'accept');
  };

  onRejectRequest  = (uid) => {
    const { selected } = this.props;
    this.props.answerRequest(selected.id, uid, 'reject');
  };

  onRemoveInvite  = (email) => {
    const { selected } = this.props;
    this.props.removeInvite(selected.id, email);
  };

  onRemoveDriver  = (uid) => {
    const { selected } = this.props;
    this.props.removeDriver(selected.id, uid);
  };

  onClickInvite  = () => {
    const { selected } = this.props;
    const email = this.refs.email.value.trim();
    if (email.length > 0){
      this.props.inviteDriverByEmail(selected.id, email);
      this.refs.email.value = "";
    }
  };

  onClickInviteLink = (link) => {
    return this.props.t("fleetSettings.joinFleet.clipboardMessage") + link
  }

  notify = () => {
    toast.info(this.props.t("fleetSettings.joinFleet.copyToClipboard"));
  };

  render() {

    const { loading, selected, fleetDetail, updatingFleet, invites, requests, invitesLoading, t } = this.props;

    if (_.isEmpty(selected)) {
      return <Redirect to={{ pathname: '/' }}/>; 
    }

    if (loading) {
      return (<Loading/>)
    }

    const clipboardMessage = this.onClickInviteLink(selected.publicInviteLink)

    return (
      <div className="is-fullwidth">
        <ToastContainer autoClose={3000}/>
        <div className="columns">
          <div className="column">
            <FleetDetailSettings selected={selected} fleetDetail={fleetDetail} updatingFleet={updatingFleet}
                                 updateFleet={this.props.updateFleet}/>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">{t('fleetSettings.invites')}</p>
              </header>
              <div className="card-content">
                <p className="subtitle"><strong>1. </strong>{t('fleetSettings.authDriver.title')}</p>
                <p><strong>a. </strong>{t('fleetSettings.authDriver.1')}<strong> {t('fleetSettings.authDriver.1-1')}</strong></p>
                <div className="columns">
                  <div className="column">
                    <input className="input" type="email" ref="email" placeholder="Email del conductor a agregar"/>
                  </div>
                  <div className="column">
                    <button className="button is-primary"
                      onClick={this.onClickInvite}>
                      {t('fleetSettings.authDriver.add')}
                    </button>
                  </div>
                </div>
                <p><strong>b. </strong>{t('fleetSettings.authDriver.2')} <strong>{selected.privateInviteLink}</strong></p>
                <br />
                <p className="subtitle"><strong>2. </strong>{t('fleetSettings.joinFleet.title')}</p>
                <div className="columns is-vcentered">
                  <div className="column is-narrow">
                    <p><strong>a. </strong>{t('fleetSettings.joinFleet.1')} <strong>{selected.publicInviteLink}</strong></p>
                  </div>
                  <div className="column">
                  <CopyToClipboard text={clipboardMessage}>
                    <button className="button is-primary"
                      onClick={this.notify}>
                      {t('commons.copy')}
                    </button>
                  </CopyToClipboard>
                  </div>
                </div>
                <p><strong>b. </strong>{t('fleetSettings.joinFleet.2')}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">{t('fleetSettings.fleetsBoss')}</p>
              </header>
              <div className="card-content">
                <ChiefDriverTable drivers={fleetDetail.bossList}/>
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">{t('fleetSettings.drivers')}</p>
              </header>
              <div className="card-content">
                  <DriverTable fleetDetail={fleetDetail} drivers={fleetDetail? fleetDetail.drivers : []} onRemoveDriver={this.onRemoveDriver}/>
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">{t('fleetSettings.pendingInvites')}</p>
              </header>
              <div className="card-content">
                <InvitesTable invites={invites} onRemoveInvite={this.onRemoveInvite}/>
              </div>
            </div>
          </div>
        </div>

        <div className="columns no-margin-bottom">
          <div className="column">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">{t('fleetSettings.joinRequests')}</p>
              </header>
              <div className="card-content">
                <JoinRequestTable requests={requests}
                                  onAcceptRequest={this.onAcceptRequest}
                                  onRejectRequest={this.onRejectRequest}/>
              </div>
            </div>
          </div>
        </div>

        {invitesLoading && <Loading/>}
      </div>

    );
  }
}

FleetSettings.propTypes = {
  fleetDetail: PropTypes.object.isRequired,
  selected: PropTypes.object,
  updatingFleet: PropTypes.bool.isRequired,
  updateFleet: PropTypes.func.isRequired,
  getFleetInvites: PropTypes.func.isRequired,
  invites: PropTypes.array.isRequired,
  requests: PropTypes.array.isRequired,
  inviteDriverByEmail: PropTypes.func.isRequired,
  removeInvite: PropTypes.func.isRequired,
  removeDriver: PropTypes.func.isRequired,
  invitesLoading: PropTypes.bool.isRequired,
  answerRequest: PropTypes.func.isRequired
};

export default withTranslation('translation')(FleetSettings);
