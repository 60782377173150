import React, {Component} from "react";
import _ from "lodash";
import {withTranslation} from "react-i18next";
import DriverSuggestionContainer from "../../containers/DriverSuggestionContainer";
import MakesSelector from "./MakesSelector"
import ModelSelector from "./ModelSelector"
import classNames from "classnames";

const VEHICLE_TYPE = [
  {value: 20, textKey: "vehiclesTable.vehicleType.20"},
  {value: 21, textKey: "vehiclesTable.vehicleType.21"},
  {value: 1, textKey: "vehiclesTable.vehicleType.1"},
  {value: 2, textKey: "vehiclesTable.vehicleType.2"},
  {value: 3, textKey: "vehiclesTable.vehicleType.3"},
  {value: 4, textKey: "vehiclesTable.vehicleType.4"},
  {value: 5, textKey: "vehiclesTable.vehicleType.5"},
  {value: 6, textKey: "vehiclesTable.vehicleType.6"},
  {value: 7, textKey: "vehiclesTable.vehicleType.7"},
  {value: 8, textKey: "vehiclesTable.vehicleType.8"},
  {value: 9, textKey: "vehiclesTable.vehicleType.9"}
];


class EditVehicleModal extends Component {

  state = {
    fleeDrivers: [],
    suggestions: [],
    suggestedInputValue: "",
    currentVehicle: {
      drivers: []
    },
    isIncomplete: true,
    wasValidated: false
  };

  componentWillMount() {
    const { vehicle } = this.props;
    let isIncomplete = this.isFormIncomplete(vehicle);
    this.setState({ currentVehicle: vehicle, isIncomplete });
  }

  onSuggestionSelected = (event, suggestion) => {
    if (suggestion) {
      const { currentVehicle } = this.state;
      currentVehicle.drivers.push(suggestion);
      this.setState({ currentVehicle });
    }
  };

  onRemoveDriver = (removedDriver) => {
    const { currentVehicle } = this.state;
    _.remove(currentVehicle.drivers, d => d.email === removedDriver.email);
    this.setState({ currentVehicle });
  };

  onChangeInput = ev => {
    let { currentVehicle, isIncomplete } = this.state;

    if (ev.target.type === 'number' || ev.target.name === 'vehicle_type' || ev.target.name === 'year') {
      currentVehicle[ev.target.name] = parseInt(ev.target.value);
    } else {
      currentVehicle[ev.target.name] = ev.target.value;
    }

    isIncomplete = this.isFormIncomplete(currentVehicle);
    this.setState({ currentVehicle, isIncomplete });
  };

  onMakeSelected = make => {
    let { currentVehicle, isIncomplete } = this.state;

    currentVehicle.make = make;

    isIncomplete = this.isFormIncomplete(currentVehicle);
    this.setState({ currentVehicle, isIncomplete });
  };

  onModelSelected = model => {
    let { currentVehicle, isIncomplete } = this.state;

    currentVehicle.model = model;

    isIncomplete = this.isFormIncomplete(currentVehicle);
    this.setState({ currentVehicle, isIncomplete });
  };

  isFormIncomplete(currentVehicle) {
    return !currentVehicle.name || !currentVehicle.make || !currentVehicle.model ||
      !currentVehicle.vin || !currentVehicle.plate || !currentVehicle.odometer ||
      !currentVehicle.year;
  }

  onSave = () => {
    const { currentVehicle, isIncomplete } = this.state;
    const { onClose } = this.props;
    if (isIncomplete) {
      this.setState({wasValidated: true})
    } else {
      onClose(true, currentVehicle);
    }
  };

  getValidationClassName = (isValid) => {
    const { wasValidated } = this.state;

    if (isValid) {
      return 'is-success';
    } else if (wasValidated) {
      return 'is-danger';
    } else {
      return '';
    }
  };

  render() {
    const { onClose, t } = this.props;
    const { currentVehicle } = this.state;

    return (
      <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            {currentVehicle.id ?
              <p className="modal-card-title">{t('vehiclesTable.edit.title') + currentVehicle.plate}</p> :
              <p className="modal-card-title">{t('vehiclesTable.edit.isNewTitle')}</p>
            }
          </header>

          <section className="modal-card-body has-text-left">
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label">{t('vehiclesTable.edit.name')} *</label>
                  <div className="control">
                    <input className={classNames('input', this.getValidationClassName(currentVehicle.name !== ""))}
                           type="text" value={currentVehicle.name} name="name" onChange={this.onChangeInput}/>
                  </div>
                </div>
              </div>

              <div className="column is-one-third">
                <div className="field">
                  <label className="label">{t('vehiclesTable.edit.vin')} *</label>
                  <div className="control">
                    <input className={classNames('input', this.getValidationClassName(currentVehicle.vin !== ""))}
                           type="text" value={currentVehicle.vin ? currentVehicle.vin : ''} name="vin" onChange={this.onChangeInput}/>
                  </div>
                </div>
              </div>
            </div>

            <div className="field">
              <label className="label">{t('vehiclesTable.edit.drivers')}</label>

              <div className="control">
                <DriverSuggestionContainer selected={currentVehicle.drivers} onSelected={this.onSuggestionSelected} cleanOnSelected={true}/>
              </div>

              <div className="vehicle-drivers-container">
                <div className="field is-grouped is-grouped-multiline">
                  {currentVehicle.drivers.map(((driver, index) => (
                    <div className="control" key={index + "-driver-div"}>
                      <div className="tags has-addons">
                        <span className="tag is-light">{driver.name}</span>
                        <a className="tag is-delete is-danger" onClick={() => this.onRemoveDriver(driver)}></a>
                      </div>
                    </div>
                  )))}
                </div>
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label">{t('vehiclesTable.edit.odometer')} *</label>
                  <div className="control">
                    <input className={classNames('input', this.getValidationClassName(!isNaN(parseInt(currentVehicle.odometer))))}
                           type="number" value={currentVehicle.odometer} name="odometer"
                           onChange={this.onChangeInput} pattern="[0-9]" min={0}/>
                  </div>
                </div>
              </div>

              <div className="column">
                <div className="field">
                  <label className="label">{t('vehiclesTable.edit.plate')} *</label>
                  <div className="control">

                    <input className={classNames('input', this.getValidationClassName(currentVehicle.plate !== ""))}
                           type="text" value={currentVehicle.plate ? currentVehicle.plate : ''} name="plate" onChange={this.onChangeInput}/>
                  </div>
                </div>
              </div>

              <div className="column">
                <div className="field">
                  <label className="label">{t('vehiclesTable.edit.type')} *</label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select value={currentVehicle.vehicle_type} name="vehicle_type" onChange={this.onChangeInput}>
                        {
                          VEHICLE_TYPE.map(((type) => (
                            <option key={'vehicle-type-' + type.value} value={type.value}>{t(type.textKey)}</option>
                          )))
                        }
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="columns">
              <div className="column  is-one-third  ">
                <div className="field">
                  <label className="label">{t('vehiclesTable.edit.make')}</label>
                  <MakesSelector makeSelected={currentVehicle.make}
                                 className={this.getValidationClassName(currentVehicle.make !== undefined)}
                                 onMakeSelected={this.onMakeSelected} />
                </div>
              </div>

              <div className="column is-two-fifths">
                <div className="field">
                  <label className="label">{t('vehiclesTable.edit.model')}</label>
                  <ModelSelector makeSelected={currentVehicle.make} modelSelected={currentVehicle.model}
                                 className={this.getValidationClassName(currentVehicle.model !== undefined)}
                                 onModelSelected={this.onModelSelected} />
                </div>
              </div>

              <div className="column is-one-fifth">
                <div className="field">
                  <label className="label">{t('vehiclesTable.edit.year')} *</label>
                  <div className="control">
                    <input className={classNames('input', this.getValidationClassName(!isNaN(parseInt(currentVehicle.year))))}
                           type="number" value={currentVehicle.year} min={1980}
                           name="year" onChange={this.onChangeInput} pattern="[0-9]"/>
                  </div>
                </div>
              </div>
            </div>

          </section>

          <footer className="modal-card-foot">
            <div className="buttons">
              <button className="button is-primary" onClick={() => this.onSave()}>{t('commons.save')}</button>
              <button className="button" onClick={() => onClose(false, currentVehicle)}>{t('commons.cancel')}</button>
            </div>
          </footer>
        </div>
      </div>
    )
  }
}

export default withTranslation('translation')(EditVehicleModal);