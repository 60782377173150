import {checkStatus, parseJson} from '../api/utils'
import {BASE_URL} from '../config'
import _ from "lodash";

const SET_POLYGON_MARKERS = 'woo/roadMaps/SET_POLYGON_MARKERS';
const SAVE_POLYGON_MARKER_REQUEST = 'woo/roadMaps/SAVE_POLYGON_MARKER_REQUEST';
const SAVE_POLYGON_MARKER_REQUEST_SUCCESS = 'woo/roadMaps/SAVE_POLYGON_MARKER_REQUEST_SUCCESS';

const initialState = {
    polygonMarkers: null,
    polygonMarkersLoading: false
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
      case SET_POLYGON_MARKERS:
        const filteredMarkers = action.polygonMarkers.filter(so => so.deleted === false)
        return {
          ...state,
          polygonMarkers: filteredMarkers
        };
      case SAVE_POLYGON_MARKER_REQUEST:
        return {
          ...state,
          polygonMarkersLoading: true
        };
      case SAVE_POLYGON_MARKER_REQUEST_SUCCESS:
        const polygonMarkersWithoutNewOneWithBadIndex = state.polygonMarkers.filter(pm => pm.id !== 0)
        const newPolygonMarkerWithProperIndex = action.polygonMarker
        return {
          ...state,
          polygonMarkers: [
            ...polygonMarkersWithoutNewOneWithBadIndex,
            newPolygonMarkerWithProperIndex
          ],
          polygonMarkersLoading: false
        };
      default:
        return state;
    }
}

// action creators

const setPolygonMarkers = (polygonMarkers) => ({ type: SET_POLYGON_MARKERS, polygonMarkers });

const savePolygonMarkerRequested = () => {
    return {
      type: SAVE_POLYGON_MARKER_REQUEST
    }
};

export const savePolygonMarkersRequestSuccess = (polygonMarker) => {
    return {
      type: SAVE_POLYGON_MARKER_REQUEST_SUCCESS,
      polygonMarker
    }
};

export const fetchPolygonMarkers = (fleetId) => {

    return dispatch => {
  
      let token = localStorage.getItem("token");
      let url = `${BASE_URL}/api/fleet/${fleetId}/polygon_marker`;
  
      return fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .then(checkStatus)
      .then(parseJson)
      .then((json) => {
        dispatch(setPolygonMarkers(json))
      });
  
    }
};

function getPolygonMarker(marker) {
    return {
      "name": marker.name,
      "points": marker.points,
      "description": marker.description,
      "url_photo": "",
      "marker_type": marker.marker_type
    }
}

export const createPolygonMarker = (fleetId, marker) => {
    return dispatch => {
      dispatch(savePolygonMarkerRequested());
      let token = localStorage.getItem("token");
      const url = BASE_URL + '/api/fleet/' + fleetId + '/polygon_marker' ;
  
      return fetch(url, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(getPolygonMarker(marker)),
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .then(checkStatus)
      .then(parseJson)
      .then((json) => {
        dispatch(savePolygonMarkersRequestSuccess(json));
      }).catch(error =>{
        console.log("error", error)
      })
    }
};

export const updatePolygonMarker = (fleetId, polygonMarker) => {
    return dispatch => {
      let token = localStorage.getItem("token");
      const url = BASE_URL + '/api/fleet/' + fleetId + '/polygon_marker/' + polygonMarker.id;
  
      return fetch(url, {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify(getPolygonMarker(polygonMarker)),
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .then(checkStatus).then((json) => {
      }).catch(error =>{
        console.log("error", error)
      })
    }
};

export const deletePolygonMarker = (fleetId, polygonMarker) => {
    return dispatch => {
      let token = localStorage.getItem("token");
      const url = BASE_URL + '/api/fleet/' + fleetId + '/polygon_marker/' + polygonMarker.id ;
  
      return fetch(url, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .then(checkStatus)
      .then(parseJson)
      .then((json) => {
      }).catch(error =>{
      })
    }
};