import React, {Component} from 'react';
import Loading from '../components/Loading'
import {withTranslation} from "react-i18next";
import CardsTable from "../containers/crt/CardsTableContainer";

const DAILY= 'Daily';
const WEEKLY = 'Weekly';
const MONTHLY = 'Monthly';

class ExpensesConfig extends Component {

  state = {
    driverSelected: null,
    creditCardSelected: null,
    saytInput:""
  };

  componentWillMount() {
    // this.reloadFleet();
    this.lastFleetId = 0;
  }

  reloadFleet = () => {
    this.props.fetchCreditCards(this.props.fleetDetail.id);
    this.props.getExpensesAlertsConfiguration(this.props.fleetDetail.id);
  };

  onCreditCardSelected = (event, creditCard) => {
    // show add alert popup
    this.setState({creditCardSelected: creditCard});
  };

  handleSelect = (range) => {
    this.setState({dateRangeSelected: range});
  };

  getFilteredCards() {
    const {creditCards} = this.props;
    const {saytInput} = this.state;

    let result = creditCards;

    if (saytInput.length < 3) {
      return result;
    }

    return result.filter(c => {
      return (c.holder + c.number).toLocaleLowerCase().includes(saytInput.toLocaleLowerCase());
    });
  }

  updateFleetAlert = () => {
    const { fleetDetail } = this.props;
    const { editAlertIndex, inputValue} = this.state;
    const expenses =  this.getAlertsConfig();
    const alert = expenses[editAlertIndex];

    this.props.updateFleetExpenseAlertConfig(fleetDetail.id, alert.type, inputValue);

    this.setState({editAlert:false, editAlertIndex:0, card:null})
  };

  updateInputValue = evt => {
    this.setState({
      inputValue: evt.target.value
    });
  };

  editCardAlert = (alert, typeIndex) => {
    this.setState({editAlert:true, editAlertIndex: typeIndex, inputValue: alert.amount})
  };

  getAlertsConfig = () => {
    const { expensesAlertsConfig } = this.props;

    const expenses = [
        { type: DAILY, amount: 0},
        { type: WEEKLY, amount: 0},
        { type: MONTHLY, amount: 0},
      ];

    if (expensesAlertsConfig.hasOwnProperty("fleet") && expensesAlertsConfig.fleet.length > 0) {
      for (let expense of expensesAlertsConfig.fleet) {
        for (let e of expenses) {
          if (expense.type === e.type) {
            e.amount = expense.amount
          }
        }
      }
    }

    return expenses
  };

  changeAlertPopup = () => {
    const {selected, t} = this.props;
    const { editAlert, editAlertIndex } = this.state;

    const expenses =  this.getAlertsConfig()

    if (editAlert) {
      const alert = expenses[editAlertIndex]
      return (
        <div className={editAlert ? "modal is-active" : "modal"}>
            <div className="modal-background"/>
            <div className="modal-content">
              <div className="box">
                <div className="field">
                  <label className="label">{selected.name}</label>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label">
                    <label className="label">{t('expensesConfig.alert.limit.' + alert.type)}</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input className="input" type="number" value={this.state.inputValue} onChange={this.updateInputValue}/>
                      </div>

                    </div>

                  </div>

                </div>

                <div className="buttons is-right">
                  <div className="button is-danger is-outlined"
                       onClick={() => this.setState({editAlert: false, card:null, editAlertIndex:0, inputValue:0})}>
                    <span>Cancelar</span>
                    <span className="icon is-small">
                      <i className="fa fa-times"/>
                    </span>
                  </div>
                  <div className="button is-primary"
                       onClick={() => this.updateFleetAlert()}>
                    <span>Aceptar</span>
                    <span className="icon is-small">
                      <i className="fa fa-check"/>
                    </span>
                  </div>
                </div>

              </div>
            </div>

        </div>
      )
    }
    return
  }

  render() {
    const { creditCards, t, fleetDetail, } = this.props;
    const { editAlert } = this.state;


    // if fleetDetail is null or is not the same that was showed latest
    if (!fleetDetail || (fleetDetail.id !== this.lastFleetId)) {
      this.lastFleetId = fleetDetail.id;
      this.reloadFleet();
      return (<Loading wait={0}/>)
    }

    const fleetAlertsConfig =  this.getAlertsConfig()

    return (
      <div className="is-fullwidth">
        {!creditCards && <Loading wait={0}/>}

        { editAlert && this.changeAlertPopup() }

        <div className="columns">
          <div className="column">
            <div className="card">

              <div className="card-content">
                <div className="columns">

                  <div className="column is-one-third">
                    <strong>{t('expensesConfig.alert.title')}</strong>
                  </div>
                  {fleetAlertsConfig.map(((c, index) => (
                  <div className="column" key={"fleetAlertsConfig" + index}>
                    <div className="level no-margin-bottom" style={{marginBotton:"0px"}}>
                      <div className="level-left">
                        <p><strong>{t('expensesConfig.alert.'+ c.type)}</strong> { c.amount > 0 ? " $ " + c.amount: "sin configurar"}</p>
                        <p>
                          <span className="icon" onClick={() => this.editCardAlert(c, index)}>
                            <i className="fa fa-edit" />
                          </span>
                        </p>
                      </div>

                    </div>
                  </div>
                  )))}
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="columns">

          <div className="column">
            <div className="field">
              <div className="control">
                <input className="input" type="text" placeholder={t('cards.sayt')}
                       onChange={(e) => this.setState({saytInput: e.target.value})} />
              </div>
            </div>
          </div>

        </div>
        <div className="columns">

          <div className="column">
            <CardsTable creditCards={this.getFilteredCards()} onCreditCardSelected={this.onCreditCardSelected} />
          </div>

        </div>
      </div>
    );
  }
}

export default withTranslation('translation')(ExpensesConfig);