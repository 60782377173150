import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import plusIcon from '../../images/plus.png'
import _ from 'lodash';
import {withRouter} from "react-router";
import {CRT_CLOSED, CRT_DELETED, CRT_OPEN} from "../../pages/Crts";
import Timestamp from "react-timestamp";
import EditCrtModal from "./EditCrtModal";
import moment from "moment";
import CrtStatus from "./CrtStatus"

const CrtModal = withTranslation('translation')(props => (

  <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">{props.t(`${props.localePrefix}.title`)}</p>
      </header>
      <section className="modal-card-body">
        {props.t(`${props.localePrefix}.body`) + props.crt.crt_id}
      </section>
      <footer className="modal-card-foot">
        <div className="buttons">
          <button className="button is-danger" onClick={() => props.onClose(true, props.crt)}>{props.t('commons.yes')}</button>
          <button className="button" onClick={() => props.onClose(false, props.crt)}>{props.t('commons.cancel')}</button>
        </div>
      </footer>
    </div>
  </div>
));

const EMPTY_CRT = {
  id: 0,
  crt_id: "",
  start_date: moment(new Date()).startOf('day').subtract(1, 'days').unix() * 1000,
  end_date: undefined,
  status: CRT_OPEN,
  drivers: []
};


class CrtsTable extends Component {

  state = {
    showDeleteModal: false,
    showEditModal: false,
    currentCrt: null
  };

  onClickDelete = (crt) => {
    this.setState({currentCrt: _.cloneDeep(crt), showDeleteModal: true});
  };

  onClickEdit = (crt) => {
    this.setState({currentCrt: _.cloneDeep(crt), showEditModal: true});
  };

  onClickCreateNew = () => {
    this.setState({currentCrt: _.cloneDeep(EMPTY_CRT), showEditModal: true});
  };

  onClickClose = (crt) => {
    this.setState({currentCrt: _.cloneDeep(crt), showCloseModal: true});
  };

  onCloseDeleteModal = (shouldDelete, crt) => {
    let { fleetDetail, crts } = this.props;

    if (shouldDelete) {
      // remove all vehicle if exist
      _.remove(crts, c => c.id === crt.id);
      // server call
      this.props.deleteCRT(fleetDetail.id, crt);
    }

    this.setState({showDeleteModal: false, crts});
  };

  onCloseCloseModal = (shouldClose, crt) => {
    let { fleetDetail, crts } = this.props;

    if (shouldClose) {
      let originalCrt = _.find(crts, c => c.id === crt.id);
      originalCrt.end_date = moment(new Date()).unix() * 1000;
      originalCrt.status = CRT_CLOSED;
      // server call
      this.props.closeCRT(fleetDetail.id, crt);
    }

    this.setState({showCloseModal: false, crts});
  };

  onCloseEditModal = (shouldEdit, crt) => {
    let { fleetDetail, crts } = this.props;

    if (shouldEdit) {
      // replace edited vehicle
      if (crt.id !== 0) {
        _.remove(crts, v => v.id === crt.id);
      }
      crts.unshift(crt);
      // server call
      this.props.saveCRT(fleetDetail.id, crt);
    }

    this.setState({showEditModal: false, crts});
  };

  onClickDetail = (crt) => {
    let { fleetDetail } = this.props;
    this.props.history.push(`/fleet/${fleetDetail.id}/crt/${crt.id}`);
  };

  render() {
    let { crts } = this.props;
    const { fleetDetail, t } = this.props;
    const { showEditModal, showDeleteModal, showCloseModal, currentCrt } = this.state;

    crts = _.filter(crts, (crt) => (crt.status !== CRT_DELETED));

    return (
      <div>

        {showCloseModal &&
          <CrtModal onClose={this.onCloseCloseModal} crt={currentCrt} localePrefix={"crtsTable.close"}/>
        }

        {showDeleteModal &&
          <CrtModal onClose={this.onCloseDeleteModal} crt={currentCrt} localePrefix={"crtsTable.delete"}/>
        }

        {showEditModal &&
          <EditCrtModal onClose={this.onCloseEditModal} crt={currentCrt} fleeDrivers={fleetDetail.drivers} />
        }

        <div className="vehicles-container">

          {crts.map(((crt, index) => (

            <div className="card vechicle-card" key={'crt' + crt.id}>

              <div className="card-content">

                <div className="columns is-vcentered">

                  <div className="column">
                      <div className="columns is-vcentered has-text-centered">
                        <div className="column is-1">
                          <CrtStatus crt={crt}/>
                        </div>
                        <div className="column is-2">
                          <strong>{crt.crt_id}</strong>
                        </div>
                        <div className="column is-2">
                          <Timestamp time={crt.start_date/1000} format="full"/>
                        </div>
                        <div className="column is-2">
                          {crt.end_date ?
                            <Timestamp time={crt.end_date/1000} format="full"/>
                          :
                            t('commons.never_date')
                          }
                        </div>

                        <div className="column is-4">
                          {crt.drivers.length === 0 &&
                            <span className="tag is-warning">{t('crtsTable.withoutDrivers')}</span>
                          }
                          {crt.drivers.length > 0 &&
                            <div className="field is-grouped is-grouped-multiline">
                              {crt.drivers.map(((driver) => (
                                <div className="control" key={driver.uid}>
                                  <span className="tag is-light">{driver.name}</span>
                                </div>
                              )))}
                            </div>
                          }
                        </div>

                      </div>
                  </div>

                  <div className="column is-2">
                    <div className="field is-grouped is-grouped-centered">
                      <button className="button is-primary is-outlined margin-right-5x" disabled={crt.id === 0}
                         onClick={() => this.onClickDetail(crt)}><i className="fa fa-line-chart"></i></button>
                      <button className="button is-primary is-outlined margin-right-5x" disabled={crt.id === 0}
                         onClick={() => {this.onClickEdit(crt)}}><i className="fa fa-pencil"></i></button>
                      <button className="button is-primary is-outlined margin-right-5x" disabled={crt.id === 0 || crt.status !== CRT_OPEN}
                         onClick={() => this.onClickClose(crt)}><i className="fa fa-lock"></i></button>
                      <button className="button is-primary is-outlined" disabled={crt.id === 0}
                         onClick={() => {this.onClickDelete(crt)}}><i className="fa fa-times"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )))}
        </div>

        <a className="fab button is-success is-pulled-right" onClick={() => {this.onClickCreateNew()}}><img src={plusIcon}/></a>

      </div>
    );
  }
}

export default withRouter(withTranslation('translation')(CrtsTable));
