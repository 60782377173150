import {logoutUser} from "../redux/auth";
import { store } from "../index"

export const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  switch(response.status){
    case 401:
      // logout endpoint call
      // fetch(`${BASE_URL}/api/logout`, {
      //   method: 'GET',
      //   headers: {
      //     'Accept': 'text/html'
      //   }
      // })
      // .then(response => response.text());

    // alert message and redirect to login
    // if (window.confirm('Su sesión ha expirado')) 
    //   {
    //       window.location.href = '/login'
    //   }


      if (window.location.pathname !== '/login') {
        store.dispatch(logoutUser());
        alert("Su sesión ha expirado");
      }

      break;

    case 400:
      throw new Error(`Client error with code: ${response.status}. Message: ${response.statusText}`);

    case 403:
      alert("Usuario o contraseña incorrectos.");
      throw new Error("Usuario o contraseña incorrectos.");

    case 404:
      throw new Error("No se encontro la entidad buscada.");

    default:
      alert("Error desconocido. Por favor contacte a info@woocar.io\nCode: " + response.status + "\nDesc: " + response.statusText);
      throw new Error("Error desconocido, vuelva a intentarlo mas tarde.");
  }
  
};

export const parseJson = response => response.json();
