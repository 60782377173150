import React, {Component} from 'react';
import Loading from '../components/Loading'
import {withTranslation} from "react-i18next";
import {VehiclesTableContainer} from '../containers/VehiclesContainer'

class Vehicles extends Component {

  state = {
    saytInput: "",
    radioSelected: "all"
  };

  componentWillMount() {
    const {fleetDetail} = this.props;
    this.props.fetchVehicles(fleetDetail.id);
  }

  getFilteredVehicle() {
    const {fleetVehicles} = this.props;
    const {saytInput, radioSelected} = this.state;

    let result;

    switch (radioSelected) {
      case "all":
        result = fleetVehicles;
        break;
      case "assigned":
        result = fleetVehicles.filter(v => {return v.drivers.length > 0;});
        break;
       case "notAssigned":
        result = fleetVehicles.filter(v => {return v.drivers.length === 0;});
        break;
    }

    if (saytInput.length < 3) {
      return result;
    }

    return result.filter(v => {
      return (v.name + v.vin + v.plate + v.model).toLocaleLowerCase().includes(saytInput.toLocaleLowerCase());
    });
  }

  render() {
    const { fleetVehicles, t} = this.props;

    if (!fleetVehicles) {
      return (<Loading wait={0}/>);
    }

    return (
      <div className="is-fullwidth">
        <div className="columns">
          <div className="column">
            <div className="field">
              <div className="control">
                <input className="input" type="text" placeholder={t('vehicles.sayt')}
                       onChange={(e) => this.setState({saytInput: e.target.value})} />
              </div>
            </div>
          </div>

          <div className="column is-one-third">
            <div className="control vehicle-filter has-text-centered">
              <label className="radio">
                <input type="radio" name="answer"
                       onChange={(e) => this.setState({radioSelected: 'all'})}
                       checked={this.state.radioSelected === 'all'} /> {t('commons.filter.all')}
              </label>
              <label className="radio">
                <input type="radio" name="answer"
                       onChange={(e) => this.setState({radioSelected: 'assigned'})}
                       checked={this.state.radioSelected === 'assigned'} /> {t('commons.filter.assigned')}
              </label>
              <label className="radio">
                <input type="radio" name="answer"
                       onChange={(e) => this.setState({radioSelected: 'notAssigned'})}
                       checked={this.state.radioSelected === 'notAssigned'} /> {t('commons.filter.notAssigned')}
              </label>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <VehiclesTableContainer vehicles={this.getFilteredVehicle()} />
          </div>
        </div>

      </div>
    );
  }
}

export default withTranslation('translation')(Vehicles);