import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import RoadMapRow from "./RoadMapRow";
import DeleteModal from "./RoadMapDeleteModal"
import EditModal from "./RoadMapEditModal";
import StopoverCommentsModal from './StopoverCommentsModal';
import _ from "lodash";
import Filters from "./RoadMapFilters";
import plusIcon from "../../images/plus.png";
import {EMPTY_ROAD_MAP} from "./constants";
import { BASE_URL } from '../../config';
import { checkStatus, parseJson } from '../../api/utils';

class RoadMapsTable extends Component {

    state = {
        showDeleteModal: false,
        showEditModal: false,
        loadingComments: false,
        showCommentsModal: false,
        comments: null,
        currentRoadMap: null,
        currentStopOver: null,
        saytInput: "",
        fleetId: null,
        roadMaps: null,
        nextCursor: null
    };

    refresh = () => {
        // reloads page
        window.location.reload(false);
      };

    componentDidMount() {
        const {
            fetchRoadMaps,
            fetchStops,
            fetchMarkers,
            fetchVehicles,
            fetchPolygonMarkers,
            fleetId,
            stopsOvers,
            roadMaps,
            markers,
            polygonMarkers,
            vehicles
        } = this.props
        if (!roadMaps) fetchRoadMaps(fleetId)
        if (!stopsOvers) fetchStops(fleetId)
        if (!markers) fetchMarkers(fleetId)
        if (!vehicles) fetchVehicles(fleetId)
        if (!polygonMarkers) fetchPolygonMarkers(fleetId)

        this.setState({fleetId: fleetId})
    }

    onClickDelete = (roadMap) => {
        this.setState({currentRoadMap: _.cloneDeep(roadMap), showDeleteModal: true});
    };

    getFilteredRoadMaps() {
        const { roadMaps } = this.props;
        const { saytInput } = this.state;

        if (!roadMaps) return [];

        if (saytInput.length < 3) {
            return roadMaps.sort((a, b) => b.creation_date - a.creation_date);
        }

        return roadMaps
            .filter(v => {
                return (v.name).toLocaleLowerCase().includes(saytInput.toLocaleLowerCase());
            })
            .sort((a, b) => b.creation_date - a.creation_date);
    }

    fetchMoreRoadmaps = () => {
        const { fetchMoreRoadMaps, fleetId, rmCursor } = this.props;
        fetchMoreRoadMaps(fleetId, rmCursor);
    }

    onChangeSearchFilter = (value) => {
        this.setState({saytInput: value})
    }

    onCloseDeleteModal = (shouldDelete, roadMap) => {
        let {roadMaps, fleetId, deleteRoadMap} = this.props;

        if (shouldDelete) {

            _.remove(roadMaps, v => v.id === roadMap.id);
            // server call
            deleteRoadMap(fleetId, roadMap);
        }

        this.setState({showDeleteModal: false});
    };

    onCloseEditModal = (shouldEdit, roadMap) => {
        let {roadMaps, fleetId, saveRoadMap} = this.props;

        if (shouldEdit) {

            // if roadMap.id == 0, it means this is a new roadmap that's being created since 0 is the default value and a new one will be assigned during creation
            if (roadMap.id !== 0) {
                _.remove(roadMaps, v => v.id === roadMap.id && v.title === roadMap.title );
            }
            roadMaps.unshift(roadMap);
            saveRoadMap(fleetId, roadMap)
        }

        this.setState({showEditModal: false});
    };

    onClickEdit = (roadMap) => {
        this.setState({currentRoadMap: _.cloneDeep(roadMap), showEditModal: true});
    };

    onClickNewRoadMap = () => {
        this.setState({currentRoadMap: _.cloneDeep(EMPTY_ROAD_MAP), showEditModal: true});
    };

    onClickDuplicateRoadMap = (roadMap) => {
        let auxRoadMap = _.cloneDeep(EMPTY_ROAD_MAP)
        auxRoadMap.drivers = roadMap.drivers
        auxRoadMap.start_date = roadMap.start_date
        auxRoadMap.end_date = roadMap.end_date
        auxRoadMap.description = roadMap.description
        auxRoadMap.vehicle = roadMap.vehicle
        auxRoadMap.stopovers = roadMap.stopovers
        auxRoadMap.markers = roadMap.markers
        auxRoadMap.eta = roadMap.eta
        this.setState({currentRoadMap: _.cloneDeep(auxRoadMap), showEditModal: true});
    };

    onClickComments = (stopover_id) => {
        this.setState({ loadingComments: true, showCommentsModal: true })
        let token = localStorage.getItem("token");
        const url = BASE_URL + '/api/stopover/' + stopover_id + '/comments'

        return fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ token
        }
        })
        .then(checkStatus)
        .then(parseJson)
        .then((json) => {
            this.setState({ loadingComments: false, comments: json })
        }).catch(error =>{
            console.log('Error fetching comments: ', error);
            this.setState({ showCommentsModal:false, loadingComments: false})
        })
    };

    onCloseCommentsModal = () => {
        this.setState({ showCommentsModal: false })
    };

    render() {
        const {showDeleteModal, currentRoadMap, showEditModal, showCommentsModal, comments} = this.state
        const { drivers, stopsOvers, markers, vehicles, t } = this.props
        return (
            <div> 
                {showDeleteModal && <DeleteModal onClose={this.onCloseDeleteModal} roadMap={currentRoadMap} />}
                {showEditModal && <EditModal onClose={this.onCloseEditModal} roadMap={currentRoadMap} drivers={drivers} stopsOvers ={stopsOvers} markers={markers} vehicles={vehicles} />}
                {showCommentsModal && <StopoverCommentsModal onClose={this.onCloseCommentsModal} comments={comments} loading={this.state.loadingComments} />}
                <Filters onChange={this.onChangeSearchFilter}/>
              <div> <button className= 'button is-primary' onClick={ this.refresh } align="right">{t('roadMapTable.update')}</button>  </div>
              <br></br>
                {this.getFilteredRoadMaps().map(((rm) => (
                    <RoadMapRow  key={rm.id} onDelete={this.onClickDelete} roadMap={rm} onEdit={this.onClickEdit}
                                 onDuplicate={this.onClickDuplicateRoadMap} fleetId={this.state.fleetId} onComments={this.onClickComments} />
                )))}
              {this.props.rmCursor && <div> <button className= 'button is-primary' onClick={ this.fetchMoreRoadmaps } align="right">Mostrar mas</button>  </div>}
                <a className="fab button is-success is-pulled-right" onClick={() => {this.onClickNewRoadMap()}}><img src={plusIcon}/></a>
            </div>
        );
    }


}

export default withRouter(withTranslation('translation')(RoadMapsTable));
