import React from 'react'
import {render} from 'react-dom'
import {Provider} from 'react-redux'
import {Route, Switch} from 'react-router'
import {BrowserRouter} from 'react-router-dom'
import registerServiceWorker from './registerServiceWorker'
import {applyMiddleware, createStore} from 'redux'
import thunk from 'redux-thunk';
import './index.css'
import App from './App'
import reducers from './redux/reducers'
import {I18nextProvider} from "react-i18next";
import i18next from './i18n';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { CAPTCHA_KEY} from "./config";

export const store = createStore(reducers, applyMiddleware(thunk));

render((
    <GoogleReCaptchaProvider
        reCaptchaKey={CAPTCHA_KEY}
        useEnterprise="true"
        container={{
            element: "recaptcha",
            parameters: {
                badge: 'bottomright'
            }
        }}
    >
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
          <BrowserRouter>
            <Switch>
                <Route path="/" component={App}/>
            </Switch>
          </BrowserRouter>
      </Provider>
    </I18nextProvider>
    </GoogleReCaptchaProvider>
), document.getElementById('root'));

registerServiceWorker();

