// Automatically generated by deployToProd.sh
const firebase_config = {
  apiKey: 'AIzaSyAGbo4ZDI-kWhGUXWE6uEEMeNJlmt8kNyU',
  authDomain: 'woocar-app.firebaseapp.com',
  databaseURL: 'https://woocar-app.firebaseio.com'
};

const auth0_config = {
  domain: 'woocar.auth0.com',
  client_id: '47GCalpkxM54jUxCK5LZYZ9mnO1U43C2',
  redirect_uri: 'https://flotas.woocar.io',
  logoutUrl: 'https://flotas.woocar.io'
};

export const CAPTCHA_KEY = '6LeYuzUpAAAAAAhRPpFSkaKES0Coxx6qwUNU7KjF';

export const BASE_URL = 'https://woocar-app.appspot.com';
export const FIREBASE_CONFIG = firebase_config;
export const AUTH0_CONFIG = auth0_config;
export const STATIC_MAP = '//maps.googleapis.com/maps/api/staticmap?zoom=11&scale=4&size=210x70&maptype=roadmap&key=AIzaSyAGbo4ZDI-kWhGUXWE6uEEMeNJlmt8kNyU';
