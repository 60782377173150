import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import DeleteModal from "./StopOverDeleteModal"
import EditModal from "./StopOverEditModal";
import _ from "lodash";
import Filters from "./StopOversFilters";
import plusIcon from "../../images/plus.png";
import StopOverRow from "./StopOverRow";
import {EMPTY_STOP_OVER} from "./constants";

class StopOversTable extends Component {

    state = {
        showDeleteModal: false,
        showEditModal: false,
        currentStopOver: null,
        saytInput: "",
    };

    onClickDelete = (stopOver) => {
        this.setState({currentStopOver: _.cloneDeep(stopOver), showDeleteModal: true});
    };

    getFilteredStopOvers() {
        const {stopsOvers} = this.props;
        const {saytInput} = this.state;

        if (!stopsOvers) return []

        if (saytInput.length < 3) {
            return stopsOvers.sort((a, b) => b.creation_date - a.creation_date);
        }
        return stopsOvers.filter(s => {
            return (s.address + s.name + s.description).toLocaleLowerCase().includes(saytInput.toLocaleLowerCase());
        })
        .sort((a, b) => b.creation_date - a.creation_date);
    }

    onChangeSearchFilter = (value) => {
        this.setState({saytInput: value})
    }

    onCloseDeleteModal = (shouldDelete, stopOver) => {
        let {stopsOvers, deleteStopOver, fleetId} = this.props;

        if (shouldDelete) {
            _.remove(stopsOvers, v => v.id === stopOver.id);
            // server call
            deleteStopOver(fleetId, stopOver);
        }

        this.setState({showDeleteModal: false});
    };

    onCloseEditModal = (shouldEdit, stopOver) => {
        let {stopsOvers, fleetId, saveStopOver } = this.props;
        if (shouldEdit) {
            if (stopOver.id !== 0) {
                _.remove(stopsOvers, v => v.id === stopOver.id);
            }

            stopsOvers.unshift(stopOver);

            // server call
            saveStopOver(fleetId, stopOver);
        }

        this.setState({showEditModal: false});
    };

    onClickEdit = (stopOver) => {
        this.setState({currentStopOver: _.cloneDeep(stopOver), showEditModal: true});
    };

    onClickNewStopOver = () => {
        this.setState({currentStopOver: _.cloneDeep(EMPTY_STOP_OVER), showEditModal: true});
    };

    onClickDuplicateRoadMap = (stopOver) => {
        let auxStopOver = _.cloneDeep(EMPTY_STOP_OVER)
        auxStopOver.location.lat = stopOver.location.lat
        auxStopOver.location.lon = stopOver.location.lon
        auxStopOver.address = stopOver.address
        auxStopOver.duration = stopOver.duration
        auxStopOver.stopover_type = stopOver.stopover_type
        auxStopOver.client = stopOver.client
        auxStopOver.client_id = stopOver.client_id
        auxStopOver.external_id = stopOver.external_id
        this.setState({currentStopOver: _.cloneDeep(auxStopOver), showEditModal: true});
    };

    render() {
        const {showDeleteModal, currentStopOver, showEditModal} = this.state
        const {should_show_external_ids} = this.props
        return (
            <div>
                {showDeleteModal && <DeleteModal onClose={this.onCloseDeleteModal} stopOver={currentStopOver} />}
                {showEditModal && <EditModal onClose={this.onCloseEditModal} stopOver={currentStopOver} shouldShowExternalIds={should_show_external_ids} />}
                <Filters onChange={this.onChangeSearchFilter}/>
                {this.getFilteredStopOvers().map(((so) => (
                    <StopOverRow  onDelete={this.onClickDelete} stopOver={so} onEdit={this.onClickEdit}
                                 onDuplicate={this.onClickDuplicateRoadMap} key={so.id}/>
                )))}
                <a className="fab button is-success is-pulled-right" onClick={() => {this.onClickNewStopOver()}}><img src={plusIcon}/></a>
            </div>
        );
    }
}

export default withRouter(withTranslation('translation')(StopOversTable));